import "core-js/modules/es.array.concat.js";
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from '../../../components/UI';
import { profileAccountBonusContainer } from './styles';
import NetworkBonus from './Tabs/NetworkBonus';
import MatchingBonus from './Tabs/MatchingBonus';
import DirectSalesBonus from './Tabs/DirectSalesBonus';
import StartupBonus from './Tabs/StartupBonus';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MyBonuses = function MyBonuses() {
  var _useParams = useParams(),
    userId = _useParams.id,
    accountId = _useParams.accountId;
  return ___EmotionJSX("div", {
    className: profileAccountBonusContainer
  }, ___EmotionJSX(Tabs, {
    startingRoute: "/profiles/profile/".concat(userId, "/accounts/").concat(accountId, "/my-bonuses"),
    routeMatch: "/profiles/profile/:id/accounts/:accountId/my-bonuses"
  }, ___EmotionJSX(Tab, {
    label: "Network Bonus",
    url: "/network",
    component: function component() {
      return ___EmotionJSX(NetworkBonus, {
        userId: userId,
        accountId: accountId
      });
    }
  }), ___EmotionJSX(Tab, {
    label: "Direct Sales Bonus",
    url: "/direct",
    component: function component() {
      return ___EmotionJSX(DirectSalesBonus, {
        userId: userId,
        accountId: accountId
      });
    }
  }), ___EmotionJSX(Tab, {
    label: "Matching Bonus",
    url: "/matching",
    component: function component() {
      return ___EmotionJSX(MatchingBonus, {
        userId: userId,
        accountId: accountId
      });
    }
  }), ___EmotionJSX(Tab, {
    label: "Startup Bonus",
    url: "/startup",
    component: function component() {
      return ___EmotionJSX(StartupBonus, {
        userId: userId,
        accountId: accountId
      });
    }
  })));
};
MyBonuses.propTypes = {
  accountId: PropTypes.string,
  userId: PropTypes.string
};
export default MyBonuses;