import { css } from 'emotion';
export var headline = css({
  fontWeight: 600,
  padding: 4
});
export var backContainer = css({
  marginBottom: 48
});
export var paperContainer = css({
  '& > div > *:last-child': {
    marginBottom: 0
  }
});
export var marginBottom32 = css({
  marginBottom: 32
});
export var marginBottom48 = css({
  marginBottom: 48
});
export var imagePreview = css({
  maxWidth: '100%',
  height: 'auto',
  cursor: 'zoom-in'
});
export var otherDocumentsElement = function otherDocumentsElement(theme) {
  return css({
    display: 'block',
    cursor: 'pointer',
    marginBottom: 8,
    padding: 6,
    transition: 'background-color .3s ease-in',
    '& > *': {
      marginBottom: 4,
      '&:last-child': {
        marginBottom: 0
      }
    },
    '&:hover': {
      backgroundColor: theme.whiteGray
    }
  });
};
export var userInfoContain = css({
  paddingLeft: 12,
  marginBottom: 12
});
export var userInfoCard = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 16
});
export var countryImage = css({
  marginRight: 12,
  width: 20,
  height: 20
});
export var countryContainer = css({
  display: 'flex',
  alignItems: 'center',
  padding: 16,
  '& > .flag-img': {
    maxWidth: '24px',
    marginRight: '8px',
    border: '3px solid white'
  }
});
export var containerWithButtons = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '24px 32px 0px 32px',
  marginBottom: 24
});
export var taskContainer = css({
  marginBottom: 48
});
export var supportContainer = css({
  padding: '48px 40px'
});