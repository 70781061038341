import React from 'react';
import { Route } from 'react-router-dom';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { useAuth, Notifications, Menu, SideNav } from '../../components/UI';
import { PrivateRoutes } from '../Private/PrivateRoutes';
import { PublicRoutes } from '../Public/PublicRoutes';
import Unauthorized from '../Public/Unauthorized';
import PackagesPage from '../Private/Packages';
import { OrdersPage } from '../Private/Orders';
import { GiftCodesPage } from '../Private/GiftCodes';
import ProfilesPage from '../Private/Profiles/ProfilesPage';
import AccountsPage from '../Private/Accounts';
import Dashboard from '../Private/Dashboard';
import { TransactionsPage } from '../Private/Transactions';
import NotFound from '../Private/NotFound';
import NotificationsPage from '../Private/Notifications';
import SettingsPage from '../Private/Settings';
import { DepositsPage } from '../Private/Deposits';
import { PlansPage } from '../Private/Plans';
import { KycPage } from '../Private/Kyc';
import { TasksPage } from '../Private/Tasks';
import RecognitionPage from '../Private/Recognition/RecognitionPage';
import ImportExport from '../Private/ImportExport/ImportExport';
import { SubscriptionsPage } from '../Private/Subscriptions';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MAIN_PUBLIC_ROUTE = '/login';
var App = function App() {
  var _useAuth = useAuth(),
    isAuthenticated = _useAuth.isAuthenticated,
    isAuthorized = _useAuth.isAuthorized;
  var isAdmin = hasRole(USER_ROLES.ADMINISTRATOR);
  var isKycOperator = hasRole(USER_ROLES.KYC_OPERATOR);
  var isSupport = hasRole(USER_ROLES.SUPPORT);
  var isTeamLeader = hasRole(USER_ROLES.TEAM_LEADER);
  var isAT = isAdmin || isTeamLeader;
  var isAST = isAdmin || isSupport || isTeamLeader;
  var isAKT = isAdmin || isKycOperator || isTeamLeader;
  var checkHomeRoute = function checkHomeRoute() {
    switch (true) {
      case isAdmin:
        return '/dashboard';
      case isKycOperator:
        return '/profiles';
      case isSupport:
        return '/packages';
      case isTeamLeader:
        return '/packages';
      default:
        return '/';
    }
  };
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Notifications, null), isAuthenticated ? isAuthorized ? ___EmotionJSX(SideNav, {
    homeRoute: checkHomeRoute(),
    routes: PrivateRoutes({
      isAdmin: isAdmin,
      isKycOperator: isKycOperator,
      isSupport: isSupport,
      isTeamLeader: isTeamLeader
    }),
    notFoundComponent: NotFound
  }, isAT && ___EmotionJSX(Menu, {
    withPadding: true,
    url: "/dashboard",
    label: "Dashboard",
    icon: "home",
    component: Dashboard
  }), isAST && ___EmotionJSX(Menu, {
    url: "/packages",
    label: "Packages",
    icon: "pages",
    component: PackagesPage
  }), isAST && ___EmotionJSX(Menu, {
    withPadding: true,
    url: "/orders",
    label: "Orders",
    icon: "shopping_cart",
    component: OrdersPage
  }), isAST && ___EmotionJSX(Menu, {
    withPadding: true,
    url: "/gift-codes",
    label: "Gift codes",
    icon: "fas fa-gifts",
    iconType: {
      material: false
    },
    component: GiftCodesPage
  }), ___EmotionJSX(Menu, {
    url: "/profiles",
    label: "Profiles",
    icon: "perm_identity",
    component: ProfilesPage
  }), ___EmotionJSX(Menu, {
    url: "/accounts",
    label: "Accounts",
    icon: "fas fa-id-card-alt",
    iconType: {
      material: false
    },
    component: AccountsPage
  }), isAKT && ___EmotionJSX(Menu, {
    withPadding: true,
    url: "/kyc",
    label: "KYC",
    icon: "fas fa-file-image",
    iconType: {
      material: false
    },
    component: KycPage
  }), isAST && ___EmotionJSX(Menu, {
    url: "/transactions",
    label: "Transactions",
    icon: "fas fa-hand-holding-usd",
    iconType: {
      material: false
    },
    component: TransactionsPage
  }), isAST && ___EmotionJSX(Menu, {
    url: "/recognition",
    label: "Recognition",
    icon: "fas fa-gift",
    iconType: {
      material: false
    },
    component: RecognitionPage
  }), isAST && ___EmotionJSX(Menu, {
    url: "/deposits",
    label: "Deposits",
    icon: "fas fa-donate",
    iconType: {
      material: false
    },
    component: DepositsPage
  }), isAdmin && ___EmotionJSX(Menu, {
    url: "/plans",
    label: "Plans",
    icon: "fas fa-file-invoice-dollar",
    iconType: {
      material: false
    },
    component: PlansPage
  }), isAST && ___EmotionJSX(Menu, {
    withPadding: true,
    url: "/subscriptions",
    label: "Subscriptions",
    icon: "pages",
    component: SubscriptionsPage
  }), isAT && ___EmotionJSX(Menu, {
    url: "/tasks",
    label: "Tasks",
    icon: "fas fa-tasks",
    iconType: {
      material: false
    },
    component: TasksPage
  }), isAdmin && ___EmotionJSX(Menu, {
    url: "/settings",
    label: "Settings",
    icon: "fas fa-cog",
    iconType: {
      material: false
    },
    component: SettingsPage
  }), isAT && ___EmotionJSX(Menu, {
    url: "/bulk",
    label: "Import / Export",
    icon: "fas fa-upload",
    iconType: {
      material: false
    },
    component: ImportExport
  }), isAT && ___EmotionJSX(Menu, {
    withPadding: true,
    url: "/notifications",
    label: "Notifications",
    icon: "fas fa-comment",
    iconType: {
      material: false
    },
    component: NotificationsPage
  })) : ___EmotionJSX(Route, {
    path: "/",
    component: Unauthorized
  }) : PublicRoutes(MAIN_PUBLIC_ROUTE));
};
export default App;