import { css } from 'emotion';
export var iconInputUpAndDown = css({
  cursor: 'pointer',
  border: 'solid 1px #e9eaf0',
  height: 20,
  padding: '0 2px',
  '&:first-child': {
    borderBottom: 0,
    borderTopRightRadius: 3
  },
  '&:nth-child(2)': {
    borderBottomRightRadius: 3
  }
});
export var counterInput = function counterInput(width) {
  return css({
    fontSize: 14,
    fontWeight: 600,
    color: '#5f636f',
    padding: '7px',
    width: width !== null && width !== void 0 ? width : 40,
    minWidth: 40,
    border: 'solid 1px #e9eaf0',
    borderRadius: 0,
    textAlign: 'center'
  });
};
export var containerInputWrap = css({
  display: 'flex',
  alignItems: 'center'
});