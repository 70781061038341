import { css } from 'emotion';
export var item = function item(hasID, isLastItem, theme) {
  return css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
    padding: 8,
    paddingBottom: 16,
    borderBottom: "1px solid ".concat(theme.borderLight)
  }, hasID && {
    cursor: 'pointer',
    transition: 'background-color .3s ease-in',
    '&:hover': {
      backgroundColor: theme.whiteGray
    }
  }, isLastItem && {
    marginBottom: 0,
    borderBottom: 'none'
  });
};
export var pic = css({
  paddingRight: '1rem',
  width: '2.5rem',
  marginRight: '0.5rem'
});
export var img = css({
  marginRight: '0.5rem'
});
export var title = function title(theme) {
  return css({
    fontWeight: 500,
    color: theme.gray,
    fontSize: '1rem',
    paddingRight: '1.25rem',
    flexGrow: 1,
    transition: 'color 0.3s ease'
  });
};
export var containerHeader = function containerHeader(theme) {
  return css({
    borderBottom: "1px solid ".concat(theme.borderLight),
    padding: '16px 32px'
  });
};