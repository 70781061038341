function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import "core-js/modules/es.array.flat.js";
import "core-js/modules/es.array.unscopables.flat.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { cx } from 'emotion';
import { withTheme } from 'emotion-theming';
import { rippleBaseStyles, animationOn, onFocus } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Ripple = function Ripple(props) {
  var rippleClassName = useRef();
  var onFocusClassName = useRef();
  var children = props.children,
    className = props.className,
    backColor = props.backColor,
    focusColor = props.focusColor,
    flat = props.flat,
    guideId = props.guideId,
    theme = props.theme;
  var guideIdProp = guideId ? {
    'data-guide-id': guideId
  } : {};
  var handleMouseDown = function handleMouseDown(e) {
    e.preventDefault();
    var _e$currentTarget$getB = e.currentTarget.getBoundingClientRect(),
      width = _e$currentTarget$getB.width,
      height = _e$currentTarget$getB.height,
      left = _e$currentTarget$getB.left,
      top = _e$currentTarget$getB.top;
    var size = Math.max(width, height);
    var x = flat ? 0 : parseInt(e.clientX - left - size / 2, 10);
    var y = flat ? 0 : parseInt(e.clientY - top - size / 2, 10);
    rippleClassName.current = animationOn(x, y, size);
    e.currentTarget.classList.add(rippleClassName.current);
    e.currentTarget.focus();
  };
  var handleMouseUp = function handleMouseUp(e) {
    e.preventDefault();
    var elementClass = e.currentTarget.classList;
    setTimeout(function () {
      elementClass.remove(rippleClassName.current);
    }, 180);
  };
  var handleFocus = function handleFocus(e) {
    e.preventDefault();
    onFocusClassName.current = onFocus(theme, focusColor);
    e.currentTarget.classList.add(onFocusClassName.current);
  };
  var handleBlur = function handleBlur(e) {
    e.preventDefault();
    e.currentTarget.classList.remove(onFocusClassName.current);
  };
  return ___EmotionJSX("div", _extends({
    role: "presentation",
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onMouseLeave: handleMouseUp,
    onFocus: handleFocus,
    onBlur: handleBlur,
    className: cx(rippleBaseStyles(theme, backColor, focusColor), className)
  }, guideIdProp), children);
};
Ripple.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  backColor: PropTypes.string,
  focusColor: PropTypes.string,
  flat: PropTypes.bool,
  guideId: PropTypes.string,
  theme: PropTypes.object
};
export default withTheme(Ripple);