import { css } from 'emotion';
export var tableContainer = function tableContainer(className, theme) {
  return css({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.white,
    border: "1px solid ".concat(theme.whiteGrayDark),
    padding: 16
  }, className);
};
export var containerBody = css({
  marginBottom: 15
});
export var filterBarContainer = function filterBarContainer(theme) {
  return css({
    position: 'relative',
    backgroundColor: theme.white,
    padding: 16,
    border: "1px solid ".concat(theme.whiteGrayDark),
    marginBottom: 32
  });
};
export var tableHead = css({
  display: 'flex',
  flexFlow: 'row nowrap',
  cursor: 'pointer'
});
export var tableRow = function tableRow(theme, hasOnClick) {
  return css({
    display: 'flex',
    alignItems: 'center',
    color: theme.grayDark,
    transition: 'background-color .3s ease-in-out',
    '&:hover': {
      backgroundColor: theme.highlights,
      '& i': {
        color: theme.textLightPrimary
      }
    },
    '& i': {
      color: theme.textLightDisabled
    },
    '&:nth-child(2n)': {
      backgroundColor: theme.whiteGrayLight,
      '&:hover': {
        backgroundColor: theme.highlights,
        '& i': {
          color: theme.textLightPrimary
        }
      }
    }
  }, hasOnClick && {
    cursor: 'pointer'
  });
};
export var tableCell = function tableCell(_ref) {
  var minWidth = _ref.minWidth,
    width = _ref.width,
    flex = _ref.flex,
    sm = _ref.sm,
    md = _ref.md,
    lg = _ref.lg,
    xl = _ref.xl;
  return css({
    fontSize: '1rem',
    lineHeight: 1.46,
    letterSpacing: '0.35px',
    flex: flex !== null && flex !== void 0 ? flex : width ? "0 1 ".concat(width, "px") : '1 0 60px',
    minWidth: minWidth !== null && minWidth !== void 0 ? minWidth : "".concat(minWidth, "px"),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 6px',
    outline: 'none',
    wordBreak: 'break-word'
  }, sm && {
    '@media (max-width: 575px)': {
      display: 'none'
    }
  }, md && {
    '@media (max-width: 767px)': {
      display: 'none'
    }
  }, lg && {
    '@media (max-width: 991px)': {
      display: 'none'
    }
  }, xl && {
    '@media (max-width: 1199px)': {
      display: 'none'
    }
  });
};
export var tableHeadCell = function tableHeadCell(options, theme) {
  return css(tableCell(options), {
    fontSize: 12,
    padding: 6,
    fontStyle: 'italic',
    color: theme.grayLighter,
    fontWeight: 500
  }, (options === null || options === void 0 ? void 0 : options.sortable) && {
    cursor: 'default',
    '& b': {
      cursor: 'pointer',
      '&:hover + i': {
        opacity: 1
      }
    }
  });
};
export var tableHeadIcon = function tableHeadIcon(isAsc, isDesc) {
  return css({
    fontSize: 18,
    opacity: isAsc || isDesc ? 1 : 0,
    transform: "rotate(".concat(isDesc ? 0 : 180, "deg)"),
    transition: 'transform 300ms'
  });
};
export var tableCellContent = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '& span': {
    display: 'inline'
  }
});
export var tableCellEmpty = function tableCellEmpty(theme) {
  return css({
    width: 32,
    height: 4,
    backgroundColor: theme.whiteGray,
    borderRadius: 12,
    margin: '0 auto'
  });
};
export var tableCellCheckButton = css({
  '& i': {
    margin: 0
  }
});
export var paginationContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap'
});
export var shownResultsIndicator = function shownResultsIndicator(theme) {
  return css({
    color: theme.textLightSecondary,
    width: 80,
    textAlign: 'center',
    fontSize: 11,
    marginRight: 8
  });
};
export var paginationButton = function paginationButton(disabled, isActive, theme) {
  return css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    width: 32,
    height: 32,
    margin: '0 2px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.whiteGray
    }
  }, isActive && {
    backgroundColor: theme.whiteGray
  }, disabled && {
    cursor: 'not-allowed',
    color: theme.textLightDisabled,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  });
};
export var noResultsContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '80px 20px',
  textAlign: 'center',
  flexDirection: 'column'
});
export var noResultsIcon = function noResultsIcon(theme) {
  return css({
    width: 80,
    height: 80,
    backgroundColor: theme.primaryLight,
    marginBottom: 16,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  });
};
export var noResultsTitle = css({
  fontWeight: 500,
  marginBottom: 12
});
export var noResultsSubtitle = function noResultsSubtitle(theme) {
  return css({
    color: theme.textLightSecondary
  });
};
export var pageSizeDropdown = css({
  width: 80
});
export var tableTitleHeadline = css({
  marginBottom: 48,
  fontWeight: 600
});