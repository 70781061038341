import _fromPairs from "lodash/fromPairs";
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
import React from 'react';
import PropTypes from 'prop-types';
import { colComp } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var COLUMN_DISPLAY_NAME = 'GridColumn';
var Col = function Col(props) {
  var children = props.children,
    role = props.role,
    tabIndex = props.tabIndex,
    onClick = props.onClick,
    onMouseDown = props.onMouseDown,
    onMouseUp = props.onMouseUp,
    onMouseEnter = props.onMouseEnter,
    onMouseLeave = props.onMouseLeave,
    onDrag = props.onDrag;
  var events = [['onClick', onClick], ['onMouseDown', onMouseDown], ['onMouseUp', onMouseUp], ['onMouseEnter', onMouseEnter], ['onMouseLeave', onMouseLeave], ['onDrag', onDrag]].filter(function (el) {
    return Boolean(el[1]);
  });
  if (events.length) events.push(['role', role || 'button'], ['tabIndex', tabIndex || -1]);
  return ___EmotionJSX("div", _extends({
    className: colComp(props)
  }, _fromPairs(events)), children);
};
Col.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  role: PropTypes.string,
  tabIndex: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onDrag: PropTypes.func
};
Col.displayName = COLUMN_DISPLAY_NAME;
export default Col;