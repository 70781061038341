import { css } from 'emotion';
export var item = function item(isLastItem, theme) {
  return css({
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderBottom: "0.07rem dashed ".concat(theme.borderLight),
    '&:first-child': {
      paddingTop: '0'
    }
  }, isLastItem && {
    borderBottom: '0'
  });
};
export var pic = css({
  width: 32,
  height: 32,
  marginRight: 16
});
export var img = css({
  marginRight: '0.5rem',
  borderRadius: 6
});
export var info = css({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: '1.25rem',
  flexGrow: '1'
});
export var username = function username(theme) {
  return css({
    fontWeight: 500,
    fontSize: '1rem',
    color: theme.gray,
    transition: 'color 0.3s ease'
  });
};
export var text = function text(theme) {
  return css({
    fontSize: '1rem',
    margin: 0,
    color: theme.grayLight
  });
};
export var button = function button(theme) {
  return css({
    color: theme.info,
    backgroundColor: theme.infoLight,
    border: "1px solid ".concat(theme.infoLight),
    padding: '0.5rem 1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.875rem',
    lineHeight: 1.5,
    borderRadius: '0.2rem',
    fontWeight: 500,
    '&:focus, &:active, &:hover': {
      backgroundColor: theme.info,
      color: theme.white
    }
  });
};
export var containerHeader = function containerHeader(theme) {
  return css({
    borderBottom: "1px solid ".concat(theme.borderLight),
    padding: '16px 32px'
  });
};