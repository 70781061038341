export var optionsSearchCriteria = [{
  label: 'ID',
  value: 'id'
}, {
  label: 'Nickname',
  value: 'nickName'
}, {
  label: 'Country',
  value: 'profileCountry'
}, {
  label: 'Email',
  value: 'profileEmail'
}, {
  label: 'Phone number',
  value: 'profilePhone'
}
// {
//   label: 'Package',
//   value: 'package',
// },
];