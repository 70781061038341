function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.string.iterator.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Form, FormInput, FormParagraph, CheckBoxForm } from '../../UI';
import { rowComp, colComp } from '../../UI/Grid';
import BackButton from '../../Shared/BackButton';
import { validationsRegexp } from '../../../utils/validations';
import { backContainer, bonusContainer, paperContain } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var testPattern = function testPattern(val, regex) {
  return regex.test(val);
};
var AddEditPlan = function AddEditPlan(props) {
  var headerTitle = props.headerTitle,
    textSubmitButton = props.textSubmitButton,
    handleSubmit = props.handleSubmit,
    _props$values = props.values,
    values = _props$values === void 0 ? {} : _props$values;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(BackButton, {
    className: backContainer,
    title: headerTitle
  }), ___EmotionJSX(Paper, {
    className: paperContain
  }, ___EmotionJSX(Form, {
    onSubmit: function onSubmit(formValues) {
      return handleSubmit(_objectSpread(_objectSpread({}, formValues), {}, {
        compoundPeriod: 'Month'
      }));
    },
    submitButton: {
      children: textSubmitButton
    },
    values: values
  }, ___EmotionJSX(FormParagraph, {
    className: rowComp({
      margin: '0 -16px'
    })
  }, ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 8,
      lg: 8,
      horizontalGap: 16
    })
  }, ___EmotionJSX(FormInput, {
    id: "name",
    label: "Name",
    required: true
  }), ___EmotionJSX(FormInput, {
    id: "duration",
    label: "Duration",
    required: true,
    number: true,
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPattern);
    }
  }), ___EmotionJSX(FormInput, {
    id: "interestRate",
    label: "Interest Rate",
    number: true,
    required: true,
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.pricePattern);
    }
  }), ___EmotionJSX(CheckBoxForm, {
    id: "active",
    label: "Status",
    required: true,
    className: bonusContainer
  }), ___EmotionJSX(CheckBoxForm, {
    id: "visible",
    label: "Visibility",
    required: true,
    className: bonusContainer
  }))))));
};
AddEditPlan.propTypes = {
  headerTitle: PropTypes.string,
  textSubmitButton: PropTypes.string,
  handleSubmit: PropTypes.func,
  values: PropTypes.object
};
export default AddEditPlan;