import { css } from 'emotion';
export var afterChange = function afterChange(theme) {
  return css({
    color: theme.textSecondary,
    fontWeight: 500
  });
};
export var ipAddressColumn = css({
  display: 'flex',
  flexDirection: 'column'
});
export var locationColumn = css({
  display: 'flex',
  flexDirection: 'column'
});
export var marginBottom = function marginBottom(val) {
  return css({
    paddingBottom: val
  });
};
export var backContainerWithButton = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 48
});
export var noTitleContent = css({
  padding: '48px 40px'
});
export var loginHistoryMessage = css({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 20,
  fontSize: 18
});