import { css } from 'emotion';
var itemHeight = 51;
export var dropDownContainer = function dropDownContainer(disabled, className) {
  return css({
    position: 'relative',
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.3 : 1,
    outline: 'none',
    fontSize: '14px',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }, className);
};
export var dropDownSelect = function dropDownSelect(isOpened, isSelected, small, theme, className, hasError) {
  return css({
    border: hasError ? "1px solid ".concat(theme.error) : "1px solid ".concat(isOpened ? theme.primary : theme.whiteGray),
    borderRadius: isOpened ? '4px 4px 0 0' : '4px',
    boxShadow: isOpened ? '0 1px 4px 0 rgba(162, 163, 166, 0.4)' : 'none',
    backgroundColor: hasError ? theme.errorLight : theme.whiteGrayLight,
    padding: 12,
    color: isSelected ? theme.gray : theme.grayLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }, small && {
    padding: '6px 12px'
  }, className);
};
export var dropDownText = function dropDownText(isSelected) {
  return css({
    userSelect: isSelected ? 'text' : 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  });
};
export var dropDownOptionsContainer = function dropDownOptionsContainer(isOpened, numberOfItems, maxNumber, onTop, onLeft, theme) {
  return css({
    position: 'absolute',
    zIndex: 40,
    color: theme.textLightSecondary,
    minWidth: '100%',
    height: 'auto',
    maxHeight: numberOfItems > maxNumber ? maxNumber * itemHeight - itemHeight / 2 : 'none',
    overflowY: 'auto',
    backgroundColor: theme.white,
    transform: "scaleY(".concat(isOpened ? 1 : 0, ")"),
    transformOrigin: onTop ? 'bottom' : 'top',
    transition: 'transform 400ms ease-in-out',
    cursor: 'default'
  }, onTop ? {
    bottom: '100%',
    boxShadow: '0 -2px 4px rgba(0,0,0,0.3)',
    borderBottom: isOpened ? '1px solid #DDDDDD' : 'none',
    borderRadius: '4px 4px 0 0'
  } : {
    top: '100%',
    boxShadow: '0 4px 4px rgba(0,0,0,0.3)',
    borderTop: isOpened ? '1px solid #DDDDDD' : 'none',
    borderRadius: '0 0 4px 4px'
  }, onLeft && {
    right: 0
  });
};
export var dropDownOptionItem = function dropDownOptionItem(isSelected, hasRender, small, theme) {
  return css({
    backgroundColor: isSelected ? theme.error : theme.white,
    color: isSelected ? theme.white : '',
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: isSelected ? theme.error : theme.whiteGrayLight
    },
    '&:focus': {
      outline: 'none'
    },
    '& i': {
      marginRight: 8
    }
  }, !hasRender && {
    padding: '16px 32px'
  }, small && {
    padding: '8px 16px'
  });
};
export var dropDownGroupLabel = function dropDownGroupLabel(theme) {
  return css({
    margin: 0,
    padding: 12,
    cursor: 'default',
    color: theme.textLightSecondary,
    fontSize: 14,
    fontWeight: 400
  });
};
export var rightIconsContainer = css({
  display: 'flex',
  alignItems: 'center'
});
export var dropDownRightIcon = function dropDownRightIcon(isOpened) {
  return css({
    fontSize: 19,
    fontWeight: 600,
    transform: "rotate(".concat(isOpened ? -180 : 0, "deg)"),
    transition: 'transform 0.35s ease-out',
    cursor: 'pointer',
    display: 'inline-flex'
  });
};
export var dropDownDeleteIcon = function dropDownDeleteIcon(theme) {
  return css({
    display: 'inline-flex',
    fontSize: 17,
    height: 17,
    cursor: 'pointer',
    color: theme.textLightSecondary,
    '&:hover': {
      color: theme.textLightPrimary
    }
  });
};
export var dropdownSearch = function dropdownSearch(theme) {
  return css({
    position: 'sticky',
    top: 0,
    backgroundColor: theme.white,
    borderBottom: "1px solid ".concat(theme.grayLight)
  });
};
export var noResultsContainer = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 8px'
});
export var noResultsIcon = function noResultsIcon(theme) {
  return css({
    backgroundColor: theme.info,
    borderRadius: '50%',
    padding: 8
  });
};
export var infiniteScroll = css({
  padding: 16,
  display: 'flex',
  justifyContent: 'center'
});