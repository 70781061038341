import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import _isNaN from "lodash/isNaN";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.is-array.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Input } from '../../UI';
import { iconInputUpAndDown, counterInput, containerInputWrap } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CounterInput = function CounterInput(props) {
  var count = props.count,
    _props$max = props.max,
    max = _props$max === void 0 ? Infinity : _props$max,
    _props$min = props.min,
    min = _props$min === void 0 ? -Infinity : _props$min,
    width = props.width,
    onCountChange = props.onCountChange;
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    inputValue = _useState2[0],
    setInputValue = _useState2[1];
  useEffect(function () {
    setInputValue(count);
  }, [count]);
  var handleValueDecrement = function handleValueDecrement() {
    if (inputValue > min) {
      var decrementedValue = inputValue - 1;
      setInputValue(decrementedValue);
      onCountChange(decrementedValue);
    }
  };
  var handleValueIncrement = function handleValueIncrement() {
    if (inputValue < max) {
      var incrementedValue = inputValue + 1;
      setInputValue(incrementedValue);
      onCountChange(incrementedValue);
    }
  };
  var handleBlur = function handleBlur() {
    var num = inputValue;
    num = num > max ? max : num;
    num = num < min ? min : num;
    setInputValue(num);
    onCountChange(num);
  };
  var handleChangeInput = function handleChangeInput(_ref) {
    var value = _ref.target.value;
    var newValue = Number(value);
    if (!_isNaN(newValue)) {
      setInputValue(newValue);
    }
  };
  return ___EmotionJSX("div", {
    className: containerInputWrap
  }, ___EmotionJSX(Input, {
    className: counterInput(width),
    value: inputValue,
    onChange: handleChangeInput,
    onBlur: handleBlur
  }), ___EmotionJSX("div", null, ___EmotionJSX("div", {
    className: iconInputUpAndDown
  }, ___EmotionJSX(Icon, {
    material: true,
    iconName: "keyboard_arrow_up",
    color: "textLightSecondary",
    onClick: handleValueIncrement
  })), ___EmotionJSX("div", {
    className: iconInputUpAndDown
  }, ___EmotionJSX(Icon, {
    material: true,
    iconName: "keyboard_arrow_down",
    color: "textLightSecondary",
    onClick: handleValueDecrement
  }))));
};
CounterInput.propTypes = {
  count: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  width: PropTypes.number,
  onCountChange: PropTypes.func
};
export default CounterInput;