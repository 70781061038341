import { css } from 'emotion';
export var otherDocumentsElement = function otherDocumentsElement(theme) {
  return css({
    cursor: 'pointer',
    marginBottom: 8,
    padding: 6,
    transition: 'background-color .3s ease-in',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      marginBottom: 4,
      '&:last-child': {
        marginBottom: 0
      }
    },
    '&:hover': {
      backgroundColor: theme.whiteGray
    }
  });
};
export var containerDocuments = css({
  padding: '0px 18px'
});
export var headlineKycDoc = css({
  margin: '12px 0px'
});
export var documentsKyc = function documentsKyc(theme) {
  return css({
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      marginRight: 24,
      color: theme.warning,
      fontWeight: 800
    }
  });
};
export var statusVip = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& > *': {
    marginBottom: 6
  }
});
export var dateDoc = css({
  marginLeft: 4
});