import { css } from 'emotion';
export var pictureContainer = css({
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  borderRadius: '50%',
  marginRight: 12
});
export var shoppingCartItem = function shoppingCartItem(theme) {
  return css({
    backgroundColor: theme.white,
    padding: '24px 40px 24px 24px',
    '&:last-child': {
      borderBottom: 0
    }
  });
};
export var shoppingCartPackageName = function shoppingCartPackageName(theme) {
  return css({
    fontWeight: 500,
    color: theme.grayDark,
    marginBottom: 8,
    marginTop: 8
  });
};
export var shoppingCartPackageCheckbox = css({
  display: 'flex',
  alignItems: 'center'
});
export var shoppingCartCheckboxPercent = function shoppingCartCheckboxPercent(theme) {
  return css({
    fontSize: 14,
    fontWeight: 500,
    color: theme.grayLighter
  });
};
export var shoppingCartPackageWrap = css({
  minWidth: 100
});
export var shoppingCartPackagePrice = function shoppingCartPackagePrice(theme) {
  return css({
    fontSize: 18,
    color: theme.grayDark
  });
};