import "core-js/modules/es.array.concat.js";
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import dots from '../../../assets/images/dots.svg';
import { Image } from '../../UI';
import { networkTableActionColButton } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkTableActionCol = function NetworkTableActionCol(props) {
  var userId = props.userId,
    id = props.id;
  var history = useHistory();
  var handleIconClick = function handleIconClick() {
    history.push("/profiles/profile/".concat(userId, "/accounts/").concat(id));
  };
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: networkTableActionColButton,
    onClick: handleIconClick
  }, ___EmotionJSX(Image, {
    src: dots,
    width: 50,
    height: 50
  }));
};
NetworkTableActionCol.propTypes = {
  userId: PropTypes.string,
  id: PropTypes.number
};
export default NetworkTableActionCol;