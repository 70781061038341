import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Col, Row } from '../../UI';
import { currencyByType } from '../../../utils';
import { type as walletType } from '../../../enums/wallets';
import { ReactComponent as RightLegIcn } from '../../../assets/images/icn-rightleg.svg';
import { ReactComponent as LeftLegIcn } from '../../../assets/images/icn-leftleg.svg';
import { ReactComponent as LinkArrowIcn } from '../../../assets/images/link-arrow-right.svg';
import { bvPointsPackageInfo, bvPointsTable, bvPointsTableBonusRow, bvPointsTableHeaderRow, bvPointsTableBodyRow, bvPointsTableLabelTd, bvPointsTableLeftLegArrow, bvPointsTableLeftTd, bvPointsTableLegTh, bvPointsTableLink, bvPointsTableRightLegArrow, bvPointsTableRightTd, bvPointsTableLabelTh } from './styles';
import { getBvPointsTableRows } from './config';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkBonusBvPointsTable = function NetworkBonusBvPointsTable(props) {
  var _content$totalBv, _content$packageInfoF;
  var content = props.content,
    isCurrentRound = props.isCurrentRound,
    detailsRootPath = props.detailsRootPath;
  var theme = useTheme();
  return ___EmotionJSX("div", null, ___EmotionJSX("div", {
    className: bvPointsTable(theme)
  }, ___EmotionJSX(Row, {
    className: bvPointsTableHeaderRow(theme),
    align: "center",
    gap: 3
  }, ___EmotionJSX(Col, {
    className: bvPointsTableLabelTh
  }, ___EmotionJSX("span", null, "BV Points")), ___EmotionJSX(Col, {
    className: bvPointsTableLegTh
  }, ___EmotionJSX("div", {
    className: bvPointsTableLeftLegArrow
  }, ___EmotionJSX(LeftLegIcn, null)), ___EmotionJSX("span", null, currencyByType((_content$totalBv = content === null || content === void 0 ? void 0 : content.totalBv) !== null && _content$totalBv !== void 0 ? _content$totalBv : 0, walletType.BV)), ___EmotionJSX("div", {
    className: bvPointsTableRightLegArrow
  }, ___EmotionJSX(RightLegIcn, null)))), getBvPointsTableRows(content).map(function (row) {
    return ___EmotionJSX("div", {
      className: "bvPointsTableRow",
      key: row.label
    }, ___EmotionJSX(Row, {
      className: "".concat(bvPointsTableBodyRow(theme)),
      justify: "flex-start"
    }, ___EmotionJSX(Col, {
      className: bvPointsTableLabelTd
    }, ___EmotionJSX("span", null, row.label)), ___EmotionJSX(Col, {
      className: row.isSingleValue ? bvPointsTableBonusRow : bvPointsTableLeftTd
    }, ___EmotionJSX("span", null, row.leftData)), ___EmotionJSX(Col, {
      className: bvPointsTableRightTd
    }, !row.isSingleValue && ___EmotionJSX("span", null, row.rightData))));
  })), !(content !== null && content !== void 0 && (_content$packageInfoF = content.packageInfoForRound) !== null && _content$packageInfoF !== void 0 && _content$packageInfoF.hasNetworkBonus || true) && ___EmotionJSX("div", {
    className: bvPointsPackageInfo(theme)
  }, isCurrentRound ? ___EmotionJSX("p", null, "The current package of this account does not allow network bonus!") : ___EmotionJSX("p", null, "The redeemed package in this round did not allow network bonus!")), (content === null || content === void 0 ? void 0 : content.id) && ___EmotionJSX("div", {
    className: bvPointsTableLink
  }, ___EmotionJSX(Link, {
    to: {
      pathname: "".concat(detailsRootPath, "/details/").concat(content.id),
      state: {
        isCurrentRound: isCurrentRound
      }
    }
  }, "Details", ___EmotionJSX(LinkArrowIcn, null))));
};
NetworkBonusBvPointsTable.propTypes = {
  content: PropTypes.any,
  isCurrentRound: PropTypes.bool,
  detailsRootPath: PropTypes.string
};
export default NetworkBonusBvPointsTable;