import { css } from 'emotion';
export var amountLabel = function amountLabel(isNegative, theme, defaultColor) {
  return css({
    fontWeight: 600,
    color: defaultColor ? 'none' : isNegative ? theme.error : theme.success,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  });
};
export var walletAmountContainer = css({
  marginLeft: 'auto',
  textAlign: 'right',
  '& span': {
    display: 'inline',
    verticalAlign: 'top'
  }
});
export var walletAmountType = css({
  textAlign: 'right',
  textTransform: 'capitalize'
});