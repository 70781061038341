import { orderStatus, orderItemsType } from '../../../enums/orders';
export var optionsOrderSearchBy = [{
  value: 'accountNickName',
  label: 'Nickname'
}, {
  value: 'profileEmail',
  label: 'Email'
}, {
  value: 'id',
  label: 'Order ID'
}];
export var optionsOrderStatus = [{
  label: 'Pending',
  value: orderStatus.Pending
}, {
  label: 'Completed',
  value: orderStatus.Paid
}, {
  label: 'Rejected',
  value: orderStatus.Rejected
}];
export var orderItemsTypes = [{
  label: 'Only with packages',
  value: orderItemsType.Package
}, {
  label: 'Only with subscriptions',
  value: orderItemsType.Subscription
}];