import { css } from 'emotion';
export var importFileContainer = css({
  padding: '0px 16px'
});
export var checkButtonContainer = css({
  marginBottom: 16,
  display: 'flex',
  justifyContent: 'space-between'
});
export var actionButtons = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '30px'
});