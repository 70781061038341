import React from 'react';
import { softLogout } from '@oneecosystem/authenticate';
import { Button } from '../../../components/UI';
import { mainContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Unauthorized = function Unauthorized() {
  return ___EmotionJSX("div", {
    className: mainContainer
  }, ___EmotionJSX("h1", null, "403"), ___EmotionJSX("h2", null, "Access forbidden"), ___EmotionJSX(Button, {
    outline: true,
    large: true,
    onClick: softLogout
  }, "Logout"));
};
export default Unauthorized;