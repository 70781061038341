function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.some.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import { add } from 'exact-math';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Row, Col } from '../../UI';
import { type } from '../../../enums/wallets';
import { currencyByType } from '../../../utils';
import { summaryBoxWrap } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var OrderSummaryBox = function OrderSummaryBox(props) {
  var orderItems = props.orderItems;
  var theme = useTheme();
  var calculatePackagePrice = function calculatePackagePrice(el) {
    return el.withActivationFee ? add(el.price, 30) : el.price;
  };
  var areSamePackages = function areSamePackages(el1, el2) {
    return el1.name === el2.name && el1.withActivationFee === el2.withActivationFee;
  };
  var packageSummary = orderItems === null || orderItems === void 0 ? void 0 : orderItems.reduce(function (acc, el) {
    return acc.some(function (item) {
      return areSamePackages(el, item);
    }) ? acc.map(function (item) {
      return areSamePackages(el, item) ? _objectSpread(_objectSpread({}, item), {}, {
        amount: item.amount + 1,
        total: add(item.total, calculatePackagePrice(el))
      }) : item;
    }) : acc.concat(_objectSpread(_objectSpread({}, el), {}, {
      amount: 1,
      total: calculatePackagePrice(el)
    }));
  }, []);
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Row, {
    container: true,
    noWrap: true,
    gap: 8
  }, ___EmotionJSX(Col, {
    xs: 4,
    lg: 4,
    className: summaryBoxWrap(theme)
  }, ___EmotionJSX("h2", null, "Name")), ___EmotionJSX(Col, {
    xs: 4,
    lg: 4,
    className: summaryBoxWrap(theme)
  }, ___EmotionJSX("h2", null, "Amount")), ___EmotionJSX(Col, {
    xs: 4,
    lg: 4,
    className: summaryBoxWrap(theme)
  }, ___EmotionJSX("h2", null, "Total"))), packageSummary === null || packageSummary === void 0 ? void 0 : packageSummary.map(function (item, ind) {
    return ___EmotionJSX(Row, {
      container: true,
      noWrap: true,
      gap: 8,
      key: ind
    }, ___EmotionJSX(Col, {
      lg: 4,
      className: summaryBoxWrap(theme)
    }, ___EmotionJSX("p", null, item.name)), ___EmotionJSX(Col, {
      lg: 4,
      className: summaryBoxWrap(theme)
    }, ___EmotionJSX("p", null, item.amount)), ___EmotionJSX(Col, {
      lg: 4,
      className: summaryBoxWrap(theme)
    }, ___EmotionJSX("p", null, currencyByType(item.total, type.CASH))));
  }));
};
OrderSummaryBox.propTypes = {
  orderItems: PropTypes.array
};
export default OrderSummaryBox;