import { css } from 'emotion';
export var bvPointsTable = function bvPointsTable(theme) {
  return css({
    width: '100%',
    '& .bvPointsTableRow': {
      '&:nth-child(even)': {
        backgroundColor: theme.backgroundLightGray
      },
      '&:nth-child(odd)': {
        backgroundColor: theme.white
      }
    }
  });
};
export var bvPointsTableHeaderRow = function bvPointsTableHeaderRow(theme) {
  return css({
    fontSize: '16px',
    letterSpacing: '-0.17px',
    color: theme.grayDark,
    fontWeight: '400',
    padding: '0 16px 32px 16px'
  });
};
export var bvPointsTableBodyRow = function bvPointsTableBodyRow(theme) {
  return css({
    flexWrap: 'nowrap',
    fontSize: '14px',
    letterSpacing: '-0.41px',
    color: theme.gray,
    fontWeight: '600',
    padding: '8px 16px'
  });
};
export var bvPointsTableLabelTh = css({
  fontSize: '19px',
  letterSpacing: '0.2px',
  flexBasis: '40%'
});
export var bvPointsTableLegTh = css({
  flexBasis: '60%',
  position: 'relative',
  fontSize: '19px',
  letterSpacing: 'normal',
  textAlign: 'center',
  paddingRight: '30px'
});
export var bvPointsTableLeftLegArrow = css({
  position: 'absolute',
  left: '16px',
  top: 0
});
export var bvPointsTableRightLegArrow = css({
  position: 'absolute',
  right: '40px',
  top: 0
});
export var bvPointsTableLabelTd = css({
  fontSize: '13px',
  letterSpacing: '0.2px',
  fontWeight: '400',
  flexBasis: '40%'
});
export var bvPointsTableLeftTd = css({
  flexBasis: '30%'
});
export var bvPointsTableRightTd = css({
  textAlign: 'right',
  paddingRight: '20px',
  flexBasis: '30%'
});
export var bvPointsPackageInfo = function bvPointsPackageInfo(theme) {
  return css({
    padding: '12px',
    marginTop: '24px',
    border: "2px solid ".concat(theme.whiteGray),
    textAlign: 'center'
  });
};
export var bvPointsTableLink = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '32px 32px 0 32px',
  '& svg': {
    opacity: '0.3',
    marginLeft: '8px'
  },
  '&:hover': {
    '& svg': {
      transition: 'opacity 0.3s ease',
      opacity: 1
    }
  }
});
export var bvPointsTableBonusRow = css({
  fontSize: '13px',
  letterSpacing: '0.2px',
  fontWeight: '600',
  textAlign: 'right',
  flexBasis: '30%'
});