function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { css } from '@emotion/react';
export var revertButtonsContainer = process.env.NODE_ENV === "production" ? {
  name: "1eoy87d",
  styles: "display:flex;justify-content:space-between"
} : {
  name: "1f14jyj-revertButtonsContainer",
  styles: "display:flex;justify-content:space-between;label:revertButtonsContainer;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9hcHAvc3JjL2NvbXBvbmVudHMvR2lmdENvZGVzL1JldmVydEdpZnRDb2RlTW9kYWwvc3R5bGVzLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVzQyIsImZpbGUiOiIvYXBwL3NyYy9jb21wb25lbnRzL0dpZnRDb2Rlcy9SZXZlcnRHaWZ0Q29kZU1vZGFsL3N0eWxlcy5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB7IGNzcyB9IGZyb20gJ0BlbW90aW9uL3JlYWN0JztcblxuZXhwb3J0IGNvbnN0IHJldmVydEJ1dHRvbnNDb250YWluZXIgPSBjc3Moe1xuICBkaXNwbGF5OiAnZmxleCcsXG4gIGp1c3RpZnlDb250ZW50OiAnc3BhY2UtYmV0d2VlbicsXG59KTsiXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};