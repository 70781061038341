import { css } from 'emotion';
export var formGroup = function formGroup(className, theme) {
  return css({
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    fontWeight: 600,
    color: theme.gray
  }, className);
};
export var formLabel = function formLabel(theme) {
  return css({
    fontWeight: 600,
    lineHeight: 1,
    fontSize: 13,
    color: theme.grayLight,
    display: 'block',
    marginBottom: 8
  });
};