import React from 'react';
import { Tabs, Tab } from '../../../components/UI';
import ImportPage from './ImportPage';
import ExportPage from './ExportPage';
import { tabContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ImportExportPage = function ImportExportPage() {
  return ___EmotionJSX(Tabs, {
    startingRoute: "/bulk",
    tabsContentClass: tabContainer
  }, ___EmotionJSX(Tab, {
    label: "Import",
    url: "/import",
    component: function component() {
      return ___EmotionJSX(ImportPage, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "Export",
    url: "/export",
    component: function component() {
      return ___EmotionJSX(ExportPage, null);
    }
  }));
};
export default ImportExportPage;