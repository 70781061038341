import { css } from 'emotion';
export var tabContainer = css({
  padding: '48px 40px'
});
export var backContainer = css({
  marginBottom: 48
});
export var paperContainer = css({
  '& > div > *:last-child': {
    marginBottom: 0
  }
});
export var transactionInfoContainer = css({
  paddingLeft: 12,
  marginBottom: 32
});
export var scrollContainer = css({
  '& > div': {
    maxHeight: 'calc(100vh - 240px)',
    overflowY: 'auto',
    minHeight: '100%'
  }
});
export var labelContainer = css({
  fontWeight: 600,
  padding: '12px 6px'
});
export var valueContainer = css({
  padding: 12
});
export var description = css({
  fontWeight: 600,
  padding: '12px 6px',
  fontSize: 15
});
export var backContainerWithButton = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 48,
  '& > h1': {
    marginBottom: 0,
    fontWeight: 600
  }
});
export var userInfoContainer = css({
  marginBottom: 32,
  paddingLeft: 12
});
export var transactionIdStyle = function transactionIdStyle(theme) {
  return css({
    color: theme.textSecondary
  });
};
export var dateAndTimeStyles = function dateAndTimeStyles(theme) {
  return css({
    color: theme.gray
  });
};
export var header = css({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between'
});
export var buttonContainer = css({
  display: 'flex',
  justifyContent: 'space-between'
});
export var descriptionModal = css({
  marginBottom: 32
});