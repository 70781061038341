import "core-js/modules/es.number.constructor.js";
import React from 'react';
import PropTypes from 'prop-types';
import { GroupOfRows, Head } from '../../../../../components/Profiles/MatchingTree';
import { Breadcrumbs } from '../../../../../components/UI';
import BackButton from '../../../../../components/Shared/BackButton';
import { matchingTree, tableDataBody, tableDataBodyWrap } from './styles';
import { withBreadcrumbs, breadcrumbs } from '../../styles';
import { myMatchingTreeBreadcrumbs } from '../../config';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MatchingTree = function MatchingTree(props) {
  var accountId = props.accountId,
    userId = props.userId;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Breadcrumbs, {
    items: myMatchingTreeBreadcrumbs(userId),
    className: breadcrumbs
  }), ___EmotionJSX("div", {
    className: withBreadcrumbs
  }, ___EmotionJSX(BackButton, {
    title: "Network of People"
  })), ___EmotionJSX("div", {
    className: matchingTree
  }, ___EmotionJSX("div", {
    className: tableDataBodyWrap
  }, ___EmotionJSX("div", {
    className: tableDataBody
  }, ___EmotionJSX(Head, null), ___EmotionJSX(GroupOfRows, {
    accountId: Number(accountId),
    level: 1
  })))));
};
MatchingTree.propTypes = {
  accountId: PropTypes.string,
  userId: PropTypes.string
};
export default MatchingTree;