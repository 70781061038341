import { css } from 'emotion';
export var packageListContainer = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});
export var pictureContainer = css({
  borderRadius: '50%',
  marginRight: 18,
  flexBasis: 70,
  flexShrink: 0
});
export var packageInfo = css({
  display: 'inline-block',
  marginBottom: 4,
  marginRight: 8
});
export var infoContainer = css({
  marginRight: 18,
  fontWeight: 600,
  '& > span': {
    fontWeight: 400,
    marginLeft: 6
  }
});