import { css } from 'emotion';
export var tabContainer = css({
  padding: '48px 40px'
});
export var headContainer = css({
  padding: '48px 40px 0'
});
export var backContainer = css({
  marginBottom: 48
});
export var assigneeToImageContainer = css({
  width: 30,
  height: 'auto',
  maxHeight: 30,
  borderRadius: '50%',
  marginRight: 12
});
export var assigneeContainer = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: 2,
  padding: '6px 0px',
  cursor: 'pointer'
});
export var nameContainer = css({
  marginRight: 12
});
export var statsInformationContainer = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: 32
});
export var statsPaperContainer = css({
  padding: 0
});
export var statsMissedDailyGoals = function statsMissedDailyGoals(theme) {
  return css({
    padding: '6px 12px',
    width: 250,
    color: theme.error
  });
};
export var titleOutstandingGoals = css({
  margin: '12px 0'
});