import { css } from 'emotion';
export var container = function container(className) {
  return css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }, className);
};
export var pictureContainer = css({
  borderRadius: '50%',
  marginRight: 18,
  flexBasis: 70,
  flexShrink: 0
});
export var userInfo = css({
  display: 'inline-block',
  marginBottom: 4,
  marginRight: 8
});
export var statusContainer = css({
  padding: '2px 4px',
  borderRadius: 3
});
export var infoContainer = css({
  marginRight: 5,
  fontWeight: 600,
  '& > span': {
    fontWeight: 400,
    marginLeft: 6
  }
});
export var marginBottom4 = css({
  marginBottom: 4
});