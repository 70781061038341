import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import _orderBy from "lodash/orderBy";
import _isArray from "lodash/isArray";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.map.js";
import React, { useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Button, Icon } from '../../UI';
import { buttonInfo, definition, descriptionHistory, descriptionContainer, infoArrow, buttonContainer, userReasonStyles } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TransactionDescriptions = function TransactionDescriptions(props) {
  var description = props.description,
    reason = props.reason,
    userReason = props.userReason,
    externalTopupHash = props.externalTopupHash;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showDescription = _useState2[0],
    setShowDescription = _useState2[1];
  var theme = useTheme();
  var handleReadMore = function handleReadMore() {
    return setShowDescription(true);
  };
  return ___EmotionJSX("div", {
    className: descriptionContainer
  }, externalTopupHash && ___EmotionJSX("p", {
    className: userReasonStyles
  }, "CP Hash:", ___EmotionJSX("span", null, externalTopupHash)), userReason && ___EmotionJSX("p", {
    className: userReasonStyles
  }, "User Reason:", ___EmotionJSX("span", null, userReason)), ___EmotionJSX("p", {
    className: definition
  }, "Explanation:", ___EmotionJSX("span", null, reason)), _isArray(description) && description.length > 0 && ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", {
    className: definition
  }, "Description:", ___EmotionJSX("span", null, _orderBy(description, 'createdOn', 'desc')[0].description)), showDescription && ___EmotionJSX("div", {
    className: descriptionHistory
  }, ___EmotionJSX("p", null, "Description history"), ___EmotionJSX("ol", null, description.map(function (el) {
    return ___EmotionJSX("li", {
      key: el.createdOn
    }, el.description);
  }))), !showDescription && ___EmotionJSX("div", {
    className: buttonContainer
  }, ___EmotionJSX(Button, {
    small: true,
    info: true,
    className: buttonInfo(theme),
    onClick: handleReadMore
  }, "Read More"), ___EmotionJSX(Icon, {
    material: true,
    iconName: "keyboard_arrow_down",
    className: infoArrow(theme)
  }))));
};
TransactionDescriptions.propTypes = {
  description: PropTypes.any,
  reason: PropTypes.string,
  userReason: PropTypes.string,
  externalTopupHash: oneOfType([PropTypes.number, PropTypes.string])
};
export default TransactionDescriptions;