import _isNumber from "lodash/isNumber";
import _invert from "lodash/invert";
import _startCase from "lodash/startCase";
import "core-js/modules/es.array.concat.js";
import { kycStatus } from '../../../enums/kyc';
var generalData = [{
  label: 'Type',
  value: 'type',
  startCase: true
}, {
  label: 'Sub Type',
  value: 'subType',
  startCase: true
}, {
  label: 'Created On',
  value: 'createdOn',
  date: true
}, {
  label: 'VIP State',
  render: function render(doc) {
    return doc ? doc.isVip ? "VIP / ".concat(doc.vipState) : 'Non VIP' : 'N/A';
  }
}, {
  label: 'State',
  render: function render(doc) {
    return _isNumber(doc === null || doc === void 0 ? void 0 : doc.state) ? _startCase(_invert(kycStatus)[doc === null || doc === void 0 ? void 0 : doc.state]) : 'N/A';
  }
}, {
  label: 'Reviewed On',
  value: 'reviewedOn',
  dateTime: true
}, {
  label: 'Reviewed By',
  value: 'reviewedBy'
}];
export var idProofInfo = [{
  label: 'Passport number',
  value: 'documentNumber'
}, {
  label: 'Date of birth',
  value: 'dateOfBirth',
  date: true
}, {
  label: 'Date of issue',
  value: 'issuedAtDate',
  date: true
}, {
  label: 'Date of expiry',
  value: 'expirationDate',
  date: true
}].concat(generalData);
export var addressProofInfo = [{
  label: 'Full Name',
  value: 'profile.fullName'
}, {
  label: 'Address',
  value: 'address'
}, {
  label: 'Country',
  value: 'country'
}, {
  label: 'City',
  value: 'city'
}, {
  label: 'Postal code',
  value: 'zip'
}].concat(generalData);