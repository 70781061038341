function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _omit from "lodash/omit";
import _isArray from "lodash/isArray";
import _isNil from "lodash/isNil";
import _omitBy from "lodash/omitBy";
import _isPlainObject from "lodash/isPlainObject";
import _mapValues from "lodash/mapValues";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';
import { jsonParse } from '../../../utils';
var dateTimeFormat = 'MM-DD-YYYY';
export var useUrlParams = function useUrlParams() {
  var history = useHistory();
  var _history$location = history.location,
    pathname = _history$location.pathname,
    search = _history$location.search,
    state = _history$location.state;
  var urlQueryParams = _mapValues(qs.parse(search, {
    arrayFormat: 'index'
  }), function (el) {
    if ((/\d{4}-\d{2}-\d{2}/g.test(el) || /\d{4}\/\d{2}\/\d{2}/g.test(el)) && moment(el, dateTimeFormat).isValid()) return moment(el, dateTimeFormat);
    return jsonParse(el, el);
  });
  var setUrlQueryParams = function setUrlQueryParams(params) {
    var mappedParams = _mapValues(_objectSpread(_objectSpread({}, urlQueryParams), params), function (el) {
      switch (true) {
        case _isPlainObject(el):
          return JSON.stringify(el);
        case moment.isMoment(el):
          return el.format(dateTimeFormat);
        case moment.isDate(el):
          return moment(el).format(dateTimeFormat);
        default:
          return el;
      }
    });
    var filteredUrlParams = qs.stringify(_omitBy(mappedParams, _isNil), {
      arrayFormat: 'index'
    });
    history.replace({
      to: pathname,
      state: state,
      search: filteredUrlParams ? "?".concat(filteredUrlParams) : search
    });
  };
  var clearUrlQueryParams = function clearUrlQueryParams(paramsToClear) {
    var search = '';
    if (_isArray(paramsToClear)) search = "?".concat(qs.stringify(_omit(urlQueryParams, paramsToClear)));
    history.replace({
      to: pathname,
      state: state,
      search: search
    });
  };
  return {
    urlQueryParams: urlQueryParams,
    setUrlQueryParams: setUrlQueryParams,
    clearUrlQueryParams: clearUrlQueryParams
  };
};