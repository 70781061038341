import { css } from 'emotion';
export var containerSearch = css({
  marginBottom: 16,
  '&:last-of-type': {
    marginBottom: 4
  }
});
export var inputSearch = function inputSearch(theme) {
  return css({
    backgroundColor: theme.whiteGrayLight
  });
};
export var countryContainer = css({
  display: 'flex',
  alignItems: 'center',
  padding: 16,
  '& > .flag-img': {
    maxWidth: '24px',
    marginRight: '8px',
    border: '3px solid white'
  }
});
export var countryImage = css({
  marginRight: 12,
  width: 20,
  height: 20
});
export var countryKycContainer = function countryKycContainer(theme) {
  return css({
    color: theme.gray
  });
};
export var headline = function headline(theme) {
  return css({
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: "1px solid ".concat(theme.borderLight),
    padding: '0 0 12px 0',
    marginBottom: 32,
    '& > h1': {
      marginRight: 8,
      marginBottom: 8
    }
  });
};