import { css } from 'emotion';
export var latestTransactionsContainer = function latestTransactionsContainer(theme) {
  return css({
    backgroundColor: theme.white,
    boxShadow: '0 1px 2px rgba(0,0,0,0.3)'
  });
};
export var headerContainerWallets = function headerContainerWallets(theme) {
  return css({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: "1px solid ".concat(theme.borderLight),
    padding: '24px 32px'
  });
};
export var linkHistory = function linkHistory(theme) {
  return css({
    color: theme.info
  });
};
export var noTransactions = function noTransactions(theme) {
  return css({
    color: theme.gray,
    fontWeight: 600,
    lineHeight: 1,
    padding: '24px',
    textAlign: 'center'
  });
};
export var descTransactions = css({
  display: 'flex',
  flexDirection: 'row',
  marginTop: 6,
  alignItems: 'center',
  '& > div': {
    marginRight: 12,
    '& > p': {
      marginRight: 12
    }
  }
});
export var headerWallet = function headerWallet(theme) {
  return css({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    color: theme.white
  });
};
export var internalContainer = css({
  flex: 1,
  borderRadius: 3
});
export var paddingLeft = css({
  paddingLeft: 6
});
export var rowTransactions = function rowTransactions(theme) {
  return css({
    display: 'flex',
    alignItems: 'center',
    color: theme.grayDark,
    padding: 24,
    fontWeight: 600,
    '&:nth-child(2n)': {
      backgroundColor: theme.whiteGrayLight
    }
  });
};
export var walletContainer = function walletContainer(theme) {
  return {
    backgroundColor: theme.blackLight,
    boxShadow: '0 1px 2px rgba(0,0,0,0.3)',
    minHeight: 161,
    padding: '24px 32px 12px 32px'
  };
};
export var walletIcon = function walletIcon(theme) {
  return css({
    color: theme.error,
    fontSize: 30,
    marginBottom: 12
  });
};
export var marginRight32 = css({
  marginRight: 32
});
export var skeletonContainer = css({
  padding: '0px 18px',
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  marginTop: 6,
  alignItems: 'center'
});