import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { ReactComponent as FrozenIcn } from '../../../assets/images/icn-frozen-snowflake.svg';
import { ReactComponent as InactiveIcn } from '../../../assets/images/icn-inactive.svg';
import defaultAvatarLogo from '../../../assets/images/default-avatar.png';
import { networkTreeAvatarAccountContainer, networkTreeAvatarAccountContainerPicture, networkTreeAvatarAccountNicknameWrap, networkTreeAvatarAccountNicknameWrapName, networkTreeAvatarAccountNicknameWrapNickname, networkTreeAvatarAccountNicknameWrapPackage } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkAccountInfo = function NetworkAccountInfo(_ref) {
  var _nodeData$pictureUrl;
  var nodeData = _ref.nodeData,
    onClick = _ref.onClick,
    packageColor = _ref.packageColor,
    root = _ref.root;
  var theme = useTheme();
  var isFrozen = nodeData.status === 'Frozen';
  var isInactive = nodeData.status === 'Inactive';
  var isTopElement = !(nodeData !== null && nodeData !== void 0 && nodeData.parent);
  var isEmpty = !nodeData.id;
  var leg = nodeData.id === root.id ? 'root' : (nodeData === null || nodeData === void 0 ? void 0 : nodeData.path.charAt(root.path.length + 1)) === '1' ? 'left' : 'right';
  var handleClick = function handleClick(e) {
    e.stopPropagation();
    if (!isEmpty && !isTopElement) onClick(nodeData.id);
  };
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: "0",
    onClick: handleClick,
    className: networkTreeAvatarAccountContainer
  }, ___EmotionJSX("div", {
    className: networkTreeAvatarAccountContainerPicture(theme, !nodeData.pictureUrl),
    style: {
      borderColor: packageColor,
      backgroundImage: "url(".concat((_nodeData$pictureUrl = nodeData.pictureUrl) !== null && _nodeData$pictureUrl !== void 0 ? _nodeData$pictureUrl : defaultAvatarLogo, ")")
    }
  }, (isFrozen || isInactive) && !isEmpty && ___EmotionJSX("div", null, isFrozen && ___EmotionJSX(FrozenIcn, null), isInactive && ___EmotionJSX(InactiveIcn, null))), ___EmotionJSX("div", {
    className: networkTreeAvatarAccountNicknameWrap
  }, ___EmotionJSX("p", {
    className: networkTreeAvatarAccountNicknameWrapName(theme)
  }, "".concat(nodeData.name, " ").concat(isTopElement ? "(".concat(leg, ")") : '')), ___EmotionJSX("p", {
    className: networkTreeAvatarAccountNicknameWrapNickname(theme)
  }, "".concat(nodeData.nickName, " ").concat(nodeData.rank && "(".concat(nodeData.rank, ")")))), ___EmotionJSX("p", {
    className: networkTreeAvatarAccountNicknameWrapPackage(theme)
  }, nodeData.planName.substring(0, 40)));
};
NetworkAccountInfo.propTypes = {
  nodeData: PropTypes.object,
  onClick: PropTypes.func,
  packageColor: PropTypes.string,
  root: PropTypes.object
};
export default NetworkAccountInfo;