export var accountsTransferStatsColumns = [{
  name: 'Country',
  value: 'country',
  width: 200,
  sortable: true
}, {
  name: 'Total V2 accounts',
  value: 'version2Total',
  sortable: true
}, {
  name: 'Newly created V2 accounts',
  value: 'newlyCreated',
  sortable: true
}, {
  name: 'Total Transferred to V2',
  value: 'transferredAccounts',
  sortable: true
}, {
  name: 'Total accounts',
  value: 'totalAccounts',
  sortable: true
}];