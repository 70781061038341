export var optionsProfileSearchBy = [{
  value: 'email',
  label: 'Email'
}, {
  value: 'phone',
  label: 'Phone'
}, {
  value: 'username',
  label: 'Username'
}, {
  value: 'userId',
  label: 'UserID'
}];
export var optionsStatus = [{
  value: 'new',
  label: 'New'
}, {
  value: 'pending',
  label: 'Pending'
}, {
  value: 'active',
  label: 'Active'
}, {
  value: 'frozen',
  label: 'Frozen'
}, {
  value: 'terminated',
  label: 'Terminated'
}];