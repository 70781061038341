import { css, cx } from 'emotion';
export var loader = function loader(size, _ref, theme) {
  var color = _ref.color,
    fullScreen = _ref.fullScreen;
  return cx('spinner', css({
    width: size,
    height: size,
    position: 'absolute',
    top: "calc(50% - ".concat(size / 2, "px)"),
    left: "calc(50% - ".concat(size / 2, "px)"),
    zIndex: 10
  }, fullScreen && {
    position: 'fixed'
  }, color && {
    '& .path': {
      stroke: theme[color]
    }
  }));
};