import React from 'react';
import { Table } from '../../../../components/UI';
import { getPaymentMethods } from '../../../../services/paymentMethods';
import { paymentMethodsColumns } from './config';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PaymentMethodsPage = function PaymentMethodsPage() {
  return ___EmotionJSX(Table, {
    getDataKey: "content",
    getDataMethod: getPaymentMethods,
    emptyMessage: "There are no Payment Methods.",
    columns: paymentMethodsColumns,
    hasPagination: false
  });
};
export default PaymentMethodsPage;