import _isEmpty from "lodash/isEmpty";
import React, { useRef } from 'react';
import { Button, FormInput, Modal } from '../../UI';
import { validateLength } from '../../../utils/validations';
import { revertButtonsContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var RevertGiftCodeModal = function RevertGiftCodeModal(_ref) {
  var modalRef = _ref.modalRef,
    onSubmit = _ref.onSubmit,
    onCancel = _ref.onCancel;
  var commentRef = useRef('');
  var handleCancel = function handleCancel() {
    modalRef.current.close();
    onCancel && onCancel();
  };
  var handleOk = function handleOk() {
    if (_isEmpty(commentRef.current.value)) return commentRef.current.changeIsTouched(true);
    onSubmit(commentRef.current.value, function () {
      return modalRef.current.close();
    });
  };
  return ___EmotionJSX(Modal, {
    ref: modalRef,
    large: true,
    modalTitle: "Revert Gift Code"
  }, ___EmotionJSX("h4", null, "If you revert gift code/s the following the immediate assets acquired by the package/s will be returned to the system:"), ___EmotionJSX("ul", null, ___EmotionJSX("li", null, "the package/s associated with it will be removed from the account\u2019s packages"), ___EmotionJSX("li", null, "any Pending token exchange transactions will be rejected by the system"), ___EmotionJSX("li", null, "tokens will be subtracted from the token wallet (can have negative balance)"), ___EmotionJSX("li", null, "unused splits balances will be subtracted from the account splits"), ___EmotionJSX("li", null, "BVs generated by the redeem will be removed"), ___EmotionJSX("li", null, "ImmediateMiningBalance will be subtracted from the SplitsBalances")), ___EmotionJSX(FormInput, {
    required: true,
    id: "comment",
    type: "textarea",
    placeholder: "Leave a comment",
    ref: commentRef,
    validate: validateLength(200)
  }), ___EmotionJSX("div", {
    className: revertButtonsContainer
  }, ___EmotionJSX(Button, {
    secondary: true,
    onClick: handleCancel
  }, "Decline"), ___EmotionJSX(Button, {
    onClick: handleOk
  }, "Revert")));
};
export default RevertGiftCodeModal;