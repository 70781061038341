import { css } from 'emotion';
export var mainContainer = function mainContainer(hasButtons, className) {
  return css(hasButtons && {
    marginBottom: 32
  }, className);
};
export var userData = function userData(hasButtons) {
  return css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }, hasButtons && {
    marginBottom: 12
  });
};
export var pictureContainer = css({
  borderRadius: '50%',
  marginRight: 18,
  flexBasis: 70,
  flexShrink: 0
});
export var userInfo = css({
  display: 'inline-block',
  marginBottom: 4,
  marginRight: 8
});
export var statusContainer = function statusContainer(theme) {
  return css({
    color: theme.info,
    backgroundColor: theme.infoLight,
    padding: '2px 4px',
    borderRadius: 1
  });
};
export var infoContainer = css({
  fontWeight: 600,
  '& > span': {
    fontWeight: 400,
    marginLeft: 8
  }
});
export var marginBottom4 = css({
  marginBottom: 4
});