import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { currencyByType } from '../../../utils/displayData';
import { transactionType as transactionTypeEnum } from '../../../enums/transactions';
import { amountLabel, walletAmountContainer, walletAmountType } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Amount = function Amount(props) {
  var amount = props.amount,
    currency = props.currency,
    transactionType = props.transactionType,
    onTransactionList = props.onTransactionList,
    walletType = props.walletType;
  var theme = useTheme();
  var isNegative = amount < 0 || transactionTypeEnum[transactionType] === transactionTypeEnum.Outbound;
  return ___EmotionJSX("div", {
    className: walletAmountContainer
  }, ___EmotionJSX("span", {
    className: amountLabel(isNegative, theme, onTransactionList)
  }, ___EmotionJSX("span", null, "".concat(isNegative ? '-' : '+'), "\xA0", "".concat(currency ? currencyByType(Math.abs(amount), currency) : Math.abs(amount)))), onTransactionList && ___EmotionJSX("p", {
    className: walletAmountType
  }, walletType));
};
Amount.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transactionType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onTransactionList: PropTypes.bool,
  walletType: PropTypes.string
};
export default Amount;