import React from 'react';
import { Table } from '../../../components/UI';
import { getSplitRunsHistory } from '../../../services/wallets';
import { splitRunsColumns } from './config';
import { jsx as ___EmotionJSX } from "@emotion/react";
var SplitRunHistory = function SplitRunHistory() {
  return ___EmotionJSX(Table, {
    getDataKey: "splitRunHistory",
    getDataMethod: getSplitRunsHistory,
    columns: splitRunsColumns,
    emptyMessage: "There are no Split Runs.",
    pageSize: 10
  });
};
export default SplitRunHistory;