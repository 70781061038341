import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import { useTheme } from 'emotion-theming';
import { Paper } from '../../UI';
import { chartOrderStatisticsChart } from '../config';
import { container, containerHeader, content, item, info, description, value, progress, progressSm, stat, chart } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var OrderStatisticsChart = function OrderStatisticsChart() {
  var ref = useRef();
  var theme = useTheme();
  useEffect(function () {
    var chart = new Chart(ref.current, chartOrderStatisticsChart(theme));
    return function () {
      chart.destroy();
    };
  });
  return ___EmotionJSX(Paper, {
    header: ___EmotionJSX("div", {
      className: containerHeader(theme)
    }, ___EmotionJSX("h3", null, "Order Statistics"))
  }, ___EmotionJSX("div", {
    className: container
  }, ___EmotionJSX("div", {
    className: content
  }, ___EmotionJSX("div", {
    className: item
  }, ___EmotionJSX("div", {
    className: info
  }, ___EmotionJSX("span", {
    className: description(theme)
  }, "Annual Taxes EMS"), ___EmotionJSX("span", {
    className: value(theme)
  }, "$400,000")), ___EmotionJSX("div", {
    className: info
  }, ___EmotionJSX("span", {
    className: description(theme)
  }, "Finance Review Date"), ___EmotionJSX("span", {
    className: value(theme)
  }, "July 24,2019"))), ___EmotionJSX("div", {
    className: item
  }, ___EmotionJSX("div", {
    className: info
  }, ___EmotionJSX("span", {
    className: description(theme)
  }, "Average Revenue"), ___EmotionJSX("span", {
    className: value(theme)
  }, "$60M")), ___EmotionJSX("div", {
    className: info
  }, ___EmotionJSX("span", {
    className: description(theme)
  }, "Revenue Margin"), ___EmotionJSX("div", {
    className: progress
  }, ___EmotionJSX("progress", {
    max: "100",
    value: "40",
    className: progressSm
  }), ___EmotionJSX("span", {
    className: stat(theme)
  }, "40%"))))), ___EmotionJSX("div", {
    className: chart,
    style: {
      height: '250px'
    }
  }, ___EmotionJSX("canvas", {
    ref: ref,
    width: 683,
    height: 312,
    id: "kt_chart_order_statistics"
  }))));
};
export default OrderStatisticsChart;