import _isFunction from "lodash/isFunction";
import _kebabCase from "lodash/kebabCase";
import _isString from "lodash/isString";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { tabContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Tab = function Tab(props) {
  var label = props.label,
    active = props.active,
    onClick = props.onClick,
    uuid = props.uuid;
  var theme = useTheme();
  var handleClick = function handleClick() {
    return onClick(label);
  };
  return ___EmotionJSX("div", _extends({
    role: "presentation",
    className: tabContainer(active, theme)
  }, _isFunction(onClick) && {
    onClick: handleClick
  }, _isString(uuid) && {
    'data-id': "tab-".concat(_kebabCase(uuid))
  }), label);
};
Tab.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.object,
  uuid: PropTypes.string
};
export default Tab;