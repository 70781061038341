// import React, { useState, useRef } from 'react';
import React from 'react';
import { Table
// showApiError,
// showSuccess,
} from '../../../components/UI';
import WithdrawalSearchBar from '../../../components/Transactions/WithdrawalSearchBar';
// import WithdrawalActions from '../../../components/Transactions/WithdrawalActions';
import { getAllWithdrawals } from '../../../services/wallets';
import { withdrawalColumns } from './config';
import { backContainerWithButton } from './styles';

// const WithdrawalList = () => {
// const [changeStatus, setChangeStatus] = useState([]);
// const tableRef = useRef();

// const handleChangeStatus = async options => {
//   console.log(options);
//   const [, err] = await updateWithdrawalStatus({
//     ...options
//   });

//   setChangeStatus(changeStatus.filter(el => el.id !== options.id));

//   if (err) return showApiError(err);
//   showSuccess('Successfully changed Status.');
//   tableRef.current.getData();
// };
import { jsx as ___EmotionJSX } from "@emotion/react";
var WithdrawalList = function WithdrawalList() {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: backContainerWithButton
  }, ___EmotionJSX("h1", null, " All Withdrawals")), ___EmotionJSX(Table
  // ref={tableRef}
  // onSelect={setChangeStatus}
  , {
    getDataKey: "content",
    getDataMethod: getAllWithdrawals,
    columns: withdrawalColumns,
    emptyMessage: "There are no Withdrawals.",
    filterBar: WithdrawalSearchBar,
    pageSize: 10
  }));
};
export default WithdrawalList;