import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'emotion-theming';
import { CheckButton } from '../Button';
import { Icon } from '../Icon';
import { tableHead, tableHeadCell, tableHeadIcon, tableCellCheckButton } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TableHead = function TableHead(props) {
  var columns = props.columns,
    getData = props.getData,
    tableOptions = props.tableOptions,
    theme = props.theme,
    hasSelection = props.hasSelection,
    selectRow = props.selectRow,
    allSelected = props.allSelected;
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    sort = _useState2[0],
    setSort = _useState2[1];
  var changeSort = function changeSort(column) {
    return function () {
      var value = column.value;
      var isDesc = sort === value;
      var newSort = isDesc ? "".concat(value, "Descending") : sort === "".concat(value, "Descending") ? null : value;
      setSort(newSort);
      getData({
        sort: newSort && {
          sortField: value,
          sortOrder: isDesc ? 'desc' : 'asc'
        }
      });
    };
  };
  return ___EmotionJSX("div", {
    className: tableHead
  }, hasSelection && !!tableOptions.current.totalResults && ___EmotionJSX("div", {
    className: tableHeadCell({
      flex: '0 0 40px',
      render: true
    }, theme)
  }, ___EmotionJSX(CheckButton, {
    onChange: selectRow,
    checked: allSelected,
    className: tableCellCheckButton
  })), columns.map(function (column) {
    var sortable = column.sortable,
      name = column.name,
      value = column.value;
    return sortable ? ___EmotionJSX("div", {
      key: name,
      className: tableHeadCell(column, theme),
      role: "button",
      tabIndex: "-1",
      onClick: changeSort(column)
    }, ___EmotionJSX("p", null, name), ___EmotionJSX(Icon, {
      material: true,
      iconName: "arrow_downward",
      className: tableHeadIcon(sort === value, sort === "".concat(value, "Descending"))
    })) : ___EmotionJSX("div", {
      key: name,
      className: tableHeadCell(column, theme)
    }, ___EmotionJSX("p", null, name));
  }));
};
TableHead.propTypes = {
  columns: PropTypes.array,
  getData: PropTypes.func,
  theme: PropTypes.object,
  hasSelection: PropTypes.bool,
  selectRow: PropTypes.func,
  allSelected: PropTypes.bool,
  tableOptions: PropTypes.object
};
export default withTheme(TableHead);