import "core-js/modules/es.array.map.js";
import React from 'react';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { rowsDefinition } from './config';
import { directStartupBonusRoundInfoContainer, directStartupBonusRoundInfoLabel, directStartupBonusRoundInfoRow, directStartupBonusRoundInfoValue } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var DirectSaleStartupBonusRoundInfo = function DirectSaleStartupBonusRoundInfo(props) {
  var isCurrentRound = props.isCurrentRound,
    bonusRoundData = props.bonusRoundData;
  var theme = useTheme();
  return ___EmotionJSX("div", {
    className: directStartupBonusRoundInfoContainer
  }, rowsDefinition(isCurrentRound).map(function (row) {
    return ___EmotionJSX("div", {
      className: directStartupBonusRoundInfoRow(theme),
      key: row.label
    }, ___EmotionJSX("p", {
      className: directStartupBonusRoundInfoLabel(theme)
    }, row.label), ___EmotionJSX("p", {
      className: directStartupBonusRoundInfoValue
    }, row.render(bonusRoundData)));
  }));
};
DirectSaleStartupBonusRoundInfo.propTypes = {
  isCurrentRound: PropTypes.bool,
  bonusRoundData: PropTypes.object
};
export default DirectSaleStartupBonusRoundInfo;