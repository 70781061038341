import { css } from 'emotion';
export var matchingCurrentBonusWrap = function matchingCurrentBonusWrap(theme) {
  return css({
    marginBottom: '40px',
    hight: '100%',
    backgroundColor: theme.white,
    color: theme.grayDark,
    boxShadow: "0 6px 8px 0 ".concat(theme.border),
    border: "1px solid ".concat(theme.borderLight)
  });
};
export var matchingCurrentBonusHeader = function matchingCurrentBonusHeader(theme) {
  return css({
    padding: '32px 40px',
    borderBottom: "1px solid ".concat(theme.borderLight)
  });
};
export var matchingCurrentBonusHeaderLeftTopWrap = css({
  display: 'flex',
  alignItems: 'center',
  '& h3': {
    fontSize: '19px',
    letterSpacing: '-0.2px',
    fontWeight: '400'
  }
});
export var matchingCurrentBonusHeaderLeftBottomWrap = css({
  fontWeight: '600',
  marginTop: '16px'
});
export var matchingCurrentBonusBody = css({
  padding: '34px 40px 40px 24px'
});
export var matchingCurrentBonusBodyListItem = function matchingCurrentBonusBodyListItem(theme) {
  return css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px 16px 16px',
    fontWeight: '500',
    '&:nth-child(even)': {
      backgroundColor: theme.backgroundLightGray
    },
    '& p': {
      lineHeight: '2',
      fontSize: '14px',
      color: theme.gray
    }
  });
};
export var matchingCurrentBonusBodyListItemValue = function matchingCurrentBonusBodyListItemValue(theme) {
  return css({
    lineHeight: '1.1',
    fontSize: '18px',
    letterSpacing: '-0.53px',
    color: theme.grayDark,
    '& div': {
      marginBottom: '0px'
    }
  });
};