import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
var _excluded = ["children", "options", "disabled", "theme", "optionListClassName"];
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.map.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'emotion-theming';
import Button from './Button';
import Ripple from '../Ripple';
import { buttonDropdownContainer, buttonDropdownOptionsContainer, buttonDropdownOptionItem } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ButtonDropdown = function ButtonDropdown(_ref) {
  var children = _ref.children,
    _ref$options = _ref.options,
    options = _ref$options === void 0 ? [] : _ref$options,
    _ref$disabled = _ref.disabled,
    disabled = _ref$disabled === void 0 ? !options.length : _ref$disabled,
    theme = _ref.theme,
    optionListClassName = _ref.optionListClassName,
    buttonProps = _objectWithoutProperties(_ref, _excluded);
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1];
  var handleBlur = function handleBlur(_ref2) {
    var currentTarget = _ref2.currentTarget;
    return setTimeout(function () {
      if (!currentTarget.contains(document.activeElement) && isOpen) setIsOpen(false);
    });
  };
  var renderBaseOptionElement = function renderBaseOptionElement(option, i) {
    var optionRender = option.render ? option.render : option.label;
    return ___EmotionJSX("div", {
      key: "buttonDropdownItem".concat(i * Math.random()),
      role: "presentation",
      onClick: option.onClick
    }, ___EmotionJSX(Ripple, {
      backColor: "secondaryDark",
      className: buttonDropdownOptionItem(theme)
    }, optionRender));
  };
  return ___EmotionJSX("div", {
    className: buttonDropdownContainer,
    onBlur: handleBlur
  }, ___EmotionJSX(Button, _extends({}, buttonProps, {
    disabled: disabled,
    onClick: function onClick() {
      return setIsOpen(!isOpen);
    }
  }), children), ___EmotionJSX("div", {
    className: buttonDropdownOptionsContainer(isOpen, options.length, optionListClassName, theme)
  }, options.map(renderBaseOptionElement)));
};
ButtonDropdown.propTypes = {
  options: PropTypes.array,
  children: PropTypes.any,
  theme: PropTypes.object,
  disabled: PropTypes.bool,
  optionListClassName: PropTypes.string
};
export default withTheme(ButtonDropdown);