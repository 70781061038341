import React from 'react';
import { Table } from '../../../components/UI';
import KycSearchBar from '../../../components/Kyc/KycSearchBar';
import { kycColumns } from './config';
import { getAllKyc } from '../../../services/kyc';
import { jsx as ___EmotionJSX } from "@emotion/react";
var pageSizes = [20, 30, 50];
var KycPage = function KycPage() {
  return ___EmotionJSX(Table, {
    hasSelection: true,
    getDataKey: "items",
    getDataMethod: getAllKyc,
    emptyMessage: "There are no KYC documents.",
    columns: kycColumns,
    pageSizes: pageSizes,
    filterBar: KycSearchBar,
    pageSize: 50
  });
};
export default KycPage;