import { css } from 'emotion';
export var headerContain = function headerContain(className) {
  return css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }, className);
};
export var buttonBack = function buttonBack(theme) {
  return css({
    color: theme.gray,
    padding: 0,
    marginRight: 32,
    '& > svg': {
      marginRight: 12
    },
    '& > span': {
      fontSize: '1rem',
      fontWeight: 600,
      '&:hover': {
        color: theme.primary
      }
    },
    '&:focus, &:active': {
      color: theme.gray
    }
  });
};