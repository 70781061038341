import { css } from 'emotion';
export var colorStatus = function colorStatus(theme, _ref) {
  var type = _ref.type,
    className = _ref.className;
  var colorStatusTypeMap = {
    info: {
      color: theme.info,
      backgroundColor: theme.infoLight
    },
    error: {
      color: theme.error,
      backgroundColor: theme.errorLight
    },
    success: {
      color: theme.success,
      backgroundColor: theme.successLight
    },
    warning: {
      color: theme.warning,
      backgroundColor: theme.warningLight
    },
    orange: {
      color: theme.orange,
      backgroundColor: theme.orangeLight
    }
  };
  return css(colorStatusTypeMap[type], {
    fontWeight: 500,
    padding: '4px 8px',
    borderRadius: 4
  }, className);
};