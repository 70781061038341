import { css } from 'emotion';
export var containerSearch = css({
  marginBottom: 12,
  display: 'flex',
  alignItems: 'center'
});
export var inputSearch = function inputSearch(theme) {
  return css({
    backgroundColor: theme.whiteGrayLight
  });
};