export var searchCriteriaOptions = [{
  label: 'Email',
  value: 'email'
}, {
  label: 'Phone',
  value: 'phone'
}, {
  label: 'Username',
  value: 'username'
}, {
  label: 'UserID',
  value: 'userId'
}];