import React from 'react';
import PropTypes from 'prop-types';
import { skeletonClass } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Skeleton = function Skeleton(props) {
  var count = props.count,
    className = props.className;
  var elements = [];
  for (var i = 0; i < count; i++) {
    elements.push(___EmotionJSX("span", {
      key: i,
      className: skeletonClass(props, className)
    }, "\u200C"));
  }
  return elements;
};
Skeleton.defaultProps = {
  count: 1,
  duration: '1.2'
};
Skeleton.propTypes = {
  count: PropTypes.number,
  duration: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  circle: PropTypes.bool,
  className: PropTypes.string
};
export default Skeleton;