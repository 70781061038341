import { css } from 'emotion';
export var item = function item(isLastItem, theme) {
  return css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderBottom: "0.07rem dashed ".concat(theme.borderLight),
    '&:first-child': {
      paddingTop: '0'
    }
  }, isLastItem && {
    borderBottom: '0'
  });
};
export var number = css({
  width: '7rem',
  textAlign: 'right'
});
export var iconArrow = css({
  margin: '0px 16px'
});
export var containerHeader = function containerHeader(theme) {
  return css({
    borderBottom: "1px solid ".concat(theme.borderLight),
    padding: '16px 32px'
  });
};
export var accountContainer = function accountContainer(theme) {
  return css({
    fontSize: '1rem',
    margin: 0,
    color: theme.grayLight
  });
};