import { css } from 'emotion';
export var container = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 48,
  '& > h1': {
    marginBottom: 0,
    fontWeight: 600
  }
});
export var footerContainer = css({
  display: 'flex',
  justifyContent: 'space-between'
});
export var navLink = function navLink(theme) {
  return css({
    borderRadius: 2,
    padding: '10px 20px',
    color: theme.white,
    border: "1px solid ".concat(theme.primaryLight),
    textAlign: 'center',
    backgroundColor: theme.primaryLight,
    fontSize: 14,
    '&:hover': {
      backgroundColor: theme.primary
    },
    '&:focus, &:active': {
      backgroundColor: theme.primaryLight,
      color: theme.textPrimaryFocused
    }
  });
};
export var containerBack = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 32
});
export var paperContainer = css({
  '& > div > *:last-child': {
    marginBottom: 0
  }
});
export var marginBottom = function marginBottom(val) {
  return css({
    marginBottom: val
  });
};
export var paddingLeft = function paddingLeft(value) {
  return css({
    paddingLeft: value
  });
};
export var packageInfoContainer = css({
  paddingLeft: 12,
  marginBottom: 32
});
export var tabContainer = css({
  padding: '48px 40px'
});