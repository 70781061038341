import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Dropdown } from '../../UI';
import SearchInput from '../../Shared/SearchInput';
import { containerSearch } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var defaultSortOptions = [{
  label: 'Sort by: Date',
  value: 'createdOn'
}, {
  label: 'Sort by: Value',
  value: 'price'
}, {
  label: 'Sort by: Name',
  value: 'name'
}];
var FilterBar = function FilterBar(props) {
  var onFilter = props.onFilter,
    onSort = props.onSort,
    sortOptions = props.sortOptions;
  var handleSortChange = function handleSortChange(_ref) {
    var value = _ref.value;
    onSort(value);
  };
  var handleFilterChange = function handleFilterChange(value) {
    onFilter(value);
  };
  var defaultSortValue = sortOptions && sortOptions.length > 0 ? sortOptions[0] : defaultSortOptions[0];
  return ___EmotionJSX(Row, {
    align: "flex-start",
    justify: "space-between",
    verticalGap: 8,
    className: containerSearch,
    horizontalGap: 16
  }, ___EmotionJSX(Col, {
    xl: 9,
    lg: 5,
    sm: 6,
    xs: 12
  }, ___EmotionJSX(SearchInput, {
    placeholder: "Search by name",
    onChange: handleFilterChange
  })), ___EmotionJSX(Col, {
    xl: 3,
    lg: 4,
    sm: 6,
    xs: 12
  }, ___EmotionJSX(Dropdown, {
    noClear: true,
    options: sortOptions || defaultSortOptions,
    onChange: handleSortChange,
    value: defaultSortValue
  })));
};
FilterBar.propTypes = {
  onFilter: PropTypes.func,
  onSort: PropTypes.func,
  sortOptions: PropTypes.array
};
export default FilterBar;