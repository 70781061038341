import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
import _isString from "lodash/isString";
import _isNil from "lodash/isNil";
import _uniq from "lodash/uniq";
import _isFunction from "lodash/isFunction";
import _isArray from "lodash/isArray";
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.array.join.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Icon } from '../Icon';
import { getFormChilds } from './utils';
import { getNestedProperty } from '../../../utils';
import { successBar, successBarColorEffect, successBarContent } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var _SuccessBar = function SuccessBar(_ref) {
  var onHide = _ref.onHide,
    message = _ref.message;
  var theme = useTheme();
  return ___EmotionJSX("div", {
    className: successBar
  }, ___EmotionJSX("div", {
    className: successBarColorEffect(theme)
  }), ___EmotionJSX("div", {
    className: successBarContent
  }, _isArray(message) ? "Successfully edited: ".concat(message.join(', ')) : message, ___EmotionJSX(Icon, {
    material: true,
    color: "secondary",
    iconName: "close",
    onClick: onHide
  })));
};
_SuccessBar.propTypes = {
  onHide: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};
export var useSuccessBar = function useSuccessBar() {
  var _useState = useState(null),
    _useState2 = _slicedToArray(_useState, 2),
    isVisible = _useState2[0],
    setIsVisible = _useState2[1];
  var formRef = useRef({});
  var messageToShow = useRef([]);
  useEffect(function () {
    if (!isVisible) return function () {};
    var timer = setTimeout(function () {
      setIsVisible(false);
    }, 5000);
    return function () {
      return clearTimeout(timer);
    };
  }, [isVisible]);
  var hideSuccessBar = function hideSuccessBar() {
    return setIsVisible(false);
  };
  var setUpMessageAndShow = function setUpMessageAndShow(val) {
    messageToShow.current = val;
    setIsVisible(true);
  };
  var showSuccessBar = function showSuccessBar(values, mapFunction) {
    if (_isString(values)) {
      setUpMessageAndShow(values);
      return;
    }
    var children = formRef.current.children;
    var childrens = getFormChilds(children);
    var editedChildrens = childrens
    // Filter those children which do not have a representative value inside the form object
    .filter(function (el) {
      return el.props.id && !_isNil(getNestedProperty(values, el.props.id.split('.')));
    })
    // Map the fields that have values to their labels or pass the child.id and child to map function
    .map(function (child) {
      return _isFunction(mapFunction) ? mapFunction(child.props.id, child) : child.props.label;
    });

    // Save edited fields by removing the duplicates
    setUpMessageAndShow(_uniq(editedChildrens));
  };
  return {
    isVisible: isVisible,
    hideSuccessBar: hideSuccessBar,
    showSuccessBar: showSuccessBar,
    formRef: formRef,
    SuccessBar: function SuccessBar(formProps) {
      return ___EmotionJSX(_SuccessBar, _extends({
        onHide: hideSuccessBar,
        message: messageToShow.current
      }, formProps));
    }
  };
};
export default _SuccessBar;