import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.freeze.js";
import "core-js/modules/es.object.define-properties.js";
var _templateObject;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { css, keyframes } from 'emotion';
import { Types } from './Store';
var generateBackColor = function generateBackColor(type, theme) {
  switch (type) {
    case Types.SUCCESS:
      return theme.successLight;
    case Types.ERROR:
      return theme.errorLight;
    case Types.WARNING:
      return theme.warningLight;
    case Types.INFO:
      return theme.infoLight;
    default:
      return theme.successLight;
  }
};
var generateColor = function generateColor(type, theme) {
  switch (type) {
    case Types.SUCCESS:
      return theme.successDark;
    case Types.ERROR:
      return theme.errorDark;
    case Types.WARNING:
      return theme.warningDark;
    case Types.INFO:
      return theme.infoDark;
    default:
      return theme.successDark;
  }
};
var animation = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% {\n    transform: translateX(100%);\n  }\n  100% {\n    transform: translateX(0);\n  }\n"])));
export var notification = function notification(type, isHidden, theme) {
  return css({
    padding: 16,
    marginBottom: 16,
    color: theme.white,
    backgroundColor: generateBackColor(type, theme),
    borderRadius: 10,
    transform: isHidden ? 'translateX(calc(100% + 20px))' : 'none',
    transition: 'transform 0.4s ease-in-out',
    animation: "".concat(animation, " 0.4s ease-in-out"),
    cursor: 'pointer',
    '&:last-of-type': {
      marginBottom: 0
    }
  });
};
export var notifContainer = function notifContainer(type, theme) {
  return css({
    display: 'flex',
    alignItems: 'center',
    maxWidth: 500,
    fontSize: '1rem',
    color: generateColor(type, theme),
    '& > svg, & > i': {
      marginRight: 12
    }
  });
};
export var notificationsOverlay = css({
  zIndex: 1100
});
export var allNotificationsContainer = css({
  position: 'fixed',
  top: 20,
  right: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: 'calc(100% - 40px)'
});