import { css } from 'emotion';
export var descriptionContainer = css({
  width: '100%'
});
export var buttonInfo = function buttonInfo(theme) {
  return css({
    backgroundColor: 'transparent',
    color: theme.info,
    padding: 0,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:focus, &:active': {
      backgroundColor: 'transparent',
      color: theme.info
    }
  });
};
export var definition = css({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontSize: 12,
  fontWeight: 600,
  '& > span': {
    fontWeight: 400,
    marginLeft: 6
  }
});
export var userReasonStyles = css({
  fontSize: 12,
  fontWeight: 600,
  '& > span': {
    fontWeight: 400,
    marginLeft: 6
  }
});
export var descriptionHistory = css({
  '& > p': {
    fontWeight: 600,
    fontSize: 12
  },
  '& > ol': {
    padding: '0 12px',
    margin: 0
  }
});
export var buttonContainer = css({
  display: 'flex',
  alignItems: 'center'
});
export var infoArrow = function infoArrow(theme) {
  return css({
    color: "".concat(theme.info, " !important")
  });
};