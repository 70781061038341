import { css } from 'emotion';
export var addPackageContainerButton = function addPackageContainerButton(theme, isSelected) {
  return css({
    '& .package-card-info': {
      borderColor: isSelected ? theme.grayDark : theme.border
    }
  });
};
export var titleButtonContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 24
});
export var titleContent = css({
  marginRight: 32
});
export var contentContainer = css({
  height: 'auto',
  flexGrow: 1,
  overflow: 'auto'
});