import { css } from 'emotion';
export var titleContainer = css({
  marginBottom: 10
});
export var labelContainer = css({
  fontWeight: 700,
  padding: '12px 6px'
});
export var valueContainer = css({
  padding: 12
});