import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
/* eslint-disable react/prop-types */
import React from 'react';
import ColorBatch from '../../../components/Shared/ColorBatch';
import { DetailsLink } from '../../../components/Shared/TableComponents';
import { mapOrderStatus } from '../../../components/Orders/OrderGeneralInfo/config';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ordersColumns = function ordersColumns() {
  return [{
    name: 'ID',
    value: 'id',
    width: 60
  }, {
    name: 'Date',
    value: 'createdOn',
    dateTimeFormat: 'DD/MM/YYYY',
    width: 90
  }, {
    name: 'Date of completion',
    value: 'modifiedOn',
    dateTimeFormat: 'DD/MM/YYYY',
    width: 90
  }, {
    name: 'User',
    render: function render(row) {
      return ___EmotionJSX("div", {
        style: {
          padding: '12px 0'
        }
      }, ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Nickname: "), ___EmotionJSX("span", null, "".concat(row.nickName))), ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Email: "), ___EmotionJSX("span", null, row.email)));
    },
    flex: '12 0 0px'
  }, {
    name: 'Payment Type',
    value: 'paymentTypeId',
    width: 110
  }, {
    name: 'Status',
    render: function render(row) {
      var _mapOrderStatus$row$s, _mapOrderStatus$row$s2;
      return ___EmotionJSX(ColorBatch, {
        type: (_mapOrderStatus$row$s = mapOrderStatus[row.status]) === null || _mapOrderStatus$row$s === void 0 ? void 0 : _mapOrderStatus$row$s.color
      }, (_mapOrderStatus$row$s2 = mapOrderStatus[row.status]) === null || _mapOrderStatus$row$s2 === void 0 ? void 0 : _mapOrderStatus$row$s2.text);
    },
    width: 100
  }, {
    name: 'Total amount',
    value: 'totalAmount'
  }, {
    name: 'View details',
    render: function render(row) {
      return ___EmotionJSX(DetailsLink, {
        row: row,
        url: "/orders/order/".concat(row.id)
      });
    },
    width: 100
  }].filter(Boolean);
};
export var optionsEmailOrPhone = [{
  value: 'email',
  label: 'Email'
}, {
  value: 'phone',
  label: 'Phone'
}];
export var detailOrderBreadCrumb = function detailOrderBreadCrumb() {
  var url = '/orders';
  return [{
    url: url,
    label: 'Orders'
  }, {
    label: 'Detail Page'
  }];
};
export var shoppingCartPages = [{
  url: '/orders',
  label: 'Orders'
}, {
  url: '/orders/add-order',
  label: 'Add Order'
}, {
  label: 'Shopping Cart'
}];