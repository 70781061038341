import _isNil from "lodash/isNil";
import React from 'react';
import { ReactComponent as DefaultCountryImage } from '../../../assets/images/default-country-image.svg';
import { selectFlagImg } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var countryComp = function countryComp(image, className) {
  return !_isNil(image) ? ___EmotionJSX("img", {
    src: image,
    className: className || selectFlagImg,
    alt: "".concat(image, "-flag")
  }) : ___EmotionJSX("div", {
    className: className || selectFlagImg
  }, ___EmotionJSX(DefaultCountryImage, null));
};