import { css } from 'emotion';
export var container = css({
  marginBottom: 24
});
export var dropdownContainer = css({
  marginRight: 12
});
export var imageContainer = css({
  marginLeft: 20
});
export var questionImage = css({
  display: 'flex'
});
export var tooltipStyle = css({
  minWidth: 250
});
export var buttonStyle = css({
  marginRight: 0
});