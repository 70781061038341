import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Button/CheckButton';
import { containerCheckBox } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CheckBoxForm = function CheckBoxForm(props) {
  var id = props.id,
    label = props.label,
    value = props.value,
    _onChange = props.onChange,
    className = props.className;
  return ___EmotionJSX(Checkbox, {
    checked: value,
    onChange: function onChange(value) {
      return _onChange(id, value);
    },
    className: containerCheckBox(className)
  }, label);
};
CheckBoxForm.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};
export default CheckBoxForm;