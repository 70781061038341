import _isFunction from "lodash/isFunction";
export var isDev = env === 'development';
export var isSG = env === 'staging';
export var isProd = !isDev && !isSG;
export var ifDev = function ifDev(callback) {
  return isDev && _isFunction(callback) && callback();
};
export var ifSGorDev = function ifSGorDev(callback) {
  return (isDev || isSG) && _isFunction(callback) && callback();
};
export var ifSGOnly = function ifSGOnly(callback) {
  return isSG && _isFunction(callback) && callback();
};
export var ifProd = function ifProd(callback) {
  return isProd && _isFunction(callback) && callback();
};