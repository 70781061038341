function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import _isString from "lodash/isString";
import _isBoolean from "lodash/isBoolean";
import _isNumber from "lodash/isNumber";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/web.timers.js";
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import moment from 'moment';
export default (function () {
  var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'data';
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1000;
  return function (options) {
    var pageIndex = options.pageIndex,
      pageSize = options.pageSize,
      startDate = options.startDate,
      endDate = options.endDate,
      redeemed = options.redeemed;
    var filteredData = _toConsumableArray(data);
    if (startDate && endDate) {
      filteredData = data.filter(function (el) {
        return moment(el.date).isBetween(startDate, endDate);
      });
    }
    if (_isBoolean(redeemed)) {
      filteredData = data.filter(function (el) {
        return el.redeemed === redeemed && el.status !== 'Pending';
      });
    }
    if (_isString(redeemed)) filteredData = data.filter(function (el) {
      return el.status === redeemed;
    });
    if (_isNumber(pageIndex) && _isNumber(pageSize)) {
      filteredData = filteredData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
    }
    return new Promise(function (resolve) {
      setTimeout(function () {
        var _ref;
        resolve([(_ref = {}, _defineProperty(_ref, key, filteredData), _defineProperty(_ref, "total", data.length), _ref)]);
      }, timeout);
    });
  };
});