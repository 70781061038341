import React from 'react';
import ItemDetails from '../../Shared/ItemDetails';
import { giftCodeGeneralInfo } from './config';
import { jsx as ___EmotionJSX } from "@emotion/react";
var GiftCodeGeneralInfo = function GiftCodeGeneralInfo(props) {
  var data = props.data;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(ItemDetails, {
    item: data,
    properties: giftCodeGeneralInfo
  }));
};
export default GiftCodeGeneralInfo;