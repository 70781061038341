import { type as walletType } from '../../../enums/wallets';
import { currencyByType } from '../../../utils/displayData';
export var dataQuickStatsChart = function dataQuickStatsChart(_ref) {
  var theme = _ref.theme,
    coin = _ref.coin,
    cash = _ref.cash;
  return [{
    id: 'quick-stat-1',
    value: 570,
    desc: 'Total Sales',
    data: [10, 14, 18, 11, 9, 12, 14, 17, 18, 14],
    color: theme.info,
    border: 3
  }, {
    id: 'quick-stat-2',
    value: '234+',
    desc: 'Transactions',
    data: [12, 12, 18, 11, 15, 12, 13, 16, 11, 18],
    color: theme.success,
    border: 3
  }, {
    id: 'quick-stat-3',
    value: currencyByType(coin === null || coin === void 0 ? void 0 : coin.total, walletType.ONE),
    desc: 'Total ONE Transactions',
    data: coin === null || coin === void 0 ? void 0 : coin.transactions,
    color: theme.gray,
    labels: coin === null || coin === void 0 ? void 0 : coin.transactions,
    border: 3
  }, {
    id: 'quick-stat-4',
    value: currencyByType(cash === null || cash === void 0 ? void 0 : cash.total, walletType.CASH),
    desc: 'Total CASH Transactions',
    data: cash === null || cash === void 0 ? void 0 : cash.transactions,
    color: theme.infoDark,
    labels: cash === null || cash === void 0 ? void 0 : cash.transactions,
    border: 3
  }];
};