import { css } from 'emotion';
export var withTitleContent = css({
  padding: '0px 40px 48px 40px'
});
export var latestTransactionsContainer = function latestTransactionsContainer(theme) {
  return css({
    backgroundColor: theme.white,
    boxShadow: '0 1px 2px rgba(0,0,0,0.3)'
  });
};
export var headerContainerWallets = function headerContainerWallets(theme) {
  return css({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: "1px solid ".concat(theme.borderLight),
    padding: '24px 32px'
  });
};
export var linkHistory = function linkHistory(theme) {
  return css({
    color: theme.info
  });
};
export var noTransactions = function noTransactions(theme) {
  return css({
    color: theme.gray,
    fontWeight: 600,
    lineHeight: 1,
    padding: '24px',
    textAlign: 'center'
  });
};
export var withBreadcrumbs = css({
  padding: '0px 0px 48px 40px'
});
export var breadcrumbs = css({
  padding: '48px 0px 0px 40px'
});
export var noTitleContent = css({
  padding: '48px 40px'
});
export var marginBottom40 = css({
  marginBottom: 40
});
export var addPackageContainer = css(withTitleContent, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});
export var profileAccountContainer = css({
  position: 'relative'
});
export var tabsButton = css({
  position: 'absolute',
  right: 22,
  top: 16
});