import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isNil from "lodash/isNil";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { Paper, Row, Col, Breadcrumbs, Skeleton } from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import ColorBatch from '../../../components/Shared/ColorBatch';
import { displayProperty } from '../../../utils/displayData';
import { generalInfo, pagesDetails } from './config';
import { containerBack, paperContainer, navLink, tabContainer, statusContainer, labelContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var DetailPagePlan = function DetailPagePlan() {
  var theme = useTheme();
  var _useLocation = useLocation(),
    state = _useLocation.state;
  var item = state;
  return ___EmotionJSX("div", {
    className: tabContainer
  }, ___EmotionJSX(Breadcrumbs, {
    items: pagesDetails(item.type)
  }), ___EmotionJSX("div", {
    className: containerBack
  }, ___EmotionJSX(BackButton, {
    title: "Plan Information"
  }), ___EmotionJSX(Link, {
    to: {
      pathname: '/plans/edit-plan',
      state: _objectSpread(_objectSpread({}, item), {}, {
        type: item.type
      })
    },
    className: navLink(theme)
  }, "Edit Plan")), ___EmotionJSX(Row, {
    gap: 16
  }, ___EmotionJSX(Col, null, ___EmotionJSX(Paper, {
    className: paperContainer,
    header: "General info"
  }, ___EmotionJSX(Row, {
    margin: "0 0 16px"
  }, generalInfo.map(function (el) {
    return ___EmotionJSX(Col, {
      key: el.label,
      md: 3,
      gap: 8
    }, ___EmotionJSX("h4", null, el.label), ___EmotionJSX("span", null, _isNil(item) ? ___EmotionJSX(Skeleton, {
      width: 120,
      height: 18
    }) : ___EmotionJSX("span", null, displayProperty(el, item))));
  }), ___EmotionJSX(Col, {
    md: 3,
    gap: 8
  }, ___EmotionJSX("h4", null, "Interest Rate"), ___EmotionJSX("span", null, _isNil(item === null || item === void 0 ? void 0 : item.interestRate) ? ___EmotionJSX(Skeleton, {
    width: 120,
    height: 18
  }) : ___EmotionJSX("span", null, item.interestRate, " %"))), ___EmotionJSX(Col, {
    md: 3,
    gap: 8
  }, ___EmotionJSX("h4", {
    className: labelContainer
  }, "Status"), ___EmotionJSX("span", null, _isNil(item === null || item === void 0 ? void 0 : item.active) ? ___EmotionJSX(Skeleton, {
    width: 120,
    height: 22
  }) : ___EmotionJSX(ColorBatch, {
    type: item.active ? 'success' : 'error',
    className: statusContainer
  }, item.active ? 'Active' : 'Inactive'))), ___EmotionJSX(Col, {
    md: 3,
    gap: 8
  }, ___EmotionJSX("h4", {
    className: labelContainer
  }, "Visibility"), ___EmotionJSX("span", null, _isNil(item === null || item === void 0 ? void 0 : item.visible) ? ___EmotionJSX(Skeleton, {
    width: 120,
    height: 22
  }) : ___EmotionJSX(ColorBatch, {
    type: item.visible ? 'success' : 'error',
    className: statusContainer
  }, item.visible ? 'Visible' : 'Invisible'))))))));
};
export default DetailPagePlan;