import React from 'react';
import PropTypes from 'prop-types';
import { backgroundPicture, imageContainer, child } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Image = function Image(props) {
  var children = props.children,
    src = props.src,
    alt = props.alt,
    width = props.width,
    height = props.height,
    img = props.img;
  return img ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX("img", {
    src: src,
    alt: alt,
    width: width || '100%',
    height: height || 'auto',
    className: imageContainer(props)
  }), ___EmotionJSX("div", {
    className: child(props)
  }, children)) : ___EmotionJSX("div", {
    title: alt,
    className: backgroundPicture(props)
  }, children);
};
Image.propTypes = {
  children: PropTypes.any,
  img: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default Image;