import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { currencyByType } from '../../../utils';
import { type as walletType } from '../../../enums/wallets';
export var rowsDefinition = function rowsDefinition(isCurrentRound) {
  return [!isCurrentRound && {
    label: 'Bonus Cap',
    render: function render(data) {
      return currencyByType((data === null || data === void 0 ? void 0 : data.bonusCap) || 0, walletType.CASH);
    }
  }, {
    label: 'Total Bonus',
    render: function render(data) {
      return currencyByType((data === null || data === void 0 ? void 0 : data.totalBonus) || 0, walletType.CASH);
    }
  }, {
    label: isCurrentRound ? 'Bonus to be Paid' : 'Paid Bonus',
    render: function render(data) {
      return currencyByType((data === null || data === void 0 ? void 0 : data.paidBonus) || 0, walletType.CASH);
    }
  }].filter(function (el) {
    return el;
  });
};