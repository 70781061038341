function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _isFunction from "lodash/isFunction";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Row, Col, Button, Image, pluralizeMonths } from '../../UI';
import avatar from '../../../assets/images/package.png';
import { containerShop, containerPackageShop, shopPackageCardTop, pictureContainer, shopPackageCardAdd, buttonAddToCard, titlePackage } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Subscription = function Subscription(props) {
  var _props$item = props.item,
    item = _props$item === void 0 ? {} : _props$item,
    onAddToCard = props.onAddToCard,
    onClick = props.onClick,
    className = props.className,
    _props$detailsRoute = props.detailsRoute,
    detailsRoute = _props$detailsRoute === void 0 ? '/subscriptions/subscription' : _props$detailsRoute;
  var name = item.name,
    price = item.price,
    duration = item.duration,
    vendor = item.vendor,
    picThumbnailUrl = item.picThumbnailUrl,
    subscriptionCatalogueItemId = item.subscriptionCatalogueItemId;
  var theme = useTheme();
  var handleLinkClick = function handleLinkClick(e) {
    return e.preventDefault();
  };
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: containerShop(className),
    onClick: onClick
  }, ___EmotionJSX(Link, _extends({
    to: "".concat(detailsRoute, "/").concat(subscriptionCatalogueItemId),
    className: "package-card-info ".concat(shopPackageCardTop(theme))
  }, _isFunction(onClick) && {
    onClick: handleLinkClick
  }), ___EmotionJSX("h4", {
    style: {
      flex: '0 0 100%',
      marginBottom: 12
    }
  }, vendor), ___EmotionJSX("div", {
    className: containerPackageShop
  }, ___EmotionJSX(Image, {
    size: "contain",
    src: picThumbnailUrl !== null && picThumbnailUrl !== void 0 ? picThumbnailUrl : avatar,
    width: 60,
    height: 60,
    className: pictureContainer
  }), ___EmotionJSX("h4", {
    className: titlePackage
  }, name, ___EmotionJSX("br", null), "\u20AC", price.toFixed(2), ___EmotionJSX("br", null), duration, " ", pluralizeMonths(duration)))), onAddToCard && ___EmotionJSX("div", {
    className: "package-card-add ".concat(shopPackageCardAdd(theme))
  }, ___EmotionJSX(Row, {
    align: "center",
    horizontalGap: 4,
    verticalGap: 4,
    justify: "space-between"
  }, ___EmotionJSX(Col, {
    basis: "auto",
    grow: "0"
  }, ___EmotionJSX(Button, {
    className: buttonAddToCard(theme),
    secondary: true,
    onClick: function onClick() {
      return onAddToCard(_objectSpread({}, item));
    }
  }, "Add to cart")))));
};
Subscription.propTypes = {
  item: PropTypes.any,
  onAddToCard: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  detailsRoute: PropTypes.string
};
export default Subscription;