function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _isNil from "lodash/isNil";
var _excluded = ["label"];
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Input, Button, Row, Col, Skeleton, CheckButton, Icon, Tooltip } from '../../UI';
import DatePicker from '../../UI/DatePicker';
import { stringToBoolean } from '../../../utils';
import { inputContainer, booleanContainer, questionImage, imageContainer, tooltipStyle } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var TYPE = {
  BOOL: 'boolean',
  DATE: 'date',
  DATE_RANGE: 'date-range',
  INPUT: 'input'
};
export var InputField = function InputField(_ref) {
  var _mapFunctions, _mapComponents, _mapComponents$type;
  var onClick = _ref.onClick,
    valueProp = _ref.value,
    label = _ref.label,
    type = _ref.type,
    _ref$componentProps = _ref.componentProps,
    componentProps = _ref$componentProps === void 0 ? {} : _ref$componentProps,
    rowProps = _ref.rowProps,
    description = _ref.description;
  var _useState = useState(valueProp),
    _useState2 = _slicedToArray(_useState, 2),
    value = _useState2[0],
    setValue = _useState2[1];
  var internalLabel = componentProps.label,
    compProps = _objectWithoutProperties(componentProps, _excluded);
  var theme = useTheme();
  useEffect(function () {
    setValue(valueProp);
  }, [valueProp]);
  var BooleanComponent = ___EmotionJSX("div", {
    className: booleanContainer
  }, ___EmotionJSX(CheckButton, _extends({
    checked: stringToBoolean(value),
    onChange: function onChange(val) {
      return setValue("".concat(val));
    }
  }, compProps), internalLabel));
  var DateRangeComponent = ___EmotionJSX("div", {
    className: booleanContainer
  }, ___EmotionJSX(DatePicker, _extends({
    onChange: setValue
  }, compProps)));
  var DateComponent = ___EmotionJSX("div", {
    className: booleanContainer
  }, ___EmotionJSX(Input, _extends({
    value: value,
    onChange: function onChange(_ref2) {
      var target = _ref2.target;
      return setValue(target.value);
    },
    className: inputContainer
  }, compProps)));
  var InputComponent = ___EmotionJSX(Input, _extends({
    value: value,
    onChange: function onChange(_ref3) {
      var target = _ref3.target;
      return setValue(target.value);
    },
    className: inputContainer
  }, compProps));
  var mapFunctions = (_mapFunctions = {}, _defineProperty(_mapFunctions, TYPE.BOOL, function () {
    return onClick(value);
  }), _defineProperty(_mapFunctions, TYPE.DATE, function () {
    return value.length && onClick(value);
  }), _defineProperty(_mapFunctions, TYPE.INPUT, function () {
    return value.length && onClick(value);
  }), _mapFunctions);
  var mapComponents = (_mapComponents = {}, _defineProperty(_mapComponents, TYPE.BOOL, BooleanComponent), _defineProperty(_mapComponents, TYPE.DATE, DateComponent), _defineProperty(_mapComponents, TYPE.DATE_RANGE, DateRangeComponent), _mapComponents);
  return ___EmotionJSX(Row, _extends({
    align: "center"
  }, rowProps), ___EmotionJSX(Col, {
    md: 4
  }, ___EmotionJSX("h4", null, label)), ___EmotionJSX(Col, {
    md: 7,
    dFlex: true
  }, _isNil(value) ? ___EmotionJSX(Skeleton, {
    width: "100%",
    height: 50
  }) : (_mapComponents$type = mapComponents[type]) !== null && _mapComponents$type !== void 0 ? _mapComponents$type : InputComponent, ___EmotionJSX(Button, {
    onClick: mapFunctions[type]
  }, "Save")), ___EmotionJSX(Col, {
    md: 1
  }, ___EmotionJSX("div", {
    className: imageContainer
  }, ___EmotionJSX(Tooltip, {
    content: ___EmotionJSX("div", {
      className: tooltipStyle
    }, description),
    position: "right"
  }, ___EmotionJSX(Icon, {
    iconName: "fa fa-question-circle",
    className: questionImage,
    color: theme.grayLight
  })))));
};
InputField.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string,
  type: PropTypes.string,
  componentProps: PropTypes.object,
  rowProps: PropTypes.object,
  description: PropTypes.string
};