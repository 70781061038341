export var networkOptions = [{
  label: 'Nickname',
  value: 'nickName'
}, {
  label: 'Username',
  value: 'profileUserName'
}, {
  label: 'Email',
  value: 'profileEmail'
}, {
  label: 'Phone',
  value: 'profilePhone'
}];