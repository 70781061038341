import { css } from 'emotion';
export var inputDesc = css({
  marginRight: 6,
  padding: '4px 6px',
  marginTop: 4,
  lineHeight: 1
});
export var informationContainer = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 4,
  '& h4': {
    marginRight: 6,
    fontSize: 15
  },
  '& span': {
    fontSize: 14
  }
});
export var informationContainerWithActions = css(informationContainer, {
  alignItems: 'flex-start'
});
export var commentsContainer = css({
  '& h3': {
    marginBottom: 12
  },
  '& .commentsList': {
    maxHeight: 185,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  '& .commentItem': {
    marginBottom: 4,
    whiteSpace: 'pre-line',
    '& > strong': {
      marginRight: 6
    }
  }
});
export var multiLineText = css({
  whiteSpace: 'pre-line'
});
export var oneRowContainer = css({
  display: 'flex',
  alignItems: 'center',
  '& > .expand, & > textarea': {
    flex: 1,
    marginRight: 16
  }
});
export var multiRowContainer = css({
  display: 'flex',
  flexDirection: 'column',
  '& > *': {
    marginBottom: 12,
    '&:last-child': {
      marginBottom: 0
    }
  }
});