function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Col, Dropdown, Modal, ReactSelectDropdown, Row, Spinner } from '../../UI';
import CounterInput from '../../Orders/CounterInput';
import useAccounts from '../../../hooks/useAccounts';
import { accountsOptions } from '../../../enums/account';
import { jsx as ___EmotionJSX } from "@emotion/react";
var AddVotesModal = function AddVotesModal(props) {
  var _filterState$count, _filterState$account;
  var modalRef = props.modalRef,
    onSubmit = props.onSubmit;
  var _useAccounts = useAccounts(),
    searchAccountRef = _useAccounts.searchAccountRef,
    hasMore = _useAccounts.hasMore,
    fetchAccounts = _useAccounts.fetchAccounts;
  var _useState = useState({
      count: 1,
      account: null
    }),
    _useState2 = _slicedToArray(_useState, 2),
    filterState = _useState2[0],
    setFilterState = _useState2[1];
  var handleSubmit = function handleSubmit() {
    onSubmit(filterState);
    setFilterState({
      count: 1,
      account: null
    });
  };
  var handleChangeAccount = function handleChangeAccount(value) {
    setFilterState(function (prev) {
      return _objectSpread(_objectSpread({}, prev), {}, {
        account: value
      });
    });
  };
  var handleChangeCount = function handleChangeCount(value) {
    setFilterState(function (prev) {
      return _objectSpread(_objectSpread({}, prev), {}, {
        count: value
      });
    });
  };
  var MenuList = function MenuList(_ref) {
    var children = _ref.children;
    return ___EmotionJSX("div", {
      style: {
        maxHeight: '300px',
        overflow: 'auto'
      }
    }, ___EmotionJSX(InfiniteScroll, {
      loadMore: function loadMore() {
        fetchAccounts({
          page: searchAccountRef.current.pageIndex + 1
        });
      },
      hasMore: hasMore,
      loader: ___EmotionJSX(Spinner, {
        key: 0
      }),
      initialLoad: false,
      useWindow: false
    }, children));
  };
  var handleModalClose = function handleModalClose() {
    setFilterState({
      count: 1,
      account: null
    });
  };
  return ___EmotionJSX(Modal, {
    ref: modalRef,
    large: true,
    modalTitle: "Add Votes",
    onClose: handleModalClose
  }, ___EmotionJSX(Row, {
    gap: 8,
    align: "center"
  }, ___EmotionJSX(Col, {
    xl: 3,
    lg: 3,
    md: 12,
    sm: 12,
    xs: 12
  }, ___EmotionJSX(Dropdown, {
    noClear: true,
    value: accountsOptions[0],
    options: accountsOptions,
    onChange: function onChange(_ref2) {
      var value = _ref2.value;
      searchAccountRef.current.searchCriteria = value;
    }
  })), ___EmotionJSX(Col, {
    xl: 5,
    lg: 5,
    md: 12,
    sm: 12,
    xs: 12
  }, ___EmotionJSX(ReactSelectDropdown, {
    async: true,
    cachOptions: true,
    defaultOptions: true,
    placeholder: "Search account",
    loadOptions: function loadOptions(inputValue, callback) {
      fetchAccounts({
        inputValue: inputValue,
        callback: callback
      });
    },
    components: {
      MenuList: MenuList
    },
    onChange: handleChangeAccount
  })), ___EmotionJSX(Col, {
    xl: 2,
    lg: 2,
    md: 12,
    sm: 12,
    xs: 12
  }, ___EmotionJSX(CounterInput, {
    count: (_filterState$count = filterState.count) !== null && _filterState$count !== void 0 ? _filterState$count : 1,
    min: 1,
    onCountChange: handleChangeCount,
    width: 80
  })), ___EmotionJSX(Col, {
    xl: 2,
    lg: 2,
    md: 12,
    sm: 12,
    xs: 12
  }, ___EmotionJSX(Button, {
    onClick: handleSubmit,
    disabled: !((_filterState$account = filterState.account) !== null && _filterState$account !== void 0 && _filterState$account.id)
  }, "Submit"))));
};
AddVotesModal.propTypes = {
  modalRef: PropTypes.object,
  onSubmit: PropTypes.func
};
export default AddVotesModal;