import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _omit from "lodash/omit";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import { getLeadersList } from '../../../../services/survey';
import { Table, tableTitleHeadline } from '../../../../components/UI';
import { voteTypes } from '../../../../enums/survey';
import RegionalLeadersFilterBar from '../../../../components/Profiles/RegionalLeadersFilterBar';
import { noTitleContent, titleContainer } from '../styles';
import { leadersColumns } from '../config';
import { jsx as ___EmotionJSX } from "@emotion/react";
var RegionalLeadersList = function RegionalLeadersList() {
  var fetchLeadersList = function fetchLeadersList(requestOptions) {
    var options = _objectSpread(_objectSpread({}, _omit(requestOptions, 'region')), {}, {
      voteTypeSpecifierValue: requestOptions.region,
      voteType: voteTypes.regionalLeaders
    });
    return getLeadersList(options);
  };
  return ___EmotionJSX("section", {
    className: noTitleContent
  }, ___EmotionJSX("div", {
    className: titleContainer
  }, ___EmotionJSX("h1", {
    className: tableTitleHeadline
  }, "Regional Leaders Ranking")), ___EmotionJSX(Table, {
    getDataKey: "votes",
    getDataMethod: fetchLeadersList,
    filterBar: function filterBar(options) {
      return RegionalLeadersFilterBar(_objectSpread({}, options));
    },
    columns: leadersColumns,
    pageSize: 20
  }));
};
export default RegionalLeadersList;