import { css } from 'emotion';
export var detailsContainer = function detailsContainer(theme) {
  return css({
    color: theme.info,
    fontWeight: 600
  });
};
export var titleStyles = css({
  display: 'flex',
  textAlign: 'center'
});