import { css } from 'emotion';
export var matchingTree = css({
  margin: '32px 24px'
});
export var tableDataBodyWrap = css({
  '@media (max-width: 1200px)': {
    overflowX: 'auto'
  }
});
export var tableDataBody = css({
  padding: '24px',
  backgroundColor: 'white',
  '@media (max-width: 1200px)': {
    minWidth: '1000px'
  }
});