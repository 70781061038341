function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Paper, Form, FormInput, FormParagraph, CheckBoxForm, FormDropdown, Modal, Button, Icon, CheckButton } from '../../UI';
import { rowComp, colComp } from '../../UI/Grid';
import BackButton from '../BackButton';
import UploadImage from '../UploadImage';
import FormColorInput from '../../UI/Form/FormColorInput';
import _validate from '../../../utils/inputValidation';
import { validateLength, validationsRegexp } from '../../../utils/validations';
import { optionsTypePackage } from './definitions';
import avatar from '../../../assets/images/package.png';
import { backContainer, marginBottom, bonusContainer, paddingLeft, levelMatching, bonus, marginTop, questionUpdatePackage, buttonContainer, questionIcon, versionCheckContainer, prevVersionContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var testPattern = function testPattern(val, regex) {
  return regex.test(val);
};
var AddPackageForm = function AddPackageForm(props) {
  var headerTitle = props.headerTitle,
    textSubmitButton = props.textSubmitButton,
    onSubmit = props.onSubmit,
    _props$values = props.values,
    values = _props$values === void 0 ? {} : _props$values,
    isEdit = props.isEdit,
    paymentMethodsOptions = props.paymentMethodsOptions;
  var theme = useTheme();
  var splitRef = useRef();
  var maxSplitRef = useRef();
  var _useState = useState({
      showModal: false,
      version: false
    }),
    _useState2 = _slicedToArray(_useState, 2),
    changeVersion = _useState2[0],
    setChangeVersion = _useState2[1];
  var validateSplit = function validateSplit(splitValue) {
    var _maxSplitRef$current, _maxSplitRef$current2, _maxSplitRef$current3;
    return _validate(splitValue, ['required', 'positiveInt']) || !!((_maxSplitRef$current = maxSplitRef.current) !== null && _maxSplitRef$current !== void 0 && _maxSplitRef$current.value) && ((_maxSplitRef$current2 = maxSplitRef.current) === null || _maxSplitRef$current2 === void 0 ? void 0 : _maxSplitRef$current2.value) < parseInt(splitValue, 10) && {
      msg: "Split must be less than or equal to ".concat((_maxSplitRef$current3 = maxSplitRef.current) === null || _maxSplitRef$current3 === void 0 ? void 0 : _maxSplitRef$current3.value)
    };
  };
  var validateMaxSplit = function validateMaxSplit(maxSplitValue) {
    var _splitRef$current, _splitRef$current2, _splitRef$current3;
    return _validate(maxSplitValue, ['required', 'positiveInt']) || !!((_splitRef$current = splitRef.current) !== null && _splitRef$current !== void 0 && _splitRef$current.value) && parseInt(maxSplitValue, 10) < ((_splitRef$current2 = splitRef.current) === null || _splitRef$current2 === void 0 ? void 0 : _splitRef$current2.value) && {
      msg: "Max splits must be greater than or equal to ".concat((_splitRef$current3 = splitRef.current) === null || _splitRef$current3 === void 0 ? void 0 : _splitRef$current3.value)
    };
  };
  var onClose = function onClose() {
    setChangeVersion({
      showModal: false,
      version: false
    });
  };
  var handleAccept = function handleAccept() {
    setChangeVersion({
      showModal: false,
      version: true
    });
  };
  var handleSubmit = function handleSubmit(values) {
    return onSubmit(isEdit ? _objectSpread(_objectSpread({}, values), {}, {
      updatePastVersions: changeVersion.version
    }) : values);
  };
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(BackButton, {
    className: backContainer,
    title: headerTitle
  }), ___EmotionJSX(Paper, null, ___EmotionJSX(Form, {
    onSubmit: handleSubmit,
    onChange: function onChange(id) {
      id === 'split' && maxSplitRef.current.checkForError(maxSplitRef.current.value);
      id === 'maxSplits' && splitRef.current.checkForError(splitRef.current.value);
    },
    submitButton: {
      children: textSubmitButton
    },
    values: values
  }, ___EmotionJSX(FormParagraph, {
    className: rowComp({
      margin: '0 -16px'
    })
  }, ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 12,
      lg: 12,
      horizontalGap: 16
    })
  }, ___EmotionJSX(UploadImage, {
    id: "picThumbnailUrl",
    defaultValue: avatar
  })), ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 4,
      lg: 6,
      horizontalGap: 16
    })
  }, ___EmotionJSX(FormInput, {
    id: "name",
    label: "Name",
    required: true
  }), ___EmotionJSX(FormDropdown, {
    id: "type",
    label: "Type",
    placeholder: "Select type...",
    mapValue: function mapValue(val) {
      return val === null || val === void 0 ? void 0 : val.value;
    },
    options: optionsTypePackage,
    required: true,
    noClear: true
  }), ___EmotionJSX(FormInput, {
    id: "description",
    label: "Description",
    required: true,
    validate: validateLength(2000)
  }), ___EmotionJSX(FormInput, {
    id: "price",
    label: "Price",
    required: true,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'price']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.pricePattern);
    }
  }), ___EmotionJSX(FormInput, {
    id: "tokens",
    label: "Tokens",
    number: true,
    required: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "academyTokens",
    label: "Max Academy Tokens",
    number: true,
    required: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "split",
    label: "Split",
    required: true,
    number: true,
    ref: splitRef,
    validate: validateSplit,
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "maxSplits",
    label: "Max splits",
    required: true,
    number: true,
    ref: maxSplitRef,
    validate: validateMaxSplit,
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "promoSplits",
    label: "Promo splits",
    required: true,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  })), ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 4,
      lg: 6,
      horizontalGap: 16
    })
  }, ___EmotionJSX(FormInput, {
    id: "difficulty",
    label: "Difficulty reduction",
    required: true,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "exchangeReduction",
    label: "Exchange reduction",
    required: true,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "businessVolume",
    label: "Business volume",
    required: true,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "bonusCap",
    label: "Bonus Cap",
    required: true,
    number: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormInput, {
    id: "gasAmount",
    label: "GAS Amount",
    number: true,
    required: true,
    validate: function validate(val) {
      return _validate(val, ['required', 'positiveInt']);
    },
    pattern: function pattern(val) {
      return testPattern(val, validationsRegexp.positiveIntPatternWithZero);
    }
  }), ___EmotionJSX(FormColorInput, {
    id: "color",
    label: "Color",
    required: true,
    placement: "right",
    defaultColor: "#6379c9",
    validate: function validate(val) {
      return _validate(val, ['required', 'validHex']);
    }
  }), isEdit && ___EmotionJSX(FormDropdown, {
    id: "shopPaymentMethodIds",
    label: "Payment Methods",
    placeholder: "Select payment methods...",
    multiSelect: true,
    mapValue: function mapValue(val) {
      return val === null || val === void 0 ? void 0 : val.map(function (el) {
        return el.id;
      });
    },
    options: paymentMethodsOptions,
    required: true,
    noClear: true,
    uniqueKey: "id",
    displayKey: "displayName"
  })), ___EmotionJSX(FormParagraph, {
    className: colComp({
      xl: 4,
      lg: 6,
      horizontalGap: 16
    })
  }, ___EmotionJSX("h3", {
    className: marginBottom(16)
  }, "Applicable for:"), ___EmotionJSX(CheckBoxForm, {
    id: "hasNetworkBonus",
    label: "Networking bonus",
    className: bonusContainer
  }), ___EmotionJSX("h4", {
    className: "".concat(paddingLeft(24), " ").concat(marginBottom(16))
  }, "Matching bonus:"), ___EmotionJSX(FormParagraph, {
    className: levelMatching
  }, ___EmotionJSX(CheckBoxForm, {
    id: "hasMatch1Bonus",
    label: "1st level"
  }), ___EmotionJSX(CheckBoxForm, {
    id: "hasMatch2Bonus",
    label: "2nd level"
  }), ___EmotionJSX(CheckBoxForm, {
    id: "hasMatch3Bonus",
    label: "3rd level"
  }), ___EmotionJSX(CheckBoxForm, {
    id: "hasMatch4Bonus",
    label: "4th level"
  })), ___EmotionJSX(FormParagraph, {
    className: bonus
  }, ___EmotionJSX(CheckBoxForm, {
    id: "immediateMining",
    label: "Automining is allowed"
  }), ___EmotionJSX("h4", {
    className: " ".concat(marginTop(16), " ").concat(marginBottom(16))
  }, "Properties:"), ___EmotionJSX(CheckBoxForm, {
    id: "active",
    label: "Status"
  }), ___EmotionJSX(CheckBoxForm, {
    id: "visible",
    label: "Visibility"
  }), ___EmotionJSX(CheckBoxForm, {
    id: "allSplitsAtOnce",
    label: "All Splits At Once"
  }), ___EmotionJSX(CheckBoxForm, {
    id: "mandatoryActivation",
    label: "Activation fee required"
  })))), isEdit && ___EmotionJSX("div", {
    className: versionCheckContainer
  }, ___EmotionJSX(CheckButton, {
    checked: changeVersion.version,
    onChange: function onChange(val) {
      setChangeVersion({
        showModal: true,
        version: val
      });
    }
  }), ___EmotionJSX("h5", null, "Update all previous versions of the package"))), ___EmotionJSX(Modal, {
    show: !!changeVersion.showModal,
    onClose: onClose
  }, ___EmotionJSX("div", {
    className: prevVersionContainer(theme)
  }, ___EmotionJSX(Icon, {
    iconName: "contact_support",
    material: true,
    className: questionIcon(theme),
    style: {
      display: 'flex',
      justifyContent: 'center'
    }
  }), ___EmotionJSX("h3", {
    className: questionUpdatePackage
  }, "Are you sure you want to update previous versions of the package that may have been redeemed?"), ___EmotionJSX("div", {
    className: buttonContainer
  }, ___EmotionJSX(Button, {
    onClick: onClose,
    secondary: true
  }, "Cancel"), ___EmotionJSX(Button, {
    onClick: handleAccept
  }, "Accept"))))));
};
AddPackageForm.propTypes = {
  headerTitle: PropTypes.string,
  textSubmitButton: PropTypes.string,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  isEdit: PropTypes.bool,
  paymentMethodsOptions: PropTypes.array
};
export default AddPackageForm;