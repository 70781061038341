import { css } from 'emotion';
export var container = function container(_ref, theme) {
  var withShadow = _ref.withShadow,
    className = _ref.className;
  return css({
    width: '100%',
    backgroundColor: theme.white,
    border: "1px solid ".concat(theme.borderLight),
    borderRadius: 4
  }, withShadow && {
    boxShadow: '0 6px 8px 0 rgba(224, 225, 229, 0.58)'
  }, className);
};
export var contentContainer = function contentContainer(_ref2) {
  var noPadding = _ref2.noPadding,
    autoHeight = _ref2.autoHeight;
  return css({
    padding: noPadding ? 0 : '24px 32px',
    height: autoHeight ? 'auto' : '100%'
  });
};
export var headerContainer = function headerContainer(theme) {
  return css({
    borderBottom: "1px solid ".concat(theme.borderLight),
    padding: '20px 32px'
  });
};