import { css } from 'emotion';
export var backContainer = css({
  marginBottom: 48
});
export var editPaymentMethodContainer = css({
  maxWidth: 850
});
export var marginRight = function marginRight(val) {
  return css({
    marginRight: val
  });
};