import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isNil from "lodash/isNil";
var _mapEagleStatus;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/* eslint-disable react/prop-types */
import React from 'react';
import { DetailsLink } from '../../../../components/Shared/TableComponents';
import ColorBatch from '../../../../components/Shared/ColorBatch';
import { eagleStatus } from '../../../../enums/bonuses';
import { profileRankId } from '../../../../enums/profiles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var mapEagleStatus = (_mapEagleStatus = {}, _defineProperty(_mapEagleStatus, eagleStatus.Pending, {
  text: 'Pending',
  color: 'info'
}), _defineProperty(_mapEagleStatus, eagleStatus.Succeeded, {
  text: 'Succeeded',
  color: 'success'
}), _defineProperty(_mapEagleStatus, eagleStatus.RanksSaved, {
  text: 'Ranks Saved',
  color: 'warning'
}), _defineProperty(_mapEagleStatus, eagleStatus.WalletsRewarded, {
  text: 'Wallets Rewarded',
  color: 'error'
}), _mapEagleStatus);
export var eagleSuccess = {
  "true": {
    text: 'Yes',
    color: 'success'
  },
  "false": {
    text: 'No',
    color: 'error'
  }
};
export var eagleColumns = [{
  name: 'Date From',
  value: 'targetDateFrom',
  dateTimeFormat: 'DD/MM/YYYY'
}, {
  name: 'Date To',
  value: 'targetDateTo',
  dateTimeFormat: 'DD/MM/YYYY'
}, {
  name: 'Run ID',
  value: 'id'
}, {
  name: 'Success',
  value: 'success',
  render: function render(row) {
    var _eagleSuccess$row$suc, _eagleSuccess$row$suc2;
    return ___EmotionJSX(ColorBatch, {
      type: (_eagleSuccess$row$suc = eagleSuccess[row.success]) === null || _eagleSuccess$row$suc === void 0 ? void 0 : _eagleSuccess$row$suc.color
    }, (_eagleSuccess$row$suc2 = eagleSuccess[row.success]) === null || _eagleSuccess$row$suc2 === void 0 ? void 0 : _eagleSuccess$row$suc2.text);
  }
}, {
  name: 'Status ',
  render: function render(_ref, NullItem) {
    var _mapEagleStatus$statu, _mapEagleStatus$statu2;
    var status = _ref.status;
    return !_isNil(status) ? ___EmotionJSX(ColorBatch, {
      type: (_mapEagleStatus$statu = mapEagleStatus[status]) === null || _mapEagleStatus$statu === void 0 ? void 0 : _mapEagleStatus$statu.color
    }, (_mapEagleStatus$statu2 = mapEagleStatus[status]) === null || _mapEagleStatus$statu2 === void 0 ? void 0 : _mapEagleStatus$statu2.text) : ___EmotionJSX(NullItem, null);
  }
}, {
  name: 'View details ',
  render: function render(row) {
    return ___EmotionJSX(DetailsLink, {
      row: row,
      url: "/recognition/eagle-run-history/".concat(row.id)
    });
  }
}];
export var eagleRunProfileHistoryBreadCrumb = [{
  url: '/recognition/eagle',
  label: 'Eagle'
}, {
  label: 'Eagle Run Details'
}];
export var eagleRunHistoryColumns = [{
  name: 'Date From',
  value: 'rankRunTargetDateFrom',
  dateTimeFormat: 'DD/MM/YYYY',
  width: 90
}, {
  name: 'Date To',
  value: 'rankRunTargetDateTo',
  dateTimeFormat: 'DD/MM/YYYY',
  "with": 90
}, {
  name: 'Profile Id',
  value: 'profileId',
  width: 90
}, {
  name: 'User',
  render: function render(row) {
    return ___EmotionJSX("div", null, (row === null || row === void 0 ? void 0 : row.firstName) && (row === null || row === void 0 ? void 0 : row.lastName) && ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Name:"), " ", row.firstName, row.lastName), (row === null || row === void 0 ? void 0 : row.userName) && ___EmotionJSX("p", null, ___EmotionJSX("b", null, "UserName:"), " ", row.userName), (row === null || row === void 0 ? void 0 : row.email) && ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Email:"), " ", row.email), (row === null || row === void 0 ? void 0 : row.phone) && ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Phone:"), " ", row.phone));
  }
}, {
  name: 'Previous Rank',
  render: function render(_ref2, NullItem) {
    var previousRankId = _ref2.previousRankId;
    return _isNil(previousRankId) ? ___EmotionJSX(NullItem, null) : profileRankId[previousRankId];
  }
}, {
  name: 'Current Rank',
  render: function render(_ref3, NullItem) {
    var eagleRankId = _ref3.eagleRankId;
    return _isNil(eagleRankId) ? ___EmotionJSX(NullItem, null) : profileRankId[eagleRankId];
  }
}, {
  name: 'Previous Stars',
  value: 'previousRankStars',
  width: 80
}, {
  name: 'Current Stars',
  value: 'eagleRankStars',
  width: 80
}];