import "core-js/modules/es.array.concat.js";
import validate from './inputValidation';
export var validationsRegexp = {
  positiveIntPattern: /^[1-9][0-9]{0,}$/,
  positiveIntPatternWithZero: /^0$|^[1-9][0-9]{0,}$/,
  balancePattern: /^[0-9]{1,}\.*[0-9]{0,2}$/,
  coinBalancePattern: /^[0-9]{1,}\.*[0-9]{0,6}$/,
  longDecimalPattern: /^[0-9]{1,}\.*[0-9]{0,15}$/,
  pricePattern: /^(?:[1-9][0-9]{0,})\.*[0-9]{0,2}$/,
  phone: /^$|^[0-9]$/,
  pinPattern: /^[0-9]{0,4}$/
};
export var validateLength = function validateLength(length) {
  return function (value) {
    return validate(value, ['required']) || value.length > length && {
      msg: "Maximum length of the characters is ".concat(length, "!")
    };
  };
};
export var validateTwoLength = function validateTwoLength(firstLength, secondLength) {
  return function (value) {
    return validate(value, ['required']) || (value.length > secondLength || value.length < firstLength) && {
      msg: "Length of the characters is  between ".concat(firstLength, " and ").concat(secondLength, "!")
    };
  };
};
export var validateLengthNotRequired = function validateLengthNotRequired(length) {
  return function (value) {
    return value.length > length && {
      msg: "Maximum length of the characters is ".concat(length, "!")
    };
  };
};
export var validateTwoLengthNotRequired = function validateTwoLengthNotRequired(firstLength, secondLength) {
  return function (value) {
    return (value.length > secondLength || value.length < firstLength) && {
      msg: "Length of the characters is  between ".concat(firstLength, " and ").concat(secondLength, "!")
    };
  };
};