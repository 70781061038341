import { css } from 'emotion';
export var containerClass = function containerClass(className) {
  return css({
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    marginLeft: 0,
    padding: 0
  }, className);
};
export var text = function text(theme) {
  return css({
    fontSize: 14,
    fontWeight: 600,
    color: theme.grayLight
  });
};
export var linkItem = function linkItem(theme, isActive) {
  return css({
    fontSize: 14,
    fontWeight: 600,
    color: isActive ? theme.info : theme.grayLight
  });
};
export var separator = function separator(theme) {
  return css({
    margin: '0px 8px',
    fontSize: 14,
    fontWeight: 600,
    color: theme.grayLight
  });
};