import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import _isFunction from "lodash/isFunction";
import _isNil from "lodash/isNil";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.is-array.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { useState, useRef, useEffect } from 'react';
import hash from 'object-hash';
import { outputValue, removeNodes, prepareValue } from './utils';
export var useDropDown = function useDropDown(_ref) {
  var initValue = _ref.value,
    options = _ref.options,
    onChange = _ref.onChange,
    onBlur = _ref.onBlur,
    open = _ref.open,
    alwaysFocused = _ref.alwaysFocused,
    multiSelect = _ref.multiSelect,
    disabled = _ref.disabled,
    mapValue = _ref.mapValue,
    _ref$displayKey = _ref.displayKey,
    displayKey = _ref$displayKey === void 0 ? 'label' : _ref$displayKey,
    _ref$uniqueKey = _ref.uniqueKey,
    uniqueKey = _ref$uniqueKey === void 0 ? 'value' : _ref$uniqueKey,
    _ref$placeholder = _ref.placeholder,
    placeholder = _ref$placeholder === void 0 ? '' : _ref$placeholder,
    noSimplify = _ref.noSimplify,
    renderSelected = _ref.renderSelected;
  var selectedValue = prepareValue({
    value: initValue,
    options: options,
    multiSelect: multiSelect,
    uniqueKey: uniqueKey
  });
  var _useState = useState(selectedValue),
    _useState2 = _slicedToArray(_useState, 2),
    selected = _useState2[0],
    setSelected = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    isOpen = _useState4[0],
    setIsOpen = _useState4[1];
  var selectRef = useRef({
    parentElement: null,
    offsetWidth: 0
  });
  var isDisabled = !(options !== null && options !== void 0 && options.length) || disabled;
  useEffect(function () {
    open && setIsOpen(true);
  }, []);
  useEffect(function () {
    setTimeout(function () {
      var _selectRef$current, _selectRef$current$pa;
      alwaysFocused && isOpen && ((_selectRef$current = selectRef.current) === null || _selectRef$current === void 0 ? void 0 : (_selectRef$current$pa = _selectRef$current.parentElement) === null || _selectRef$current$pa === void 0 ? void 0 : _selectRef$current$pa.focus());
    });
  });
  useEffect(function () {
    setSelected(selectedValue);
  }, [hash({
    selectedValue: removeNodes(selectedValue, displayKey, uniqueKey)
  })]);
  var openClose = function openClose() {
    return !isDisabled && setIsOpen(function (prev) {
      return !prev;
    });
  };
  var areEquals = function areEquals(source, value) {
    // Check if the values are defined because isEqual with 2 undefined returns true
    var areDef = !_isNil(source[uniqueKey]) && !_isNil(value[uniqueKey]);
    var areSame = _isEqual(source[uniqueKey], value[uniqueKey]);
    return areDef && areSame;
  };
  var handleBlur = function handleBlur(_ref2) {
    var currentTarget = _ref2.currentTarget;
    return setTimeout(function () {
      if (!currentTarget.contains(document.activeElement) && isOpen) {
        setIsOpen(false);
        onBlur && onBlur();
      }
    });
  };
  var handleSelect = function handleSelect(newValue) {
    if (multiSelect) {
      if (_isNil(newValue)) return changeValue([]);
      var selectedUnique = selected === null || selected === void 0 ? void 0 : selected.filter(function (el) {
        return !_isNil(el);
      });
      var valueIndex = selectedUnique.findIndex(function (e) {
        return areEquals(e, newValue);
      });
      if (valueIndex === -1) return changeValue([].concat(_toConsumableArray(selectedUnique), [newValue]));
      selectedUnique.splice(valueIndex, 1);
      return changeValue(selectedUnique);
    }
    setIsOpen(false);
    return changeValue(newValue);
  };
  var handleClear = function handleClear(e) {
    e === null || e === void 0 ? void 0 : e.stopPropagation();
    handleSelect(null);
  };
  var changeValue = function changeValue(newValue) {
    var _selectRef$current2, _selectRef$current2$p;
    if (_isEqual(newValue, selected)) return;
    setSelected(newValue);
    setTimeout(function () {
      return onChange(outputValue(newValue, displayKey, mapValue, !noSimplify));
    });

    // Focus the dropdown so it can trigger onBlur function which will check for error
    // This is for the case where the user open the dropdown and do not select nothing (if the field is required is should show error)
    isOpen && ((_selectRef$current2 = selectRef.current) === null || _selectRef$current2 === void 0 ? void 0 : (_selectRef$current2$p = _selectRef$current2.parentElement) === null || _selectRef$current2$p === void 0 ? void 0 : _selectRef$current2$p.focus());
  };
  var getOptionValue = function getOptionValue(val) {
    return val && val[displayKey];
  };
  var hasSelection = multiSelect ? selected === null || selected === void 0 ? void 0 : selected.length : !_isEmpty(selected);
  var displayValue = _isFunction(renderSelected) ? renderSelected(selected) : multiSelect ? selected === null || selected === void 0 ? void 0 : selected.map(getOptionValue).join(', ') : getOptionValue(selected);
  var calculateDisplayValue = function calculateDisplayValue() {
    return hasSelection ? displayValue : placeholder;
  };
  return {
    openClose: openClose,
    handleBlur: handleBlur,
    handleSelect: handleSelect,
    handleClear: handleClear,
    isOpen: isOpen,
    selected: selected,
    displayValue: displayValue,
    selectRef: selectRef,
    calculateDisplayValue: calculateDisplayValue
  };
};