export var statusOptions = [{
  label: 'Unpaid',
  value: 'Unpaid'
}, {
  label: 'Redeemed',
  value: 'Redeemed'
}, {
  label: 'NOT Redeemed',
  value: 'NotRedeemed'
}];
export var searchRedeemerOptions = [{
  label: 'Redeemer User Name',
  value: 'userName'
}, {
  label: 'Redeemer Phone',
  value: 'phone'
}, {
  label: 'Redeemer Email',
  value: 'email'
}, {
  label: 'Redeemer UserID',
  value: 'userId'
}, {
  label: 'Redeemer Nickname',
  value: 'nickName'
}];
export var searchBuyerOptions = [{
  label: 'Buyer User Name',
  value: 'userName'
}, {
  label: 'Buyer Phone',
  value: 'phone'
}, {
  label: 'Buyer Email',
  value: 'email'
}, {
  label: 'Buyer Nickname',
  value: 'nickName'
}, {
  label: 'Buyer UserID',
  value: 'userId'
}];
export var searchByOptions = [{
  label: 'Gift Code',
  value: 'giftCode'
}, {
  label: 'Order ID',
  value: 'orderId'
}];
export var noResultsMessages = {
  buyerSearchFor: 'Buyer',
  redeemerSearchFor: 'Redeemer',
  paymentMethod: 'Payment Method',
  status: 'Status',
  packageName: 'Package',
  purchaseStartDate: 'Created Period',
  redeemStartDate: 'Redeem Period',
  searchCriteria: 'Gift code or order id'
};