export var giftCodeGeneralInfo = [{
  label: 'Order id',
  value: 'id'
}, {
  label: 'Date of order',
  value: 'dateOfOrder',
  dateTime: true
}, {
  label: 'Date of redeem',
  value: 'dateOfRedeem',
  dateTime: true
}, {
  label: 'Expire date',
  value: 'expireDate',
  dateTime: true
}, {
  label: 'Order Package Name',
  value: 'packageName'
}, {
  label: 'Promo tokens',
  value: 'promoTokens'
}, {
  label: 'Academy Tokens Reward',
  value: 'academyTokensReward'
}, {
  label: 'Academy Tokens Rewarded on',
  value: 'academyTokensRewardedOn',
  dateTime: true
}];