import { css } from 'emotion';
export var tabContainer = css({
  padding: '48px 40px'
});
export var backContainer = css({
  marginBottom: 48
});
export var bonusContainer = css({
  paddingLeft: 20,
  marginBottom: 12
});
export var paperContain = css({
  marginBottom: 50,
  maxWidth: 500
});