export var orderStatus = {
  Pending: 'Pending',
  Paid: 'Paid',
  Rejected: 'Rejected'
};
export var orderPaymentStatus = {
  Pending: 'Pending',
  Confirmed: 'Confirmed',
  Denied: 'Denied'
};
export var orderPaymentLabels = {
  BankDetails: 'Bank',
  CashWallet: 'Cash',
  ReserveCashWallet: 'Reserve Cash',
  CoinWallet: 'Coin',
  System: 'System',
  PerfectMoney: 'Perfect Money',
  ChinaUnionPay: 'China Union Pay'
};
export var ORDER_PAYMENT_STATUS = {
  PENDING: 'Pending',
  CONFIRMED: 'Confirmed',
  DENIED: 'Denied'
};
export var paymentType = {
  BankDetails: 'BankDetails',
  PerfectMoney: 'PerfectMoney',
  ChinaUnionPay: 'ChinaUnionPay',
  CashWallet: 'CashWallet',
  Bank50Coins50: 'Bank50Coins50',
  Cash75Bank25: 'Cash75Bank25',
  Bank75Cash25: 'Bank75Cash25',
  Bank75ReserveCash25: 'Bank75ReserveCash25',
  Bank75Coins25: 'Bank75Coins25',
  System: 'System',
  Cash75ReserveCash25: 'Cash75ReserveCash25',
  Cash75Coin25: 'Cash75Coin25',
  Bank50ReserveCash50: 'Bank50ReserveCash50',
  Cash50ReserveCash50: 'Cash50ReserveCash50',
  Cash50Coins50: 'Cash50Coins50'
};
export var orderItemsType = {
  Package: 'Package',
  Subscription: 'Subscription'
};
export var packageTypes = {
  GasPackage: 'GasPackage',
  Package: 'Package'
};