import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { css } from 'emotion';
export var networkTree = css({
  padding: '40px'
});
export var networkTreePortlet = function networkTreePortlet(theme) {
  return css({
    border: "1px solid ".concat(theme.lightGray),
    borderRadius: '3px'
  });
};
export var networkTreePortletHeader = function networkTreePortletHeader(theme) {
  return css({
    backgroundColor: theme.white,
    padding: '32px 30px 24px',
    borderBottom: "1px solid ".concat(theme.borderLight),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      alignItems: 'start'
    }
  });
};
export var networkTreeFilter = css({
  width: '100%'
});
export var networkTreePortletBody = function networkTreePortletBody(theme) {
  return css({
    backgroundColor: theme.white,
    padding: '32px',
    position: 'relative'
  });
};
export var networkTreePortletBodyButton = function networkTreePortletBodyButton(position) {
  return css(_defineProperty({
    position: 'absolute',
    top: '32px'
  }, position, '32px'));
};
export var networkTreeSectionHeadingSecondaryLight = function networkTreeSectionHeadingSecondaryLight(theme) {
  return css({
    fontSize: '18px',
    letterSpacing: '-0.2px',
    color: theme.grayDark,
    fontWeight: 400,
    width: '260px',
    paddingRight: '20px',
    '@media (max-width: 767px)': {
      marginBottom: '8px'
    }
  });
};