import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Link } from 'react-router-dom';
import { Row } from '../Grid';
import oneLifeLogo from '../../../assets/images/one-life-logo-text.png';
import NavLinks from './NavLinks';
import { useAuth } from '../Authenticate';
import { navBarContainer, appAvatarIcon } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NavBar = function NavBar(_ref) {
  var logoLink = _ref.logoLink,
    label = _ref.label;
  var _useAuth = useAuth(),
    isAuthenticated = _useAuth.isAuthenticated;
  var theme = useTheme();
  return ___EmotionJSX("div", {
    className: navBarContainer(theme)
  }, ___EmotionJSX(Row, {
    justify: "space-between",
    align: "center",
    noWrap: true
  }, isAuthenticated ? ___EmotionJSX("h3", null, label) : ___EmotionJSX(Link, {
    to: logoLink
  }, ___EmotionJSX("img", {
    src: oneLifeLogo,
    className: appAvatarIcon,
    alt: "Logo"
  })), ___EmotionJSX(NavLinks, null)));
};
NavBar.propTypes = {
  logoLink: PropTypes.string,
  label: PropTypes.string
};
export default NavBar;