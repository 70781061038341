import React, { useRef } from 'react';
import { Button } from '../../../components/UI';
import UploadFileModal from '../../../components/Import/UploadFileModal';
import { uploadButton } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ImportPage = function ImportPage() {
  var modalRef = useRef();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h1", null, "Import Transactions"), ___EmotionJSX("label", {
    htmlFor: "uploadImage"
  }, ___EmotionJSX(Button, {
    className: uploadButton,
    onClick: function onClick() {
      return modalRef.current.open();
    }
  }, "Upload File")), ___EmotionJSX(UploadFileModal, {
    modalRef: modalRef
  }));
};
export default ImportPage;