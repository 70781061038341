import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _mapTaskStatus;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import { Image } from '../../../components/UI';
import { DetailsLink } from '../../../components/Shared/TableComponents';
import ColorBatch from '../../../components/Shared/ColorBatch';
import { taskStatus } from '../../../enums/tasks';
import defaultAvatar from '../../../assets/images/default-avatar.png';
import { assigneeToImageContainer, assigneeContainer, nameContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var mapTaskStatus = (_mapTaskStatus = {}, _defineProperty(_mapTaskStatus, taskStatus.toDo, {
  text: 'To Do',
  color: 'warning'
}), _defineProperty(_mapTaskStatus, taskStatus.inProgress, {
  text: 'In Progress',
  color: 'info'
}), _defineProperty(_mapTaskStatus, taskStatus.done, {
  text: 'Done',
  color: 'success'
}), _mapTaskStatus);
var refTypeMap = {
  kyc: 'kyc'
};
export var tasksColumns = function tasksColumns(handleClickAssignee) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [{
    name: 'ID',
    value: 'id',
    width: 60
  }, {
    name: 'Type',
    value: 'type',
    startCase: true
  }, {
    name: 'Status',
    value: 'status',
    render: function render(row) {
      var _mapTaskStatus$row$st, _mapTaskStatus$row$st2;
      return ___EmotionJSX(ColorBatch, {
        type: (_mapTaskStatus$row$st = mapTaskStatus[row.status]) === null || _mapTaskStatus$row$st === void 0 ? void 0 : _mapTaskStatus$row$st.color
      }, (_mapTaskStatus$row$st2 = mapTaskStatus[row.status]) === null || _mapTaskStatus$row$st2 === void 0 ? void 0 : _mapTaskStatus$row$st2.text);
    }
  }, options.showAssignee && {
    name: 'Assignee to',
    render: function render(row) {
      var _row$assigneeProfile$, _row$assigneeProfile;
      return ___EmotionJSX("div", {
        className: assigneeContainer,
        onClick: handleClickAssignee,
        role: "button",
        tabIndex: 0
      }, ___EmotionJSX(Image, {
        img: true,
        src: defaultAvatar,
        alt: "".concat(row.id),
        className: assigneeToImageContainer
      }), ___EmotionJSX("h5", {
        className: nameContainer
      }, (_row$assigneeProfile$ = row === null || row === void 0 ? void 0 : (_row$assigneeProfile = row.assigneeProfile) === null || _row$assigneeProfile === void 0 ? void 0 : _row$assigneeProfile.userName) !== null && _row$assigneeProfile$ !== void 0 ? _row$assigneeProfile$ : 'Unassigned'));
    }
  }, {
    name: 'View details',
    render: function render(row) {
      var item = row.items && row.items[0];
      var type = refTypeMap[item === null || item === void 0 ? void 0 : item.refType];
      var itemId = item === null || item === void 0 ? void 0 : item.refId;
      return ___EmotionJSX(DetailsLink, {
        row: row,
        url: "/tasks/".concat(type, "/").concat(row.id, "/").concat(itemId)
      });
    }
  }].filter(Boolean);
};
export var detailPageBreadcrumb = [{
  url: '/tasks',
  label: 'Tasks'
}, {
  label: 'Detail Page'
}];
export var statsInformation = [{
  label: 'Pending',
  value: 'pending'
}, {
  label: 'Approved',
  value: 'approved'
}, {
  label: 'Declined',
  value: 'rejected'
}, {
  label: 'Total',
  value: 'total'
}, {
  label: 'Daily goal',
  value: 'dailyGoal'
}];