import { css } from 'emotion';
export var infoContainer = css({
  fontWeight: 600,
  '& > span': {
    fontWeight: 400
  }
});
export var transactionInfo = css({
  display: 'inline-block',
  marginBottom: 4,
  marginRight: 8,
  fontSize: 13,
  fontWeight: 400
});
export var columnContainer = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 0'
});
export var profileEmailText = function profileEmailText(theme) {
  return css({
    color: theme.grayLight
  });
};