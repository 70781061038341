import { css } from 'emotion';
export var networkBonusHistory = css({
  marginBottom: '40px'
});
export var networkBonusSectionHeadingSecondary = css({
  marginBottom: '16px'
});
export var networkBonusHistoryDateItem = function networkBonusHistoryDateItem(theme) {
  return css({
    padding: '16px',
    backgroundColor: theme.white,
    border: "1px solid ".concat(theme.whiteGray),
    borderRadius: '3px',
    marginBottom: '2px',
    '&:nth-child(odd)': {
      backgroundColor: theme.backgroundLightGray,
      '& .bvPointsTableRow': {
        '&:nth-child(even)': {
          backgroundColor: theme.white
        },
        '&:nth-child(odd)': {
          backgroundColor: theme.backgroundLightGray
        }
      }
    }
  });
};
export var networkBonusHistoryItemHeader = function networkBonusHistoryItemHeader(theme) {
  return css({
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.grayDark
  });
};
export var networkBonusHistoryItemHeaderDate = function networkBonusHistoryItemHeaderDate(show) {
  return css({
    letterSpacing: '0.38px',
    opacity: '0.7'
  }, show && {
    transition: 'all 0.3s ease',
    opacity: 1,
    fontWeight: '600'
  });
};
export var networkBonusHistoryItemHeaderRightWrap = css({
  display: 'flex',
  alignItems: 'center'
}, {
  '@media (max-width: 575px)': {
    justifyContent: 'space-between'
  }
});
export var networkBonusHistoryItemHeaderAmountWrap = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: '24px'
});
export var networkBonusHistoryItemHeaderAmountLabel = css({
  fontSize: '13px',
  marginRight: '8px',
  lineHeight: 1
});
export var networkBonusSectionHeadingLight = css({
  fontWeight: '600',
  letterSpacing: '-0.47px',
  lineHeight: 1
});
export var networkBonusHistoryItemHeaderArrowWrap = function networkBonusHistoryItemHeaderArrowWrap(theme, show) {
  return css({
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.whiteGray,
    '& svg': {
      opacity: '0.3',
      marginTop: '3px'
    }
  }, show && {
    transition: 'all 0.3s ease',
    backgroundColor: 'transparent',
    '& svg': {
      transition: 'all 0.3s ease',
      transform: 'rotate(180deg)',
      opacity: 1,
      marginTop: '0px'
    },
    '&:hover': {
      transition: 'all 0.3s ease',
      backgroundColor: theme.whiteGray
    }
  });
};
export var networkBonusHistoryItemBody = css({
  padding: '32px 40px'
}, {
  '@media (max-width: 575px)': {
    padding: '24px 0px'
  }
});
export var networkBonusHistoryShowMore = css({
  marginTop: '24px',
  '@media (max-width: 575px)': {
    marginTop: '24px'
  }
});
export var networkBonusHistoryShowMoreButton = function networkBonusHistoryShowMoreButton(theme) {
  return css({
    '& a': {
      color: theme.white
    }
  });
};
export var collapseDiv = function collapseDiv(isOpen) {
  return css({
    display: isOpen ? 'block' : 'none',
    overflow: 'hidden',
    transition: 'height 0.4s ease-in-out'
  });
};