function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import _isFunction from "lodash/isFunction";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Row, Col, Button, Image, CheckButton } from '../../UI';
import avatar from '../../../assets/images/package.png';
import CounterInput from '../../Orders/CounterInput';
import { containerShop, containerPackageShop, shopPackageCardTop, pictureContainer, shopPackageCardAdd, addCheckButton, addCheckButtonPercent, buttonAddToCard, titlePackage } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Package = function Package(props) {
  var _props$item = props.item,
    item = _props$item === void 0 ? {} : _props$item,
    onAddToCard = props.onAddToCard,
    onClick = props.onClick,
    className = props.className;
  var name = item.name,
    price = item.price,
    picThumbnailUrl = item.picThumbnailUrl,
    catalogueItemId = item.catalogueItemId,
    mandatoryActivation = item.mandatoryActivation;
  var theme = useTheme();
  var _useState = useState(1),
    _useState2 = _slicedToArray(_useState, 2),
    amount = _useState2[0],
    setAmount = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    activationFee = _useState4[0],
    setActivationFee = _useState4[1];
  var handleLinkClick = function handleLinkClick(e) {
    return e.preventDefault();
  };
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: containerShop(className),
    onClick: onClick
  }, ___EmotionJSX(Link, _extends({
    to: "/packages/package/".concat(catalogueItemId),
    className: "package-card-info ".concat(shopPackageCardTop(theme))
  }, _isFunction(onClick) && {
    onClick: handleLinkClick
  }), ___EmotionJSX("div", {
    className: containerPackageShop
  }, ___EmotionJSX(Image, {
    size: "contain",
    src: picThumbnailUrl !== null && picThumbnailUrl !== void 0 ? picThumbnailUrl : avatar,
    width: 60,
    height: 60,
    className: pictureContainer
  }), ___EmotionJSX("h4", {
    className: titlePackage
  }, name, ___EmotionJSX("br", null), "\u20AC", price.toFixed(2)))), onAddToCard && ___EmotionJSX("div", {
    className: "package-card-add ".concat(shopPackageCardAdd(theme))
  }, ___EmotionJSX("div", {
    className: addCheckButton
  }, !mandatoryActivation && ___EmotionJSX(CheckButton, {
    onChange: function onChange(event) {
      return setActivationFee(event);
    }
  }), ___EmotionJSX("p", {
    className: addCheckButtonPercent(theme)
  }, "With activation fee (+ \u20AC30)")), ___EmotionJSX(Row, {
    align: "center",
    horizontalGap: 4,
    verticalGap: 4,
    justify: "space-between"
  }, ___EmotionJSX(Col, {
    basis: "auto",
    grow: "0"
  }, ___EmotionJSX(CounterInput, {
    count: 1,
    min: 1,
    max: 200,
    onCountChange: function onCountChange(count) {
      return setAmount(count);
    }
  })), ___EmotionJSX(Col, {
    basis: "auto",
    grow: "0"
  }, ___EmotionJSX(Button, {
    className: buttonAddToCard(theme),
    secondary: true,
    onClick: function onClick() {
      return onAddToCard(_objectSpread(_objectSpread({}, item), {}, {
        activationFee: activationFee,
        amount: amount
      }));
    }
  }, "Add to cart")))));
};
Package.propTypes = {
  item: PropTypes.any,
  onAddToCard: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string
};
export default Package;