import React from 'react';
import PropTypes from 'prop-types';
import { findCountryImage } from '../../../enums/countries';
import { countryComp } from '../../Shared/Country';
import { networkTableCountryCol } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkTableCountryCol = function NetworkTableCountryCol(props) {
  var country = props.country;
  return ___EmotionJSX("div", {
    className: networkTableCountryCol
  }, countryComp(findCountryImage(country)), ___EmotionJSX("div", null, country));
};
NetworkTableCountryCol.propTypes = {
  country: PropTypes.string
};
export default NetworkTableCountryCol;