import { css } from 'emotion';
export var container = css({
  marginBottom: 24,
  '& h2': {
    marginBottom: 16
  },
  '& > *:last-child': {
    marginBottom: 0
  }
});
export var inputContainer = css({
  marginRight: 12
});
export var booleanContainer = css({
  height: 49,
  display: 'flex',
  alignItems: 'center',
  flex: 1
});
export var imageContainer = css({
  marginLeft: 20
});
export var questionImage = css({
  display: 'flex'
});
export var tooltipStyle = css({
  minWidth: 250
});