import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Icon } from '../Icon';
import { noResultsContainer, noResultsTitle, noResultsIcon, noResultsSubtitle } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NoResults = function NoResults(_ref) {
  var emptyMessage = _ref.emptyMessage;
  var theme = useTheme();
  return ___EmotionJSX("section", {
    className: noResultsContainer
  }, ___EmotionJSX("div", {
    className: noResultsIcon(theme)
  }, ___EmotionJSX(Icon, {
    material: true,
    iconName: "search",
    color: "white",
    size: 48
  })), ___EmotionJSX("p", null, emptyMessage));
};
NoResults.propTypes = {
  emptyMessage: PropTypes.string
};
export var Error = function Error() {
  var theme = useTheme();
  return ___EmotionJSX("section", {
    className: noResultsContainer
  }, ___EmotionJSX("div", {
    className: noResultsIcon(theme)
  }, ___EmotionJSX(Icon, {
    material: true,
    iconName: "report",
    size: 48,
    color: "white"
  })), ___EmotionJSX("h2", {
    className: noResultsTitle
  }, "Request failure."), ___EmotionJSX("p", {
    className: noResultsSubtitle(theme)
  }, "There was problem getting the results.", ___EmotionJSX("br", null), "Please try again later."));
};