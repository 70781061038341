import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isString from "lodash/isString";
import _isArray from "lodash/isArray";
import _isBoolean from "lodash/isBoolean";
import _isNumber from "lodash/isNumber";
import _pick from "lodash/pick";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import queryString from 'query-string';
import { transactionStatus } from '../../enums/transactions';
import { validDate, endOfTheDay, startOfTheDay } from '../../utils';
export var getTransactionsModel = function getTransactionsModel(response) {
  var _response$receiver, _response$sender;
  return response && _objectSpread(_objectSpread({}, response), {}, {
    completionStatus: transactionStatus[response === null || response === void 0 ? void 0 : response.completionStatus],
    modifiedOn: validDate(response.modifiedOn),
    createdOn: validDate(response.createdOn),
    receiver: (_response$receiver = response.receiver) !== null && _response$receiver !== void 0 ? _response$receiver : 'Platform',
    sender: (_response$sender = response.sender) !== null && _response$sender !== void 0 ? _response$sender : 'Platform'
  });
};
export var getAllTransactionParams = function getAllTransactionParams(options) {
  var _statusOptionsValues;
  var type = options.type,
    status = options.status,
    senderSearchBy = options.senderSearchBy,
    senderSearchFor = options.senderSearchFor,
    receiverSearchBy = options.receiverSearchBy,
    receiverSearchFor = options.receiverSearchFor,
    walletType = options.walletType,
    startDate = options.startDate,
    endDate = options.endDate,
    senderBalanceFrom = options.senderBalanceFrom,
    senderBalanceTo = options.senderBalanceTo,
    receiverBalanceFrom = options.receiverBalanceFrom,
    receiverBalanceTo = options.receiverBalanceTo,
    _options$sort = options.sort,
    sort = _options$sort === void 0 ? {
      sortField: 'id',
      sortOrder: 'desc'
    } : _options$sort,
    walletId = options.walletId,
    excludeMaster = options.excludeMaster,
    reason = options.reason,
    senderFirstName = options.senderFirstName,
    senderLastName = options.senderLastName,
    receiverFirstName = options.receiverFirstName,
    receiverLastName = options.receiverLastName,
    fromArchive = options.fromArchive,
    withTopupInfo = options.withTopupInfo,
    amountFrom = options.amountFrom,
    amountTo = options.amountTo;
  var filterField = [];
  var filterValue = [];
  var dateRange = {};
  var priceRange = {};
  var statusOptionsValues = (_statusOptionsValues = {}, _defineProperty(_statusOptionsValues, transactionStatus.Pending, 'pending'), _defineProperty(_statusOptionsValues, transactionStatus.Completed, 'completed'), _defineProperty(_statusOptionsValues, transactionStatus.Rejected, 'rejected'), _defineProperty(_statusOptionsValues, transactionStatus.Blocked, 'blocked'), _statusOptionsValues);
  var mapSort = function mapSort(sort) {
    return {
      sortField: sort.sortField,
      sortOrder: sort.sortOrder
    };
  };
  if (statusOptionsValues[status]) {
    filterField.push('completionStatus');
    filterValue.push(statusOptionsValues[status]);
  }
  if (type) {
    filterField.push('type');
    filterValue.push(type);
  }
  if (_isString(walletType) && walletType) {
    filterField.push('walletType');
    filterValue.push(walletType);
  }
  if (_isArray(walletType)) {
    walletType.forEach(function (el) {
      filterField.push('walletType');
      filterValue.push(el);
    });
  }
  if (senderSearchBy && senderSearchFor !== null && senderSearchFor !== void 0 && senderSearchFor.length) {
    filterField.push(options.senderSearchBy);
    filterValue.push(options.senderSearchFor);
  }
  if (receiverSearchBy && receiverSearchFor !== null && receiverSearchFor !== void 0 && receiverSearchFor.length) {
    filterField.push(options.receiverSearchBy);
    filterValue.push(options.receiverSearchFor);
  }
  if (startDate && endDate) {
    dateRange.DateFrom = startOfTheDay(startDate).format();
    dateRange.DateEnd = endOfTheDay(endDate).format();
  }
  if (amountFrom && amountTo && amountFrom >= 0 && amountTo >= 0) {
    priceRange.amountFrom = amountFrom;
    priceRange.amountTo = amountTo;
  }
  if (reason) {
    filterField.push('reason');
    filterValue.push(reason);
  }
  if (senderFirstName !== null && senderFirstName !== void 0 && senderFirstName.length) {
    filterField.push('senderFirstName');
    filterValue.push(senderFirstName);
  }
  if (senderLastName !== null && senderLastName !== void 0 && senderLastName.length) {
    filterField.push('senderLastName');
    filterValue.push(senderLastName);
  }
  if (receiverFirstName !== null && receiverFirstName !== void 0 && receiverFirstName.length) {
    filterField.push('receiverFirstName');
    filterValue.push(receiverFirstName);
  }
  if (receiverLastName !== null && receiverLastName !== void 0 && receiverLastName.length) {
    filterField.push('receiverLastName');
    filterValue.push(receiverLastName);
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), sort && mapSort(sort)), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), _isBoolean(excludeMaster) && {
    excludeMaster: excludeMaster
  }), dateRange), priceRange), walletId && {
    walletId: walletId
  }), _isNumber(senderBalanceFrom) && {
    senderBalanceFrom: senderBalanceFrom
  }), _isNumber(senderBalanceTo) && {
    senderBalanceTo: senderBalanceTo
  }), _isNumber(receiverBalanceFrom) && {
    receiverBalanceFrom: receiverBalanceFrom
  }), _isNumber(receiverBalanceTo) && {
    receiverBalanceTo: receiverBalanceTo
  }), _isBoolean(fromArchive) && {
    fromArchive: fromArchive
  }), _isBoolean(withTopupInfo) && {
    withTopupInfo: withTopupInfo
  }), {
    arrayFormat: 'index'
  });
};
export var exportTransactionsQuery = function exportTransactionsQuery(options) {
  var accountId = options.accountId,
    nickName = options.nickName,
    startDate = options.startDate,
    endDate = options.endDate,
    type = options.type,
    walletType = options.walletType,
    reason = options.reason,
    _options$sort2 = options.sort,
    sort = _options$sort2 === void 0 ? {
      sortField: 'id',
      sortOrder: 'desc'
    } : _options$sort2,
    fromArchive = options.fromArchive,
    ccRecipients = options.ccRecipients;
  var filterField = [];
  var filterValue = [];
  var dateRange = {};
  var mapSort = function mapSort(sort) {
    return {
      sortField: sort.sortField,
      sortOrder: sort.sortOrder
    };
  };
  if (type) {
    filterField.push('type');
    filterValue.push(type);
  }
  if (walletType) {
    filterField.push('walletType');
    filterValue.push(walletType);
  }
  if (reason) {
    filterField.push('reason');
    filterValue.push(reason);
  }
  if (startDate && endDate) {
    dateRange.DateFrom = startOfTheDay(startDate).format();
    dateRange.DateEnd = endOfTheDay(endDate).format();
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, sort && mapSort(sort)), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), dateRange), accountId && {
    accountId: accountId
  }), nickName && {
    nickName: nickName
  }), _isBoolean(fromArchive) && {
    fromArchive: fromArchive
  }), ccRecipients && {
    ccRecipients: ccRecipients
  }));
};