import { css } from 'emotion';
export var networkTreeAvatarContainer = function networkTreeAvatarContainer(theme, isEmpty) {
  return css({
    borderRadius: '8px',
    boxShadow: '0 2px 13px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.white,
    margin: '0 24px',
    height: '150px',
    flex: '1 0 170px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    overflow: isEmpty ? 'visible' : 'hidden',
    '@media (min-width: 1080px) and (max-width: 1199px)': {
      margin: '0 8px'
    },
    '@media (max-width: 991px)': {
      margin: '0 8px'
    }
  });
};