import { css } from 'emotion';
export var actionButtonsContainer = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > *': {
    marginRight: 24,
    '&:last-child': {
      marginRight: 0
    }
  }
});