import { leadershipRunStatus, leadershipRunType } from '../../../enums/bonuses';
export var optionsLeadershipRunStatus = [{
  label: 'Pending',
  value: leadershipRunStatus.Pending
}, {
  label: 'Succeeded',
  value: leadershipRunStatus.Succeeded
}, {
  label: 'Ranks Saved',
  value: leadershipRunStatus.RanksSaved
}];
export var optionsLeadershipRunType = [{
  label: 'Regular',
  value: leadershipRunType.Regular
}, {
  label: 'Preliminary',
  value: leadershipRunType.Preliminary
}];