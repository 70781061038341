import { css } from 'emotion';
export var commentContainer = css({
  marginTop: 16
});
export var actionContainer = css({
  display: 'flex',
  flexDirection: 'row',
  '& > *': {
    marginRight: 20,
    '&:last-child': {
      marginRight: 0
    }
  }
});