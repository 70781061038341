import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col } from '../../UI';
import { accountRankId } from '../../../enums/account';
import { modalRanks } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkLeadershipDetails = function NetworkLeadershipDetails(props) {
  var rowData = props.rowData;
  var modalRef = useRef();
  var left = rowData.filter(function (el) {
    return el.isLeft;
  });
  var right = rowData.filter(function (el) {
    return !el.isLeft;
  });
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Button, {
    info: true,
    onClick: function onClick() {
      return modalRef.current.open();
    }
  }, "View"), ___EmotionJSX(Modal, {
    ref: modalRef,
    modalTitle: "Network Leadership Details"
  }, ___EmotionJSX(Row, {
    gap: 8
  }, ___EmotionJSX(Col, {
    sm: 6,
    xs: 6
  }, ___EmotionJSX("h4", null, "Left")), ___EmotionJSX(Col, {
    sm: 6,
    xs: 6
  }, ___EmotionJSX("h4", null, "Right")), ___EmotionJSX(Col, {
    sm: 6,
    xs: 6
  }, left.map(function (el, ind) {
    return ___EmotionJSX("div", {
      key: ind
    }, ___EmotionJSX("h4", {
      className: modalRanks
    }, accountRankId[el.rankId], " : ", el.networkAccountsCount));
  })), ___EmotionJSX(Col, {
    sm: 6,
    xs: 6
  }, right.map(function (el, ind) {
    return ___EmotionJSX("div", {
      key: ind
    }, ___EmotionJSX("h4", {
      className: modalRanks
    }, accountRankId[el.rankId], " : ", el.networkAccountsCount));
  })))));
};
NetworkLeadershipDetails.propTypes = {
  rowData: PropTypes.array
};
export default NetworkLeadershipDetails;