import { css } from 'emotion';
export var containerSearch = css({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  '& > *': {
    marginBottom: 16
  }
});
export var datePicker = css({
  marginRight: 32,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});
export var priceRangeContainer = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: 32,
  flex: '0 0 320px'
});
export var mainOptionsContainer = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: 32,
  flex: '1 0 420px'
});
export var transactionLabelText = css({
  fontSize: 13,
  marginRight: 8,
  whiteSpace: 'nowrap'
});
export var transactionInput = css({
  marginLeft: 8
});
export var transactionDropdown = css({
  width: 150,
  maxWidth: 250
});
export var optionsContainer = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: 32,
  flex: '0 0 200px'
});
export var buttonContainer = css({
  display: 'flex',
  justifyContent: 'space-around'
});
export var exportButton = css({
  marginRight: 16
});
export var exportTooltip = css({
  minWidth: 200
});
export var questionIcon = css({
  marginLeft: 8
});
export var tooltipContent = css({
  width: 250,
  textAlign: 'left',
  padding: 8
});