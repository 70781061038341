import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import _pick from "lodash/pick";
import _omit from "lodash/omit";
import _isNil from "lodash/isNil";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import moment from 'moment';
import queryString from 'query-string';
export var getSubscriptionPlanModel = function getSubscriptionPlanModel(responseData) {
  return _objectSpread(_objectSpread({}, responseData), {}, {
    planActive: responseData.active
  });
};
export var getSubscriptionListItemsModel = function getSubscriptionListItemsModel(catalogueItem) {
  var subscriptionListItems = [];
  (catalogueItem.subscriptionPlans || []).forEach(function (plan) {
    subscriptionListItems = [].concat(_toConsumableArray(subscriptionListItems), [_objectSpread(_objectSpread({
      subscriptionCatalogueItemId: catalogueItem.id
    }, getSubscriptionPlanModel(plan)), {}, {
      visible: catalogueItem.visible,
      active: catalogueItem.active,
      picThumbnailUrl: catalogueItem.picThumbnailUrl,
      color: catalogueItem.color
    })]);
  });
  return subscriptionListItems;
};
export var getFlattedSubscriptionsListByPlans = function getFlattedSubscriptionsListByPlans(subscriptionsCatalogueItems) {
  var subscriptionItems = [];
  subscriptionsCatalogueItems === null || subscriptionsCatalogueItems === void 0 ? void 0 : subscriptionsCatalogueItems.forEach(function (item) {
    subscriptionItems = [].concat(_toConsumableArray(subscriptionItems), _toConsumableArray(getSubscriptionListItemsModel(item)));
  });
  return subscriptionItems;
};
export var getSubscriptionModel = function getSubscriptionModel(response) {
  return _objectSpread({
    subscriptionCatalogueItemId: response.id
  }, response);
};
export var getAccountGroupedSubscriptions = function getAccountGroupedSubscriptions(accountSubscriptions) {
  var groupedByCatItemAndVendor = [];
  var processedVendorNames = [];
  var processedSubscriptionCatItemsIds = [];
  var addToProcessedSubscriptions = function addToProcessedSubscriptions(subscription) {
    processedVendorNames.push(subscription.vendor);
    processedSubscriptionCatItemsIds.push(subscription.subscriptionCatalogueItemId);
  };
  var getMappedSubscription = function getMappedSubscription(subscription) {
    var mappedSubscription = mapAccountSubscriptionToGroupedItem(subscription);
    mappedSubscription.totalPrice = subscription.price;
    mappedSubscription.activeSubscriptionPlans.push(subscription);
    return mappedSubscription;
  };
  accountSubscriptions.forEach(function (s) {
    var isFirsVendor = processedVendorNames.indexOf(s.vendor) === -1;
    var isFirstCatItem = processedSubscriptionCatItemsIds.indexOf(s.subscriptionCatalogueItemId) === -1;
    if (isFirsVendor) {
      addToProcessedSubscriptions(s);
      var mappedSubscription = getMappedSubscription(s);
      groupedByCatItemAndVendor.push(mappedSubscription);
    } else if (isFirstCatItem) {
      processedSubscriptionCatItemsIds.push(s.subscriptionCatalogueItemId);
      var _mappedSubscription = getMappedSubscription(s);
      groupedByCatItemAndVendor.push(_mappedSubscription);
    } else {
      var processedSubscription = groupedByCatItemAndVendor.find(function (processed) {
        return processed.subscriptionCatalogueItemId === s.subscriptionCatalogueItemId;
      });
      processedSubscription && processedSubscription.activeSubscriptionPlans.push(s);
      processedSubscription.totalPrice++;
    }
  });
  var result = groupedByCatItemAndVendor.map(addTotalsToAccountSubscription);
  return result;
};
export var getAllSubscriptionsParams = function getAllSubscriptionsParams(options) {
  var _options$searchInput;
  var defaultSort = {
    sortField: 'id',
    sortOrder: 'desc'
  };
  var mapSort = function mapSort(sort) {
    return {
      sortField: sort.sortField === 'catalogueItemId' ? 'id' : sort.sortField,
      sortOrder: sort.sortOrder
    };
  };
  var filterField = [];
  var filterValue = [];
  if (!_isNil(options.active)) {
    filterField.push('active');
    filterValue.push(options.active);
  }
  if (!_isNil(options.catItemActive)) {
    filterField.push('catItemActive');
    filterValue.push(options.catItemActive);
  }
  if (!_isNil(options.catItemVisible)) {
    filterField.push('catItemVisible');
    filterValue.push(options.catItemVisible);
  }
  if (!_isNil(options.vendor)) {
    filterField.push('vendor');
    filterValue.push(options.vendor);
  }
  if (options.searchCriteria && (_options$searchInput = options.searchInput) !== null && _options$searchInput !== void 0 && _options$searchInput.length) {
    filterField.push(options.searchCriteria);
    filterValue.push(options.searchInput);
  }
  return queryString.stringify(_objectSpread(_objectSpread(_objectSpread({}, _pick(options, ['pageIndex', 'pageSize'])), options.sort ? mapSort(options.sort) : defaultSort), filterField.length && filterValue.length && {
    filterField: filterField,
    filterValue: filterValue
  }), {
    arrayFormat: 'index'
  });
};
export var editSubscriptionBody = function editSubscriptionBody(body) {
  var keysToOmit = ['lastModified', 'modifiedBy', 'modifiedOn', 'catalogueItemId', 'createdBy', 'createdOn', 'rowVersion', 'systemName'];
  return _omit(body, keysToOmit);
};

// PRIVATE
var mapAccountSubscriptionToGroupedItem = function mapAccountSubscriptionToGroupedItem(subscription) {
  var subscriptionCatalogueItemId = subscription.subscriptionCatalogueItemId,
    vendor = subscription.vendor,
    picThumbnailUrl = subscription.picThumbnailUrl,
    pictureUrl = subscription.pictureUrl;
  var mappedResult = {
    subscriptionCatalogueItemId: subscriptionCatalogueItemId,
    vendor: vendor,
    picThumbnailUrl: picThumbnailUrl,
    pictureUrl: pictureUrl,
    totalValidUntil: null,
    validFrom: null,
    totalPrice: null,
    totalDuration: null,
    activeSubscriptionPlans: []
  };
  return mappedResult;
};
var getValidUntil = function getValidUntil(fromDate, duration) {
  var validUntil = moment(fromDate).add(duration, 'months');
  return validUntil;
};
var addTotalsToAccountSubscription = function addTotalsToAccountSubscription(s) {
  var mappedSubscription = _objectSpread({}, s);
  var totalDuration = s.activeSubscriptionPlans.length === 1 ? s.activeSubscriptionPlans[0].duration : s.activeSubscriptionPlans.map(function (p) {
    return p.duration;
  }).reduce(function (prev, curr) {
    return prev + curr;
  }, 0);
  mappedSubscription.totalDuration = totalDuration;
  var lastPlan = s.activeSubscriptionPlans[s.activeSubscriptionPlans.length - 1];
  mappedSubscription.totalValidUntil = getValidUntil(lastPlan.validFrom, lastPlan.duration);
  mappedSubscription.validFrom = new Date(s.activeSubscriptionPlans[0].validFrom);
  return mappedSubscription;
};