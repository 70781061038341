import { css } from 'emotion';
export var header = css({
  cursor: 'pointer'
});
export var content = function content(isOpen, height) {
  return css({
    height: isOpen ? height : 0,
    overflow: 'hidden',
    transition: 'height 0.4s ease-in-out'
  });
};
export var container = css({
  display: 'flex',
  flexDirection: 'column'
});