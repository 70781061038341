import React from 'react';
import PropTypes from 'prop-types';
import ColorBatch from '../../Shared/ColorBatch';
import { accountStatusMap } from './config';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkTableStatusCol = function NetworkTableStatusCol(_ref) {
  var _accountStatusMap$sta, _accountStatusMap$sta2;
  var status = _ref.status;
  return ___EmotionJSX(ColorBatch, {
    type: (_accountStatusMap$sta = accountStatusMap[status]) === null || _accountStatusMap$sta === void 0 ? void 0 : _accountStatusMap$sta.color
  }, (_accountStatusMap$sta2 = accountStatusMap[status]) === null || _accountStatusMap$sta2 === void 0 ? void 0 : _accountStatusMap$sta2.text);
};
NetworkTableStatusCol.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default NetworkTableStatusCol;