import React from 'react';
import { Tabs, Tab } from '../../../components/UI';
import ProfilesList from './ProfilesList';
import RegionalLeadersList from './RegionalLeadersList';
import CountryBoardLeadersList from './CountryBoardLeadersList';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ProfilesPage = function ProfilesPage() {
  return ___EmotionJSX(Tabs, {
    startingRoute: "/profiles"
  }, ___EmotionJSX(Tab, {
    label: "Profiles ",
    url: "/list",
    component: function component() {
      return ___EmotionJSX(ProfilesList, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "Regional Leaders",
    url: "/leaders/regional",
    component: function component() {
      return ___EmotionJSX(RegionalLeadersList, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "Country Board Leaders",
    url: "/leaders/country-board",
    component: function component() {
      return ___EmotionJSX(CountryBoardLeadersList, null);
    }
  }));
};
export default ProfilesPage;