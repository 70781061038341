import React from 'react';
import PropTypes from 'prop-types';
import { Table, Breadcrumbs } from '../../../../../components/UI';
import BackButton from '../../../../../components/Shared/BackButton';
import { getWithdrawalMethodsByUserId } from '../../../../../services/wallets';
import { withdrawalMethodsHistoryColumns, profileWithdrawalsBreadcrumbs } from './config';
import { backContainerWithButton } from '../../styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var WithdrawalMethodsHistory = function WithdrawalMethodsHistory(props) {
  var userId = props.userId;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Breadcrumbs, {
    items: profileWithdrawalsBreadcrumbs
  }), ___EmotionJSX("div", {
    className: backContainerWithButton
  }, ___EmotionJSX(BackButton, {
    title: "Withdrawal Methods History "
  })), ___EmotionJSX(Table, {
    getDataKey: "withdrawalMethods",
    getDataMethod: function getDataMethod(tableOptions) {
      return getWithdrawalMethodsByUserId(userId, tableOptions);
    },
    columns: withdrawalMethodsHistoryColumns,
    pageSize: 10,
    emptyMessage: "There are no Withdrawal Methods."
  }));
};
WithdrawalMethodsHistory.propTypes = {
  userId: PropTypes.string
};
export default WithdrawalMethodsHistory;