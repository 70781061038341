import { css } from 'emotion';
export var charityConfigContainer = css({
  display: 'flex',
  gap: 50
});
export var charityConfigLeftSide = css({
  flex: '2 0 0',
  display: 'flex',
  flexDirection: 'column',
  '& div': {
    marginBottom: 10
  },
  '& .CTA-button': {
    maxWidth: 100,
    alignSelf: 'flex-start'
  }
});
export var charityConfigRightSide = css({
  flex: '3 0 0'
});
export var charityConfigResultContainer = css({
  '& .date': {
    fontSize: 14
  }
});
export var charityConfigResultHeader = function charityConfigResultHeader(theme) {
  return css({
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.infoLight,
    padding: '10px 20px',
    fontWeight: 600,
    fontSize: 16
  });
};
export var charityConfigResultBody = function charityConfigResultBody(theme) {
  return css({
    padding: '10px 20px',
    borderBottom: "1px solid ".concat(theme.whiteGrayDark),
    display: 'flex',
    alignItems: 'center'
  });
};
export var totalAmountText = css({
  textAlign: 'right',
  fontSize: 16,
  fontWeight: 700,
  marginTop: 16
});