import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import _isFunction from "lodash/isFunction";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.is-array.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Icon';
import { checkButtonContainer, iconContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var checkboxFilter = 'invert(63%) sepia(89%) saturate(2820%) hue-rotate(115deg) brightness(91%) contrast(101%)';
var Checkbox = function Checkbox(_ref) {
  var checked = _ref.checked,
    disabled = _ref.disabled,
    onChange = _ref.onChange,
    radio = _ref.radio,
    children = _ref.children,
    className = _ref.className;
  var _useState = useState(checked),
    _useState2 = _slicedToArray(_useState, 2),
    check = _useState2[0],
    setCheck = _useState2[1];
  useEffect(function () {
    setCheck(checked);
  }, [checked]);
  var handleChange = function handleChange() {
    if (disabled) return;
    setCheck(function (prev) {
      _isFunction(onChange) && onChange(!prev);
      return !prev;
    });
  };
  var iconName = radio ? check ? 'radio_button_checked' : 'radio_button_unchecked' : check ? 'check_box' : 'check_box_outline_blank';
  var colorClassName = radio && check ? 'primaryLight' : 'grayLight';
  return ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    className: "".concat(checkButtonContainer(className, disabled), " ").concat(disabled ? 'disabled' : ''),
    onClick: handleChange
  }, ___EmotionJSX(Icon, {
    iconName: iconName,
    material: true,
    outline: true,
    twoTone: !radio && check,
    color: colorClassName,
    filter: !radio && check && checkboxFilter,
    className: iconContainer
  }), children);
};
Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  radio: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool
};
export default Checkbox;