import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '../UI';
import { packageListContainer, pictureContainer, packageInfo, infoContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Package = function Package(_ref) {
  var _item$maxSplits;
  var item = _ref.item;
  return ___EmotionJSX("article", {
    className: packageListContainer
  }, ___EmotionJSX(Image, {
    size: "contain",
    src: item.picThumbnailUrl,
    width: 70,
    height: 70,
    className: pictureContainer
  }), ___EmotionJSX("section", null, ___EmotionJSX("h2", {
    className: packageInfo
  }, item.systemName), ___EmotionJSX("p", {
    className: infoContainer
  }, "Splits:", ' ', ___EmotionJSX("span", null, item.split, " (max: ", (_item$maxSplits = item.maxSplits) !== null && _item$maxSplits !== void 0 ? _item$maxSplits : 'N/A', ")")), ___EmotionJSX("p", {
    className: infoContainer
  }, "All splits at once: ", ___EmotionJSX("span", null, item.allSplitsAtOnce ? 'Yes' : 'No')), ___EmotionJSX("p", {
    className: infoContainer
  }, "Status:", ' ', ___EmotionJSX("span", null, item.active ? 'Active' : 'Not Active', "/", item.visible ? 'Visible' : 'Not Visible'))));
};
Package.propTypes = {
  item: PropTypes.object
};
export default Package;