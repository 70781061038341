import { css } from 'emotion';
import { headerContainer } from '../../../components/UI';
export var tabContainer = css({
  padding: '48px 40px'
});
export var container = function container(theme) {
  return css(headerContainer(theme), {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  });
};
export var containerBack = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 48
});
export var paperContainer = css({
  '& > div > *:last-child': {
    marginBottom: 0
  }
});
export var buttonContainer = css({
  display: 'flex',
  alignItems: 'center'
});
export var button = css({
  padding: '12px 18px'
});
export var labelContainer = css({
  marginBottom: 6
});
export var depositGeneralInfoContainer = css({
  marginRight: 32
});
export var depositInfoContainer = function depositInfoContainer(theme) {
  return css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: "1px solid ".concat(theme.borderLight),
    padding: '20px 32px'
  });
};
export var depositUserContainer = css({
  fontWeight: 600,
  ' & > p': {
    '& > span': {
      fontWeight: 400
    }
  }
});