import { css } from 'emotion';
export var priceRangeContainer = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});
export var separatorDash = css({
  padding: 6
});
export var priceFromTo = function priceFromTo(hasError, theme, className) {
  return css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    width: '100%'
  }, hasError && {
    border: " 1px solid ".concat(theme.error)
  }, className);
};
export var iconClear = css({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 12,
  fontSize: 16
});
export var rangeInput = css({
  padding: '4px 6px'
});
export var labelStyles = function labelStyles(labelPosition) {
  return css({
    paddingRight: 8,
    fontSize: 13,
    whiteSpace: 'nowrap'
  }, labelPosition === 'top' && {
    paddingLeft: 8,
    fontWeight: 600,
    fontSize: 12
  });
};
export var priceRangeWithLabel = function priceRangeWithLabel(labelPosition) {
  return css({
    display: 'flex',
    alignItems: 'center'
  }, labelPosition === 'top' && {
    flexDirection: 'column',
    alignItems: 'start'
  });
};