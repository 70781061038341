import { useRef, useEffect } from 'react';
export var useEventListener = function useEventListener(eventName, handler) {
  var element = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : window;
  var savedHandler = useRef();
  useEffect(function () {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(function () {
    // Make sure element supports addEventListener
    var isSupported = element && element.addEventListener;
    if (!isSupported) return function () {};
    var eventListener = function eventListener(event) {
      return savedHandler.current(event);
    };
    element.addEventListener(eventName, eventListener);
    return function () {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};