import { css } from 'emotion';
export var networkTreeAvatarInfo = function networkTreeAvatarInfo(theme, isOpen) {
  return css({
    width: isOpen ? '100%' : '100px',
    height: isOpen ? '100%' : '100px',
    backgroundColor: theme.darkGray,
    padding: '16px',
    zIndex: 10,
    position: 'absolute',
    top: isOpen ? 0 : '-50px',
    right: isOpen ? 0 : '-50px',
    borderRadius: isOpen ? 0 : '0 0 50% 50%',
    transition: 'all 0.5s, border-radius 2s, top 1s'
  });
};
export var networkTreeAvatarInfoIcon = function networkTreeAvatarInfoIcon(theme, isOpen) {
  return css({
    position: 'absolute',
    right: '60px',
    top: '60px',
    zIndex: 100,
    fontSize: '14px',
    fontWeight: 600,
    color: theme.white,
    opacity: 1,
    cursor: 'pointer',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    border: "1px solid ".concat(theme.white),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }, isOpen && {
    right: '15px !important',
    top: '15px !important',
    border: 'none !important',
    fontSize: '18px !important'
  });
};
export var networkTreeAvatarInfoContents = function networkTreeAvatarInfoContents(isOpen) {
  return css({
    padding: '8px',
    opacity: 0,
    transform: 'scale(0.5) translateY(-200%)',
    transition: 'opacity 0.2s, transform 0.8s'
  }, isOpen && {
    opacity: 1,
    transform: 'scale(1) translateY(0)'
  });
};
export var networkTreeAvatarInfoContentsSponsorWrap = function networkTreeAvatarInfoContentsSponsorWrap(theme) {
  return css({
    textAlign: 'left',
    fontSize: '13px',
    color: theme.white,
    lineHeight: '1.2'
  });
};
export var networkTreeAvatarInfoContentsSponsorWrapName = function networkTreeAvatarInfoContentsSponsorWrapName(theme) {
  return css({
    margin: '8px 0px',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: 1,
    color: theme.white
  });
};
export var networkTreeAvatarInfoContentsSponsorNickname = function networkTreeAvatarInfoContentsSponsorNickname(theme) {
  return css({
    fontSize: '12px',
    letterSpacing: '0.33px',
    fontWeight: 600,
    color: theme.white
  });
};
export var networkTreeAvatarInfoContentsCountryWrap = function networkTreeAvatarInfoContentsCountryWrap(theme) {
  return css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    marginTop: '16px',
    color: theme.white,
    '& > img': {
      maxWidth: '18px',
      marginRight: '8px'
    }
  });
};
export var networkTreeAvatarInfoContentsCountryName = function networkTreeAvatarInfoContentsCountryName(theme) {
  return css({
    fontSize: '14px',
    color: theme.white
  });
};
export var networkTreeAvatarInfoContentsMatchingButton = function networkTreeAvatarInfoContentsMatchingButton(theme) {
  return css({
    fontSize: '12px',
    padding: '5px',
    fontWeight: 600,
    color: theme.white,
    cursor: 'pointer',
    '&.kt-spinner--right': {
      '&:before': {
        right: 'auto',
        left: '50%',
        marginLeft: '-10px'
      }
    }
  });
};
export var networkTreeAvatarInfoContentsMatchingLevel = function networkTreeAvatarInfoContentsMatchingLevel(theme, isSmall) {
  return css({
    fontSize: '12px',
    padding: '0px',
    fontWeight: 600,
    color: theme.white,
    lineHeight: isSmall ? '1.1' : '1.5'
  });
};