import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.freeze.js";
import "core-js/modules/es.object.define-properties.js";
var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { css, keyframes } from 'emotion';
var showSuccess = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    from {\n        transform: scale(0);\n    }\n    to {\n        transform: scale(1);\n    }\n"])));
var tipInPlace = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    from {\n        width: 1em;\n        top: 1em;\n        left: -2.6em;\n    }\n    to {\n        width: 2.3em;\n        top: 4.3em;\n        left: 1.4em;\n        visibility: visible;\n    }\n"])));
var longInPlace = keyframes(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    from {\n        width: 0em;\n        top: 5.1em;\n        left: 3.2em;\n    }\n    to {\n        width: 4em;\n        top: 3.70em;\n        left: 2.75em;\n        visibility: visible;\n    }\n"])));
export var successContainer = function successContainer(theme, className) {
  return css({
    position: 'absolute',
    backgroundColor: theme.white,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    zIndex: 60,
    padding: 24,
    borderRadius: 8
  }, className);
};
export var successIcon = function successIcon(theme) {
  return css({
    display: 'inline-block',
    width: 100,
    height: 100,
    borderRadius: '50%',
    border: "2px solid ".concat(theme.success),
    backgroundColor: theme.white,
    position: 'relative',
    overflow: 'hidden',
    transformOrigin: 'center',
    animation: "".concat(showSuccess, " 180ms ease-in-out "),
    transform: 'scale(1)',
    marginBottom: 24
  });
};
export var successIconTip = function successIconTip(theme) {
  return css({
    display: 'block',
    position: 'absolute',
    height: '2px',
    backgroundColor: theme.success,
    borderRadius: '10px',
    width: '2.4em',
    top: '4.3em',
    left: '1em',
    transform: 'rotate(45deg)',
    animation: "".concat(tipInPlace, " 300ms ease-in-out "),
    animationFillMode: 'forwards',
    animationDelay: '180ms',
    visibility: 'hidden'
  });
};
export var successIconLong = function successIconLong(theme) {
  return css({
    display: 'block',
    position: 'absolute',
    height: '2px',
    backgroundColor: theme.success,
    borderRadius: '10px',
    transform: 'rotate(-45deg)',
    width: '4em',
    top: '3.70em',
    left: '2.5em',
    animation: "".concat(longInPlace, " 140ms ease-in-out "),
    animationFillMode: 'forwards',
    visibility: 'hidden',
    animationDelay: '440ms'
  });
};
export var titleSection = css({
  fontWeight: 600,
  textAlign: 'center'
});