export var leadershipRunType = {
  Regular: 'Regular',
  Preliminary: 'Preliminary'
};
export var leadershipRunStatus = {
  Pending: 'Pending',
  Succeeded: 'Succeeded',
  RanksSaved: 'RanksSaved'
};
export var eagleStatus = {
  Pending: 'Pending',
  Succeeded: 'Succeeded',
  RanksSaved: 'RanksSaved',
  WalletsRewarded: 'WalletsRewarded'
};