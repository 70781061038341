import { css } from 'emotion';
export var tableTitleContainer = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 36,
  '& > h1': {
    marginBottom: 12
  }
});
export var marginRight12 = css({
  marginRight: 12
});
export var tabContainer = css({
  padding: '48px 40px'
});