import { css } from 'emotion';
export var bonuses = css({
  padding: '0 40px'
});
export var withBreadcrumbs = css({
  padding: '0px 0px 48px 40px'
});
export var breadcrumbs = css({
  padding: '48px 0px 0px 40px'
});
export var profileAccountBonusContainer = css({
  position: 'relative'
});
export var networkBonusWhiteSectionWrap = css({
  margin: '0 32px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '5',
  '&>div': {
    maxWidth: '50%',
    flexBasis: '50%',
    flexGrow: 0,
    padding: '20px'
  },
  '@media (max-width: 1200px)': {
    '&>div': {
      maxWidth: '100%',
      flexBasis: '100%'
    }
  }
});
export var matchingBonusContainer = css({
  margin: '0 40px 40px'
});
export var matchingBonusSectionHeading = css({
  fontSize: '19px',
  fontWeight: '600',
  letterSpacing: '-0.47px',
  lineHeight: '1',
  marginBottom: '16px'
});
export var matchingBonusSectionHeadingSecondary = css({
  marginBottom: '32px',
  fontWeight: '400'
});
export var matchingBonusSubrow = function matchingBonusSubrow(theme) {
  return css({
    fontSize: '11px',
    color: theme.grayLight
  });
};
export var directSaleBonusContainer = css({
  margin: '0 32px 40px'
});
export var startupBonusContent = css({
  textAlign: 'center',
  margin: '0px 32px'
});
export var startupBonusContentText = function startupBonusContentText(theme) {
  return css({
    fontSize: '16px',
    lineHeight: '1.75',
    color: theme.gray,
    width: '100%',
    maxWidth: '600px',
    margin: '0px auto 40px',
    '@media (max-width: 575px)': {
      fontSize: '14px',
      margin: '0px auto 24px'
    }
  });
};
export var networkBonusDetails = css({
  margin: '0 32px',
  '&>div': {
    marginBottom: '24px'
  }
});