import React from 'react';
import ColorBatch from '../../../../components/Shared/ColorBatch';
import { DetailsLink } from '../../../../components/Shared/TableComponents';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var paymentMethodsColumns = [{
  name: 'ID',
  value: 'id',
  width: 60
}, {
  name: 'Created Date',
  value: 'createdOn',
  dateTimeFormat: 'DD/MM/YYYY',
  width: 120
}, {
  name: 'Display Name',
  value: 'displayName',
  flex: '8 0 0px'
}, {
  name: 'Status',
  value: 'active',
  render: function render(row) {
    return ___EmotionJSX(ColorBatch, {
      type: row.active ? 'success' : 'error'
    }, row.active ? 'Active' : 'Inactive');
  }
}, {
  name: 'Visibility',
  value: 'visible',
  render: function render(row) {
    return ___EmotionJSX(ColorBatch, {
      type: row.visible ? 'success' : 'error'
    }, row.visible ? 'Visible' : 'Invisible');
  }
}, {
  name: 'Is in Promo',
  value: 'isInPromo',
  render: function render(row) {
    return ___EmotionJSX(ColorBatch, {
      type: row.isInPromo ? 'success' : 'error'
    }, row.isInPromo ? 'Yes' : 'No');
  }
}, {
  name: 'View details',
  render: function render(row) {
    return ___EmotionJSX(DetailsLink, {
      row: row,
      url: "/settings/payment-methods/".concat(row.id)
    });
  },
  width: 100
}];
export var generalInfo = [{
  label: 'Payment Method ID',
  value: 'id'
}, {
  label: 'Date of Created',
  value: 'createdOn',
  dateTime: true
}, {
  label: 'Date of Modified',
  value: 'modifiedOn',
  dateTime: true
}, {
  label: 'Name',
  value: 'name'
}, {
  label: 'Display name',
  value: 'displayName'
}];
export var detailPageBreadcrumbs = [{
  url: '/settings/payment-methods',
  label: 'Payment Methods'
}, {
  label: 'Detail Page'
}];
export var editPageBreadcrumbs = function editPageBreadcrumbs(id) {
  return [{
    url: '/settings/payment-methods',
    label: 'Payment Methods'
  }, {
    url: "/settings/payment-methods/".concat(id),
    label: 'Detail Page'
  }, {
    label: 'Edit Package'
  }];
};