import { css } from 'emotion';
export var actionContainer = css({
  padding: 12,
  '& > *': {
    marginRight: 20,
    '&:last-child': {
      marginRight: 0
    }
  }
});
export var container = css({
  alignItems: 'center'
});
export var inputCommentContainer = css({
  height: 320,
  ' & > textarea': {
    height: 320
  }
});