import _omit from "lodash/omit";
import _isNil from "lodash/isNil";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { Skeleton } from '../../UI';
import { detailsContainer, titleStyles } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var DetailsLink = function DetailsLink(props) {
  var row = props.row,
    url = props.url,
    title = props.title,
    search = props.search;
  var theme = useTheme();
  return _isNil(row) ? ___EmotionJSX(Skeleton, {
    width: 90,
    height: 20
  }) : ___EmotionJSX(Link, {
    to: {
      pathname: url,
      state: _omit(row, '_table'),
      search: search
    },
    className: detailsContainer(theme)
  }, ___EmotionJSX("span", {
    className: titleStyles
  }, title !== null && title !== void 0 ? title : 'View details'));
};
DetailsLink.propTypes = {
  row: PropTypes.object,
  url: PropTypes.string,
  title: PropTypes.any,
  search: PropTypes.string
};
export default DetailsLink;