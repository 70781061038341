import { eagleStatus } from '../../../enums/bonuses';
export var optionsEagleRunStatus = [{
  label: 'Pending',
  value: eagleStatus.Pending
}, {
  label: 'Succeeded',
  value: eagleStatus.Succeeded
}, {
  label: 'Ranks Saved',
  value: eagleStatus.RanksSaved
}, {
  label: 'Wallets Rewarded',
  value: eagleStatus.WalletsRewarded
}];