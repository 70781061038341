import { css } from 'emotion';
export var networkTreeAvatarAccountContainer = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer'
});
export var networkTreeAvatarAccountContainerPicture = function networkTreeAvatarAccountContainerPicture(theme, isDefault) {
  return css({
    backgroundColor: theme.white,
    width: '45px',
    minHeight: '45px',
    borderRadius: '50%',
    borderWidth: '4px',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '6px',
    marginTop: '12px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    fill: isDefault ? theme.whiteGray : 'auto',
    '&:hover': {
      padding: 0
    }
  });
};
export var networkTreeAvatarAccountNicknameWrap = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flexGrow: 1
});
export var networkTreeAvatarAccountNicknameWrapName = function networkTreeAvatarAccountNicknameWrapName(theme) {
  return css({
    backgroundColor: theme.white,
    fontSize: '11px',
    letterSpacing: '0.33px',
    fontWeight: 600,
    color: theme.grayDark
  });
};
export var networkTreeAvatarAccountNicknameWrapNickname = function networkTreeAvatarAccountNicknameWrapNickname(theme) {
  return css({
    fontSize: '11px',
    letterSpacing: '0.33px',
    fontWeight: 600,
    color: theme.grayLight,
    marginBottom: '12px'
  });
};
export var networkTreeAvatarAccountNicknameWrapPackage = function networkTreeAvatarAccountNicknameWrapPackage(theme) {
  return css({
    fontSize: '12px',
    fontWeight: 600,
    color: theme.grayDark
  });
};