import { css } from 'emotion';
import { headerContainer } from '../../components/UI/Paper';
export var contentContainer = css({
  width: '100vw',
  height: '100vh'
});
export var container = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 32,
  height: '100%',
  width: '100%'
});
export var formTitleContainer = function formTitleContainer(theme) {
  return css({
    width: 400,
    border: "1px solid ".concat(theme.whiteGray),
    borderRadius: 3,
    boxShadow: '0 6px 8px 0 rgba(224, 225, 229, 0.58)',
    textAlign: 'center'
  });
};
export var loginTitle = function loginTitle(theme) {
  return css(headerContainer(theme), {
    backgroundColor: theme.whiteGrayLight,
    '& + *': {
      paddingTop: 32
    }
  });
};
export var title = function title(theme) {
  return css({
    color: theme.grayDark,
    fontWeight: 600,
    fontSize: 28
  });
};
export var forgotStyles = function forgotStyles(theme) {
  return css({
    paddingTop: 16,
    '& > a': {
      fontSize: 12,
      color: theme.info,
      fontWeight: 400,
      textDecoration: 'underline',
      '&:hover': {
        color: theme.infoDark
      }
    }
  });
};
export var formContainer = css({
  padding: 48
});
export var formInputContainer = css({
  marginBottom: 20
});
export var titleForgot = function titleForgot(theme) {
  return css({
    color: theme.grayDark,
    fontWeight: 600,
    marginBottom: 10,
    fontSize: 20
  });
};
export var backButton = function backButton(theme) {
  return css({
    color: theme.infoDark,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
    border: "1px solid ".concat(theme.infoDark),
    padding: '0 32px',
    '&: hover': {
      backgroundColor: theme.infoLight
    }
  });
};
export var submitButtonContainer = css({
  display: 'flex',
  justifyContent: 'space-between'
});