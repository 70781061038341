import React from 'react';
import { Tabs, Tab } from '../../../components/UI';
import LeadershipRunHistory from './LeadershipRank/LeadershipRunHistory';
import EagleHistory from './EagleRank/EagleHistory';
import { tabContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var RecognitionPage = function RecognitionPage() {
  return ___EmotionJSX(Tabs, {
    startingRoute: "/recognition",
    tabsContentClass: tabContainer
  }, ___EmotionJSX(Tab, {
    label: "Leadership",
    url: "/leadership-history",
    component: LeadershipRunHistory
  }), ___EmotionJSX(Tab, {
    label: "Eagle",
    url: "/eagle-history",
    component: EagleHistory
  }));
};
export default RecognitionPage;