import _isNil from "lodash/isNil";
import "core-js/modules/es.function.name.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '../../UI';
import ColorBatch from '../../Shared/ColorBatch';
import { container, packageInfo, infoContainer, statusContainer, marginBottom4 } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PlanInfo = function PlanInfo(props) {
  var data = props.data,
    className = props.className;
  return _isNil(data) ? ___EmotionJSX("div", {
    className: container(className)
  }, ___EmotionJSX("section", null, ___EmotionJSX(Skeleton, {
    width: 200,
    height: 20,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 60,
    height: 15,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 120,
    height: 15,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 80,
    height: 15,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 50,
    height: 15,
    className: marginBottom4
  }))) : ___EmotionJSX("div", {
    className: container(className)
  }, ___EmotionJSX("section", null, ___EmotionJSX("h2", {
    className: packageInfo
  }, data.name), ___EmotionJSX(ColorBatch, {
    type: data.active ? 'success' : 'error',
    className: statusContainer
  }, data.active ? 'Active Plan' : 'Inactive Plan'), ___EmotionJSX(ColorBatch, {
    type: data.visible ? 'success' : 'error',
    className: statusContainer
  }, data.visible ? 'Visible Plan' : 'Invisible Plan'), ___EmotionJSX("p", {
    className: infoContainer
  }, "Plan ID: ", ___EmotionJSX("span", null, data.id)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Compound Period: ", ___EmotionJSX("span", null, data.compoundPeriod)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Duration: ", ___EmotionJSX("span", null, data.duration)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Interest Rate: ", ___EmotionJSX("span", null, data.interestRate))));
};
PlanInfo.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string
};
export default PlanInfo;