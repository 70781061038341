import React from 'react';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { Tabs, Tab } from '../../../components/UI';
import PackagesList from './Packages/PackagesList';
import BundlesList from './Bundles/BundlesList';
import { tabContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PackagePage = function PackagePage() {
  var isSupport = hasRole(USER_ROLES.SUPPORT);
  return ___EmotionJSX(Tabs, {
    startingRoute: "/packages",
    tabsContentClass: tabContainer
  }, ___EmotionJSX(Tab, {
    label: "Packages ",
    url: "/all-packages",
    component: function component() {
      return ___EmotionJSX(PackagesList, null);
    }
  }), !isSupport && ___EmotionJSX(Tab, {
    label: "Bundles",
    url: "/all-bundles",
    component: function component() {
      return ___EmotionJSX(BundlesList, null);
    }
  }));
};
export default PackagePage;