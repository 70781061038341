import _isEmpty from "lodash/isEmpty";
import "core-js/modules/es.array.concat.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image, Skeleton } from '../../UI';
import ColorBatch from '../ColorBatch';
import { mapProfileStatus } from '../../../enums/profiles';
import avatar from '../../../assets/images/default-avatar.png';
import { container, pictureContainer, userInfo, statusContainer, infoContainer, marginBottom4 } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var UserInformation = function UserInformation(props) {
  var _data$picThumbnailUrl, _mapProfileStatus$dat, _mapProfileStatus$dat2;
  var data = props.data,
    className = props.className;
  return _isEmpty(data) ? ___EmotionJSX("div", {
    className: container(className)
  }, ___EmotionJSX(Skeleton, {
    width: 70,
    height: 70,
    circle: true,
    className: pictureContainer
  }), ___EmotionJSX("div", null, ___EmotionJSX(Skeleton, {
    width: 300,
    height: 20,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 320,
    height: 15,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 120,
    height: 15,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 270,
    height: 15,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 200,
    height: 15
  }))) : ___EmotionJSX("div", {
    className: container(className)
  }, ___EmotionJSX(Link, {
    to: "/profiles/profile/".concat(data.userId, "/profile-info")
  }, ___EmotionJSX(Image, {
    src: (_data$picThumbnailUrl = data.picThumbnailUrl) !== null && _data$picThumbnailUrl !== void 0 ? _data$picThumbnailUrl : avatar,
    width: 70,
    height: 70,
    className: pictureContainer
  })), ___EmotionJSX("div", null, ___EmotionJSX(Link, {
    to: "/profiles/profile/".concat(data.userId, "/profile-info")
  }, ___EmotionJSX("h2", {
    className: userInfo
  }, data.firstName || data.lastName ? "".concat(data.firstName, " ").concat(data.lastName) : data.fullName)), ___EmotionJSX(ColorBatch, {
    type: (_mapProfileStatus$dat = mapProfileStatus[data.profileStatus]) === null || _mapProfileStatus$dat === void 0 ? void 0 : _mapProfileStatus$dat.color,
    className: statusContainer
  }, (_mapProfileStatus$dat2 = mapProfileStatus[data.profileStatus]) === null || _mapProfileStatus$dat2 === void 0 ? void 0 : _mapProfileStatus$dat2.text), data.userName && ___EmotionJSX("p", {
    className: infoContainer
  }, "Username: ", ___EmotionJSX("span", null, data.userName)), data.nickName && ___EmotionJSX("p", {
    className: infoContainer
  }, "Nickname: ", ___EmotionJSX("span", null, data.nickName)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Profile ID: ", ___EmotionJSX("span", null, data.userId)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Account ID: ", ___EmotionJSX("span", null, data.accountId)), data.email && ___EmotionJSX("p", {
    className: infoContainer
  }, "Email address: ", ___EmotionJSX("span", null, data.email)), data.phone && ___EmotionJSX("p", {
    className: infoContainer
  }, "Phone number: ", ___EmotionJSX("span", null, data.phone))));
};
UserInformation.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string
};
export default UserInformation;