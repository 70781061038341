import { css } from 'emotion';
export var summaryBoxWrap = function summaryBoxWrap(theme) {
  return css({
    fontWeight: 500,
    padding: '12px 6px',
    color: theme.grayDark,
    transition: 'background-color .3s ease-in-out',
    '&:hover': {
      backgroundColor: theme.highlights
    },
    '&:nth-child(3n - 1)': {
      '&:hover': {
        backgroundColor: theme.highlights
      }
    }
  });
};