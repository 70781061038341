import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { css } from 'emotion';
export var tabs = css({
  width: '100%'
});
export var tabsListContainerWrapper = function tabsListContainerWrapper(showArrows) {
  return css({
    position: 'relative',
    overflow: 'hidden'
  }, showArrows && {
    paddingRight: 90
  });
};
export var tabsListContainer = function tabsListContainer(theme, tabListStyle) {
  return css(_objectSpread(_objectSpread({
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: -50,
    paddingBottom: 50,
    display: 'flex',
    width: '100%',
    backgroundColor: theme.white,
    paddingLeft: 25
  }, tabListStyle), {}, {
    '& > *': {
      display: 'inline-block'
    }
  }) // isDragging && {
  //   '& *': {
  //     cursor: 'grabbing',
  //     '&:hover': {
  //       backgroundColor: 'transparent',
  //     },
  //   },
  // }
  );
};
var activeTab = function activeTab(theme) {
  return {
    fontWeight: 600,
    color: theme.textLightPrimary,
    borderBottom: "2px solid ".concat(theme.primary)
  };
};
export var tabContainer = function tabContainer(active, theme) {
  return css({
    cursor: 'pointer',
    padding: '24px 24px',
    fontSize: 14,
    fontWeight: 400,
    color: theme.textLightSecondary,
    backgroundColor: theme.white
  }, active && activeTab(theme));
};
export var tabLinkContainer = function tabLinkContainer(theme) {
  return css({
    '&.active > *': activeTab(theme)
  });
};
var arrowStyle = function arrowStyle(theme, isDisabled) {
  return {
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 26,
    color: isDisabled ? theme.textLightDisabled : theme.textLightPrimary,
    borderRadius: '50%',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  };
};
export var scrollLeftIcon = function scrollLeftIcon(theme, isDisabled) {
  return css(_objectSpread({
    position: 'absolute',
    top: 0,
    right: 44
  }, arrowStyle(theme, isDisabled)));
};
export var scrollRightIcon = function scrollRightIcon(theme, isDisabled) {
  return css(_objectSpread({
    position: 'absolute',
    top: 0,
    right: 0
  }, arrowStyle(theme, isDisabled)));
};
export var tabsContentClass = css({});