import { css } from 'emotion';
export var formContainer = css({
  marginBottom: 24
});
export var deleteButton = css({
  marginLeft: 12,
  paddingLeft: 12,
  paddingRight: 0
});
export var countryColumn = css({
  '& label': {
    display: 'block',
    textAlign: 'right',
    fontSize: 14,
    marginRight: 16
  }
});