import "core-js/modules/es.string.small.js";
import { css } from 'emotion';
export var modalContainer = css({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  overflow: 'hidden auto',
  display: 'block'
});
export var modalScrollContainer = function modalScrollContainer(_ref) {
  var large = _ref.large,
    small = _ref.small;
  return css({
    minHeight: 'calc(100vh - 56px)',
    maxWidth: 500,
    margin: '28px auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }, large && {
    '@media (min-width: 992px)': {
      maxWidth: 800
    }
  }, small && {
    '@media (min-width: 576px)': {
      maxWidth: 300
    }
  });
};
export var modalContentContainer = function modalContentContainer(theme, className) {
  return css({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.white,
    padding: 24,
    borderRadius: 8
  }, className);
};
export var headerContainer = function headerContainer(theme) {
  return css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 32,
    paddingBottom: 16,
    borderBottom: "1px solid ".concat(theme.borderLight)
  });
};
export var closeIconStyles = css({
  textAlign: 'right',
  marginBottom: 32
});