import { css } from 'emotion';
var getTooltipOpenClass = function getTooltipOpenClass(align, position) {
  return {
    visibility: 'visible',
    transform: align ? 'translate(0, 0)' : position === 'top' || position === 'bottom' ? 'translate(-50%, 0)' : 'translate(0, -50%)'
  };
};
export var tooltipContainer = function tooltipContainer(_ref, isOpen) {
  var className = _ref.className,
    position = _ref.position,
    align = _ref.align,
    onClick = _ref.onClick;
  return css({
    position: 'relative',
    display: 'inline-block',
    textAlign: 'center',
    cursor: 'pointer'
  }, !onClick && {
    '&:hover > *:first-child': getTooltipOpenClass(align, position)
  }, isOpen && {
    '& > *:first-child': getTooltipOpenClass(align, position)
  }, className);
};
export var popupTooltip = function popupTooltip(_ref2, theme) {
  var position = _ref2.position,
    align = _ref2.align,
    singleLine = _ref2.singleLine,
    backgroundColor = _ref2.backgroundColor,
    color = _ref2.color,
    border = _ref2.border,
    borderRadius = _ref2.borderRadius;
  return css({
    position: 'absolute',
    padding: '3px 6px',
    backgroundColor: backgroundColor ? theme[backgroundColor] || backgroundColor : theme.white,
    color: color ? theme[color] || color : theme.textLightPrimary,
    whiteSpace: singleLine ? 'nowrap' : 'normal',
    visibility: 'hidden',
    transition: 'all .3s linear',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    border: border || "1px solid ".concat(theme.grayLight),
    borderRadius: borderRadius || 8,
    zIndex: 1
  }, position === 'bottom' && {
    top: 'calc(100% + 8px)',
    left: align ? align === 'left' ? 0 : 'auto' : '50%',
    right: align && align === 'right' ? 0 : 'auto',
    transform: align ? 'translate(0, 8px)' : 'translate(-50%, 8px)'
  }, position === 'top' && {
    bottom: 'calc(100% + 8px)',
    left: align ? align === 'left' ? 0 : 'auto' : '50%',
    right: align && align === 'right' ? 0 : 'auto',
    transform: align ? 'translate(0, -8px)' : 'translate(-50%, -8px)'
  }, position === 'left' && {
    right: 'calc(100% + 8px)',
    top: align ? align === 'top' ? 0 : 'auto' : '50%',
    bottom: align && align === 'bottom' ? 0 : 'auto',
    transform: align ? 'translate(-8px, 0)' : 'translate(-8px, -50%)'
  },
  // Default position
  position === 'right' && {
    left: 'calc(100% + 8px)',
    top: align ? align === 'top' ? 0 : 'auto' : '50%',
    bottom: align && align === 'bottom' ? 0 : 'auto',
    transform: align ? 'translate(8px, 0)' : 'translate(8px, -50%)'
  });
};