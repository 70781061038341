import React from 'react';
import { Tabs, Tab } from '../../../components/UI';
import AccountsList from './AccountsList';
import { tabContainer } from './styles';
import AccountsTransferStats from './TransferStats';
import { jsx as ___EmotionJSX } from "@emotion/react";
var AccountsPage = function AccountsPage() {
  return ___EmotionJSX(Tabs, {
    startingRoute: "/accounts",
    tabsContentClass: tabContainer
  }, ___EmotionJSX(Tab, {
    label: "Accounts List",
    url: "/list",
    component: function component() {
      return ___EmotionJSX(AccountsList, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "Accounts Transfer Statistics",
    url: "/transfer-stats",
    component: function component() {
      return ___EmotionJSX(AccountsTransferStats, null);
    }
  }));
};
export default AccountsPage;