export var optionsCatItemActive = [{
  label: 'Active Catalogue Items',
  value: true
}, {
  label: 'Inactive Catalogue Items',
  value: false
}];
export var optionsCatItemVisibility = [{
  label: 'Visible Catalogue Items',
  value: true
}, {
  label: 'Invisible Catalogue Items',
  value: false
}];
export var optionsPlansStatus = [{
  label: 'Plan Active',
  value: true
}, {
  label: 'Plan Inactive',
  value: false
}];
export var optionsSubscriptionsSearchBy = [{
  value: 'name',
  label: 'Name'
}, {
  value: 'vendor',
  label: 'Vendor'
}];