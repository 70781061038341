export var profileHistoryFilterOptions = [{
  label: 'Name',
  value: 'name'
}, {
  label: 'Email',
  value: 'email'
}, {
  label: 'Date of birth',
  value: 'dateOfBirth'
}, {
  label: 'Address',
  value: 'address'
}, {
  label: 'Phone number',
  value: 'phone'
}, {
  label: 'Status',
  value: 'status'
}];