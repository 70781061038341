import React from 'react';
import { Breadcrumbs, Paper } from '../../../../components/UI';
import BackButton from '../../../../components/Shared/BackButton';
import AddEditForm from '../../../../components/Bundles/AddEditForm';
import { addPageBreadcrumbs } from './config';
import { tabContainer, containerBack } from '../styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var CreateBundle = function CreateBundle() {
  return ___EmotionJSX("div", {
    className: tabContainer
  }, ___EmotionJSX(Breadcrumbs, {
    items: addPageBreadcrumbs
  }), ___EmotionJSX("div", {
    className: containerBack
  }, ___EmotionJSX(BackButton, {
    title: "Create Bundle"
  })), ___EmotionJSX(Paper, null, ___EmotionJSX(AddEditForm, null)));
};
export default CreateBundle;