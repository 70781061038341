import React from 'react';
import { Table, tableTitleHeadline } from '../../../../components/UI';
import EagleHistorySearchBar from '../../../../components/Recognition/EagleHistorySearchBar';
import { getAllEagleHistory } from '../../../../services/bonuses';
import { eagleColumns } from './config';
import { jsx as ___EmotionJSX } from "@emotion/react";
var EagleHistory = function EagleHistory() {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h1", {
    className: tableTitleHeadline
  }, "All Eagle Runs"), ___EmotionJSX(Table, {
    getDataKey: "items",
    getDataMethod: getAllEagleHistory,
    columns: eagleColumns,
    filterBar: EagleHistorySearchBar,
    emptyMessage: "There are no Eagle Runs.",
    pageSize: 10
  }));
};
export default EagleHistory;