import _isNil from "lodash/isNil";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect, matchPath, useLocation } from 'react-router-dom';
import NavBar from '../NavBar';
import { MENU_SEPARATOR_NAME } from './MenuSeparator';
import { contentContainer, content } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Content = function Content(_ref) {
  var _sideNavChildrens$fin, _currentRoute$label;
  var sideNavChildrens = _ref.sideNavChildrens,
    routes = _ref.routes,
    notFoundComponent = _ref.notFoundComponent,
    homeRoute = _ref.homeRoute;
  var location = useLocation();
  var RenderContent = sideNavChildrens.filter(function (el) {
    return el.type.displayName !== MENU_SEPARATOR_NAME;
  }).map(function (menu, i) {
    return ___EmotionJSX(Route, {
      key: "sideNavMenu-".concat(i),
      exact: menu.props.exact,
      path: menu.props.url,
      component: menu.props.component
    });
  });
  var currentRoute = (_sideNavChildrens$fin = sideNavChildrens.find(function (el) {
    return matchPath(location.pathname, el.props.url);
  })) === null || _sideNavChildrens$fin === void 0 ? void 0 : _sideNavChildrens$fin.props;
  return ___EmotionJSX("section", {
    className: contentContainer
  }, ___EmotionJSX(NavBar, {
    label: (_currentRoute$label = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.label) !== null && _currentRoute$label !== void 0 ? _currentRoute$label : 'OneLife',
    logoLink: homeRoute
  }), ___EmotionJSX("article", {
    className: content(currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.withPadding)
  }, ___EmotionJSX(Switch, null, homeRoute && ___EmotionJSX(Redirect, {
    exact: true,
    from: "/",
    to: homeRoute
  }), !_isNil(routes) && routes, RenderContent, notFoundComponent && ___EmotionJSX(Route, {
    component: notFoundComponent
  }))));
};
Content.propTypes = {
  sideNavChildrens: PropTypes.array,
  routes: PropTypes.array,
  notFoundComponent: PropTypes.func,
  homeRoute: PropTypes.string
};
export default Content;