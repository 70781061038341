import { css } from 'emotion';
export var valuePercentWrap = css({
  display: 'flex',
  alignItems: 'center'
});
export var valuePercentDiv = function valuePercentDiv(theme, sm) {
  return css({
    fontSize: sm ? '18px' : '28px',
    lineHeight: '1.1',
    letterSpacing: sm ? '-0.53px' : '-1.31px',
    color: sm ? theme.grayDark : theme.blackLight,
    marginBottom: '8px'
  });
};
export var valuePercentPercentage = function valuePercentPercentage(theme, isPositive) {
  return css({
    marginLeft: '10px',
    paddingLeft: '14px',
    position: 'relative',
    fontSize: '11px',
    letterSpacing: '-0.52px',
    fontWeight: '600',
    lineHeight: '1.2',
    width: '40px',
    color: isPositive ? theme.successDark : theme.errorDark,
    '&:before': {
      position: 'absolute',
      left: '0',
      top: 'calc(50% - 3px)',
      content: '',
      width: '0px',
      height: '0px',
      borderLeft: '4px solid transparent',
      borderRight: '4px solid transparent',
      borderBottom: isPositive ? theme.success : 0,
      borderTop: isPositive ? 0 : theme.errorDark
    }
  });
};