import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import React, { Children } from 'react';
import PropTypes from 'prop-types';
import MenuList from './MenuList';
import Content from './Content';
import { mainContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var SideNav = function SideNav(_ref) {
  var _ref$children = _ref.children,
    initChildren = _ref$children === void 0 ? [] : _ref$children,
    routes = _ref.routes,
    notFoundComponent = _ref.notFoundComponent,
    homeRoute = _ref.homeRoute;
  var children = Children.toArray(initChildren).filter(Boolean);
  return ___EmotionJSX("main", {
    className: mainContainer
  }, ___EmotionJSX(MenuList, {
    sideNavChildrens: children
  }), ___EmotionJSX(Content, {
    sideNavChildrens: children,
    routes: routes,
    notFoundComponent: notFoundComponent,
    homeRoute: homeRoute
  }));
};
SideNav.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  routes: PropTypes.array,
  notFoundComponent: PropTypes.func,
  homeRoute: PropTypes.string
};
export default SideNav;