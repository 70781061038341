import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../UI';
import { uploadFileContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var UploadFile = function UploadFile(props) {
  var handleChange = props.handleChange,
    multiple = props.multiple,
    acceptFileType = props.acceptFileType;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("label", {
    htmlFor: "uploadImage"
  }, ___EmotionJSX(Button, {
    secondary: true
  }, "Upload File")), ___EmotionJSX("input", {
    id: "uploadImage",
    type: "file",
    accept: acceptFileType,
    multiple: multiple,
    onChange: handleChange,
    className: uploadFileContainer
  }));
};
UploadFile.propTypes = {
  handleChange: PropTypes.func,
  multiple: PropTypes.bool,
  acceptFileType: PropTypes.string
};
export default UploadFile;