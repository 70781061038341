import { css } from 'emotion';
export var backContainer = css({
  marginBottom: 48
});
export var subscriptionPlanContainer = function subscriptionPlanContainer(theme) {
  return css({
    '& .disabled': {
      backgroundColor: theme.whiteGrayLighter,
      padding: '16px 0 0 16px',
      '& > strong': {
        marginBottom: 8,
        display: 'block'
      }
    }
  });
};
export var checkboxesContainer = css({
  paddingRight: 20,
  marginTop: 32
});
export var formActionButtonContaner = css({
  marginRight: -50,
  marginTop: 30,
  paddingLeft: 12,
  paddingRight: 0,
  display: 'inline-block',
  height: 'fit-content',
  alignSelf: 'flex-start'
});
export var navLink = function navLink(theme) {
  return css({
    borderRadius: 2,
    padding: '10px 20px',
    color: theme.white,
    border: "1px solid ".concat(theme.primaryLight),
    textAlign: 'center',
    backgroundColor: theme.primaryLight,
    fontSize: 14,
    '&:hover': {
      backgroundColor: theme.primary
    },
    '&:focus, &:active': {
      backgroundColor: theme.primaryLight,
      color: theme.textPrimaryFocused
    }
  });
};