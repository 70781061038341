import _isString from "lodash/isString";
import _isNumber from "lodash/isNumber";
import "core-js/modules/es.array.reverse.js";
import { css } from 'emotion';
var NUMBER_OF_COLUMNS = 12;
export var row = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row'
};
export var rowComp = function rowComp(_ref) {
  var column = _ref.column,
    reverse = _ref.reverse,
    noWrap = _ref.noWrap,
    align = _ref.align,
    justify = _ref.justify,
    padding = _ref.padding,
    margin = _ref.margin,
    minWidth = _ref.minWidth,
    className = _ref.className,
    gap = _ref.gap,
    horizontalGap = _ref.horizontalGap,
    verticalGap = _ref.verticalGap,
    flex = _ref.flex;
  return css(row, {
    flexDirection: column ? reverse ? 'column-reverse' : 'column' : reverse ? 'row-reverse' : 'row',
    flexWrap: noWrap ? 'no-wrap' : 'wrap'
  }, align && {
    alignItems: align
  }, justify && {
    justifyContent: justify
  }, gap && {
    margin: "-".concat(gap, "px")
  }, horizontalGap && {
    marginLeft: "-".concat(horizontalGap, "px"),
    marginRight: "-".concat(horizontalGap, "px")
  }, verticalGap && {
    marginTop: "-".concat(verticalGap, "px"),
    marginBottom: "-".concat(verticalGap, "px")
  }, flex && {
    flex: flex
  }, padding && {
    padding: padding
  }, margin && {
    margin: margin
  }, minWidth && {
    minWidth: minWidth
  }, className || {});
};
var baseCol = {
  flexBasis: '100%',
  maxWidth: '100%',
  flexGrow: 1,
  flexShrink: 0,
  outline: 'none'
};
export var col = baseCol;
export var xsCol = function xsCol(xs) {
  return {
    '@media (max-width: 575px)': {
      flexBasis: "".concat(100 / (NUMBER_OF_COLUMNS / xs), "%"),
      maxWidth: "".concat(100 / (NUMBER_OF_COLUMNS / xs), "%")
    }
  };
};
export var smCol = function smCol(sm) {
  return {
    '@media (min-width: 576px)': {
      flexBasis: "".concat(100 / (NUMBER_OF_COLUMNS / sm), "%"),
      maxWidth: "".concat(100 / (NUMBER_OF_COLUMNS / sm), "%")
    }
  };
};
export var mdCol = function mdCol(md) {
  return {
    '@media (min-width: 768px)': {
      flexBasis: "".concat(100 / (NUMBER_OF_COLUMNS / md), "%"),
      maxWidth: "".concat(100 / (NUMBER_OF_COLUMNS / md), "%")
    }
  };
};
export var lgCol = function lgCol(lg) {
  return {
    '@media (min-width: 992px)': {
      flexBasis: "".concat(100 / (NUMBER_OF_COLUMNS / lg), "%"),
      maxWidth: "".concat(100 / (NUMBER_OF_COLUMNS / lg), "%")
    }
  };
};
export var xlCol = function xlCol(xl) {
  return {
    '@media (min-width: 1200px)': {
      flexBasis: "".concat(100 / (NUMBER_OF_COLUMNS / xl), "%"),
      maxWidth: "".concat(100 / (NUMBER_OF_COLUMNS / xl), "%")
    }
  };
};
export var colComp = function colComp(_ref2) {
  var xs = _ref2.xs,
    sm = _ref2.sm,
    md = _ref2.md,
    lg = _ref2.lg,
    xl = _ref2.xl,
    _ref2$className = _ref2.className,
    className = _ref2$className === void 0 ? {} : _ref2$className,
    gap = _ref2.gap,
    verticalGap = _ref2.verticalGap,
    horizontalGap = _ref2.horizontalGap,
    dFlex = _ref2.dFlex,
    align = _ref2.align,
    justify = _ref2.justify,
    direction = _ref2.direction,
    wrap = _ref2.wrap,
    flow = _ref2.flow,
    flex = _ref2.flex,
    basis = _ref2.basis,
    grow = _ref2.grow,
    shrink = _ref2.shrink,
    height = _ref2.height,
    width = _ref2.width;
  return css(baseCol, xs && xsCol(xs), sm && smCol(sm), md && mdCol(md), lg && lgCol(lg), xl && xlCol(xl), (_isNumber(gap) || _isString(gap)) && {
    padding: gap
  }, (_isNumber(verticalGap) || _isString(verticalGap)) && {
    paddingTop: verticalGap,
    paddingBottom: verticalGap
  }, (_isNumber(horizontalGap) || _isString(horizontalGap)) && {
    paddingLeft: horizontalGap,
    paddingRight: horizontalGap
  }, dFlex && {
    display: 'flex'
  }, align && {
    alignItems: align
  }, justify && {
    justifyContent: justify
  }, (_isNumber(basis) || _isString(basis)) && {
    flexBasis: basis
  }, (_isNumber(grow) || _isString(grow)) && {
    flexGrow: grow
  }, (_isNumber(shrink) || _isString(shrink)) && {
    flexShrink: shrink
  }, _isString(flex) && {
    flex: flex
  }, _isString(direction) && {
    flexDirection: direction
  }, _isString(wrap) && {
    flexWrap: wrap
  }, _isString(flow) && {
    flexFlow: flow
  }, height, width, className);
};