import _isObject from "lodash/isObject";
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { infoContainer, transactionInfo, columnContainer, profileEmailText } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ColumnProfileInformation = function ColumnProfileInformation(props) {
  var data = props.data;
  var theme = useTheme();
  return ___EmotionJSX("div", {
    className: columnContainer
  }, _isObject(data) ? ___EmotionJSX(Link, {
    to: "/profiles/profile/".concat(data === null || data === void 0 ? void 0 : data.userId, "/profile-info")
  }, ___EmotionJSX("p", {
    className: infoContainer
  }, ___EmotionJSX("span", null, data === null || data === void 0 ? void 0 : data.accountNickName)), ___EmotionJSX("p", {
    className: infoContainer
  }, ___EmotionJSX("span", {
    className: profileEmailText(theme)
  }, data === null || data === void 0 ? void 0 : data.profileEmail))) : ___EmotionJSX("h3", {
    className: transactionInfo
  }, "Platform"));
};
ColumnProfileInformation.propTypes = {
  data: PropTypes.any
};
export default ColumnProfileInformation;