import "core-js/modules/es.array.concat.js";
import React from 'react';
import { Link } from 'react-router-dom';
// import { DetailsLink } from '../../../components/Shared/TableComponents';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var accountsColumns = function accountsColumns(isKycOperator) {
  return [{
    name: 'Account Number',
    value: 'id',
    width: 100
  }, {
    name: 'Nickname',
    render: function render(row) {
      return isKycOperator ? row.nickName : ___EmotionJSX(Link, {
        to: "/profiles/profile/".concat(row.profileUserId, "/accounts/").concat(row.id)
      }, row.nickName);
    }
  }, {
    name: 'Names',
    value: 'profileFullName'
  }, {
    name: 'Phone number',
    value: 'profilePhone'
  }, {
    name: 'Email',
    value: 'profileEmail'
  }, {
    name: 'Country',
    value: 'profileCountry'
  }, {
    name: 'Package',
    value: 'planName'
  }, {
    name: 'Cash balance',
    value: 'cashWalletBalance',
    width: 100
  }, {
    name: 'Coin balance',
    value: 'coinWalletBalance',
    width: 100
  }
  // {
  //   name: 'Bonuses',
  //   render: row => (
  //     <DetailsLink
  //       row={row}
  //       url={`/accounts/bonuses/${row.id}`}
  //       title="View Bonuses"
  //     />
  //   ),
  //   width: 70,
  // },
  ];
};