import { css } from 'emotion';
export var container = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%'
});
export var item = function item(theme) {
  return css({
    padding: '1.1rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: "0.07rem dashed ".concat(theme.borderLight),
    '&:last-child': {
      paddingBottom: '0',
      borderBottom: '0'
    },
    '&:first-child': {
      paddingTop: '0'
    }
  });
};
export var title = function title(theme) {
  return css({
    fontSize: '1.1rem',
    fontWeight: 500,
    color: theme.gray
  });
};
export var description = function description(theme) {
  return css({
    fontSize: '1rem',
    fontWeight: 'normal',
    color: theme.grayLight
  });
};
export var number = function number(theme) {
  return css({
    fontSize: '1.4rem',
    fontWeight: 600,
    color: theme.grayLight
  });
};
export var color = function color(_color) {
  return css({
    color: _color
  });
};