import { css } from 'emotion';
var itemHeight = 51;
export var searchBarContainer = function searchBarContainer() {
  return css({
    borderRadius: '2px',
    position: 'relative',
    zIndex: 50,
    width: '100%',
    outline: 'none'
  });
};
export var searchBarInput = function searchBarInput(hasText, theme) {
  return css({
    color: theme[hasText ? 'textLightPrimary' : 'textLightDisabled'],
    border: 'none',
    width: '100%',
    cursor: 'text',
    fontSize: '16px',
    lineHeight: '19px',
    padding: '10px 16px',
    outline: 'none'
  });
};
export var searchBarHistoryContainer = function searchBarHistoryContainer(numberOfItems, maxNumber, theme) {
  return css({
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    color: theme.textLightSecondary,
    maxHeight: numberOfItems ? "".concat(numberOfItems > maxNumber ? maxNumber * itemHeight - itemHeight / 2 : numberOfItems * itemHeight, "px") : 0,
    overflowY: 'auto',
    backgroundColor: 'white',
    boxShadow: '0 4px 4px rgba(0,0,0,0.3)',
    transition: 'max-height 0.35s ease-out'
  });
};
export var searchBarHistoryItem = function searchBarHistoryItem(theme) {
  return css({
    padding: '16px',
    color: theme.primary,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: theme.secondaryLight
    },
    '&:focus': {
      outline: 'none'
    }
  });
};
export var searchBarSearchIcon = css({
  position: 'absolute',
  left: '9px',
  top: '9px'
});
export var searchBarDeleteIcon = css({
  cursor: 'pointer',
  position: 'absolute',
  right: '9px',
  top: '9px'
});