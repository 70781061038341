import React from 'react';
import { useTheme } from 'emotion-theming';
import NetworkInformationIcon from '../NetworkInformationIcon';
import NetworkAccountInfo from '../NetworkAccountInfo';
import { networkTreeAvatarContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkTreeAvatar = function NetworkTreeAvatar(props) {
  var nodeData = props.nodeData,
    onChange = props.onChange,
    root = props.root,
    packageColor = props.packageColor;
  var theme = useTheme();
  var isEmpty = !nodeData.id;
  return ___EmotionJSX("div", {
    className: networkTreeAvatarContainer(theme, isEmpty)
  }, !isEmpty && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(NetworkAccountInfo, {
    nodeData: nodeData,
    onClick: onChange,
    root: root,
    packageColor: packageColor
  }), ___EmotionJSX(NetworkInformationIcon, {
    nodeData: nodeData,
    rootId: root === null || root === void 0 ? void 0 : root.id
  })));
};
export default NetworkTreeAvatar;