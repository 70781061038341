function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Login from './Login';
import { contentContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var routesDefinition = [{
  exact: true,
  path: '/login',
  component: Login
}].map(function (route) {
  return ___EmotionJSX(Route, _extends({
    key: route.path
  }, route));
});
export var PublicRoutes = function PublicRoutes(mainLink) {
  return ___EmotionJSX("section", {
    className: contentContainer
  }, ___EmotionJSX(Switch, null, routesDefinition, ___EmotionJSX(Redirect, {
    to: mainLink
  })));
};