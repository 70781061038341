import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '../../UI';
import { unclaimAccountButtonsContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ConfirmDialog = function ConfirmDialog(props) {
  var children = props.children,
    title = props.title,
    disabled = props.disabled,
    modalRef = props.modalRef,
    onConfirm = props.onConfirm,
    onCancel = props.onCancel;
  return ___EmotionJSX(Modal, {
    ref: modalRef,
    large: true,
    withoutClose: true,
    modalTitle: title
  }, children, ___EmotionJSX("div", {
    className: unclaimAccountButtonsContainer
  }, ___EmotionJSX(Button, {
    onClick: onCancel
  }, "Cancel"), ___EmotionJSX(Button, {
    onClick: onConfirm,
    disabled: disabled
  }, "Confirm")));
};
ConfirmDialog.propTypes = {
  modalRef: PropTypes.object,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
};
export default ConfirmDialog;