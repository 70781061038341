import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { quickStatsConfig } from '../config';
import { container, content, number, description, chart } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var QuickStatsChart = function QuickStatsChart(props) {
  var theme = useTheme();
  var canvasRef = useRef();
  var value = props.value,
    desc = props.desc,
    data = props.data,
    color = props.color,
    border = props.border,
    id = props.id;
  useEffect(function () {
    var chart = new Chart(canvasRef.current, quickStatsConfig(props));
    return function () {
      chart.destroy();
    };
  }, [data, color, border]);
  return ___EmotionJSX("div", {
    className: container
  }, ___EmotionJSX("div", {
    className: content
  }, ___EmotionJSX("span", {
    className: number(theme)
  }, value), ___EmotionJSX("span", {
    className: description(theme)
  }, desc)), ___EmotionJSX("div", {
    className: chart
  }, ___EmotionJSX("canvas", {
    ref: canvasRef,
    id: id
  })));
};
QuickStatsChart.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  desc: PropTypes.string,
  data: PropTypes.array,
  color: PropTypes.string,
  border: PropTypes.number
};
export default QuickStatsChart;