import _isNil from "lodash/isNil";
import React from 'react';
import PropTypes from 'prop-types';
import { Image, Skeleton } from '../../UI';
import ColorBatch from '../../Shared/ColorBatch';
import { mapProfileStatus } from '../../../enums/profiles';
import avatar from '../../../assets/images/default-avatar.png';
import { mainContainer, pictureContainer, userInfo, userData, infoContainer, marginBottom4 } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var UserInfo = function UserInfo(props) {
  var _ref, _user$pictureThumbnai, _user$profile, _user$profile2, _mapProfileStatus$use, _mapProfileStatus$use2, _user$userId;
  var user = props.user,
    className = props.className,
    withButtons = props.withButtons,
    inKyc = props.inKyc;
  return _isNil(user) ? ___EmotionJSX("section", {
    className: mainContainer(withButtons, className)
  }, ___EmotionJSX("article", {
    className: userData(withButtons)
  }, ___EmotionJSX(Skeleton, {
    width: 70,
    height: 70,
    circle: true,
    className: pictureContainer
  }), ___EmotionJSX("section", null, ___EmotionJSX(Skeleton, {
    width: 250,
    height: 25,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 320,
    height: 20
  })))) : ___EmotionJSX("section", {
    className: mainContainer(withButtons, className)
  }, ___EmotionJSX("article", {
    className: userData(withButtons)
  }, ___EmotionJSX(Image, {
    src: (_ref = (_user$pictureThumbnai = user === null || user === void 0 ? void 0 : user.pictureThumbnailUrl) !== null && _user$pictureThumbnai !== void 0 ? _user$pictureThumbnai : user === null || user === void 0 ? void 0 : user.pictureUrl) !== null && _ref !== void 0 ? _ref : avatar,
    width: 70,
    height: 70,
    size: "contain",
    className: pictureContainer
  }), ___EmotionJSX("section", null, ___EmotionJSX("div", {
    className: userInfo
  }, inKyc ? (user === null || user === void 0 ? void 0 : (_user$profile = user.profile) === null || _user$profile === void 0 ? void 0 : _user$profile.fullName) && ___EmotionJSX("h2", null, user === null || user === void 0 ? void 0 : (_user$profile2 = user.profile) === null || _user$profile2 === void 0 ? void 0 : _user$profile2.fullName) : ((user === null || user === void 0 ? void 0 : user.firstName) || (user === null || user === void 0 ? void 0 : user.lastName)) && ___EmotionJSX("h2", null, user.firstName, " ", user.lastName)), (user === null || user === void 0 ? void 0 : user.status) && ___EmotionJSX(ColorBatch, {
    type: (_mapProfileStatus$use = mapProfileStatus[user.status]) === null || _mapProfileStatus$use === void 0 ? void 0 : _mapProfileStatus$use.color
  }, (_mapProfileStatus$use2 = mapProfileStatus[user.status]) === null || _mapProfileStatus$use2 === void 0 ? void 0 : _mapProfileStatus$use2.text), ___EmotionJSX("p", {
    className: infoContainer
  }, "User ID:", ___EmotionJSX("span", null, (_user$userId = user === null || user === void 0 ? void 0 : user.userId) !== null && _user$userId !== void 0 ? _user$userId : user === null || user === void 0 ? void 0 : user.createdBy, " ")), (user === null || user === void 0 ? void 0 : user.userName) && ___EmotionJSX("p", {
    className: infoContainer
  }, "Username:", ___EmotionJSX("span", null, user === null || user === void 0 ? void 0 : user.userName, " ")))));
};
UserInfo.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
  withButtons: PropTypes.bool,
  inKyc: PropTypes.bool
};
export default UserInfo;