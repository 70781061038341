import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { getType, baseIcon } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Icon = function Icon(props) {
  var onClick = props.onClick,
    style = props.style;
  var theme = useTheme();
  return ___EmotionJSX("i", {
    role: "presentation",
    className: baseIcon(getType(props), props, theme),
    style: style,
    onClick: onClick
  });
};
Icon.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object
};
export default Icon;