import { css } from 'emotion';
export var baseButton = function baseButton(_ref, loading) {
  var theme = _ref.theme,
    disabled = _ref.disabled,
    color = _ref.color,
    backColor = _ref.backColor;
  return css({
    borderRadius: 4,
    cursor: disabled ? 'not-allowed' : 'pointer',
    padding: '10px 20px',
    color: theme[color] || theme.white,
    letterSpacing: 0.5,
    lineHeight: 1,
    textAlign: 'center',
    opacity: loading || disabled ? 0.4 : 1,
    backgroundColor: theme[backColor] || theme.primaryLight,
    userSelect: 'none',
    outline: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    fontSize: 14,
    transition: 'width 400ms ease-in-out',
    '&:hover': {
      backgroundColor: loading || disabled ? theme.primaryLight : theme.primary
    },
    '&:focus, &:active': {
      backgroundColor: theme.primaryLight,
      color: loading || disabled ? theme.white : theme.textPrimaryFocused
    },
    '& > i': {
      marginRight: 6
    }
  }, (disabled || loading) && {
    '& *': {
      pointerEvents: 'none'
    }
  });
};
export var successButton = function successButton(_ref2) {
  var theme = _ref2.theme,
    disabled = _ref2.disabled;
  return css({
    color: theme.white,
    backgroundColor: theme.success,
    '&:hover': {
      backgroundColor: disabled ? theme.successLight : theme.successDark
    },
    '&:focus, &:active': {
      backgroundColor: disabled ? theme.successLight : theme.successDark,
      color: disabled ? theme.successLight : theme.whiteGrayDark
    }
  });
};
export var warningButton = function warningButton(_ref3) {
  var theme = _ref3.theme,
    disabled = _ref3.disabled;
  return css({
    color: theme.white,
    backgroundColor: theme.warning,
    '&:hover': {
      backgroundColor: disabled ? theme.warningLight : theme.warningDark
    },
    '&:focus, &:active': {
      backgroundColor: disabled ? theme.warningLight : theme.warningDark,
      color: disabled ? theme.warningLight : theme.whiteGrayDark
    }
  });
};
export var infoButton = function infoButton(_ref4) {
  var theme = _ref4.theme,
    disabled = _ref4.disabled;
  return css({
    color: theme.white,
    backgroundColor: theme.info,
    '&:hover': {
      backgroundColor: disabled ? theme.infoLight : theme.infoDark
    },
    '&:focus, &:active': {
      backgroundColor: disabled ? theme.infoLight : theme.infoDark,
      color: disabled ? theme.infoLight : theme.whiteGrayDark
    }
  });
};
export var secondaryButton = function secondaryButton(_ref5) {
  var theme = _ref5.theme,
    disabled = _ref5.disabled;
  return css({
    color: theme.textSecondary,
    backgroundColor: theme.secondaryLight,
    '&:hover': {
      backgroundColor: disabled ? theme.secondaryLight : theme.secondary
    },
    '&:focus, &:active': {
      backgroundColor: disabled ? theme.secondaryLight : theme.secondaryDark,
      color: disabled ? theme.secondaryLight : theme.grayLight
    }
  });
};
export var outlineButton = function outlineButton(_ref6) {
  var theme = _ref6.theme,
    disabled = _ref6.disabled;
  return css({
    color: theme.gray,
    border: "1px solid ".concat(theme.grayLightest),
    backgroundColor: 'transparent',
    '&:hover': {
      border: "1px solid ".concat(disabled ? theme.grayLightest : theme.grayLight),
      backgroundColor: 'transparent'
    },
    '&:focus, &:active': {
      color: disabled ? theme.gray : theme.grayLight,
      border: "1px solid ".concat(disabled ? theme.grayLightest : theme.grayLighter),
      backgroundColor: 'transparent'
    }
  });
};
export var outlineInverseButton = function outlineInverseButton(_ref7) {
  var theme = _ref7.theme,
    disabled = _ref7.disabled;
  return css({
    color: theme.whiteGray,
    border: "1px solid ".concat(theme.whiteGrayLightest),
    backgroundColor: 'transparent',
    '&:hover': {
      border: "1px solid ".concat(disabled ? theme.whiteGrayLightest : theme.whiteGrayLight),
      backgroundColor: 'transparent'
    },
    '&:focus, &:active': {
      color: disabled ? theme.whiteGray : theme.whiteGrayLighter,
      border: "1px solid ".concat(disabled ? theme.whiteGrayLightest : theme.whiteGrayLighter),
      backgroundColor: 'transparent'
    }
  });
};
export var clearButton = function clearButton() {
  return css({
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    textAlign: 'left',
    '&:hover': {
      borderColor: 'transparent',
      backgroundColor: 'transparent'
    },
    '&:focus, &:active': {
      borderColor: 'transparent',
      backgroundColor: 'transparent'
    }
  });
};
export var smallButton = css({
  fontSize: 12,
  padding: '6px 12px',
  lineHeight: 1
});
export var largeButton = css({
  fontSize: 16,
  padding: '16px 32px'
});
export var checkButtonContainer = function checkButtonContainer(className) {
  return css({
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 600,
    '&.disabled': {
      cursor: 'not-allowed',
      '& i': {
        color: 'rgb(191 191 191 / 40%)',
        background: 'rgb(191 191 191 / 10%)'
      },
      '&:hover': {
        color: 'rgb(191 191 191 / 40%)',
        background: 'rgb(191 191 191 / 10%)'
      }
    }
  }, className);
};
export var iconContainer = css({
  marginRight: 10
});
export var buttonDropdownContainer = css({
  position: 'relative',
  outline: 'none',
  fontSize: '16px'
});
var itemHeight = 51;
export var buttonDropdownOptionsContainer = function buttonDropdownOptionsContainer(isOpened, maxNumber, optionListClassName, theme) {
  return css({
    position: 'absolute',
    top: '100%',
    right: 0,
    zIndex: 150,
    width: 'auto',
    color: theme.textLightSecondary,
    height: 'auto',
    maxHeight: maxNumber * itemHeight - itemHeight / 2,
    overflowY: 'auto',
    backgroundColor: 'white',
    transform: "scaleY(".concat(isOpened ? 1 : 0, ")"),
    transformOrigin: 'top',
    transition: 'transform 400ms ease-in-out',
    boxShadow: '0 4px 4px rgba(0,0,0,0.3)',
    borderTop: isOpened ? '1px solid #DDDDDD' : 'none',
    borderRadius: '0 0 4px 4px'
  }, optionListClassName);
};
export var buttonDropdownOptionItem = function buttonDropdownOptionItem(theme) {
  return css({
    padding: '16px 32px 16px 16px',
    color: theme.textLightPrimary,
    backgroundColor: 'transparent',
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
    '&:focus': {
      outline: 'none'
    },
    '& i, & img': {
      marginRight: 10
    }
  });
};
export var switchButtonContainer = function switchButtonContainer(width) {
  return css({
    position: 'relative',
    width: width || 70,
    userSelect: 'none'
  });
};
export var switchButtonInput = css({
  display: 'none'
});
export var switchButtonLabel = css({
  display: 'block',
  overflow: 'hidden',
  cursor: 'pointer',
  width: '100%',
  borderRadius: 20
});
export var switchButtonInner = function switchButtonInner(isChecked, activeText, inactiveText, _ref8, theme) {
  var backgroundColorBefore = _ref8.backgroundColorBefore,
    activeTextColor = _ref8.activeTextColor,
    backgroundColorAfter = _ref8.backgroundColorAfter,
    inactiveTextColor = _ref8.inactiveTextColor,
    fontSizeLabels = _ref8.fontSizeLabels,
    textSpacingLabels = _ref8.textSpacingLabels,
    sizingHeight = _ref8.sizingHeight;
  return css({
    display: 'block',
    width: '200%',
    marginLeft: isChecked ? 0 : '-100%',
    transition: 'margin 0.3s ease-in 0s',
    '&:before, &:after': {
      display: 'inline-block',
      width: '50%',
      height: sizingHeight || 30,
      padding: 0,
      lineHeight: "".concat(sizingHeight || 30, "px"),
      fontSize: fontSizeLabels || 14,
      color: 'white',
      fontWeight: 'bold'
    },
    '&:before': {
      content: activeText ? "'".concat(activeText, "'") : "'ON'",
      paddingLeft: textSpacingLabels || 10,
      backgroundColor: backgroundColorBefore || theme.primary,
      color: activeTextColor || theme.white
    },
    '&:after': {
      content: inactiveText ? "'".concat(inactiveText, "'") : "'OFF'",
      paddingRight: textSpacingLabels || 10,
      backgroundColor: backgroundColorAfter || theme.grayLight,
      color: inactiveTextColor || theme.gray,
      textAlign: 'right'
    }
  });
};
export var switchButtonCircle = function switchButtonCircle(isChecked, width, _ref9, theme) {
  var switchSize = _ref9.switchSize,
    switchBackgroundColor = _ref9.switchBackgroundColor,
    switchBorderColor = _ref9.switchBorderColor;
  return css({
    display: 'block',
    width: switchSize || 20,
    height: switchSize || 20,
    background: switchBackgroundColor || theme.white,
    position: 'absolute',
    bottom: 5,
    right: isChecked ? 4 : (width || 70) - (switchSize || 24),
    border: "2px solid ".concat(switchBorderColor || theme.grey, " "),
    borderRadius: 20,
    transition: 'all 0.3s ease-in 0s'
  });
};
export var checkGroup = css({
  '& > *': {
    marginRight: 8
  }
});