import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.freeze.js";
import "core-js/modules/es.object.define-properties.js";
var _templateObject;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { css, keyframes } from 'emotion';
export var networkTableShowMore = function networkTableShowMore(depthLevel) {
  return css({
    width: '115px',
    marginTop: '16px',
    marginBottom: '16px'
  }, depthLevel && {
    marginLeft: "".concat(48 * (depthLevel - 1), "px")
  });
};
export var networkTableNoResults = css({
  width: '100%',
  textAlign: 'center',
  margin: '30px 0'
});
export var rowContainer = function rowContainer(marginLeft) {
  return css({
    marginBottom: '12px',
    marginLeft: marginLeft
  });
};
var expandKeyframes = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    0% {\n      transform: scaleY(0.3);\n      opacity: 0;\n    }\n    50% {\n      opacity: 0.7;\n    }\n    100% {\n      transform: scaleY(1);\n      opacity: 1;\n    }\n  "])));
export var tableRowExpandable = function tableRowExpandable(theme, isExpanded) {
  return css(isExpanded && {
    '& > header': {
      backgroundColor: theme.backgroundLightGray,
      '&:hover': {
        backgroundColor: "".concat(theme.backgroundLightGray)
      }
    }
  });
};
export var tableRowExpandableContent = css({
  transformOrigin: 'top',
  animation: "0.6s ease-in-out 0s ".concat(expandKeyframes)
});
export var tableDataHeadContent = function tableDataHeadContent(theme) {
  return css({
    fontSize: '12px',
    fontWeight: 600,
    color: theme.grayDark,
    opacity: 0.7
  });
};
export var tableDataCell = function tableDataCell(theme, width) {
  return css({
    justifyContent: 'flex-end',
    padding: '10px',
    fontSize: '13px',
    letterSpacing: '-0.38px',
    fontWeight: 600,
    color: theme.grayDark,
    border: 'none'
  }, width && {
    flexBasis: width
  });
};
export var tableDataRow = function tableDataRow(theme) {
  return css({
    '&:hover': {
      backgroundColor: theme.white
    },
    '&:nth-child(2n)': {
      backgroundColor: theme.white
    }
  });
};