import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { successContainer, successIcon, successIconTip, successIconLong, titleSection } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var SuccessInfo = function SuccessInfo(props) {
  var title = props.title,
    className = props.className;
  var theme = useTheme();
  return ___EmotionJSX("div", {
    className: successContainer(theme, className)
  }, ___EmotionJSX("div", {
    className: successIcon(theme)
  }, ___EmotionJSX("span", {
    className: successIconTip(theme)
  }), ___EmotionJSX("span", {
    className: successIconLong(theme)
  })), ___EmotionJSX("h3", {
    className: titleSection
  }, title));
};
SuccessInfo.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
};
export default SuccessInfo;