import { css } from 'emotion';
export var container = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%'
});
export var content = css({
  display: 'flex',
  paddingBottom: '1rem',
  flexGrow: '1',
  flexDirection: 'column'
});
export var item = css({
  display: 'flex',
  marginBottom: '2.5rem',
  alignItems: 'center'
});
export var info = css({
  flex: 1
});
export var description = function description(theme) {
  return css({
    fontSize: '1rem',
    color: theme.gray,
    paddingBottom: '0.5rem',
    fontWeight: 500,
    display: 'block'
  });
};
export var value = function value(theme) {
  return css({
    fontSize: '1.3rem',
    fontWeight: 600,
    color: theme.gray,
    display: 'block'
  });
};
export var progress = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lineHeight: '0',
  paddingTop: '1rem'
});
export var progressSm = css({
  width: '100%',
  height: 10
});
export var stat = function stat(theme) {
  return css({
    fontSize: '1.3rem',
    fontWeight: 700,
    color: theme.textLightSecondary,
    paddingLeft: '1rem'
  });
};
export var chart = css({
  position: 'relative',
  '& > canvas': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4
  }
});
export var containerHeader = function containerHeader(theme) {
  return css({
    borderBottom: "1px solid ".concat(theme.borderLight),
    padding: '16px 32px'
  });
};