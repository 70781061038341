export var reasonOptions = [{
  label: 'fake documents',
  value: 'Manipulating the documents makes them fake. Upload valid KYC documents.'
}, {
  label: 'name of the profile holder',
  value: 'Please note that there is a discrepancy between the name of the profile holder and the name in the uploaded documents. Your account will be frozen due to suspicion of unauthorized account transfer. This is a serious violation and is regarded as fraudulent activity, which is not tolerated by the company.'
}, {
  label: 'identical documents',
  value: 'Please note that we do not accept identical documents as a proof of identity and proof of address(*example* - If Passport is uploaded for proof of identity it cannot be used as a proof of address, etc.)'
}, {
  label: 'last 3 months',
  value: 'Please upload proof of address document that has date of issue from the last 3 months.'
}, {
  label: 'with expiry',
  value: 'Please upload new proof of address document, with expiry (issue, billing, etc.) date clearly visible.'
}, {
  label: 'better quality',
  value: 'Please upload better quality images of your documents with visible and readable details.'
}, {
  label: 'better quality proof of address',
  value: 'Please upload better quality images of your proof of address documents with visible and readable details.'
}, {
  label: 'identifiable personal photo',
  value: 'Please upload image of your National passport/ID with better quality, higher resolution, readable details and identifiable personal photo.'
}, {
  label: '(dd/mm/yyyy)',
  value: 'Please upload new proof of ID document with fully visible date of birth (dd/mm/yyyy).'
}, {
  label: 'outdated',
  value: 'Your document is outdated - please upload new one with valid date of expiry.'
}, {
  label: 'Hungary',
  value: 'Please upload both of your documents (your ID document along with your Official card certifying address) in one file,to be used as a proof of address.'
}, {
  label: 'MRZ',
  value: 'Please upload the whole page of your passport/ID with all visible details, including machine-readable passport zone (MRZ) at the bottom of the identity page.'
}, {
  label: 'black/white documents',
  value: 'Black and white images of the document for proof of identity are not accepted. The documents for Proof of Identity must be coloured with all details clearly readable and visible. If black and white images are uploaded they must be appropriately certified/notarized (signed and stamped).'
}, {
  label: 'ID number',
  value: 'Please note that your filled in ID number in the system differs from the written on the corresponding documents.'
}, {
  label: 'date of expiry',
  value: 'Please note that your filled in date of expiry in the system differs from the written on the corresponding documents..'
}, {
  label: 'date of issue',
  value: 'Please note that your filled in date of issue in the system differs from the written on the corresponding documents.'
}, {
  label: 'date of birth',
  value: 'Please note that your filled in date of birth in the system differs from the written on the corresponding documents.'
}, {
  label: 'name',
  value: 'Please note that your filled in name in the system differs from the written on the corresponding documents.'
}, {
  label: 'account information does not match',
  value: 'Please note, that your account information does not match the details from your proof of address document.'
}, {
  label: 'in English/Latin Letters or officially translated',
  value: 'Please note that we accept International Passport/National Passport/National ID card/ Driving licenses/(all sides in one file) in English/Latin Letters or officially translated certified in English, as proof of ID.'
}, {
  label: 'International as proof of ID',
  value: 'Please note that we accept International Passport/National Passport/National ID card/ Driving licenses/(all sides in one file) as proof of ID.'
}, {
  label: 'full address',
  value: 'Proof of address is not accepted. Please upload full address in your account information.'
}, {
  label: 'visible full address',
  value: 'Please upload proof of address with visible full address (example Country, City, Address 1) of the document.'
}, {
  label: 'Only Utility bill',
  value: 'Only Utility bill(Not telecom), Bank Statement, Tax Authority Document, Residential Agency Document from the last 3 months in English/Latin letters having your detailed address written; duly signed and stamped ;National ID Card/National Passport if the address is shown in the document (in English/Latin letters or officially translated and certified in English) are accepted for Proof of address.'
}, {
  label: 'Black and white images',
  value: 'Black and white images of the document for proof of identity are not accepted. The documents for Proof of Identity must be coloured with all details clearly readable and visible. If black and white images are uploaded they must be appropriately certified/notarized (signed and stamped).'
}, {
  label: 'from the USA',
  value: 'Please be informed that OneLife Network examines the new developments with our legal team - if and what licenses will be needed for the company to operate in respect to all rules and new regulations. For that reason, until all matters are clarified, we temporarily suspended new registrations from the USA. Also please note that changing the country without being effectively a citizen of the second country is a violation of the regulations and the member himself is responsible for the wrong information given and bears any legal consequences arising from his actions.'
}, {
  label: 'officially translated and certified in English',
  value: 'Please upload new proof of address document in English/Latin letters or officially translated and certified in English.'
}, {
  label: 'photocopied documents',
  value: 'Please note that photocopied documents are not accepted, if they are not appropriately certified/notarized (signed and stamped).'
}, {
  label: 'Handwritten documents are not accepted',
  value: 'Handwritten documents are not accepted, even if there is a signature and stamp. Please provide a document having your address officially written in English/Latin letters and confirmed by the issuing authority.'
}, {
  label: 'cropped documents',
  value: "Please, note that we don't accept cropped documents for KYC approval. Please, upload valid KYC documents."
}];