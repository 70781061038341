import _isString from "lodash/isString";
import _isObject from "lodash/isObject";
import _isNil from "lodash/isNil";
import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Image, Icon, Skeleton } from '../../UI';
import avatar from '../../../assets/images/default-avatar.png';
import { container, pictureContainer, transactionInfo, planContainer, infoContainer, iconArrow, senderReceiver, marginBottom4, profileInfoContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TransactionInfo = function TransactionInfo(props) {
  var _data$sender, _data$sender2, _data$sender3, _data$sender4, _data$sender5, _data$sender6, _data$sender7, _data$sender8, _data$sender9, _data$sender10, _data$sender11, _data$sender12, _data$receiver, _data$receiver2, _data$receiver3, _data$receiver4, _data$receiver5, _data$receiver6, _data$receiver7, _data$receiver8, _data$receiver9, _data$receiver10, _data$receiver11, _data$receiver12;
  var data = props.data,
    className = props.className;
  var theme = useTheme();
  return ___EmotionJSX("div", {
    className: container(className)
  }, _isNil(data === null || data === void 0 ? void 0 : data.sender) ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Skeleton, {
    width: 70,
    height: 70,
    circle: true,
    className: pictureContainer
  }), ___EmotionJSX("div", null, ___EmotionJSX("h3", {
    className: senderReceiver(theme)
  }, "Sender"), ___EmotionJSX(Skeleton, {
    width: 300,
    height: 20,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 130,
    height: 17,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 130,
    height: 17,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 150,
    height: 17,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 170,
    height: 17,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 250,
    height: 17,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 100,
    height: 17,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 90,
    height: 17
  }))) : ___EmotionJSX("div", null, _isObject(data.sender) && ___EmotionJSX("div", {
    className: profileInfoContainer
  }, (data === null || data === void 0 ? void 0 : data.sender) && ___EmotionJSX(NavLink, {
    to: "/profiles/profile/".concat((_data$sender = data.sender) === null || _data$sender === void 0 ? void 0 : _data$sender.userId, "/profile-info")
  }, ___EmotionJSX(Image, {
    src: avatar,
    width: 70,
    height: 70,
    className: pictureContainer
  })), ___EmotionJSX("div", null, ___EmotionJSX("h3", {
    className: senderReceiver(theme)
  }, "Sender"), ___EmotionJSX("div", null, ___EmotionJSX(NavLink, {
    to: "/profiles/profile/".concat((_data$sender2 = data.sender) === null || _data$sender2 === void 0 ? void 0 : _data$sender2.userId, "/profile-info")
  }, ___EmotionJSX("h3", {
    className: transactionInfo
  }, (_data$sender3 = data.sender) === null || _data$sender3 === void 0 ? void 0 : _data$sender3.profileName)), ((_data$sender4 = data.sender) === null || _data$sender4 === void 0 ? void 0 : _data$sender4.accountPlan) && ___EmotionJSX("span", {
    className: planContainer(theme)
  }, (_data$sender5 = data.sender) === null || _data$sender5 === void 0 ? void 0 : _data$sender5.accountPlan), ___EmotionJSX("p", {
    className: infoContainer
  }, "Nickname: ", ___EmotionJSX("span", null, (_data$sender6 = data.sender) === null || _data$sender6 === void 0 ? void 0 : _data$sender6.accountNickName)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Username: ", ___EmotionJSX("span", null, (_data$sender7 = data.sender) === null || _data$sender7 === void 0 ? void 0 : _data$sender7.profileUserName)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Email: ", ___EmotionJSX("span", null, (_data$sender8 = data.sender) === null || _data$sender8 === void 0 ? void 0 : _data$sender8.profileEmail)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Phone number: ", ___EmotionJSX("span", null, (_data$sender9 = data.sender) === null || _data$sender9 === void 0 ? void 0 : _data$sender9.profilePhone)), ___EmotionJSX("p", {
    className: infoContainer
  }, "User ID: ", ___EmotionJSX("span", null, (_data$sender10 = data.sender) === null || _data$sender10 === void 0 ? void 0 : _data$sender10.userId)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Account ID: ", ___EmotionJSX("span", null, (_data$sender11 = data.sender) === null || _data$sender11 === void 0 ? void 0 : _data$sender11.accountId)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Wallet ID: ", ___EmotionJSX("span", null, (_data$sender12 = data.sender) === null || _data$sender12 === void 0 ? void 0 : _data$sender12.walletId))))), _isString(data === null || data === void 0 ? void 0 : data.sender) && ___EmotionJSX("div", null, ___EmotionJSX("h3", {
    className: senderReceiver(theme)
  }, "Sender"), ___EmotionJSX("h3", null, data.sender))), ___EmotionJSX(Icon, {
    iconName: "fas fa-arrow-right",
    className: iconArrow
  }), _isNil(data === null || data === void 0 ? void 0 : data.receiver) ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Skeleton, {
    width: 70,
    height: 70,
    circle: true,
    className: pictureContainer
  }), ___EmotionJSX("div", null, ___EmotionJSX("h3", {
    className: senderReceiver(theme)
  }, "Receiver"), ___EmotionJSX(Skeleton, {
    width: 300,
    height: 20,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 130,
    height: 17,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 130,
    height: 17,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 150,
    height: 17,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 170,
    height: 17,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 250,
    height: 17,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 100,
    height: 17,
    className: marginBottom4
  }), ___EmotionJSX(Skeleton, {
    width: 90,
    height: 17
  }))) : ___EmotionJSX("div", {
    className: profileInfoContainer
  }, _isObject(data === null || data === void 0 ? void 0 : data.receiver) && ___EmotionJSX(React.Fragment, null, (data === null || data === void 0 ? void 0 : data.receiver) && ___EmotionJSX(NavLink, {
    to: "/profiles/profile/".concat((_data$receiver = data.receiver) === null || _data$receiver === void 0 ? void 0 : _data$receiver.userId, "/profile-info")
  }, ___EmotionJSX(Image, {
    src: avatar,
    width: 70,
    height: 70,
    className: pictureContainer
  })), ___EmotionJSX("div", null, ___EmotionJSX("h3", {
    className: senderReceiver(theme)
  }, "Receiver"), ___EmotionJSX("div", null, ___EmotionJSX(NavLink, {
    to: "/profiles/profile/".concat((_data$receiver2 = data.receiver) === null || _data$receiver2 === void 0 ? void 0 : _data$receiver2.userId, "/profile-info")
  }, ___EmotionJSX("h3", {
    className: transactionInfo
  }, (_data$receiver3 = data.receiver) === null || _data$receiver3 === void 0 ? void 0 : _data$receiver3.profileName)), ((_data$receiver4 = data.receiver) === null || _data$receiver4 === void 0 ? void 0 : _data$receiver4.accountPlan) && ___EmotionJSX("span", {
    className: planContainer(theme)
  }, (_data$receiver5 = data.receiver) === null || _data$receiver5 === void 0 ? void 0 : _data$receiver5.accountPlan), ___EmotionJSX("p", {
    className: infoContainer
  }, "Nickname: ", ___EmotionJSX("span", null, (_data$receiver6 = data.receiver) === null || _data$receiver6 === void 0 ? void 0 : _data$receiver6.accountNickName)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Username: ", ___EmotionJSX("span", null, (_data$receiver7 = data.receiver) === null || _data$receiver7 === void 0 ? void 0 : _data$receiver7.accountNickName)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Email: ", ___EmotionJSX("span", null, (_data$receiver8 = data.receiver) === null || _data$receiver8 === void 0 ? void 0 : _data$receiver8.profileEmail)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Phone number: ", ___EmotionJSX("span", null, (_data$receiver9 = data.receiver) === null || _data$receiver9 === void 0 ? void 0 : _data$receiver9.profilePhone)), ___EmotionJSX("p", {
    className: infoContainer
  }, "User ID: ", ___EmotionJSX("span", null, (_data$receiver10 = data.receiver) === null || _data$receiver10 === void 0 ? void 0 : _data$receiver10.userId)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Account ID: ", ___EmotionJSX("span", null, (_data$receiver11 = data.receiver) === null || _data$receiver11 === void 0 ? void 0 : _data$receiver11.accountId)), ___EmotionJSX("p", {
    className: infoContainer
  }, "Wallet ID: ", ___EmotionJSX("span", null, (_data$receiver12 = data.receiver) === null || _data$receiver12 === void 0 ? void 0 : _data$receiver12.walletId))))), _isString(data.receiver) && ___EmotionJSX("div", null, ___EmotionJSX("h3", {
    className: senderReceiver(theme)
  }, "Receiver"), ___EmotionJSX("h3", null, data === null || data === void 0 ? void 0 : data.receiver))));
};
TransactionInfo.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string
};
export default TransactionInfo;