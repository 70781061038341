import React from 'react';
import { Tabs, Tab } from '../../../components/UI';
import SafeDepositList from './SafeDepositList';
import { type } from '../../../enums/wallets';
import { tabContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var DepositsPage = function DepositsPage() {
  return ___EmotionJSX(Tabs, {
    startingRoute: "/deposits",
    tabsContentClass: tabContainer
  }, ___EmotionJSX(Tab, {
    label: "Coin Safe",
    url: "/coin-safe",
    component: function component() {
      return ___EmotionJSX(SafeDepositList, {
        type: type.ONE
      });
    }
  }), ___EmotionJSX(Tab, {
    label: "Cash Safe",
    url: "/cash-safe",
    component: function component() {
      return ___EmotionJSX(SafeDepositList, {
        type: type.CASH
      });
    }
  }));
};
export default DepositsPage;