import { css } from 'emotion';
export var container = function container(className) {
  return css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    paddingTop: 16
  }, className);
};
export var pictureContainer = css({
  borderRadius: '50%',
  marginRight: 18,
  flexBasis: 70,
  flexShrink: 0
});
export var detailsContainer = css({
  flex: 1
});
export var packageInfo = css({
  marginBottom: 4,
  marginRight: 8
});
export var statusContainer = function statusContainer(theme, isActive) {
  return css({
    color: isActive ? theme.success : theme.error,
    backgroundColor: isActive ? theme.successLight : theme.errorLight,
    padding: '2px 4px',
    borderRadius: 1,
    marginRight: 12,
    '&:last-of-type': {
      marginRight: 0
    }
  });
};
export var infoContainer = css({
  display: 'flex',
  marginRight: 18,
  fontWeight: 600,
  marginBottom: 8,
  '& p': {
    fontWeight: 400
  },
  '& p:first-child': {
    fontWeight: 600,
    marginRight: 6,
    whiteSpace: 'nowrap'
  },
  '& > span': {
    fontWeight: 400,
    marginLeft: 6
  }
});
export var infoButtonContainer = css({
  display: 'flex',
  marginRight: 18
});
export var buttonPackagesInfo = css({
  padding: '6px 10px',
  marginLeft: 18
});
export var marginBottom4 = css({
  marginBottom: 4
});