import { css } from 'emotion';
export var backContainer = css({
  marginBottom: 48
});
export var bonusContainer = css({
  paddingLeft: 20,
  marginBottom: 12
});
export var levelMatching = css({
  paddingLeft: 36,
  display: 'flex',
  flexDirection: 'column'
});
export var bonus = css({
  display: 'flex',
  flexDirection: 'column'
});
export var marginBottom = function marginBottom(val) {
  return css({
    marginBottom: val
  });
};
export var marginTop = function marginTop(val) {
  return css({
    marginTop: val
  });
};
export var paddingLeft = function paddingLeft(value) {
  return css({
    paddingLeft: value
  });
};
export var questionUpdatePackage = css({
  marginBottom: 32,
  textAlign: 'center',
  marginTop: 24
});
export var buttonContainer = css({
  display: 'flex',
  justifyContent: 'space-between'
});
export var questionIcon = function questionIcon(theme) {
  return css({
    fontSize: 150,
    marginBottom: 32,
    color: theme.secondary
  });
};
export var versionCheckContainer = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});
export var prevVersionContainer = function prevVersionContainer(theme) {
  return css({
    position: 'relative',
    backgroundColor: theme.white,
    padding: '24px 32px'
  });
};