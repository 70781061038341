import React from 'react';
import { Tabs, Tab } from '../../../components/UI';
import Wallets from './Wallets';
import Profile from './Profile';
import Config from './Config';
import Bonus from './Bonus';
import Packages from './Packages';
import PaymentMethodsPage from './PaymentMethods/PaymentMethodsPage';
import CountryManagers from './CountryManagers';
import CountryBoardManagers from './CountryBoardManagers';
import { tabContainer } from './styles';
import TransferWalletsRules from './TransferWalletsRules';
import Charity from './Charity';
import { jsx as ___EmotionJSX } from "@emotion/react";
var SettingsPage = function SettingsPage() {
  return ___EmotionJSX(Tabs, {
    startingRoute: "/settings",
    tabsContentClass: tabContainer
  }, ___EmotionJSX(Tab, {
    label: "Wallets",
    url: "/wallets",
    component: function component() {
      return ___EmotionJSX(Wallets, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "Profile",
    url: "/profile",
    component: function component() {
      return ___EmotionJSX(Profile, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "Config",
    url: "/config",
    component: function component() {
      return ___EmotionJSX(Config, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "Bonus",
    url: "/bonus",
    component: function component() {
      return ___EmotionJSX(Bonus, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "Packages",
    url: "/packages",
    component: function component() {
      return ___EmotionJSX(Packages, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "Payment Methods",
    url: "/payment-methods",
    component: function component() {
      return ___EmotionJSX(PaymentMethodsPage, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "DS Country Managers",
    url: "/country-managers",
    component: function component() {
      return ___EmotionJSX(CountryManagers, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "DS Country Board Managers",
    url: "/country-board-managers",
    component: function component() {
      return ___EmotionJSX(CountryBoardManagers, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "Transfer Coin Wallets Rules",
    url: "/transfer-coin-wallets-rules",
    component: function component() {
      return ___EmotionJSX(TransferWalletsRules, null);
    }
  }), ___EmotionJSX(Tab, {
    label: "Charity",
    url: "/charity",
    component: function component() {
      return ___EmotionJSX(Charity, null);
    }
  }));
};
export default SettingsPage;