import { css } from 'emotion';
export var tabContainer = css({
  padding: '48px 40px'
});
export var container = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 48,
  '& > h1': {
    marginBottom: 0,
    fontWeight: 600
  }
});
export var navLink = function navLink(theme) {
  return css({
    borderRadius: 2,
    padding: '10px 20px',
    color: theme.white,
    border: "1px solid ".concat(theme.primaryLight),
    textAlign: 'center',
    backgroundColor: theme.primaryLight,
    fontSize: 14,
    '&:hover': {
      backgroundColor: theme.primary
    },
    '&:focus, &:active': {
      backgroundColor: theme.primaryLight,
      color: theme.textPrimaryFocused
    }
  });
};
export var containerBack = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 48
});
export var paperContainer = css({
  '& > div > *:last-child': {
    marginBottom: 0
  }
});
export var statusContainer = css({
  marginRight: 12,
  '&:last-of-type': {
    marginRight: 0
  }
});
export var labelContainer = css({
  marginBottom: 6
});