import "core-js/modules/es.array.map.js";
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTheme } from 'emotion-theming';
import { Tooltip, Row, Col } from '../../UI';
import { titleOutstandingGoals, statsMissedDailyGoals } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TooltipDailyGoals = function TooltipDailyGoals(props) {
  var _data$length, _data$length2, _data$length3, _data$length4;
  var position = props.position,
    data = props.data,
    forKycOperator = props.forKycOperator;
  var theme = useTheme();
  return data !== null && data !== void 0 && data.length ? ___EmotionJSX(Tooltip, {
    content: ___EmotionJSX(Row, null, ___EmotionJSX(Col, {
      sm: 12
    }, ___EmotionJSX("h4", {
      className: titleOutstandingGoals
    }, "Days with outstanding daily goals ", (_data$length = data === null || data === void 0 ? void 0 : data.length) !== null && _data$length !== void 0 ? _data$length : 0), ___EmotionJSX("hr", null)), data === null || data === void 0 ? void 0 : data.map(function (el, ind) {
      return ___EmotionJSX(Col, {
        key: ind,
        sm: 4,
        className: statsMissedDailyGoals(theme)
      }, moment(el).format('DD.MM.YYYY'));
    })),
    position: position
  }, !forKycOperator ? data === null || data === void 0 ? void 0 : data.length : ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h1", null, (_data$length2 = data === null || data === void 0 ? void 0 : data.length) !== null && _data$length2 !== void 0 ? _data$length2 : 0), ___EmotionJSX("h4", null, "Unfulfilled Daily Goals"))) : !forKycOperator ? (_data$length3 = data === null || data === void 0 ? void 0 : data.length) !== null && _data$length3 !== void 0 ? _data$length3 : 0 : ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h1", null, (_data$length4 = data === null || data === void 0 ? void 0 : data.length) !== null && _data$length4 !== void 0 ? _data$length4 : 0), ___EmotionJSX("h4", null, "Unfulfilled Daily Goals"));
};
TooltipDailyGoals.propTypes = {
  position: PropTypes.string,
  data: PropTypes.array,
  forKycOperator: PropTypes.bool
};
export default TooltipDailyGoals;