import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var colourStyles = {
  control: function control(styles, _ref) {
    var isDisabled = _ref.isDisabled,
      isFocused = _ref.isFocused,
      isSelected = _ref.isSelected;
    return _objectSpread(_objectSpread({}, styles), {}, {
      cursor: 'pointer',
      backgroundColor: isDisabled ? null : isSelected ? '#ffffff' : isFocused ? 'rgba(247,248,252,1)' : 'rgba(247,248,252,1)',
      borderRadius: 3,
      borderColor: isDisabled ? null : isSelected ? '#ed4a49' : isFocused ? '#ed4a49' : '#e9eaf0b3',
      outline: 0,
      boxShadow: 'none',
      minHeight: 44,
      '&:hover': {
        borderColor: 'none'
      },
      '&.custom-select__control--menu-is-open .custom-select__dropdown-indicator': {
        transform: 'rotate(-180deg)'
      }
    });
  },
  menu: function menu(styles) {
    return _objectSpread(_objectSpread({}, styles), {}, {
      marginTop: 0,
      borderRadius: 'none'
    });
  },
  placeholder: function placeholder(styles) {
    return _objectSpread(_objectSpread({}, styles), {}, {
      color: '#5f636f',
      fontSize: 14,
      fontWeight: 600
    });
  },
  singleValue: function singleValue(styles) {
    return _objectSpread(_objectSpread({}, styles), {}, {
      color: '#5f636f',
      fontSize: 14,
      fontWeight: 600,
      paddingLeft: 7
    });
  },
  indicatorsContainer: function indicatorsContainer(styles) {
    return _objectSpread(_objectSpread({}, styles), {}, {
      '& > .custom-select__dropdown-indicator': {
        cursor: 'pointer',
        display: 'inline-block',
        color: 'rgba(55,57,63,.6)',
        transition: 'transform 0.35s ease-out',
        '&:hover': {
          color: 'rgba(55,57,63,.6)'
        },
        '& > svg': {
          width: 18,
          height: 18
        }
      }
    });
  },
  indicatorSeparator: function indicatorSeparator(styles) {
    return _objectSpread(_objectSpread({}, styles), {}, {
      backgroundColor: 'none'
    });
  },
  option: function option(styles, _ref2) {
    var isDisabled = _ref2.isDisabled,
      isFocused = _ref2.isFocused,
      isSelected = _ref2.isSelected;
    return _objectSpread(_objectSpread({}, styles), {}, {
      backgroundColor: isDisabled ? null : isSelected ? '#ed4a49' : isFocused ? '#f7f8fa' : '#ffffff',
      color: isDisabled ? null : isSelected ? '#ffffff' : '#5f636f',
      fontSize: 13,
      fontWeight: 600,
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 15,
      paddingBottom: 15,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': _objectSpread(_objectSpread({}, styles[':active']), {}, {
        backgroundColor: !isDisabled && (isSelected ? '#ffffff' : '#ffffff')
      })
    });
  }
};
export default colourStyles;