import { css } from 'emotion';
export var shopPackageCardTop = function shopPackageCardTop(theme) {
  return css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 3,
    border: "solid 1px ".concat(theme.whiteGrayDark),
    backgroundColor: theme.whiteGrayLight,
    minHeight: 100,
    padding: '0px 6px',
    margin: 8
  });
};
export var pictureContainer = css({
  borderRadius: '50%',
  flexBasis: 60,
  flexShrink: 0,
  marginRight: 12
});
export var loaderTitle = css({
  marginBottom: 6
});