import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { configSalesBarChart, dataSalesBarChart } from '../config';
import { container, header, titleContainer, description, chart } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var SalesBarChart = function SalesBarChart(_ref) {
  var title = _ref.title,
    desc = _ref.desc;
  var theme = useTheme();
  var ref = useRef();
  useEffect(function () {
    var chart = new Chart(ref.current, configSalesBarChart(theme));
    return function () {
      chart.destroy();
    };
  }, [dataSalesBarChart(theme)]);
  return ___EmotionJSX("div", {
    className: container
  }, ___EmotionJSX("div", {
    className: header
  }, ___EmotionJSX("h3", {
    className: titleContainer(theme)
  }, title), ___EmotionJSX("span", {
    className: description(theme)
  }, desc)), ___EmotionJSX("div", {
    className: chart,
    style: {
      height: '120px'
    }
  }, ___EmotionJSX("canvas", {
    ref: ref
  })));
};
SalesBarChart.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string
};
export default SalesBarChart;