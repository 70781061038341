import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { currencyByType } from '../../../utils';
import { type as walletType } from '../../../enums/wallets';
import { matchingBonusSubrow } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var MatchingLevelBvRow = function MatchingLevelBvRow(props) {
  var levelBv = props.levelBv,
    hasBonusLevel = props.hasBonusLevel,
    percents = props.percents;
  var theme = useTheme();
  return ___EmotionJSX("div", null, ___EmotionJSX("div", null, currencyByType(levelBv !== null && levelBv !== void 0 ? levelBv : 0, walletType.BV)), ___EmotionJSX("span", {
    className: matchingBonusSubrow(theme)
  }, hasBonusLevel ? "".concat(percents, "%") : 'N/A'));
};
MatchingLevelBvRow.propTypes = {
  levelBv: PropTypes.number,
  hasBonusLevel: PropTypes.bool,
  percents: PropTypes.number
};
export default MatchingLevelBvRow;