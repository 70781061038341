import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isFunction from "lodash/isFunction";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { Dropdown, Spinner, ReactSelectDropdown } from '../../UI';
import useAccounts from '../../../hooks/useAccounts';
import { accountsOptions } from '../../../enums/account';
import { shoppingCartProfileContainerAccount, dropdownFilterPayment, dropdownFilter, inputFilter } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var FilterByAccount = function FilterByAccount(props) {
  var onSelect = props.onSelect,
    paymentMethods = props.paymentMethods;
  var _useAccounts = useAccounts(),
    searchAccountRef = _useAccounts.searchAccountRef,
    hasMore = _useAccounts.hasMore,
    fetchAccounts = _useAccounts.fetchAccounts;
  var filtersRef = useRef({});
  useEffect(function () {
    onSelect(null);
  }, []);
  var handleChangeAccount = function handleChangeAccount(value) {
    filtersRef.current = _objectSpread(_objectSpread({}, filtersRef.current), {}, {
      account: value
    });
    _isFunction(onSelect) && onSelect(filtersRef.current);
  };
  var handlePaymentChange = function handlePaymentChange(_ref) {
    var value = _ref.value;
    filtersRef.current = _objectSpread(_objectSpread({}, filtersRef.current), {}, {
      paymentMethod: value
    });
    _isFunction(onSelect) && onSelect(filtersRef.current);
  };

  // eslint-disable-next-line react/prop-types
  var MenuList = function MenuList(_ref2) {
    var children = _ref2.children;
    return ___EmotionJSX("div", {
      style: {
        maxHeight: '300px',
        overflow: 'auto'
      }
    }, ___EmotionJSX(InfiniteScroll, {
      loadMore: function loadMore() {
        fetchAccounts({
          page: searchAccountRef.current.pageIndex + 1
        });
      },
      hasMore: hasMore,
      loader: ___EmotionJSX(Spinner, {
        key: 0
      }),
      initialLoad: false,
      useWindow: false
    }, children));
  };
  return ___EmotionJSX("div", {
    className: shoppingCartProfileContainerAccount
  }, ___EmotionJSX(Dropdown, {
    noClear: true,
    value: accountsOptions[0],
    options: accountsOptions,
    onChange: function onChange(_ref3) {
      var value = _ref3.value;
      searchAccountRef.current.searchCriteria = value;
    },
    className: dropdownFilter
  }), ___EmotionJSX(ReactSelectDropdown, {
    async: true,
    cacheOptions: true,
    defaultOptions: true,
    placeholder: "Search profile",
    loadOptions: function loadOptions(inputValue, callback) {
      fetchAccounts({
        inputValue: inputValue,
        callback: callback
      });
    },
    onMenuOpen: function onMenuOpen() {
      var _searchAccountRef$cur;
      !((_searchAccountRef$cur = searchAccountRef.current.results) !== null && _searchAccountRef$cur !== void 0 && _searchAccountRef$cur.length) && fetchAccounts({
        inputValue: ''
      });
    },
    components: {
      MenuList: MenuList
    },
    onChange: handleChangeAccount,
    className: inputFilter
  }), ___EmotionJSX(Dropdown, {
    noClear: true,
    options: paymentMethods,
    onChange: handlePaymentChange,
    placeholder: "Select payment method",
    className: dropdownFilterPayment
  }));
};
FilterByAccount.propTypes = {
  onSelect: PropTypes.func,
  paymentMethods: PropTypes.array
};
export default FilterByAccount;