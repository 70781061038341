import { css } from 'emotion';
export var containerShop = function containerShop() {
  return css({
    cursor: 'pointer',
    flex: 1,
    position: 'relative'
  });
};
export var shopPackageCardTop = function shopPackageCardTop(theme) {
  return css({
    display: 'block',
    borderRadius: 3,
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
    backgroundColor: theme.white,
    padding: '16px',
    position: 'relative',
    zIndex: 1
  });
};
export var shopPackageCardUnder = function shopPackageCardUnder() {
  return css({
    display: 'block',
    borderRadius: 3,
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    padding: '16px',
    position: 'absolute',
    top: -4,
    left: -4,
    width: '100%',
    height: '100%',
    zIndex: 0
  });
};
export var packageCardContainer = css({
  flex: 1
});
export var subscriptionCardContainer = css({
  flex: 1,
  '& p': {
    margin: '4px 0',
    fontSize: 14,
    fontWeight: 600
  }
});
export var containerPackageShop = css({
  display: 'block',
  textAlign: 'center',
  marginBottom: 16
});
export var pictureContainer = css({
  borderRadius: '50%',
  margin: [0, 'auto'],
  display: 'inline-block',
  marginBlock: '16px'
});
export var titlePackage = css({
  wordBreak: 'break-all',
  flexGrow: 1,
  '& > p': {
    fontSize: 24
  },
  marginBottom: 32
});
export var actionInputContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  marginBottom: 12
});
export var dateContainer = function dateContainer(theme) {
  return css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& > span': {
      fontSize: 12,
      '& > span': {
        color: theme.grayLight,
        fontWeight: 600
      }
    }
  });
};
export var calendarIcon = function calendarIcon(theme) {
  return css({
    fontSize: 14,
    color: theme.info,
    marginRight: 4
  });
};
export var academyContainer = css({
  marginBottom: 6,
  fontSize: 11,
  '& > p': {
    '& > b': {
      marginRight: 6
    }
  }
});