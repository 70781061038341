export var optionsStatus = [{
  label: 'Active',
  value: true
}, {
  label: 'Inactive',
  value: false
}];
export var optionsVisibility = [{
  label: 'Visible',
  value: true
}, {
  label: 'Invisible',
  value: false
}];
export var optionsTypePackage = [{
  label: 'Educational',
  value: 'package'
}, {
  label: 'GAS',
  value: 'gasPackage'
}];