export var optionsStatus = [{
  label: 'Active',
  value: true
}, {
  label: 'Inactive',
  value: false
}];
export var optionsVisibility = [{
  label: 'Visible',
  value: true
}, {
  label: 'Invisible',
  value: false
}];