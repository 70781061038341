import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchBar from '../SearchBar';
import { Spinner } from '../Loader';
import useOptions from './useOptions';
import { dropdownSearch, infiniteScroll } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var OptionsList = function OptionsList(props) {
  var withSearch = props.withSearch,
    className = props.className,
    containerRef = props.containerRef,
    theme = props.theme;
  var _useOptions = useOptions(props),
    renderList = _useOptions.renderList,
    filterResults = _useOptions.filterResults,
    searchRef = _useOptions.searchRef,
    needInfiniteScroll = _useOptions.needInfiniteScroll,
    allOptionsLength = _useOptions.allOptionsLength,
    optionsLength = _useOptions.optionsLength,
    fetchMoreResults = _useOptions.fetchMoreResults;
  var search = withSearch && ___EmotionJSX(SearchBar, {
    inputRef: searchRef,
    placeholder: "Search",
    onSubmit: filterResults,
    className: dropdownSearch(theme)
  });
  var container = ___EmotionJSX("div", {
    role: "presentation",
    onClick: function onClick(event) {
      return event.stopPropagation();
    },
    className: className,
    ref: containerRef
  }, search, containerRef.current.isConnected && needInfiniteScroll && ___EmotionJSX(InfiniteScroll, {
    dataLength: optionsLength,
    next: fetchMoreResults,
    hasMore: !!optionsLength && allOptionsLength > optionsLength,
    scrollableTarget: containerRef.current,
    loader: ___EmotionJSX("div", {
      className: infiniteScroll
    }, ___EmotionJSX(Spinner, {
      size: 65,
      loading: true
    }))
  }, renderList), !needInfiniteScroll && renderList);
  return container;
};
OptionsList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayKey: PropTypes.string,
  uniqueKey: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.number]),
  className: PropTypes.string,
  multiSelect: PropTypes.bool,
  withSearch: PropTypes.bool,
  isOpen: PropTypes.bool,
  theme: PropTypes.object,
  containerRef: PropTypes.object
};
export default OptionsList;