export var accountRank = {
  Rookie: '',
  Sapphire: 'Sapphire',
  Ruby: 'Ruby',
  Emerald: 'Emerald',
  Diamond: 'Diamond',
  BlueDiamond: 'Blue Diamond',
  BlackDiamond: 'Black Diamond',
  CrownDiamond: 'Crown Diamond'
};
export var accountRankId = {
  0: accountRank.Rookie,
  1: accountRank.Sapphire,
  2: accountRank.Ruby,
  3: accountRank.Emerald,
  4: accountRank.Diamond,
  5: accountRank.BlueDiamond,
  6: accountRank.BlackDiamond,
  7: accountRank.CrownDiamond
};
export var accountStatus = {
  Active: 'Active',
  Frozen: 'Frozen',
  Inactive: 'Inactive'
};
export var accountsOptions = [{
  label: 'Account ID',
  value: 'id'
}, {
  label: 'Nickname',
  value: 'nickName'
}];