import { css } from 'emotion';
export var searchContainer = css({
  alignItems: 'center'
});
export var countryImage = css({
  marginRight: 12,
  width: 20,
  height: 20
});
export var countryContainer = css({
  display: 'flex',
  alignItems: 'center',
  padding: 16,
  '& > .flag-img': {
    maxWidth: '24px',
    marginRight: '8px',
    border: '3px solid white'
  }
});