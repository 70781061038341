import React from 'react';
import { softLogout } from '@oneecosystem/authenticate';
import { Button } from '../Button';
import { useAuth } from '../Authenticate';
import { rightSideContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NavLinks = function NavLinks() {
  var _useAuth = useAuth(),
    isAuthenticated = _useAuth.isAuthenticated;
  return ___EmotionJSX("div", {
    className: rightSideContainer
  }, isAuthenticated && ___EmotionJSX(Button, {
    outline: true,
    small: true,
    onClick: softLogout
  }, "Logout"));
};
export default NavLinks;