import React from 'react';
import { Tabs, Tab } from '../../../components/UI';
import SafePlansList from './SafePlansList';
import { type } from '../../../enums/wallets';
import { tabContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PlansPage = function PlansPage() {
  return ___EmotionJSX(Tabs, {
    startingRoute: "/plans",
    tabsContentClass: tabContainer
  }, ___EmotionJSX(Tab, {
    label: "Coin Safe",
    url: "/coin-safe",
    component: function component() {
      return ___EmotionJSX(SafePlansList, {
        type: type.ONE
      });
    }
  }), ___EmotionJSX(Tab, {
    label: "Cash Safe",
    url: "/cash-safe",
    component: function component() {
      return ___EmotionJSX(SafePlansList, {
        type: type.CASH
      });
    }
  }));
};
export default PlansPage;