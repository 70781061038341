import "core-js/modules/es.function.name.js";
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { imageResponsive, networkTableNameCol, networkTableNameColAvatar, networkTableNameColIcon, networkTableNameColIconHolder, networkTableNameColName, networkTableNameColNameHeadline } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkTableNameCol = function NetworkTableNameCol(props) {
  var id = props.id,
    name = props.name,
    nickName = props.nickName,
    userAvatar = props.userAvatar,
    packageColor = props.packageColor,
    level = props.level,
    downlinksCount = props.downlinksCount,
    toggleExpand = props.toggleExpand,
    isExpanded = props.isExpanded;
  var theme = useTheme();
  return ___EmotionJSX("div", {
    className: networkTableNameCol(level)
  }, level < 4 && !!downlinksCount ? ___EmotionJSX("span", {
    role: "button",
    tabIndex: "0",
    onClick: toggleExpand,
    className: networkTableNameColIcon(theme, !isExpanded && downlinksCount, isExpanded)
  }, isExpanded ? '-' : '+') : ___EmotionJSX("span", {
    className: networkTableNameColIconHolder
  }), ___EmotionJSX("div", {
    className: networkTableNameColAvatar,
    style: {
      borderColor: packageColor
    }
  }, ___EmotionJSX("img", {
    src: userAvatar,
    className: imageResponsive,
    alt: "".concat(name, "-avatar")
  })), ___EmotionJSX("div", {
    className: networkTableNameColName(theme)
  }, ___EmotionJSX("h4", {
    className: networkTableNameColNameHeadline
  }, name), ___EmotionJSX("p", null, "#", id, " ", nickName)));
};
NetworkTableNameCol.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  nickName: PropTypes.string,
  level: PropTypes.string,
  userAvatar: PropTypes.string,
  packageColor: PropTypes.string,
  downlinksCount: PropTypes.number,
  toggleExpand: PropTypes.func,
  isExpanded: PropTypes.bool
};
export default NetworkTableNameCol;