function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _pick from "lodash/pick";
import _isPlainObject from "lodash/isPlainObject";
import _isNil from "lodash/isNil";
import _isEqual from "lodash/isEqual";
import _isNumber from "lodash/isNumber";
import _isString from "lodash/isString";
import _isArray from "lodash/isArray";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.some.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import { Children } from 'react';
import hash from 'object-hash';
import { removeNodes } from '../Dropdown/utils';
import { noValue } from '../../../utils';
export var getFormChilds = function getFormChilds(children) {
  var newChildrens = [];
  var getFlatList = function getFlatList(childs) {
    var childChildren = Children.toArray(childs).filter(Boolean);
    childChildren.forEach(function (child) {
      if (child.type.displayName === 'FormParagraph') getFlatList(child.props.children);else newChildrens.push(child);
    });
  };
  getFlatList(children);
  return newChildrens;
};
export var mapPreFilledValues = function mapPreFilledValues(preFilledValues, children) {
  var newMappedValues = {};
  Object.keys(preFilledValues || {}).forEach(function (id) {
    var correspondingChild = getFormChilds(children).find(function (el) {
      return el.props.id === id;
    });
    if (correspondingChild) {
      newMappedValues[id] = correspondingChild.props.mapValue ? correspondingChild.props.mapValue(preFilledValues[id]) : preFilledValues[id];
    } else newMappedValues[id] = preFilledValues[id];
  });
  return newMappedValues;
};

// Check if the values are defined because lodash isEqual with 2 undefined returns true
var compareValues = function compareValues(source, value, keys) {
  var isStrNum = function isStrNum(val) {
    return _isString(val) || _isNumber(val);
  };

  // Compare string with object if they are related to the same thing
  // Example: code as a string and object with code property if the codes are same they are equal
  if (isStrNum(source) && _isPlainObject(value)) return keys.some(function (key) {
    return source === value[key];
  });
  if (_isPlainObject(source) && isStrNum(value)) return keys.some(function (key) {
    return value === source[key];
  });

  // Compare two objects, if they have match
  var areDef = function areDef(key) {
    return !_isNil(source[key]) && !_isNil(value[key]);
  };
  var areSame = function areSame(key) {
    return _isEqual(source[key], value[key]);
  };
  var compare = function compare(key) {
    return areDef(key) && areSame(key);
  };
  if (_isPlainObject(source) && _isPlainObject(value)) return keys.some(compare);
  var hasSort = function hasSort(item) {
    return _isArray(item) ? _toConsumableArray(item).sort() : item;
  };
  return _isEqual(hasSort(source), hasSort(value));
};
export var areEqual = function areEqual(val, id, initValue, children) {
  if (_isNil(initValue) && noValue(val)) return true;
  var correspondChild = getFormChilds(children).find(function (e) {
    return e.props.id === id;
  });
  if (!correspondChild) return null;
  var _correspondChild$prop = correspondChild.props,
    _correspondChild$prop2 = _correspondChild$prop.displayKey,
    displayKey = _correspondChild$prop2 === void 0 ? 'label' : _correspondChild$prop2,
    _correspondChild$prop3 = _correspondChild$prop.uniqueKey,
    uniqueKey = _correspondChild$prop3 === void 0 ? 'value' : _correspondChild$prop3;
  var keys = [displayKey, uniqueKey];
  return compareValues(initValue, val, keys);
};
var baseDependenciesList = ['disabled', 'className', 'readOnly', 'value', 'memoDependencies', 'isTouched'];
export var compareInputProps = function compareInputProps(props) {
  return [hash({
    props: _pick(props, baseDependenciesList)
  })];
};
export var compareDropdownProps = function compareDropdownProps(props) {
  return [hash({
    props: _objectSpread(_objectSpread({}, _pick(props, baseDependenciesList)), {}, {
      options: removeNodes(props.options, props.displayKey, props.uniqueKey)
    })
  })];
};