import { transactionStatus, excludeMasterStatus } from '../../../enums/transactions';
export var optionsStatus = [{
  label: 'Pending',
  value: transactionStatus.Pending
}, {
  label: 'Completed',
  value: transactionStatus.Completed
}, {
  label: 'Rejected',
  value: transactionStatus.Rejected
}, {
  label: 'Blocked',
  value: transactionStatus.Blocked
}];
export var searchReceiverOptions = [{
  label: 'Receiver Username',
  value: 'receiverUserName'
}, {
  label: 'Receiver Nickname',
  value: 'receiverNickName'
}, {
  label: 'Receiver Email',
  value: 'receiverEmail'
}, {
  label: 'Receiver Phone',
  value: 'receiverPhone'
}, {
  label: 'Receiver First Name',
  value: 'receiverFirstName'
}, {
  label: 'Receiver Last Name',
  value: 'receiverLastName'
}];
export var searchSenderOptions = [{
  label: 'Sender Username',
  value: 'senderUserName'
}, {
  label: 'Sender Nickname',
  value: 'senderNickName'
}, {
  label: 'Sender Email',
  value: 'senderEmail'
}, {
  label: 'Sender Phone',
  value: 'senderPhone'
}, {
  label: 'Sender First Name',
  value: 'senderFirstName'
}, {
  label: 'Sender Last Name',
  value: 'senderLastName'
}];
export var optionsWalletType = [{
  label: 'Cash',
  value: 'cash'
}, {
  label: 'Trading',
  value: 'trading'
}, {
  label: 'Coin',
  value: 'coin'
}, {
  label: 'Token',
  value: 'token'
}, {
  label: 'Gas',
  value: 'gas'
}, {
  label: 'Oesp',
  value: 'oesp'
}];
export var masterOptions = [{
  label: 'Master only',
  value: excludeMasterStatus.MasterOnly
}, {
  label: 'Non master',
  value: excludeMasterStatus.NonMaster
}];
export var optionsTransactionsType = [{
  label: 'Debit',
  value: 'outbound'
}, {
  label: 'Credit',
  value: 'inbound'
}];