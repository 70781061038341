import { css } from 'emotion';
var MENU_WIDTH = 240;
var MENU_WIDTH_SMALL = 55;
export var mainContainer = css({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'row'
});
export var sideNavContainer = function sideNavContainer(theme, isOpen) {
  return css({
    backgroundColor: theme.secondaryDark,
    width: isOpen ? MENU_WIDTH : MENU_WIDTH_SMALL,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    color: theme.textDarkSecondary,
    transition: 'all 400ms',
    '@media screen and (max-width: 991px)': {
      paddingTop: 24,
      width: MENU_WIDTH_SMALL,
      '&:hover': {
        width: MENU_WIDTH
      }
    }
  });
};
export var menuListContent = css({
  overflow: 'hidden auto',
  flex: 1,
  paddingBottom: 16
});
export var contentContainer = css({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto'
});
export var content = function content(withPadding) {
  return css({
    flex: 1
  }, withPadding && {
    padding: 40
  });
};
export var menuItem = function menuItem(theme, active) {
  return css({
    fontWeight: 400,
    color: active ? theme.textDarkPrimary : 'inherit',
    backgroundColor: active ? theme.secondaryDark : 'transparent',
    borderLeft: '4px solid transparent',
    display: 'block',
    padding: '12px 0',
    '&:hover': {
      color: theme.white
    },
    '& i': {
      margin: '0 10px',
      color: 'inherit'
    },
    '&.active': {
      color: theme.textDarkPrimary,
      backgroundColor: theme.secondaryDark,
      borderLeftColor: theme.error
    }
  });
};
export var menuItemContent = css({
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'row nowrap'
});
export var menuItemText = css({
  width: MENU_WIDTH - 47,
  position: 'absolute',
  left: 56
});
export var logoContainer = function logoContainer(theme) {
  return css({
    height: 54,
    display: 'flex',
    alignItems: 'center',
    borderBottom: "1px solid ".concat(theme.borderVeryLight),
    padding: '0 10px',
    '@media screen and (max-width: 991px)': {
      display: 'none'
    }
  });
};
export var iconToggler = css({
  '& > svg': {
    width: 26,
    height: 26,
    opacity: 0.6,
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    },
    '@media screen and (max-width: 991px)': {
      display: 'none'
    }
  }
});
export var logo = css({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  '& > img': {
    width: 48
  }
});
export var menuSeparator = function menuSeparator(theme) {
  return css({
    padding: '24px 8px 8px 20px',
    color: theme.white,
    borderBottom: "1px solid ".concat(theme.white),
    '&:first-child': {
      paddingTop: 8
    }
  });
};