import "core-js/modules/es.string.repeat.js";
import { css } from 'emotion';
export var backgroundPicture = function backgroundPicture(_ref) {
  var size = _ref.size,
    src = _ref.src,
    width = _ref.width,
    height = _ref.height,
    position = _ref.position,
    repeat = _ref.repeat,
    className = _ref.className;
  return css({
    backgroundImage: "url(".concat(src, ")"),
    backgroundSize: size || 'cover',
    backgroundPosition: position || 'center',
    backgroundRepeat: repeat || 'no-repeat',
    width: width || '100%',
    height: height || '100%'
  }, className);
};
export var imageContainer = function imageContainer(_ref2) {
  var size = _ref2.size,
    position = _ref2.position,
    className = _ref2.className;
  return css({
    objectFit: size || 'cover',
    objectPosition: position || 'center'
  }, className);
};
export var child = function child(_ref3) {
  var fontSize = _ref3.fontSize,
    color = _ref3.color;
  return css({
    fontWeight: 'bold',
    fontSize: fontSize || 16
  }, color && {
    color: color
  });
};