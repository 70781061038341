import React from 'react';
import { Table, tableTitleHeadline } from '../../../../components/UI';
import LeadershipHistorySearchBar from '../../../../components/Recognition/LeadershipHistorySearchBar';
import { getAllLeadershipHistory } from '../../../../services/bonuses';
import { leadershipRunsColumns } from './config';
import { jsx as ___EmotionJSX } from "@emotion/react";
var LeadershipRunHistory = function LeadershipRunHistory() {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h1", {
    className: tableTitleHeadline
  }, "All Leadership Runs"), ___EmotionJSX(Table, {
    getDataKey: "leadershipRuns",
    getDataMethod: getAllLeadershipHistory,
    columns: leadershipRunsColumns,
    filterBar: LeadershipHistorySearchBar,
    emptyMessage: "There are no Leadership Runs.",
    pageSize: 10
  }));
};
export default LeadershipRunHistory;