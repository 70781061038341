import React from 'react';
import PropTypes from 'prop-types';
import { jsx as ___EmotionJSX } from "@emotion/react";
var FormParagraph = function FormParagraph(props) {
  var children = props.children,
    className = props.className;
  return ___EmotionJSX("div", {
    className: className
  }, children);
};
FormParagraph.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};
FormParagraph.displayName = 'FormParagraph';
export default FormParagraph;