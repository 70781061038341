import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { colorStatus } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ColorBatch = function ColorBatch(props) {
  var children = props.children;
  var theme = useTheme();
  return ___EmotionJSX("span", {
    className: colorStatus(theme, props)
  }, children);
};
ColorBatch.propTypes = {
  children: PropTypes.any
};
export default ColorBatch;