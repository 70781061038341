import { css } from 'emotion';
export var networkTableActionColButton = css({
  cursor: 'pointer'
});
export var networkTableCountryCol = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'flex-start',
  '& img': {
    maxWidth: '24px',
    marginRight: '8px',
    borderRadius: '50%'
  },
  '& svg': {
    marginRight: '8px',
    borderRadius: '50%'
  }
});
export var networkTablePackageCol = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: '8px'
});
export var networkTablePackageColImage = css({
  width: '35px',
  height: 'auto',
  maxHeight: '100%',
  backgroundPosition: 'center right',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  marginRight: '8px'
});
export var networkTableNameCol = function networkTableNameCol(depthLevel) {
  return css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    heiht: '100%'
  }, depthLevel && {
    paddingLeft: "".concat(48 * (depthLevel - 1), "px")
  });
};
export var networkTableNameColIcon = function networkTableNameColIcon(theme, isCollapsed, isExpanded) {
  return css({
    fontSize: '20px',
    width: '24px',
    minWidth: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    marginRight: '8px'
  }, isExpanded && {
    border: "2px solid ".concat(theme.border),
    color: theme.border,
    paddingBottom: '4px'
  }, isCollapsed && {
    border: "2px solid ".concat(theme.success),
    color: theme.success
  });
};
export var networkTableNameColIconHolder = css({
  width: '24px',
  marginRight: '8px'
});
export var networkTableNameColAvatar = css({
  flex: '0 0 32px',
  height: '32px',
  border: '4px solid',
  borderRadius: '32px',
  overflow: 'hidden',
  marginRight: '8px'
});
export var imageResponsive = css({
  maxWidth: '100%',
  height: 'auto'
});
export var networkTableNameColName = function networkTableNameColName(theme) {
  return css({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& p': {
      fontSize: '12px',
      color: theme.gray
    }
  });
};
export var networkTableNameColNameHeadline = css({
  marginRight: '6px'
});