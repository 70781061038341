var defaultLabels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export var quickStatsConfig = function quickStatsConfig(_ref) {
  var color = _ref.color,
    border = _ref.border,
    data = _ref.data,
    labels = _ref.labels;
  return {
    type: 'line',
    data: {
      labels: labels || defaultLabels,
      datasets: [{
        label: '',
        borderColor: color,
        borderWidth: border,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 12,
        pointBackgroundColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
        pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
        pointHoverBorderColor: Chart.helpers.color('#000000').alpha(0.1).rgbString(),
        fill: false,
        data: data
      }]
    },
    options: {
      title: {
        display: false
      },
      tooltips: {
        enabled: false,
        intersect: false,
        mode: 'nearest',
        xPadding: 10,
        yPadding: 10,
        caretPadding: 10
      },
      legend: {
        display: false,
        labels: {
          usePointStyle: false
        }
      },
      responsive: true,
      maintainAspectRatio: true,
      hover: {
        mode: 'ErrorsPage.js'
      },
      scales: {
        xAxes: [{
          display: false,
          gridLines: false,
          scaleLabel: {
            display: true,
            labelString: 'Month'
          }
        }],
        yAxes: [{
          display: false,
          gridLines: false,
          scaleLabel: {
            display: true,
            labelString: 'Value'
          },
          ticks: {
            beginAtZero: true
          }
        }]
      },
      elements: {
        point: {
          radius: 4,
          borderWidth: 12
        }
      },
      layout: {
        padding: {
          left: 0,
          right: 10,
          top: 5,
          bottom: 0
        }
      }
    }
  };
};
export var dataOrderStatisticsChart = function dataOrderStatisticsChart(theme) {
  return {
    labels: ['1 Jan', '2 Jan', '3 Jan', '4 Jan', '5 Jan', '6 Jan', '7 Jan'],
    datasets: [{
      fill: true,
      borderWidth: 0,
      backgroundColor: Chart.helpers.color(theme.info).alpha(0.6).rgbString(),
      borderColor: Chart.helpers.color(theme.info).alpha(0).rgbString(),
      pointHoverRadius: 4,
      pointHoverBorderWidth: 12,
      pointBackgroundColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
      pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
      pointHoverBackgroundColor: theme.info,
      pointHoverBorderColor: Chart.helpers.color('#000000').alpha(0.1).rgbString(),
      data: [20, 40, 50, 25, 35, 60, 30]
    }, {
      fill: true,
      borderWidth: 0,
      backgroundColor: Chart.helpers.color(theme.info).alpha(0.2).rgbString(),
      borderColor: Chart.helpers.color(theme.info).alpha(0).rgbString(),
      pointHoverRadius: 4,
      pointHoverBorderWidth: 12,
      pointBackgroundColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
      pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
      pointHoverBackgroundColor: theme.info,
      pointHoverBorderColor: Chart.helpers.color('#000000').alpha(0.1).rgbString(),
      data: [25, 45, 55, 30, 40, 65, 35]
    }]
  };
};
export var chartOrderStatisticsChart = function chartOrderStatisticsChart(theme) {
  return {
    data: dataOrderStatisticsChart(theme),
    type: 'line',
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: false,
      scales: {
        xAxes: [{
          categoryPercentage: 0.35,
          barPercentage: 0.7,
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Month'
          },
          gridLines: false,
          ticks: {
            display: true,
            beginAtZero: true,
            fontColor: theme.textLightPrimary,
            fontSize: 13,
            padding: 10
          }
        }],
        yAxes: [{
          categoryPercentage: 0.35,
          barPercentage: 0.7,
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Value'
          },
          gridLines: {
            color: theme.border,
            drawBorder: false,
            offsetGridLines: false,
            drawTicks: false,
            borderDash: [3, 4],
            zeroLineWidth: 1,
            zeroLineColor: theme.border,
            zeroLineBorderDash: [3, 4]
          },
          ticks: {
            max: 70,
            stepSize: 10,
            display: true,
            beginAtZero: true,
            fontColor: theme.textLightPrimary,
            fontSize: 13,
            padding: 10
          }
        }]
      },
      title: {
        display: false
      },
      hover: {
        mode: 'ErrorsPage.js'
      },
      tooltips: {
        enabled: true,
        intersect: false,
        mode: 'nearest',
        bodySpacing: 5,
        yPadding: 10,
        xPadding: 10,
        caretPadding: 0,
        displayColors: false,
        backgroundColor: theme.info,
        titleFontColor: '#ffffff',
        cornerRadius: 4,
        footerSpacing: 0,
        titleSpacing: 0
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 5,
          bottom: 5
        }
      }
    }
  };
};
export var dataSalesBarChart = function dataSalesBarChart(theme) {
  return {
    labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5', 'Label 6', 'Label 7', 'Label 8', 'Label 9', 'Label 10', 'Label 11', 'Label 12', 'Label 13', 'Label 14', 'Label 15', 'Label 16'],
    datasets: [{
      backgroundColor: theme.success,
      data: [15, 20, 25, 30, 25, 20, 15, 20, 25, 30, 25, 20, 15, 10, 15, 20]
    }, {
      backgroundColor: theme.infoLight,
      data: [15, 20, 25, 30, 25, 20, 15, 20, 25, 30, 25, 20, 15, 10, 15, 20]
    }]
  };
};
export var configSalesBarChart = function configSalesBarChart(theme) {
  return {
    data: dataSalesBarChart(theme),
    type: 'bar',
    options: {
      title: {
        display: false
      },
      tooltips: {
        intersect: false,
        mode: 'nearest',
        xPadding: 10,
        yPadding: 10,
        caretPadding: 10
      },
      legend: {
        display: false
      },
      responsive: true,
      maintainAspectRatio: false,
      barRadius: 4,
      scales: {
        xAxes: [{
          display: false,
          gridLines: false,
          stacked: true
        }],
        yAxes: [{
          display: false,
          stacked: true,
          gridLines: false
        }]
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      }
    }
  };
};