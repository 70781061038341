import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withTheme } from 'emotion-theming';
import Ripple from '../Ripple';
import { Icon } from '../Icon';
import { menuItem, menuItemText, menuItemContent } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Menu = function Menu(props) {
  var label = props.label,
    icon = props.icon,
    url = props.url,
    theme = props.theme,
    _props$iconType = props.iconType,
    iconType = _props$iconType === void 0 ? {
      material: true
    } : _props$iconType;
  return ___EmotionJSX(NavLink, {
    to: url,
    className: menuItem(theme)
  }, ___EmotionJSX(Ripple, {
    backColor: "secondaryPressed",
    className: menuItemContent
  }, icon && ___EmotionJSX(Icon, _extends({
    size: 27,
    iconName: icon
  }, iconType)), ___EmotionJSX("span", {
    className: menuItemText
  }, label)));
};
Menu.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  theme: PropTypes.object,
  iconType: PropTypes.object
};
export default withTheme(Menu);