import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import _isFunction from "lodash/isFunction";
import _isNil from "lodash/isNil";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import "core-js/modules/es.string.small.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import React, { useRef, useState, useEffect } from 'react';
import { Icon } from '../Icon';
import { useConstant } from '../hooks/useConstant';
import { dropDownOptionItem, dropDownGroupLabel, noResultsContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export default (function (_ref) {
  var _searchRef$current, _searchRef$current$va, _ref2;
  var isOpen = _ref.isOpen,
    initOptions = _ref.options,
    selected = _ref.selected,
    multiSelect = _ref.multiSelect,
    onSelect = _ref.onSelect,
    displayKey = _ref.displayKey,
    containerRef = _ref.containerRef,
    uniqueKey = _ref.uniqueKey,
    small = _ref.small,
    theme = _ref.theme;
  var searchRef = useRef({});
  var _useConstant = useConstant([]),
    _useConstant2 = _slicedToArray(_useConstant, 2),
    searchResults = _useConstant2[0],
    setSearchResults = _useConstant2[1];
  var _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    filteredResults = _useState2[0],
    setFilteredResults = _useState2[1];
  var _useState3 = useState([]),
    _useState4 = _slicedToArray(_useState3, 2),
    scrollItems = _useState4[0],
    setScrollItems = _useState4[1];
  var hasSearch = (_searchRef$current = searchRef.current) === null || _searchRef$current === void 0 ? void 0 : (_searchRef$current$va = _searchRef$current.value) === null || _searchRef$current$va === void 0 ? void 0 : _searchRef$current$va.length;
  var scrollSize = 50;
  var needInfiniteScroll = (hasSearch ? searchResults === null || searchResults === void 0 ? void 0 : searchResults.length : initOptions.length) > scrollSize;
  useEffect(function () {
    var _searchRef$current2;
    isOpen && ((_searchRef$current2 = searchRef.current) === null || _searchRef$current2 === void 0 ? void 0 : _searchRef$current2.focus) && searchRef.current.focus();
  }, [isOpen]);
  useEffect(function () {
    needInfiniteScroll && setScrollItems(initOptions.slice(0, scrollSize));
  }, [initOptions.length]);
  var getOptionCode = function getOptionCode(val) {
    var _val$uniqueKey;
    return _isNil(val) ? null : (_val$uniqueKey = val[uniqueKey]) !== null && _val$uniqueKey !== void 0 ? _val$uniqueKey : val[displayKey];
  };
  var selectedCodes = multiSelect ? selected.map(getOptionCode) : getOptionCode(selected);
  var compareValues = function compareValues(value, input) {
    return String(value).toLowerCase().trim().indexOf(input.toLowerCase().trim()) !== -1;
  };
  var filterValues = function filterValues(input) {
    return function (el) {
      var _el$groupLabel;
      return (_el$groupLabel = el.groupLabel) !== null && _el$groupLabel !== void 0 ? _el$groupLabel : compareValues(el[uniqueKey], input) || compareValues(el[displayKey], input);
    };
  };
  var filterResults = function filterResults(input) {
    // eslint-disable-next-line no-param-reassign
    containerRef.current.scrollTop = 0;
    var results = initOptions.filter(filterValues(input)).filter(function (el, i, arr) {
      var _arr2;
      return !(el !== null && el !== void 0 && el.groupLabel) || arr[i + 1] && !((_arr2 = arr[i + 1]) !== null && _arr2 !== void 0 && _arr2.groupLabel);
    });
    setSearchResults(results);
    return results.length ? setFilteredResults(results.slice(0, scrollSize)) : setFilteredResults(null);
  };
  var fetchMoreResults = function fetchMoreResults() {
    return !hasSearch ? setScrollItems(function (prev) {
      return prev.concat(initOptions.slice(prev.length, prev.length + scrollSize));
    }) : setFilteredResults(function (prev) {
      return prev.concat(searchResults.slice(prev.length, prev.length + scrollSize));
    });
  };
  var renderBaseOptionElement = function renderBaseOptionElement(option, i) {
    var optionCode = getOptionCode(option);
    var hasRender = _isFunction(option.render);
    var optionRender = hasRender ? option.render(option) : option[displayKey];
    var isSelected = multiSelect ? selectedCodes.indexOf(optionCode) !== -1 : optionCode === selectedCodes;
    return option.groupLabel ? ___EmotionJSX("h2", {
      key: "dropDownItem".concat(i),
      className: dropDownGroupLabel(theme)
    }, option.groupLabel) : ___EmotionJSX("div", {
      key: "dropDownItem".concat(i),
      role: "menuitem",
      tabIndex: 0,
      onClick: function onClick() {
        return onSelect(option);
      },
      className: dropDownOptionItem(isSelected, hasRender, small, theme)
    }, multiSelect && ___EmotionJSX(Icon, {
      material: true,
      iconName: isSelected ? 'check_box' : 'check_box_outline_blank',
      color: "secondary"
    }), optionRender);
  };
  var noResults = ___EmotionJSX("div", {
    className: noResultsContainer
  }, ___EmotionJSX("h5", null, "No results found"));
  var allOptions = hasSearch ? filteredResults : needInfiniteScroll ? scrollItems : initOptions;
  var renderList = allOptions ? allOptions.map(renderBaseOptionElement) : noResults;
  return {
    allOptionsLength: (_ref2 = hasSearch ? searchResults : initOptions) === null || _ref2 === void 0 ? void 0 : _ref2.length,
    optionsLength: allOptions === null || allOptions === void 0 ? void 0 : allOptions.length,
    filterResults: filterResults,
    searchRef: searchRef,
    renderList: renderList,
    fetchMoreResults: fetchMoreResults,
    needInfiniteScroll: needInfiniteScroll
  };
});