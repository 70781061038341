import React from 'react';
import { mainContainer, content } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NotFound = function NotFound() {
  return ___EmotionJSX("div", {
    className: mainContainer
  }, ___EmotionJSX("div", {
    className: content
  }, ___EmotionJSX("h1", null, "404"), ___EmotionJSX("p", null, "OOPS! Something went wrong here")));
};
export default NotFound;