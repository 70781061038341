import _sortBy from "lodash/sortBy";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.function.name.js";
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { useTheme } from 'emotion-theming';
import { Row, Col, Dropdown } from '../../UI';
import SearchInput from '../../Shared/SearchInput';
import { containerSearch, inputSearch } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var filterOptions = [{
  label: 'Sort by: Value',
  value: 'price'
}, {
  label: 'Sort by: Date',
  value: 'createdOn'
}, {
  label: 'Sort by: Name',
  value: 'name'
}];
var FilterBar = function FilterBar(props) {
  var packages = props.packages,
    setPackages = props.setPackages;
  var theme = useTheme();
  var filterValuesRef = useRef({
    sort: filterOptions[0].value
  });
  useEffect(function () {
    if (!(packages !== null && packages !== void 0 && packages.length)) return;
    var _filterValuesRef$curr = filterValuesRef.current,
      sort = _filterValuesRef$curr.sort,
      filter = _filterValuesRef$curr.filter;
    sort && handleSortChange({
      value: sort
    });
    filter && handleFilterChange({
      target: {
        value: filter
      }
    });
  }, [hash({
    packages: packages
  })]);
  var handleSortChange = function handleSortChange(_ref) {
    var value = _ref.value;
    filterValuesRef.current.sort = value;
    setPackages(_sortBy(packages, value));
  };
  var handleFilterChange = function handleFilterChange(value) {
    filterValuesRef.current.filter = value;
    setPackages(packages.filter(function (el) {
      return el.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    }));
  };
  return ___EmotionJSX(Row, {
    className: containerSearch,
    horizontalGap: 16,
    verticalGap: 8,
    justify: "space-between"
  }, ___EmotionJSX(Col, {
    xl: 9,
    lg: 8,
    sm: 8,
    xs: 12
  }, ___EmotionJSX(SearchInput, {
    placeholder: "Search Packages",
    onChange: handleFilterChange,
    className: inputSearch(theme)
  })), ___EmotionJSX(Col, {
    xl: 3,
    lg: 4,
    sm: 4,
    xs: 12
  }, ___EmotionJSX(Dropdown, {
    noClear: true,
    options: filterOptions,
    onChange: handleSortChange,
    value: filterOptions[0]
  })));
};
FilterBar.propTypes = {
  packages: PropTypes.array,
  setPackages: PropTypes.func
};
export default FilterBar;