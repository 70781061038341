import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _mapType;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import ColorBatch from '../../../components/Shared/ColorBatch';
import { DetailsLink } from '../../../components/Shared/TableComponents';
import { type } from '../../../enums/wallets';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var mapType = (_mapType = {}, _defineProperty(_mapType, type.ONE, {
  text: 'Coin Safe',
  path: 'coin-safe',
  currency: type.ONE
}), _defineProperty(_mapType, type.CASH, {
  text: 'Cash Safe',
  path: 'cash-safe',
  currency: type.CASH
}), _mapType);
export var generalInfo = [{
  label: 'Name',
  value: 'name'
}, {
  label: 'ID',
  value: 'id'
}, {
  label: 'Compound Period',
  value: 'compoundPeriod'
}, {
  label: 'Duration',
  value: 'duration'
}];
export var planColumns = function planColumns(type) {
  return [{
    name: 'Name',
    value: 'name'
  }, {
    name: 'Duration',
    value: 'duration',
    width: 100
  }, {
    name: 'Compound Period',
    value: 'compoundPeriod',
    width: 150
  }, {
    name: 'Interest Rate',
    render: function render(row) {
      return "".concat(row.interestRate, " %");
    },
    width: 120
  }, {
    name: 'Status',
    value: 'active',
    render: function render(row) {
      return ___EmotionJSX(ColorBatch, {
        type: row.active ? 'success' : 'error'
      }, row.active ? 'Active' : 'Inactive');
    },
    sortable: true,
    width: 100
  }, {
    name: 'Visibility',
    value: 'visible',
    render: function render(row) {
      return ___EmotionJSX(ColorBatch, {
        type: row.visible ? 'success' : 'error'
      }, row.visible ? 'Visible' : 'Invisible');
    },
    sortable: true,
    width: 100
  }, {
    name: 'View details',
    render: function render(row) {
      return ___EmotionJSX(DetailsLink, {
        row: _objectSpread(_objectSpread({}, row), {}, {
          type: type
        }),
        url: "/plans/details/".concat(row.id)
      });
    },
    width: 150
  }];
};
export var pagesDetails = function pagesDetails(type) {
  return [{
    url: "/plans/".concat(type, "-safe"),
    label: "".concat(mapType[type].text)
  }, {
    label: 'Detail Page'
  }];
};
export var pagesAdd = function pagesAdd(type) {
  return [{
    url: "/plans/".concat(type, "-safe"),
    label: "".concat(mapType[type].text)
  }, {
    label: 'Add Plan'
  }];
};