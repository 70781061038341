/* eslint-disable react/prop-types */
import React from 'react';
import ColorBatch from '../../../../../components/Shared/ColorBatch';
import { jsonParse } from '../../../../../utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var withdrawalMethodsHistoryColumns = [{
  name: 'Date of create',
  value: 'createdOn',
  dateTimeFormat: 'DD.MM.YYYY, HH:mm:ss',
  width: 110
}, {
  name: 'Method Type',
  value: 'type',
  width: 110
}, {
  name: 'Previous Address',
  render: function render(_ref, NullItem) {
    var _jsonParse$addressNum, _jsonParse;
    var previousWithdrawalMethodValue = _ref.previousWithdrawalMethodValue;
    return (_jsonParse$addressNum = (_jsonParse = jsonParse(previousWithdrawalMethodValue)) === null || _jsonParse === void 0 ? void 0 : _jsonParse.addressNumber) !== null && _jsonParse$addressNum !== void 0 ? _jsonParse$addressNum : ___EmotionJSX(NullItem, null);
  }
}, {
  name: 'Current Address',
  render: function render(_ref2, NullItem) {
    var _jsonParse$addressNum2, _jsonParse2;
    var value = _ref2.value;
    return (_jsonParse$addressNum2 = (_jsonParse2 = jsonParse(value)) === null || _jsonParse2 === void 0 ? void 0 : _jsonParse2.addressNumber) !== null && _jsonParse$addressNum2 !== void 0 ? _jsonParse$addressNum2 : ___EmotionJSX(NullItem, null);
  }
}, {
  name: 'Active',
  render: function render(row) {
    return ___EmotionJSX(ColorBatch, {
      type: row.active ? 'success' : 'error'
    }, row.active ? 'Yes' : 'No');
  },
  width: 70
}];
export var profileWithdrawalsBreadcrumbs = [{
  url: '/profiles',
  label: 'Profiles'
}, {
  label: 'Withdrawals'
}];
export var profileMobileBreadcrumbs = [{
  url: '/profiles',
  label: 'Profiles'
}, {
  label: 'Mobile'
}];