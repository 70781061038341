export var kycType = {
  idProof: 'IdProof',
  addressProof: 'AddressProof'
};
export var kycStatus = {
  pending: 0,
  rejected: 1,
  approved: 2
};
export var kycVipStatus = {
  regular: 'Regular',
  unpaid: 'Unpaid',
  paid: 'Paid'
};