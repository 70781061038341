import React from 'react';
import { Table, tableTitleHeadline } from '../../../../components/UI';
import TableSearchBarProfiles from '../../../../components/Profiles/TableSearchBarProfiles';
import { getAllProfiles } from '../../../../services/profiles';
import { profileColumns } from '../config';
import { noTitleContent } from '../styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ProfilesList = function ProfilesList() {
  return ___EmotionJSX("section", {
    className: noTitleContent
  }, ___EmotionJSX("h1", {
    className: tableTitleHeadline
  }, "Profile List"), ___EmotionJSX(Table, {
    getDataKey: "profiles",
    getDataMethod: getAllProfiles,
    columns: profileColumns,
    filterBar: TableSearchBarProfiles,
    pageSize: 10
  }));
};
export default ProfilesList;