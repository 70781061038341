import { css } from 'emotion';
export var container = css({
  padding: 0
});
export var header = css({
  padding: '0.5rem 0 1.1rem 0',
  marginBottom: '0.5rem'
});
export var titleContainer = function titleContainer(theme) {
  return css({
    fontSize: '1.3rem',
    fontWeight: 500,
    marginBottom: '0',
    color: theme.gray
  });
};
export var description = function description(theme) {
  return css({
    display: 'inline-block',
    marginTop: '0.2rem',
    color: theme.grayLight
  });
};
export var content = css({
  display: 'flex',
  alignItems: 'center'
});
export var legends = css({
  paddingLeft: '2rem',
  flexGrow: 1
});
export var legend = css({
  display: 'flex',
  alignItems: 'center',
  '&:not(:first-child):not(:last-child)': {
    padding: '0.5rem 0'
  }
});
export var bullet = css({
  width: '1.5rem',
  height: '0.45rem',
  borderRadius: '1.1rem'
});
export var stats = function stats(theme) {
  return css({
    color: theme.gray,
    fontWeight: 500,
    flex: '1',
    paddingLeft: '1rem'
  });
};
export var chart = css({
  position: 'relative',
  '& > canvas': {
    position: 'relative',
    zIndex: '1'
  }
});
export var stat = function stat(theme) {
  return css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: '0',
    top: '0',
    fontSize: '2.2rem',
    fontWeight: '500',
    color: theme.grayDark,
    opacity: '0.7'
  });
};