import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import _sortBy from "lodash/sortBy";
import _isNil from "lodash/isNil";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.is-array.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { Image, Button, Skeleton } from '../../UI';
import { copyToClipboard } from '../../../utils';
import { type } from '../../../enums/wallets';
import { currencyByType } from '../../../utils/displayData';
import { container, pictureContainer, packageInfo, infoContainer, marginBottom4, buttonPackagesInfo, infoButtonContainer, detailsContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var OrderPackage = function OrderPackage(props) {
  var packagesProp = props.packages,
    className = props.className;
  var packages = _sortBy(packagesProp, 'price');
  var _useState = useState(packages !== null && packages !== void 0 && packages.length ? packages.slice(0, 10) : null),
    _useState2 = _slicedToArray(_useState, 2),
    packagesList = _useState2[0],
    setPackagesList = _useState2[1];
  useEffect(function () {
    setPackagesList(packages !== null && packages !== void 0 && packages.length ? packages.slice(0, 10) : []);
  }, [hash({
    packages: packages
  })]);
  var handleShowMore = function handleShowMore() {
    return setPackagesList(function (prev) {
      return prev.concat(packages.slice(prev.length, prev.length + 10));
    });
  };
  return _isNil(packagesList) ? Array(4).fill().map(function (el, ind, arr) {
    return ___EmotionJSX("div", {
      key: ind
    }, ___EmotionJSX("article", {
      className: container(className)
    }, ___EmotionJSX(Skeleton, {
      width: 70,
      height: 70,
      circle: true,
      className: pictureContainer
    }), ___EmotionJSX("section", null, ___EmotionJSX(Skeleton, {
      width: 150,
      height: 30,
      className: marginBottom4
    }), ___EmotionJSX(Skeleton, {
      width: 220,
      height: 20,
      className: marginBottom4
    }), ___EmotionJSX(Skeleton, {
      width: 250,
      height: 20,
      className: marginBottom4
    }), ___EmotionJSX(Skeleton, {
      width: 270,
      height: 20
    }))), arr.length - 1 !== ind && ___EmotionJSX("hr", null));
  }) : ___EmotionJSX(React.Fragment, null, packagesList === null || packagesList === void 0 ? void 0 : packagesList.map(function (item, ind, arr) {
    return ___EmotionJSX("div", {
      key: ind
    }, ___EmotionJSX("article", {
      className: container(className)
    }, ___EmotionJSX(Image, {
      size: "contain",
      src: item.picThumbnailUrl,
      width: 70,
      height: 70,
      className: pictureContainer
    }), ___EmotionJSX("section", {
      className: detailsContainer
    }, ___EmotionJSX("div", {
      className: infoButtonContainer
    }, ___EmotionJSX("h2", {
      className: packageInfo
    }, item.name)), ___EmotionJSX("div", {
      className: infoContainer
    }, ___EmotionJSX("p", null, "Price:"), ___EmotionJSX("p", null, "".concat(currencyByType(item.withActivationFee ? item.price + 30 : item.price, type.CASH)))), ___EmotionJSX("div", {
      className: infoContainer
    }, ___EmotionJSX("p", null, "Gift code:"), " ", ___EmotionJSX("p", null, item.giftCode))), ___EmotionJSX(Button, {
      className: buttonPackagesInfo,
      onClick: function onClick() {
        return copyToClipboard(item.giftCode, 'Successfully copied gift code.');
      }
    }, "Copy code")), arr.length - 1 !== ind && ___EmotionJSX("hr", null));
  }), packages.length > 10 && (packagesList === null || packagesList === void 0 ? void 0 : packagesList.length) < packages.length && ___EmotionJSX(Button, {
    onClick: handleShowMore
  }, "Show More"));
};
OrderPackage.propTypes = {
  packages: PropTypes.array,
  className: PropTypes.string
};
export default OrderPackage;