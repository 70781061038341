import { css } from 'emotion';
export var directStartupBonusRoundInfoContainer = css({
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '0px'
});
export var directStartupBonusRoundInfoRow = function directStartupBonusRoundInfoRow(theme) {
  return css({
    padding: '12px',
    borderRight: "1px solid ".concat(theme.borderLight),
    textAlign: 'center',
    backgroundColor: theme.white,
    '&:last-child': {
      borderRight: 'none'
    }
  });
};
export var directStartupBonusRoundInfoLabel = function directStartupBonusRoundInfoLabel(theme) {
  return css({
    fontSize: '12px',
    fontWeight: '600',
    color: theme.grayLight
  });
};
export var directStartupBonusRoundInfoValue = css({
  fontWeight: '600'
});