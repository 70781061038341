import { css } from 'emotion';
import image from '../../../assets/images/error-bg.jpg';
export var mainContainer = css({
  margin: -48,
  background: "url(\"".concat(image, "\") no-repeat fixed center"),
  flex: 1
});
export var content = css({
  position: 'absolute',
  top: 100,
  left: 320,
  '& > h1': {
    fontWeight: 700,
    fontSize: 150
  },
  '& > p': {
    fontSize: 20
  }
});