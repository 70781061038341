export var type = {
  ONE: 'Coin',
  COINLEGACY: 'CoinLegacy',
  OESP: 'OESP',
  COINSAFE: 'CoinSafe',
  CASHSAFE: 'CashSafe',
  CASH: 'Cash',
  TRADING: 'Trading',
  TOKEN: 'Token',
  GAS: 'Gas',
  RESERVECASH: 'ReserveCash',
  BV: 'BusinessVolume'
};
export var currencyType = {
  EURO: '€',
  EURO_LETTERS: 'EUR',
  TKN: 'TKN',
  ONE: 'ONE',
  OESP: 'OESP',
  BVP: 'BVP'
};
export var withdrawalMethodType = {
  BTC: 'btc',
  USDT: 'usdt'
};
export var bulkSendAmountType = {
  TRANSFERBYACCOUNTIDS: 'transferByAccountIds',
  TRANSFERBYWALLETSIDS: 'transferByWalletIds',
  ADDSUBTRACTBYACCOUNTIDS: 'addSubtractByAccountIds',
  TRANSFERBYACCOUNTNICKNAMES: 'transferByAccountNicknames',
  ADDSUBTRACTBYACCOUNTNICKNAMES: 'addSubtractByAccountNicknames'
};