import _isArray from "lodash/isArray";
import "core-js/modules/es.array.join.js";
import { showInfo } from '../components/UI/Notifications/Store';
export var copyToClipboard = function copyToClipboard(text, message) {
  var textArea = document.createElement('textarea');
  textArea.style.opacity = '0';
  textArea.value = _isArray(text) ? text.join('\n') : text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
  showInfo(message);
};