import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isNil from "lodash/isNil";
var _leadershipRun, _mapLeadershipStatus;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/* eslint-disable react/prop-types */
import React from 'react';
import { DetailsLink } from '../../../../components/Shared/TableComponents';
import ColorBatch from '../../../../components/Shared/ColorBatch';
import NetworkLeadershipDetails from '../../../../components/Recognition/NetworkLeadershipDetails';
import { leadershipRunType, leadershipRunStatus } from '../../../../enums/bonuses';
import { accountRankId } from '../../../../enums/account';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var leadershipRun = (_leadershipRun = {}, _defineProperty(_leadershipRun, leadershipRunType.Preliminary, {
  text: 'Preliminary',
  color: 'warning'
}), _defineProperty(_leadershipRun, leadershipRunType.Regular, {
  text: 'Regular',
  color: 'info'
}), _leadershipRun);
export var mapLeadershipStatus = (_mapLeadershipStatus = {}, _defineProperty(_mapLeadershipStatus, leadershipRunStatus.Pending, {
  text: 'Pending',
  color: 'info'
}), _defineProperty(_mapLeadershipStatus, leadershipRunStatus.Succeeded, {
  text: 'Succeeded',
  color: 'success'
}), _defineProperty(_mapLeadershipStatus, leadershipRunStatus.RanksSaved, {
  text: 'Ranks Saved',
  color: 'error'
}), _mapLeadershipStatus);
export var leadershipSuccess = {
  "true": {
    text: 'Yes',
    color: 'success'
  },
  "false": {
    text: 'No',
    color: 'error'
  }
};
export var leadershipRunsColumns = [{
  name: 'Date From',
  value: 'targetDateFrom',
  dateTimeFormat: 'DD/MM/YYYY'
}, {
  name: 'Date To',
  value: 'targetDateTo',
  dateTimeFormat: 'DD/MM/YYYY'
}, {
  name: 'Run ID',
  value: 'id'
}, {
  name: 'Run Type ',
  render: function render(row) {
    var _leadershipRun$row$ty, _leadershipRun$row$ty2;
    return ___EmotionJSX(ColorBatch, {
      type: (_leadershipRun$row$ty = leadershipRun[row.type]) === null || _leadershipRun$row$ty === void 0 ? void 0 : _leadershipRun$row$ty.color
    }, (_leadershipRun$row$ty2 = leadershipRun[row.type]) === null || _leadershipRun$row$ty2 === void 0 ? void 0 : _leadershipRun$row$ty2.text);
  }
}, {
  name: 'Success',
  value: 'success',
  render: function render(row) {
    var _leadershipSuccess$ro, _leadershipSuccess$ro2;
    return ___EmotionJSX(ColorBatch, {
      type: (_leadershipSuccess$ro = leadershipSuccess[row.success]) === null || _leadershipSuccess$ro === void 0 ? void 0 : _leadershipSuccess$ro.color
    }, (_leadershipSuccess$ro2 = leadershipSuccess[row.success]) === null || _leadershipSuccess$ro2 === void 0 ? void 0 : _leadershipSuccess$ro2.text);
  }
}, {
  name: 'Status ',
  render: function render(_ref, NullItem) {
    var _mapLeadershipStatus$, _mapLeadershipStatus$2;
    var status = _ref.status;
    return !_isNil(status) ? ___EmotionJSX(ColorBatch, {
      type: (_mapLeadershipStatus$ = mapLeadershipStatus[status]) === null || _mapLeadershipStatus$ === void 0 ? void 0 : _mapLeadershipStatus$.color
    }, (_mapLeadershipStatus$2 = mapLeadershipStatus[status]) === null || _mapLeadershipStatus$2 === void 0 ? void 0 : _mapLeadershipStatus$2.text) : ___EmotionJSX(NullItem, null);
  }
}, {
  name: 'View details ',
  render: function render(row) {
    return ___EmotionJSX(DetailsLink, {
      row: row,
      url: "/recognition/leadership-run-history/".concat(row.id)
    });
  }
}];
export var leadershipRunHistoryAccountsBreadCrumb = [{
  url: '/recognition/leadership',
  label: 'Leadership'
}, {
  label: 'Leadership Run Details'
}];
export var leadershipRunHistoryColumns = [{
  name: 'Account Id',
  value: 'accountId'
}, {
  name: 'NickName',
  value: 'nickname'
}, {
  name: 'Eligible Direct Downlinks Left',
  value: 'eligibleDirectDownlinksCountLeft'
}, {
  name: 'Eligible Direct Downlinks Right',
  value: 'eligibleDirectDownlinksCountRight'
}, {
  name: 'Left BV',
  value: 'bvLeftLegForPeriod'
}, {
  name: 'Right BV',
  value: 'bvRightLegForPeriod'
}, {
  name: 'Previous Rank',
  render: function render(_ref2, NullItem) {
    var previousRankId = _ref2.previousRankId;
    return _isNil(previousRankId) ? ___EmotionJSX(NullItem, null) : accountRankId[previousRankId];
  }
}, {
  name: 'Current Rank',
  render: function render(_ref3, NullItem) {
    var leadershipRankId = _ref3.leadershipRankId;
    return _isNil(leadershipRankId) ? ___EmotionJSX(NullItem, null) : accountRankId[leadershipRankId];
  }
}, {
  name: 'Run Type ',
  render: function render(row) {
    var _leadershipRun$row$ru, _leadershipRun$row$ru2;
    return ___EmotionJSX(ColorBatch, {
      type: (_leadershipRun$row$ru = leadershipRun[row.runType]) === null || _leadershipRun$row$ru === void 0 ? void 0 : _leadershipRun$row$ru.color
    }, (_leadershipRun$row$ru2 = leadershipRun[row.runType]) === null || _leadershipRun$row$ru2 === void 0 ? void 0 : _leadershipRun$row$ru2.text);
  }
}, {
  name: 'Network Leadership Rank Details',
  render: function render(row) {
    return ___EmotionJSX(NetworkLeadershipDetails, {
      rowData: row === null || row === void 0 ? void 0 : row.networkLeadershipRankDetails
    });
  }
}];