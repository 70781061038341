import React from 'react';
import { Breadcrumbs } from '../../../components/UI';
import BackButton from '../../../components/Shared/BackButton';
import TaskDetails from '../../../components/Shared/TaskDetails';
import KYCDetailsPage from '../Kyc/DetailsPage';
import { detailPageBreadcrumb } from './config';
import { headContainer, backContainer, tabContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TaskDetailsPage = function TaskDetailsPage(props) {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: headContainer
  }, ___EmotionJSX(Breadcrumbs, {
    items: detailPageBreadcrumb
  }), ___EmotionJSX(BackButton, {
    title: "Task Information",
    className: backContainer
  }), ___EmotionJSX(TaskDetails, null)), ___EmotionJSX("div", {
    className: tabContainer
  }, ___EmotionJSX(KYCDetailsPage, props)));
};
export default TaskDetailsPage;