import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import _isFunction from "lodash/isFunction";
import _isString from "lodash/isString";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import uuid from '../../../utils/uuid';
import { parseError } from '../../../utils/parseRequestError';
export var Types = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning'
};
var prepare = function prepare(notif) {
  return _isString(notif) ? {
    message: notif,
    id: uuid()
  } : _objectSpread(_objectSpread({}, notif), {}, {
    id: uuid()
  });
};
var store = {
  show: function show() {},
  hide: function hide() {}
};
export var showSuccess = function showSuccess(notif) {
  return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
    type: Types.SUCCESS
  }));
};
export var showError = function showError(notif) {
  return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
    type: Types.ERROR
  }));
};
export var showApiError = function showApiError(error) {
  return parseError(error).map(function (notif) {
    return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
      type: Types.ERROR
    }));
  });
};
export var showIdentityError = function showIdentityError(error) {
  return parseError(error).map(function (notif) {
    return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
      type: Types.ERROR,
      duration: 10000
    }));
  });
};
export var showInfo = function showInfo(notif) {
  return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
    type: Types.INFO
  }));
};
export var showWarning = function showWarning(notif) {
  return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
    type: Types.WARNING
  }));
};
export var hide = function hide(notif) {
  return store.hide(notif === null || notif === void 0 ? void 0 : notif.id);
};
export var notificationsHandler = function notificationsHandler(_ref) {
  var addNotification = _ref.addNotification,
    removeNotification = _ref.removeNotification;
  if (_isFunction(addNotification)) store.show = addNotification;
  if (_isFunction(removeNotification)) store.hide = removeNotification;
};