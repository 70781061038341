import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import React from 'react';
import { useTheme } from 'emotion-theming';
import { Col, Skeleton } from '../../UI';
import { shopPackageCardTop, pictureContainer, loaderTitle } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Loading = function Loading() {
  var theme = useTheme();
  return Array(10).fill().map(function (el, i) {
    return ___EmotionJSX(Col, {
      xl: 12 / 5,
      lg: 3,
      sm: 6,
      xs: 12,
      key: i
    }, ___EmotionJSX("div", {
      className: shopPackageCardTop(theme)
    }, ___EmotionJSX(Skeleton, {
      width: 60,
      height: 60,
      circle: true,
      className: pictureContainer
    }), ___EmotionJSX("div", null, ___EmotionJSX(Skeleton, {
      width: 80,
      height: 18,
      className: loaderTitle
    }), ___EmotionJSX(Skeleton, {
      width: 50,
      height: 18
    }))));
  });
};
export default Loading;