import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { css, cx } from 'emotion';
export var baseIcon = function baseIcon(type, _ref, theme) {
  var color = _ref.color,
    onClick = _ref.onClick,
    size = _ref.size,
    filter = _ref.filter,
    className = _ref.className;
  return cx(type, css({
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontVariant: 'normal',
    lineHeight: '1',
    textDecoration: 'inherit',
    textRendering: 'optimizeLegibility',
    textTransform: 'none',
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
    fontSmoothing: 'antialiased',
    fontSize: size || 24,
    color: theme[color] || color,
    fontFeatureSettings: "'liga'",
    userSelect: 'none',
    transition: 'color 0.3s ease-in-out'
  }, onClick && {
    cursor: 'pointer'
  }, filter && {
    filter: filter
  }, className));
};
var materialIcon = function materialIcon(_ref2) {
  var iconName = _ref2.iconName,
    outlined = _ref2.outlined,
    twoTone = _ref2.twoTone,
    round = _ref2.round,
    sharp = _ref2.sharp;
  return css({
    fontFamily: "'Material Icons'",
    fontStyle: 'normal',
    '&:before': {
      content: "\"".concat(iconName, "\"")
    }
  }, outlined && {
    fontFamily: "'Material Icons Outlined'"
  }, twoTone && {
    fontFamily: "'Material Icons Two Tone'"
  }, round && {
    fontFamily: "'Material Icons Round'"
  }, sharp && {
    fontFamily: "'Material Icons Sharp'"
  });
};
export var getType = function getType(options) {
  return options.material ? materialIcon(options) : options.iconName;
};