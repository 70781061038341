import { css } from 'emotion';
export var tabContainer = css({
  padding: '48px 40px'
});
export var orderContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 48,
  '& > h1': {
    marginBottom: 0,
    fontWeight: 600
  }
});
export var backContainer = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 32
});
export var paperContainer = css({
  '& > div > *:last-child': {
    marginBottom: 0
  }
});
export var comment = css({
  paddingLeft: 6,
  marginBottom: 12,
  marginTop: 32
});
export var scrollContainer = css({
  marginBottom: 32,
  '& > div': {
    maxHeight: 'calc(100vh - 240px)',
    overflowY: 'auto',
    minHeight: '100%'
  },
  '&:last-child': {
    marginBottom: 0
  }
});
export var userInfoContainer = css({
  marginBottom: 32,
  paddingLeft: 12
});
export var backButton = css({
  marginBottom: 48
});
export var shoppingCartItems = css({
  borderRadius: 3,
  marginBottom: 32
});
export var shoppingCartFooterTotal = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: 12
});
export var shoppingCartTotalLabel = function shoppingCartTotalLabel(theme) {
  return css({
    color: theme.gray,
    marginRight: 12,
    fontWeight: 600,
    fontSize: 14
  });
};
export var shoppingCartTotalAmount = function shoppingCartTotalAmount(theme) {
  return css({
    color: theme.grayDark
  });
};
export var submitButton = css({
  flexBasis: 'auto',
  flexGrow: 0,
  display: 'flex'
});
export var shoppingCartContainer = css({
  maxWidth: 850
});
export var totalSubmitContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'row'
});
export var openAllButton = css({
  marginRight: 12
});
export var navLinkViewCart = function navLinkViewCart(theme) {
  return css({
    borderRadius: 4,
    padding: '10px 20px',
    color: theme.white,
    textAlign: 'center',
    backgroundColor: theme.primaryLight,
    fontSize: 14,
    lineHeight: 1,
    maxWidth: 150,
    display: 'block',
    letterSpacing: '0.5px',
    '&:hover': {
      backgroundColor: theme.primary
    },
    '&:focus, &:active': {
      backgroundColor: theme.primaryLight,
      color: theme.textPrimaryFocused
    }
  });
};