import { css } from 'emotion';
export var paperContainer = css({
  position: 'sticky',
  top: 53,
  zIndex: 100,
  marginBottom: 16,
  '& > div > *:last-child': {
    marginBottom: 0
  }
});
export var overviewColKyc = css({
  wordBreak: 'break-all'
});