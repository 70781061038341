import { css } from 'emotion';
export var bonusLastRoundContainer = css({
  marginBottom: '40px'
});
export var bonusLastRoundBox = function bonusLastRoundBox(theme) {
  return css({
    backgroundColor: theme.white,
    border: "1px solid ".concat(theme.whiteGray),
    borderRadius: '3px',
    boxShadow: '0 6px 8px 0 rgba(224, 225, 229, 0.58)'
  });
};
export var bonusLastRoundBoxHeader = function bonusLastRoundBoxHeader(theme) {
  return css({
    padding: '32px 24px',
    borderBottom: "1px solid ".concat(theme.whiteGray),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }, {
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  });
};
export var bonusLastRoundBoxHeaderDate = css({
  letterSpacing: '0.38px',
  fontSize: '14px',
  opacity: '0.7'
});
export var bonusLastRoundBoxHeaderAmount = css({
  display: 'flex',
  alignItems: 'flex-end',
  fontWeight: '500'
});
export var sectionHeadingLight = css({
  marginRight: '16px',
  marginBottom: '10px',
  fontWeight: '300',
  paddingTop: '10px'
});
export var sectionHeadingSecondary = css({
  marginBottom: '16px'
});
export var bonusLastRoundBoxBody = css({
  padding: '32px 40px'
}, {
  '@media (max-width: 575px)': {
    padding: '32px 8px'
  }
});