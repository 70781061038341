import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.freeze.js";
import "core-js/modules/es.object.define-properties.js";
var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import "core-js/modules/es.array.concat.js";
import { css, cx, keyframes } from 'emotion';

// Form styles start
export var formContainer = css({
  width: '100%'
});
export var formFieldsContainer = css({
  marginBottom: 0
});
// Form styles end

// Input styles start (base one)
export var inputContainer = function inputContainer(isHorizontal, className) {
  return css({
    display: 'flex',
    flexDirection: isHorizontal ? 'row' : 'column',
    alignItems: 'flex-start',
    justifyContent: isHorizontal ? 'space-between' : 'flex-start',
    position: 'relative',
    width: '100%',
    height: isHorizontal ? '48px' : '115px',
    outline: 'none'
  }, className);
};
export var horizontalContainer = css({
  flex: 1,
  position: 'relative'
});
export var inputLabel = function inputLabel(isHorizontal, theme) {
  return css({
    fontWeight: 600,
    opacity: 1,
    color: theme.textLightSecondary,
    margin: "\n      ".concat(isHorizontal ? '12px' : 0, "\n      ").concat(isHorizontal ? '24px' : 0, "\n      ").concat(isHorizontal ? 0 : '8px', "\n      0")
  });
};
export var inputField = function inputField(hasError, hasText, theme) {
  return css({
    fontWeight: 400,
    lineHeight: 'normal'
  }, hasError ? {
    border: "1px solid ".concat(theme.error)
  } : hasText ? {
    border: "1px solid ".concat(theme.success),
    borderRadius: 3
  } : {});
};
var helpTextAnimation = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  from {\n    height: 0;\n    opacity: 0;\n  }\n  to {\n    height: 24px;\n    opacity: 1;\n  }\n"])));
export var inputHelpContainer = function inputHelpContainer(hasError, theme) {
  return css({
    fontSize: '13px',
    lineHeight: 1.54,
    color: hasError ? theme.errorDark : theme.successDark,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6,
    fontWeight: 400,
    animation: "".concat(helpTextAnimation, " 400ms ease-out")
  });
};
export var inputHelpIcon = function inputHelpIcon(hasError, theme) {
  return css({
    padding: hasError ? 4 : 6,
    borderRadius: '50%',
    marginRight: 6,
    backgroundColor: hasError ? theme.errorLight : theme.successLight,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      width: '100%',
      height: 'auto',
      maxHeight: '100%'
    }
  });
};
export var inputFieldRightIcon = function inputFieldRightIcon(isPasswordType) {
  return css({
    position: 'absolute',
    right: isPasswordType ? 38 : 8,
    bottom: 26
  });
};
export var inputFieldVisibilityIcon = css({
  position: 'absolute',
  right: 8,
  bottom: 26
});
// Input styles end (base one)

// Input styles start (dynamic one)
export var dynamicInputContainer = function dynamicInputContainer(isReadOnly, className, theme) {
  return cx(css({
    borderRadius: 8,
    paddingLeft: 8,
    '&:hover': {
      backgroundColor: isReadOnly ? 'transparent' : theme.whiteGray
    },
    '@media (max-width: 991px)': {
      marginTop: 8
    }
  }), className);
};
export var dynamicInputError = function dynamicInputError(theme) {
  return css({
    fontSize: '12px',
    lineHeight: 1,
    color: theme.errorDark
  });
};
export var dynamicInputErrorAdd = function dynamicInputErrorAdd(theme) {
  return css(dynamicInputError(theme), {
    paddingLeft: 12
  });
};
export var dynamicInputAddLabel = function dynamicInputAddLabel(hasError, isDisabled, required, theme) {
  return css({
    color: hasError ? theme.errorDark : isDisabled ? theme.textLightDisabled : theme.primary,
    fontSize: 16,
    lineHeight: hasError ? '17px' : '21px',
    padding: required ? hasError ? '5px 0' : '8px 0 10px' : hasError ? '8px 0 0' : '8px 0 12px',
    outline: 'none',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  });
};
export var dynamicInputLabel = function dynamicInputLabel(hasError, isDisabled, theme) {
  return css({
    color: hasError ? theme.errorDark : isDisabled ? theme.textLightDisabled : theme.textLightSecondary,
    padding: hasError ? '5px 0 5px 14px' : '10px 0 12px 14px',
    '@media (max-width: 991px)': {
      padding: '10px 0'
    }
  });
};
export var dynamicInputValue = function dynamicInputValue(hasError, isDisabled, isReadOnly, theme) {
  return css({
    color: hasError ? theme.errorDark : isDisabled ? theme.textLightDisabled : isReadOnly ? theme.textLightSecondary : theme.textLightPrimary,
    display: 'flex',
    alignItems: 'center',
    padding: '11px 9px',
    '@media (max-width: 991px)': {
      padding: '11px 9px 11px 0'
    }
  });
};
export var dynamicInputEditIcon = function dynamicInputEditIcon(theme) {
  return css({
    opacity: 0,
    fontSize: 20,
    padding: 3,
    borderRadius: 6,
    marginRight: 6,
    border: "1px solid ".concat(theme.primary)
  });
};
export var dynamicDropdownClearIcon = css({
  opacity: 0,
  fontSize: 20,
  padding: 3,
  marginRight: 6
});
export var dynamicInputValueContainer = function dynamicInputValueContainer(isDisabled, isReadOnly) {
  return css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: isReadOnly ? 'default' : isDisabled ? 'not-allowed' : 'pointer',
    outline: 'none',
    '&:hover i': !isDisabled && !isReadOnly ? {
      opacity: 1
    } : {}
  });
};
export var dynamicInputField = function dynamicInputField(hasError, theme) {
  return css({
    borderColor: hasError ? theme.errorDark : theme.primary
  });
};
// Input styles end (dynamic one)

// Dropdown styles start
export var dropdownInput = function dropdownInput(hasError, theme) {
  return hasError ? css({
    borderColor: theme.errorDark
  }) : '';
};
// DropDown styles end

// Dynamic dropdown styles start
export var dynamicDropdownField = function dynamicDropdownField(hasError, theme) {
  return css({
    paddingLeft: 9
  }, hasError ? {
    borderColor: theme.errorDark
  } : {});
};
export var dynamicDropdownFieldContainer = css({
  outline: 'none'
});
export var requiredText = css({
  fontSize: '11px',
  textTransform: 'capitalize'
});
export var dynamicSwitchButtonFieldContainer = css({
  outline: 'none',
  paddingTop: 6
});
export var rightIconsContainer = css({
  display: 'flex'
});

// Dynamic dropdown styles end

// Success Bar styles start
var successBarEffect = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  from {\n    width: 0;\n  }\n  to {\n    width: 100%;\n  }\n"])));
export var successBar = css({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.2)',
  backgroundColor: 'white',
  height: 70,
  overflow: 'hidden',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontSize: 18
});
export var successBarColorEffect = function successBarColorEffect(theme) {
  return css({
    width: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundColor: theme.successDark,
    animation: "".concat(successBarEffect, " 0.7s ease-in-out")
  });
};
export var successBarContent = css({
  position: 'relative',
  zIndex: 10,
  width: '100%',
  '& > i': {
    position: 'absolute',
    right: '20px',
    top: '0',
    cursor: 'pointer'
  }
});
// Success Bar styles end

export var containerCheckBox = function containerCheckBox(className) {
  return css({
    fontWeight: 600,
    marginBottom: 16
  }, className);
};
export var phoneInputContainer = css({
  display: 'flex'
});
export var phoneInputCountrySelect = css({
  width: 100
});
export var countryContainer = css({
  display: 'flex',
  alignItems: 'center',
  padding: 16,
  '& > .flag-img': {
    maxWidth: '24px',
    marginRight: '8px',
    border: '3px solid white'
  }
});
export var colorPickerContainer = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%'
});
export var colorPickerWrap = css({
  '& > span': {
    height: 47,
    verticalAlign: 'middle'
  }
});
export var videoPickerContainer = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%'
});
export var videoPickerWrap = css({
  'div[role=button]': {
    height: 47,
    verticalAlign: 'middle'
  }
});