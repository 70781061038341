import React from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from '../../../../components/UI';
import ProfileHistory from './ProfileHistory';
import LoginHistory from './LoginHistory';
import { jsx as ___EmotionJSX } from "@emotion/react";
var History = function History() {
  var _useParams = useParams(),
    userId = _useParams.userId;
  return ___EmotionJSX(Tabs, {
    startingRoute: "/profiles/profile/".concat(userId, "/history"),
    routeMatch: "/profiles/profile/:id/history"
  }, ___EmotionJSX(Tab, {
    label: "Profile",
    url: "/profile",
    component: function component() {
      return ___EmotionJSX(ProfileHistory, {
        userId: userId
      });
    }
  }), ___EmotionJSX(Tab, {
    label: "Login",
    url: "/login",
    component: function component() {
      return ___EmotionJSX(LoginHistory, {
        userId: userId
      });
    }
  }));
};
export default History;