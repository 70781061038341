import { css } from 'emotion';
export var rowTransactions = function rowTransactions(theme) {
  return css({
    display: 'flex',
    alignItems: 'center',
    color: theme.grayDark,
    padding: 24,
    fontWeight: 600,
    '&:nth-child(2n)': {
      backgroundColor: theme.whiteGrayLight
    }
  });
};
export var internalContainer = css({
  padding: '0px 18px',
  flex: 1
});
export var arrowTrans = css({
  fontSize: 14,
  margin: '0px 12px'
});
export var descTransactions = css({
  display: 'flex',
  flexDirection: 'row',
  marginTop: 6,
  alignItems: 'center',
  '& > div': {
    marginRight: 12,
    '& > p': {
      marginRight: 12
    }
  }
});
export var skeletonContainer = css({
  padding: '0px 18px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  marginTop: 6,
  alignItems: 'flex-start'
});
export var marginBottom6 = css({
  marginBottom: 6
});
export var statusContainer = css({
  padding: '4px 8px',
  borderRadius: 3,
  fontWeight: 600,
  marginRight: 6
});