import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { switchButtonContainer, switchButtonInput, switchButtonLabel, switchButtonInner, switchButtonCircle } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var SwitchButton = function SwitchButton(props) {
  var id = props.id,
    _props$style = props.style,
    style = _props$style === void 0 ? {} : _props$style,
    value = props.value,
    _props$onChange = props.onChange,
    onChange = _props$onChange === void 0 ? function () {} : _props$onChange,
    width = props.width,
    activeText = props.activeText,
    inactiveText = props.inactiveText;
  var theme = useTheme();
  var _useState = useState(!!value),
    _useState2 = _slicedToArray(_useState, 2),
    isChecked = _useState2[0],
    setIsChecked = _useState2[1];
  var inputId = id || Math.random();
  useEffect(function () {
    setIsChecked(value);
  }, [value]);
  var handleChange = function handleChange() {
    return setIsChecked(function (prev) {
      onChange(!prev);
      return !prev;
    });
  };
  return ___EmotionJSX("div", {
    className: switchButtonContainer(width)
  }, ___EmotionJSX("input", {
    className: switchButtonInput,
    type: "checkbox",
    name: "onOffSwitch",
    onChange: handleChange,
    id: inputId,
    checked: isChecked
  }), ___EmotionJSX("label", {
    className: switchButtonLabel,
    htmlFor: inputId
  }, ___EmotionJSX("span", {
    className: switchButtonInner(isChecked, activeText, inactiveText, style, theme)
  }), ___EmotionJSX("span", {
    className: switchButtonCircle(isChecked, width, style, theme)
  })));
};
SwitchButton.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  activeText: PropTypes.string,
  inactiveText: PropTypes.string
};
export default SwitchButton;