import React from 'react';
import { Table, tableTitleHeadline } from '../../../components/UI';
import { getMyTasks } from '../../../services/tasks';
import { tasksColumns } from './config';
import { jsx as ___EmotionJSX } from "@emotion/react";
var dataKey = 'tasks';
var MyTasksList = function MyTasksList() {
  var handleClickAssignee = function handleClickAssignee() {};
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h1", {
    className: tableTitleHeadline
  }, "My Tasks List"), ___EmotionJSX(Table, {
    getDataKey: dataKey,
    getDataMethod: getMyTasks,
    columns: tasksColumns(handleClickAssignee),
    emptyMessage: "There are no My Tasks."
  }));
};
export default MyTasksList;