import { css } from 'emotion';
export var container = function container(className) {
  return css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }, className);
};
export var pictureContainer = css({
  borderRadius: '50%',
  marginRight: 18,
  flexBasis: 70,
  flexShrink: 0
});
export var transactionInfo = css({
  display: 'inline-block',
  marginBottom: 4,
  marginRight: 8
});
export var planContainer = function planContainer(theme) {
  return css({
    color: theme.success,
    backgroundColor: theme.successLight,
    padding: '2px 4px',
    borderRadius: 1
  });
};
export var infoContainer = css({
  fontWeight: 600,
  '& > span': {
    fontWeight: 400,
    marginLeft: 12
  }
});
export var iconArrow = css({
  margin: '0px 32px'
});
export var senderReceiver = function senderReceiver(theme) {
  return css({
    fontWeight: 600,
    color: theme.grayLight
  });
};
export var marginBottom4 = css({
  marginBottom: 4
});
export var profileInfoContainer = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});