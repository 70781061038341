import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { currencyByType } from '../../../utils';
import { type as walletType } from '../../../enums/wallets';
export var getBvPointsTableRows = function getBvPointsTableRows(content, isCurrentRound) {
  var _content$leftBvBase, _content$rightBvBase, _content$leftBv, _content$rightBv, _content$leftBvBase2, _content$leftBv2, _content$rightBvBase2, _content$rightBv2, _content$leftAffiliat, _content$rightAffilia, _content$bonusCap, _content$amount, _Math$min;
  return [{
    label: 'Base',
    leftData: currencyByType((_content$leftBvBase = content === null || content === void 0 ? void 0 : content.leftBvBase) !== null && _content$leftBvBase !== void 0 ? _content$leftBvBase : 0, walletType.BV),
    rightData: currencyByType((_content$rightBvBase = content === null || content === void 0 ? void 0 : content.rightBvBase) !== null && _content$rightBvBase !== void 0 ? _content$rightBvBase : 0, walletType.BV),
    isSingleValue: false
  }, {
    label: 'New',
    leftData: currencyByType((_content$leftBv = content === null || content === void 0 ? void 0 : content.leftBv) !== null && _content$leftBv !== void 0 ? _content$leftBv : 0, walletType.BV),
    rightData: currencyByType((_content$rightBv = content === null || content === void 0 ? void 0 : content.rightBv) !== null && _content$rightBv !== void 0 ? _content$rightBv : 0, walletType.BV),
    isSingleValue: false
  }, {
    label: 'Result',
    leftData: currencyByType(((_content$leftBvBase2 = content === null || content === void 0 ? void 0 : content.leftBvBase) !== null && _content$leftBvBase2 !== void 0 ? _content$leftBvBase2 : 0) + ((_content$leftBv2 = content === null || content === void 0 ? void 0 : content.leftBv) !== null && _content$leftBv2 !== void 0 ? _content$leftBv2 : 0), walletType.BV),
    rightData: currencyByType(((_content$rightBvBase2 = content === null || content === void 0 ? void 0 : content.rightBvBase) !== null && _content$rightBvBase2 !== void 0 ? _content$rightBvBase2 : 0) + ((_content$rightBv2 = content === null || content === void 0 ? void 0 : content.rightBv) !== null && _content$rightBv2 !== void 0 ? _content$rightBv2 : 0), walletType.BV),
    isSingleValue: false
  }, {
    label: 'People',
    leftData: (_content$leftAffiliat = content === null || content === void 0 ? void 0 : content.leftAffiliates) !== null && _content$leftAffiliat !== void 0 ? _content$leftAffiliat : 0,
    rightData: (_content$rightAffilia = content === null || content === void 0 ? void 0 : content.rightAffiliates) !== null && _content$rightAffilia !== void 0 ? _content$rightAffilia : 0,
    isSingleValue: false
  }, !isCurrentRound && {
    label: 'Bonus Cap',
    leftData: currencyByType((_content$bonusCap = content === null || content === void 0 ? void 0 : content.bonusCap) !== null && _content$bonusCap !== void 0 ? _content$bonusCap : 0, walletType.CASH),
    rightData: '',
    isSingleValue: true
  }, {
    label: 'Total Bonus',
    leftData: currencyByType((_content$amount = content === null || content === void 0 ? void 0 : content.amount) !== null && _content$amount !== void 0 ? _content$amount : 0, walletType.CASH),
    rightData: '',
    isSingleValue: true
  }, {
    label: isCurrentRound ? 'Bonus to be paid' : 'Paid Bonus',
    leftData: currencyByType((_Math$min = Math.min(content === null || content === void 0 ? void 0 : content.amount, content === null || content === void 0 ? void 0 : content.bonusCap)) !== null && _Math$min !== void 0 ? _Math$min : 0, walletType.CASH),
    rightData: '',
    isSingleValue: true
  }].filter(function (el) {
    return el;
  });
};