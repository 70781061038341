function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
export var splitBalanceHistoryBreadCrumb = function splitBalanceHistoryBreadCrumb(userId, accountId) {
  return [{
    url: '/profiles',
    label: 'Profiles'
  }, {
    url: "/profiles/profile/".concat(userId, "/accounts"),
    label: 'Accounts'
  }, {
    url: "/profiles/profile/".concat(userId, "/accounts/").concat(accountId, "/wallets"),
    label: 'Wallets'
  }, {
    label: 'Split Balance History'
  }];
};
export var splitHistoryColumns = [{
  name: 'Run Id',
  value: 'splitsRunId',
  width: 80
}, {
  name: 'Before run Available Splits',
  value: 'preRunAvailableSplits'
}, {
  name: 'After run Available Splits',
  value: 'postRunAvailableSplits'
}, {
  name: 'Before run Max Splits',
  value: 'preRunMaxSplits'
}, {
  name: 'After run Max Splits',
  value: 'postRunMaxSplits'
}, {
  name: 'Before run Immediate Mining Balance',
  value: 'preRunImmediateMiningBalance'
}, {
  name: 'After run Immediate Mining Balance',
  value: 'postRunImmediateMiningBalance'
}, {
  name: 'Before run Used Immediate Mining Balance',
  value: 'preRunUsedImmediateMiningBalance'
}, {
  name: 'After run Used Immediate Mining Balance',
  value: 'postRunUsedImmediateMiningBalance'
}, {
  name: 'Before run Token Wallet Balance',
  value: 'preRunTokenWalletBalance'
}, {
  name: 'After run Token Wallet Balance',
  value: 'postRunTokenWalletBalance'
}];
var accountSubBreadcrumbsPart = function accountSubBreadcrumbsPart(userId) {
  return [{
    url: '/profiles',
    label: 'Profiles'
  }, {
    url: "/profiles/profile/".concat(userId, "/accounts"),
    label: 'Accounts'
  }];
};
export var myPackagesBreadcrumbs = function myPackagesBreadcrumbs(userId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId)), [{
    label: 'My Packages'
  }]);
};
export var mySubscriptionsBreadcrumbs = function mySubscriptionsBreadcrumbs(userId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId)), [{
    label: 'My Subscriptions'
  }]);
};
export var breadcrumbsWalletPages = function breadcrumbsWalletPages(userId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId)), [{
    label: 'Wallets'
  }]);
};
export var myMatchingTreeBreadcrumbs = function myMatchingTreeBreadcrumbs(userId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId)), [{
    label: 'My Matching'
  }]);
};
export var myNetworkTreeBreadcrumbs = function myNetworkTreeBreadcrumbs(userId) {
  return [].concat(_toConsumableArray(accountSubBreadcrumbsPart(userId)), [{
    label: 'My Network'
  }]);
};