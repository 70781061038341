function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _omit from "lodash/omit";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var trimPath = function trimPath(path) {
  return (path === null || path === void 0 ? void 0 : path.slice(-1)) === '/' ? path.slice(0, -1) : path;
};
var emptyNode = function emptyNode(parent, childPath) {
  return {
    parent: parent,
    path: "".concat(trimPath(parent.path), "/").concat(childPath),
    children: []
  };
};
export var fillEmptyChilds = function fillEmptyChilds(node, depth) {
  // If the node is empty (if there is only 1 child on this depth the other will be empty)
  if (node.profileId === 0 && node.parentId === 0 && node.uplinkId === 0) return node;

  // Reach out the final depth
  if (depth === 0) return _objectSpread(_objectSpread({}, node), {}, {
    children: []
  });
  if (!node.children.length) {
    return _objectSpread(_objectSpread({}, node), {}, {
      children: [emptyNode(node, 1), emptyNode(node, 2)]
    });
  }
  var newNode = _objectSpread(_objectSpread(_objectSpread({}, node), !node.parent && {
    path: trimPath(node === null || node === void 0 ? void 0 : node.path)
  }), {}, {
    children: node.children.map(function (el, ind) {
      var _trimPath;
      return fillEmptyChilds(_objectSpread(_objectSpread({}, el), {}, {
        parent: _omit(_objectSpread(_objectSpread({}, node), {}, {
          path: trimPath(node === null || node === void 0 ? void 0 : node.path)
        }), 'children'),
        path: (_trimPath = trimPath(el === null || el === void 0 ? void 0 : el.path)) !== null && _trimPath !== void 0 ? _trimPath : "".concat(trimPath(node.path), "/").concat(ind + 1)
      }), depth - 1);
    })
  });
  return newNode;
};