import { css } from 'emotion';
export var searchContainer = function searchContainer(className) {
  return css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    width: '100%'
  }, className);
};
export var inputSearch = function inputSearch(theme, small) {
  return css({
    fontSize: '13px',
    fontWeight: '600',
    color: theme.gray,
    padding: small ? '4px 36px' : '10px 36px',
    lineHeight: small ? 1.77 : 1.71,
    '::-webkit-input-placeholder': {
      color: theme.grayLighter
    }
  });
};
export var iconSearch = css({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  left: 8
});
export var iconClear = css({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 12
});