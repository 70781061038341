import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { Table } from '../../../../components/UI';
import { getAllBundles } from '../../../../services/bundles';
import { bundlesListColumns } from './config';
import { navLink, container } from '../styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var BundlesList = function BundlesList() {
  var theme = useTheme();
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: container
  }, ___EmotionJSX("h1", null, "Bundle List"), ___EmotionJSX(Link, {
    to: "/packages/create-bundle",
    className: navLink(theme)
  }, "Create Bundle")), ___EmotionJSX(Table, {
    getDataKey: "items",
    getDataMethod: getAllBundles,
    columns: bundlesListColumns,
    emptyMessage: "There are no Bundles",
    pageSize: 10
  }));
};
export default BundlesList;