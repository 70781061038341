import { css } from 'emotion';
export var backContainerWithButton = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 48,
  justifyContent: 'space-between',
  '& > h1': {
    marginBottom: 2,
    fontWeight: 600
  },
  '& > .buttons > div': {
    marginLeft: 10
  }
});
export var successButton = css({
  marginRight: 12,
  marginBottom: 8
});
export var academyTokensColumn = css({
  fontSize: 11,
  '& > p': {
    '& > b': {
      marginRight: 6
    }
  }
});
export var dateStyle = css({
  display: 'flex',
  flexDirection: 'column'
});
export var datesTitles = css({
  fontSize: 12
});
export var labelContainer = css({
  fontWeight: 700,
  padding: '12px 6px'
});
export var valueContainer = css({
  padding: 12
});