export var optionsChangeOwner = [{
  value: 'email',
  label: 'Email'
}, {
  value: 'phone',
  label: 'Phone'
}, {
  value: 'username',
  label: 'Username'
}, {
  value: 'userId',
  label: 'UserID'
}];