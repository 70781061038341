import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { menuSeparator } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var MENU_SEPARATOR_NAME = 'MenuSeparator';
var MenuSeparator = function MenuSeparator(_ref) {
  var children = _ref.children,
    isOpen = _ref.isOpen;
  var theme = useTheme();
  return ___EmotionJSX("h2", {
    className: menuSeparator(theme)
  }, isOpen ? children : "".concat(children).charAt(0));
};
MenuSeparator.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool
};
MenuSeparator.displayName = MENU_SEPARATOR_NAME;
export default MenuSeparator;