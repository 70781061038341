import { css } from 'emotion';
export var networkTreeFilterContainer = css({
  position: 'relative',
  outline: 'none'
});
export var spinnerNetworkTree = css({
  position: 'absolute',
  right: '5px',
  top: '5px',
  zIndex: 1,
  '& .spinner': {
    padding: 0
  },
  '& svg': {
    width: '30px',
    height: '30px'
  }
});
export var networkTreeFilterSuggestions = function networkTreeFilterSuggestions(theme) {
  return css({
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: theme.white,
    border: "1px solid ".concat(theme.border),
    borderTop: 0,
    maxHeight: '350px',
    overflowY: 'auto'
  });
};
export var networkTreeFilterSuggestionsItem = function networkTreeFilterSuggestionsItem(theme) {
  return css({
    padding: '8px 24px',
    width: '100%',
    cursor: 'pointer',
    outline: 'none',
    color: theme.gray,
    fontSize: '13px',
    fontWeight: 600,
    '&:first-child': {
      paddingTop: '8px'
    },
    '&:last-child': {
      paddingBottom: '8px'
    },
    '&:hover': {
      backgroundColor: theme.primaryLight,
      color: theme.white
    }
  });
};
export var networkTreeFilterNoResults = function networkTreeFilterNoResults(theme) {
  return css({
    padding: '8px 24px',
    width: '100%',
    cursor: 'none',
    color: theme.gray,
    fontSize: '13px',
    fontWeight: 600
  });
};