import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _mapProfileStatus;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var profileStatus = {
  New: 'New',
  Pending: 'Pending',
  Active: 'Active',
  Frozen: 'Frozen',
  Terminated: 'Terminated'
};
export var profileRank = {
  Eagle: 'Eagle',
  BronzeEagle: 'Bronze Eagle',
  SilverEagle: 'Silver Eagle',
  GoldEagle: 'Gold Eagle',
  PlatinumEagle: 'Platinum Eagle',
  LifetimeEagle: 'Lifetime Eagle'
};
export var profileRankId = {
  1: profileRank.Eagle,
  2: profileRank.BronzeEagle,
  3: profileRank.SilverEagle,
  4: profileRank.GoldEagle,
  5: profileRank.PlatinumEagle,
  6: profileRank.LifetimeEagle
};
export var mapProfileStatus = (_mapProfileStatus = {}, _defineProperty(_mapProfileStatus, profileStatus.New, {
  text: 'New',
  color: 'orange'
}), _defineProperty(_mapProfileStatus, profileStatus.Pending, {
  text: 'Pending',
  color: 'info'
}), _defineProperty(_mapProfileStatus, profileStatus.Active, {
  text: 'Active',
  color: 'success'
}), _defineProperty(_mapProfileStatus, profileStatus.Frozen, {
  text: 'Frozen',
  color: 'warning'
}), _defineProperty(_mapProfileStatus, profileStatus.Terminated, {
  text: 'Terminated',
  color: 'error'
}), _mapProfileStatus);