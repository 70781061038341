import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.freeze.js";
import "core-js/modules/es.object.define-properties.js";
var _templateObject, _templateObject2;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { css, keyframes } from 'emotion';
var lock = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  50% {top:-4%;}\n\t100% {top:-6%;}\n"])));
var colors = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  50% {transform:scale(1.1);}\n  100% {color:#ca3074;}\n"])));
export var mainContainer = css({
  width: '100%',
  height: '100vh',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: '#332851',
  'div[role=button]': {
    color: 'rgb(162,163,166)'
  },
  h1: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '30vw',
    display: 'block',
    margin: '0',
    color: '#9ae1e2',
    position: 'relative',
    zIndex: '0',
    animation: "".concat(colors, " .4s ease-in-out forwards"),
    animationDelay: '1.7s',
    '&:before': {
      content: '"U"',
      position: 'absolute',
      top: '-9%',
      right: '40%',
      transform: 'rotate(180deg)',
      fontSize: '15vw',
      color: '#f6c667',
      zIndex: '-1',
      textAlign: 'center',
      animation: "".concat(lock, " .2s ease-in-out forwards"),
      animationDelay: '1.5s'
    }
  },
  h2: {
    color: '#9ae1e2',
    fontSize: '5vw',
    margin: '0',
    textTransform: 'uppercase',
    textAlign: 'center',
    animation: "".concat(colors, " .4s ease-in-out forwards"),
    animationDelay: '2s'
  }
});