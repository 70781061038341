import React from 'react';
import { hasAtLeastOneRole, USER_ROLES } from '@oneecosystem/authenticate';
import { Tabs, Tab } from '../../../components/UI';
import MyTasksList from './MyTasksList';
import AllTasksList from './AllTasksList';
import StatsList from './StatsList';
import { tabContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var TasksPage = function TasksPage() {
  var isAKT = hasAtLeastOneRole([USER_ROLES.ADMINISTRATOR, USER_ROLES.KYC_OPERATOR, USER_ROLES.TEAM_LEADER]);
  var isAT = hasAtLeastOneRole([USER_ROLES.KYC_OPERATOR, USER_ROLES.TEAM_LEADER]);
  return ___EmotionJSX(Tabs, {
    startingRoute: "/tasks",
    tabsContentClass: tabContainer
  }, isAT && ___EmotionJSX(Tab, {
    label: "All tasks ",
    url: "/all",
    component: AllTasksList
  }), isAKT && ___EmotionJSX(Tab, {
    label: "My tasks",
    url: "/my",
    component: MyTasksList
  }), isAKT && ___EmotionJSX(Tab, {
    label: "Stats",
    url: "/stats",
    component: StatsList
  }));
};
export default TasksPage;