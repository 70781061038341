function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.function.bind.js";
import _isString from "lodash/isString";
import _isFunction from "lodash/isFunction";
var _excluded = ["label", "placeholder", "value", "options", "onBlur", "onChange", "components", "async", "dynamicValue", "className"];
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import hash from 'object-hash';
import AsyncSelect from 'react-select/async';
import { useTheme } from 'emotion-theming';
import { Spinner } from '../Loader';
import colourStyles from './ColorStyles';
import { formGroup, formLabel } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var SelectCustom = function SelectCustom(props) {
  var label = props.label,
    placeholder = props.placeholder,
    value = props.value,
    options = props.options,
    onBlur = props.onBlur,
    onChange = props.onChange,
    _props$components = props.components,
    components = _props$components === void 0 ? {} : _props$components,
    _props$async = props.async,
    async = _props$async === void 0 ? false : _props$async,
    _props$dynamicValue = props.dynamicValue,
    dynamicValue = _props$dynamicValue === void 0 ? false : _props$dynamicValue,
    className = props.className,
    rest = _objectWithoutProperties(props, _excluded);
  var prepareValue = _isString(value) ? {
    value: value,
    label: value
  } : value;
  var _useState = useState(prepareValue !== null && prepareValue !== void 0 ? prepareValue : null),
    _useState2 = _slicedToArray(_useState, 2),
    selected = _useState2[0],
    setSelected = _useState2[1];
  var theme = useTheme();
  useEffect(function () {
    dynamicValue && hash({
      value: value
    }) !== hash({
      value: selected
    }) && setSelected(value);
  }, [hash({
    value: value
  })]);
  var handleSelect = function handleSelect(selected) {
    setSelected(selected);
    _isFunction(onChange) && onChange(selected === null || selected === void 0 ? void 0 : selected.value);
  };
  var handleSelectBlur = function handleSelectBlur() {
    return _isFunction(onBlur) && onBlur();
  };
  var SelectComponent = async ? AsyncSelect : Select;
  return ___EmotionJSX("div", {
    className: formGroup(className, theme)
  }, label && ___EmotionJSX("label", {
    className: formLabel(theme)
  }, label), ___EmotionJSX(SelectComponent, _extends({}, options && {
    options: options
  }, {
    value: selected,
    placeholder: placeholder,
    onBlur: handleSelectBlur,
    styles: colourStyles,
    onChange: function onChange(value) {
      return handleSelect(value);
    },
    defaultValue: {
      label: '',
      value: ''
    },
    classNamePrefix: "custom-select",
    components: _objectSpread({
      LoadingMessage: Spinner
    }, components)
  }, rest)));
};
SelectCustom.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.any,
  value: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  async: PropTypes.bool,
  dynamicValue: PropTypes.bool,
  components: PropTypes.object,
  className: PropTypes.string
};
export default SelectCustom;