import { css } from 'emotion';
export var hashStyles = css({
  maxWidth: 100,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'pre-wrap'
});
export var hashRow = css({
  display: 'flex',
  alignItems: 'center'
});
export var copyIcon = css({
  marginLeft: 4
});