import { css } from 'emotion';
export var bonusFilterBarContainer = css({
  marginRight: '32px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap'
});
export var bonusFilterBarLeftItem = css({
  display: 'flex',
  flexWrap: 'wrap',
  '&>div': {
    display: 'flex',
    alignItems: 'center',
    '&:first-child': {
      '&>div': {
        minWidth: '100px',
        marginRight: '24px'
      }
    },
    '&>div': {
      minWidth: '150px'
    },
    '&>label': {
      fontSize: '13px',
      marginRight: '8px',
      whiteSpace: 'nowrap'
    }
  },
  '@media (max-width: 991px)': {
    flexBasis: '100%',
    '&>div': {
      marginBottom: '16px'
    }
  }
});
export var bonusFilterBarRightItem = css({
  display: 'flex',
  direction: 'column-reverse',
  justifyContent: 'flex-end'
});