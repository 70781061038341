import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Col, Row } from '../../UI';
import { columns } from './config';
import { tableDataCell, tableDataRow, tableDataHeadContent } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Head = function Head() {
  var theme = useTheme();
  return ___EmotionJSX(Row, {
    justify: "flex-end",
    align: "center",
    className: tableDataRow(theme)
  }, columns.map(function (column, index) {
    var name = column.name,
      width = column.width;
    return ___EmotionJSX(Col, {
      key: "".concat(name, "-").concat(index),
      className: tableDataCell(theme, width)
    }, name && ___EmotionJSX("b", {
      className: tableDataHeadContent(theme)
    }, name));
  }));
};
Head.propTypes = {
  theme: PropTypes.any
};
export default Head;