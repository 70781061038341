import { css } from 'emotion';
export var container = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: 'auto'
});
export var content = css({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '1rem',
  flexGrow: 1
});
export var number = function number(theme) {
  return css({
    color: theme.gray,
    fontWeight: 600,
    fontSize: '1.8rem'
  });
};
export var description = function description(theme) {
  return css({
    color: theme.grayLight,
    fontSize: '1.1rem',
    fontWeight: 400,
    marginTop: '0.55rem'
  });
};
export var chart = css({
  position: 'relative',
  width: '230px',
  height: '100px',
  '& > canvas': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4
  }
});