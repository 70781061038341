import { css } from 'emotion';
export var shoppingCartProfileContainer = css({
  backgroundColor: 'white',
  padding: 12,
  margin: '0 0 12px 0',
  borderRadius: 6
});
export var shoppingCartProfileContainerAccount = css(shoppingCartProfileContainer, {
  flex: 1,
  display: 'flex',
  flexDirection: 'row'
});
export var dropdownFilter = css({
  width: 160,
  flex: '0 0 160px',
  marginRight: 12
});
export var dropdownFilterPayment = css({
  width: 200,
  flex: '0 0 200px'
});
export var inputFilter = css({
  flex: '1 0 300px',
  marginRight: 12
});