import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import React from 'react';
import ValuePercent from '../ValuePercent';
import { currencyByType } from '../../../utils';
import { type as walletType } from '../../../enums/wallets';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var currentBonusRows = [{
  label: '1st Gen',
  render: function render(data) {
    var _data$packageInfoForR;
    return data !== null && data !== void 0 && (_data$packageInfoForR = data.packageInfoForRound) !== null && _data$packageInfoForR !== void 0 && _data$packageInfoForR.hasMatch1Bonus ? currencyByType(data === null || data === void 0 ? void 0 : data.firstLevelBv, walletType.BV) : 'N/A';
  }
}, {
  label: '2nd Gen',
  render: function render(data) {
    var _data$packageInfoForR2;
    return data !== null && data !== void 0 && (_data$packageInfoForR2 = data.packageInfoForRound) !== null && _data$packageInfoForR2 !== void 0 && _data$packageInfoForR2.hasMatch2Bonus ? currencyByType(data === null || data === void 0 ? void 0 : data.secondLevelBv, walletType.BV) : 'N/A';
  }
}, {
  label: '3rd Gen',
  render: function render(data) {
    var _data$packageInfoForR3;
    return data !== null && data !== void 0 && (_data$packageInfoForR3 = data.packageInfoForRound) !== null && _data$packageInfoForR3 !== void 0 && _data$packageInfoForR3.hasMatch3Bonus ? currencyByType(data === null || data === void 0 ? void 0 : data.thirdLevelBv, walletType.BV) : 'N/A';
  }
}, {
  label: '4th Gen',
  render: function render(data) {
    var _data$packageInfoForR4;
    return data !== null && data !== void 0 && (_data$packageInfoForR4 = data.packageInfoForRound) !== null && _data$packageInfoForR4 !== void 0 && _data$packageInfoForR4.hasMatch4Bonus ? currencyByType(data === null || data === void 0 ? void 0 : data.forthLevelBv, walletType.BV) : 'N/A';
  }
}, {
  label: 'Total Bonus',
  render: function render(data) {
    return ___EmotionJSX(ValuePercent, {
      value: data === null || data === void 0 ? void 0 : data.amount,
      sm: true
    });
  }
}, {
  label: 'Bonus to be paid',
  render: function render(data) {
    return ___EmotionJSX(ValuePercent, {
      value: Math.min(data === null || data === void 0 ? void 0 : data.amount, data === null || data === void 0 ? void 0 : data.bonusCap),
      sm: true
    });
  }
}].filter(function (el) {
  return el;
});