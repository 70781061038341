import React from 'react';
import PropTypes from 'prop-types';
import noPackageLogo from '../../../assets/images/package.png';
import { networkTablePackageCol, networkTablePackageColImage } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkTablePackageCol = function NetworkTablePackageCol(props) {
  var _ref, _ref2;
  var name = props.planName,
    currentPackagePictureUrl = props.currentPackagePictureUrl,
    currentPackagePicThumbnailUrl = props.currentPackagePicThumbnailUrl,
    packageAvatar = props.packageAvatar;
  return ___EmotionJSX("div", {
    className: networkTablePackageCol
  }, ___EmotionJSX("img", {
    className: networkTablePackageColImage,
    src: (_ref = (_ref2 = packageAvatar !== null && packageAvatar !== void 0 ? packageAvatar : currentPackagePicThumbnailUrl) !== null && _ref2 !== void 0 ? _ref2 : currentPackagePictureUrl) !== null && _ref !== void 0 ? _ref : noPackageLogo,
    alt: "package"
  }), ___EmotionJSX("div", null, name !== null && name !== void 0 ? name : 'N/A'));
};
NetworkTablePackageCol.propTypes = {
  planName: PropTypes.string,
  "package": PropTypes.string,
  packageAvatar: PropTypes.string,
  currentPackagePictureUrl: PropTypes.string,
  currentPackagePicThumbnailUrl: PropTypes.string
};
export default NetworkTablePackageCol;