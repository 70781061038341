import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Form, FormInput, CheckBoxForm } from '../../UI';
import { validateLength } from '../../../utils/validations';
import BackButton from '../../Shared/BackButton';
import { backContainer, editPaymentMethodContainer, marginRight } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var AddEditPaymentMethodsForm = function AddEditPaymentMethodsForm(props) {
  var headerTitle = props.headerTitle,
    textSubmitButton = props.textSubmitButton,
    _onSubmit = props.onSubmit,
    _props$values = props.values,
    values = _props$values === void 0 ? {} : _props$values;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(BackButton, {
    className: backContainer,
    title: headerTitle
  }), ___EmotionJSX(Paper, {
    className: editPaymentMethodContainer
  }, ___EmotionJSX(Form, {
    onSubmit: function onSubmit(values) {
      return _onSubmit(values);
    },
    submitButton: {
      children: textSubmitButton
    },
    values: values
  }, ___EmotionJSX(FormInput, {
    disabled: true,
    id: "name",
    label: "Name",
    required: true,
    validate: validateLength(50)
  }), ___EmotionJSX(FormInput, {
    id: "displayName",
    label: "Display name",
    required: true,
    validate: validateLength(100)
  }), ___EmotionJSX(CheckBoxForm, {
    id: "active",
    label: "Status",
    className: marginRight(32)
  }), ___EmotionJSX(CheckBoxForm, {
    id: "visible",
    label: "Visibility",
    className: marginRight(32)
  }), ___EmotionJSX(CheckBoxForm, {
    id: "isInPromo",
    label: "Is In Promo"
  }))));
};
AddEditPaymentMethodsForm.propTypes = {
  headerTitle: PropTypes.string,
  textSubmitButton: PropTypes.string,
  onSubmit: PropTypes.func,
  values: PropTypes.object
};
export default AddEditPaymentMethodsForm;