import "core-js/modules/es.string.fixed.js";
import { cx, css } from 'emotion';
export var containerStyle = function containerStyle(_ref) {
  var zIndex = _ref.zIndex,
    className = _ref.className,
    fullScreen = _ref.fullScreen,
    fixed = _ref.fixed;
  return cx(css({
    position: fixed ? 'fixed' : 'absolute',
    zIndex: zIndex || 200
  }, fullScreen && {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }), className);
};