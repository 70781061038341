import { css } from 'emotion';
export var containerShop = function containerShop() {
  return css({
    cursor: 'pointer',
    minWidth: 100
  });
};
export var shopPackageCardTop = function shopPackageCardTop(theme) {
  return css({
    display: 'flex',
    borderRadius: 3,
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
    backgroundColor: theme.white,
    padding: '16px 8px'
  });
};
export var packageCardContainer = css({
  flex: 1
});
export var containerPackageShop = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 16
});
export var pictureContainer = css({
  borderRadius: '50%',
  flexBasis: 60,
  flexShrink: 0,
  marginRight: 12
});
export var titlePackage = css({
  wordBreak: 'break-all',
  flexGrow: 1,
  '& > p': {
    fontSize: 24
  }
});
export var actionInputContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  marginBottom: 12
});
export var copyContainer = css({
  fontSize: 11,
  width: '100%',
  fontWeight: 500,
  marginRight: 12
});
export var copyIcon = function copyIcon(theme) {
  return css({
    position: 'absolute',
    right: 3,
    fontSize: 14,
    color: theme.grayLight
  });
};
export var dateContainer = function dateContainer(theme) {
  return css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > span': {
      fontSize: 12,
      '& > span': {
        color: theme.grayLight,
        fontWeight: 600
      }
    }
  });
};
export var calendarIcon = function calendarIcon(theme) {
  return css({
    fontSize: 14,
    color: theme.info,
    marginRight: 4
  });
};
export var academyContainer = css({
  marginBottom: 6,
  fontSize: 11,
  '& > p': {
    '& > b': {
      marginRight: 6
    }
  }
});