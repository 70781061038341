import { css } from 'emotion';
export var listLabel = css({
  marginBottom: 16
});
export var listContainer = function listContainer(hasAddButton) {
  return css({
    display: 'flex',
    flexDirection: 'column'
  }, hasAddButton && {
    marginBottom: 16
  });
};
export var itemContainer = function itemContainer(theme) {
  return css({
    display: 'flex',
    marginBottom: 16,
    borderRadius: 6,
    padding: '24px 16px',
    border: "1px solid ".concat(theme.whiteGrayDark),
    '&:last-child': {
      marginBottom: 0
    }
  });
};
export var itemContent = function itemContent(theme) {
  return css({
    zIndex: 100,
    minWidth: 45,
    minHeight: 50,
    backgroundColor: theme.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  });
};
export var deleteIconContainer = function deleteIconContainer(theme) {
  return css({
    cursor: 'pointer',
    paddingRight: 16,
    marginRight: 16,
    display: 'flex',
    alignItems: 'center',
    borderRight: "1px solid ".concat(theme.whiteGrayDark),
    backgroundColor: theme.white,
    transition: 'all .4s ease-in-out'
  });
};