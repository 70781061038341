import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _mapGiftCodeStatus;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import ColorBatch from '../../../components/Shared/ColorBatch';
import { copyToClipboard } from '../../../utils';
import { giftCodeStatus } from '../../../enums/giftCodes';
import { dateStyle } from './styles';
import { DetailsLink } from '../../../components/Shared/TableComponents';
import { Icon } from '../../../components/UI';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var mapGiftCodeStatus = (_mapGiftCodeStatus = {}, _defineProperty(_mapGiftCodeStatus, giftCodeStatus.Redeemed, {
  text: 'Redeemed',
  color: 'success'
}), _defineProperty(_mapGiftCodeStatus, giftCodeStatus.Unpaid, {
  text: 'Unpaid',
  color: 'error'
}), _defineProperty(_mapGiftCodeStatus, giftCodeStatus.NotRedeemed, {
  text: 'Not Redeemed',
  color: 'warning'
}), _mapGiftCodeStatus);
var handleCopy = function handleCopy(string) {
  copyToClipboard(string, "Successfully copied ".concat(string, "."));
};
export var giftCodesColumns = function giftCodesColumns() {
  return [{
    name: 'Order Number',
    value: 'orderId',
    minWidth: 70,
    width: 100
  }, {
    name: 'Gift code',
    render: function render(row) {
      return ___EmotionJSX("div", null, ___EmotionJSX("span", null, row === null || row === void 0 ? void 0 : row.giftCode), ___EmotionJSX(Icon, {
        material: true,
        iconName: "content_copy",
        onClick: function onClick() {
          return handleCopy(row === null || row === void 0 ? void 0 : row.giftCode);
        }
      }));
    },
    minWidth: 120
  }, {
    name: 'Buyer',
    render: function render(row) {
      var _row$buyer;
      return ___EmotionJSX("div", null, ___EmotionJSX("div", null, ___EmotionJSX("strong", null, "Account Name:"), ___EmotionJSX("span", null, " ", row === null || row === void 0 ? void 0 : (_row$buyer = row.buyer) === null || _row$buyer === void 0 ? void 0 : _row$buyer.nickName, " "), ___EmotionJSX(Icon, {
        material: true,
        iconName: "content_copy",
        onClick: function onClick() {
          var _row$buyer2;
          return handleCopy(row === null || row === void 0 ? void 0 : (_row$buyer2 = row.buyer) === null || _row$buyer2 === void 0 ? void 0 : _row$buyer2.nickName);
        }
      })), ___EmotionJSX("div", {
        className: dateStyle
      }, ___EmotionJSX("strong", null, "Order Date:"), ___EmotionJSX("span", null, moment(row.dateOfOrder).format('DD/MM/YYYY, HH:mm:ss'))));
    },
    minWidth: 200
  }, {
    name: 'Redeemer',
    render: function render(row, NullItem) {
      var _row$redeemer, _row$redeemer2;
      return row !== null && row !== void 0 && (_row$redeemer = row.redeemer) !== null && _row$redeemer !== void 0 && _row$redeemer.nickName ? ___EmotionJSX("div", null, ___EmotionJSX("div", null, ___EmotionJSX("strong", null, "Account Name:"), ___EmotionJSX("span", null, " ", row === null || row === void 0 ? void 0 : (_row$redeemer2 = row.redeemer) === null || _row$redeemer2 === void 0 ? void 0 : _row$redeemer2.nickName, " "), ___EmotionJSX(Icon, {
        material: true,
        iconName: "content_copy",
        onClick: function onClick() {
          var _row$redeemer3;
          return handleCopy(row === null || row === void 0 ? void 0 : (_row$redeemer3 = row.redeemer) === null || _row$redeemer3 === void 0 ? void 0 : _row$redeemer3.nickName);
        }
      })), ___EmotionJSX("div", {
        className: dateStyle
      }, ___EmotionJSX("strong", null, "Date of redeeming:"), ___EmotionJSX("span", null, moment(row.dateOfRedeeming).format('DD/MM/YYYY, HH:mm:ss')))) : ___EmotionJSX(NullItem, null);
    },
    minWidth: 200
  }, {
    name: 'Expire date',
    render: function render(row) {
      return ___EmotionJSX("div", {
        className: dateStyle
      }, ___EmotionJSX("span", null, moment(row === null || row === void 0 ? void 0 : row.expireDate).format('DD/MM/YYYY, HH:mm:ss')));
    },
    width: 100,
    minWidth: 100
  }, {
    name: 'Status',
    render: function render(row) {
      var _mapGiftCodeStatus$ro, _mapGiftCodeStatus$ro2;
      return ___EmotionJSX(ColorBatch, {
        type: (_mapGiftCodeStatus$ro = mapGiftCodeStatus[row === null || row === void 0 ? void 0 : row.status]) === null || _mapGiftCodeStatus$ro === void 0 ? void 0 : _mapGiftCodeStatus$ro.color
      }, (_mapGiftCodeStatus$ro2 = mapGiftCodeStatus[row === null || row === void 0 ? void 0 : row.status]) === null || _mapGiftCodeStatus$ro2 === void 0 ? void 0 : _mapGiftCodeStatus$ro2.text);
    },
    width: 100
  }, {
    name: 'Type',
    render: function render(row) {
      return ___EmotionJSX(ColorBatch, {
        type: row.isBlocked === false ? 'success' : 'error'
      }, row.isBlocked === false ? 'Active' : 'Inactive');
    },
    width: 80
  }, {
    name: 'Fee',
    render: function render(row) {
      return ___EmotionJSX("h4", null, row.withActivationFee ? 'Yes' : 'No');
    },
    width: 40
  }, {
    name: 'View Details',
    render: function render(row) {
      return ___EmotionJSX(DetailsLink, {
        row: row,
        url: "/gift-codes/gift-code/".concat(row.giftCode)
      });
    },
    width: 100
  }].filter(Boolean);
};
export var detailGiftCodeBreadCrumb = function detailGiftCodeBreadCrumb() {
  var url = '/gift-codes';
  return [{
    url: url,
    label: 'Gift Codes'
  }, {
    label: 'Detail Page'
  }];
};