import { css } from 'emotion';
export var inputContainer = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 24
});
export var buttonsContainer = function buttonsContainer(theme) {
  return css({
    color: theme.gray,
    fontWeight: 600,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center'
  });
};
export var addButton = function addButton(theme) {
  return css({
    padding: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    marginRight: 32,
    cursor: 'pointer',
    '&:hover > div': {
      backgroundColor: theme.primary
    },
    '& .pictures-input': {
      display: 'none'
    },
    '&:last-child': {
      marginRight: 0
    }
  });
};
export var removeButton = function removeButton(theme) {
  return css(addButton(theme), {
    '&:hover > div': {
      backgroundColor: 'transparent',
      opacity: 1
    }
  });
};
export var iconAdd = function iconAdd(theme) {
  return css({
    borderRadius: '50%',
    backgroundColor: theme.primaryLight,
    marginRight: 12,
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.primary
    }
  });
};
export var iconRemove = function iconRemove(theme) {
  return css(iconAdd(theme), {
    backgroundColor: 'transparent',
    opacity: 0.3,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  });
};
export var pictureContainer = css({
  borderRadius: '50%',
  marginRight: 18,
  flexBasis: 70,
  flexShrink: 0
});