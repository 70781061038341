import { css } from 'emotion';
export var noTitleContent = css({
  padding: '48px 40px'
});
export var paperContainer = css({
  '& > div > *:last-child': {
    marginBottom: 0
  }
});
export var image = css({
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 4
});
export var navLinkDetails = function navLinkDetails(theme) {
  return css({
    borderRadius: 2,
    padding: '10px 20px',
    color: theme.white,
    textAlign: 'center',
    backgroundColor: theme.primaryLight,
    fontSize: 14,
    '&:hover': {
      backgroundColor: theme.primary
    },
    '&:focus, &:active': {
      backgroundColor: theme.primaryLight,
      color: theme.textPrimaryFocused
    }
  });
};
export var linkChangeOwner = function linkChangeOwner(theme) {
  return css({
    borderRadius: 4,
    cursor: 'pointer',
    padding: '8px 20px',
    color: theme.white,
    textAlign: 'center',
    backgroundColor: theme.warning,
    fontSize: 14,
    marginTop: 12,
    letterSpacing: 0.5,
    lineHeight: 1,
    '&:hover': {
      backgroundColor: theme.warningDark
    },
    '&:focus, &:active': {
      backgroundColor: theme.warningDark,
      color: theme.whiteGrayDark
    }
  });
};
export var backContainer = css({
  marginBottom: 32
});
export var backContainerWithButton = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 48
});
export var userInfoContainerWithToggle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap'
});
export var toggleButtonContainer = css({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 10,
  fontSize: 16,
  marginBottom: 32
});
export var paperContain = css({
  marginBottom: 50,
  maxWidth: 850
});
export var formHeadline = css({
  width: '100%',
  marginBottom: 32
});
export var userInfoContain = css({
  paddingLeft: 12,
  marginBottom: 32
});
export var countryContainer = css({
  display: 'flex',
  alignItems: 'center',
  padding: 16,
  '& > .flag-img': {
    maxWidth: '24px',
    marginRight: '8px',
    border: '3px solid white'
  }
});
export var countryImage = css({
  marginRight: 12,
  width: 20,
  height: 20
});
export var otherDocumentsElement = function otherDocumentsElement(theme) {
  return css({
    display: 'block',
    cursor: 'pointer',
    marginBottom: 8,
    padding: 6,
    transition: 'background-color .3s ease-in',
    '& > *': {
      marginBottom: 4,
      '&:last-child': {
        marginBottom: 0
      }
    },
    '&:hover': {
      backgroundColor: theme.whiteGray
    }
  });
};
export var columnName = function columnName(hasRender) {
  return css({
    lineHeight: 1.46,
    letterSpacing: '0.35px',
    flex: '1 0 0%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: hasRender ? '0 6px' : '16px 6px',
    outline: 'none',
    cursor: 'default',
    wordBreak: 'break-word',
    fontSize: 12,
    fontWeight: 600
  });
};
export var columnNameContainer = css({
  display: 'flex',
  flexFlow: 'row nowrap',
  marginBottom: 12
});
export var containerBodyAccounts = function containerBodyAccounts(theme) {
  return css({
    marginBottom: 15,
    border: "1px solid ".concat(theme.borderLight)
  });
};
export var containerRowAccounts = function containerRowAccounts(theme, disabled) {
  return css({
    display: 'flex',
    alignItems: 'stretch',
    color: theme.grayDark,
    '&:hover': {
      backgroundColor: theme.highlights
    },
    '& i': {
      color: theme.textLightDisabled
    },
    '&:hover i': {
      color: !disabled && theme.textLightPrimary
    },
    '&:nth-child(2n - 1)': {
      backgroundColor: theme.whiteGrayLight,
      '&:hover': {
        backgroundColor: theme.highlights
      }
    }
  });
};
export var cellContainer = function cellContainer(width, hasRender) {
  return css({
    fontSize: '1rem',
    lineHeight: 1.46,
    letterSpacing: '0.35px',
    flex: width ? "0 1 ".concat(width, "px") : '1 0 0%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: hasRender ? '0 6px' : '16px 6px',
    outline: 'none',
    cursor: 'default',
    wordBreak: 'break-word'
  });
};
export var commentsContainer = css({
  marginTop: 16
});
export var checkBoxMarginLeft = css({
  margin: '0 0 30px 8px'
});
export var mobileContainer = css({
  maxWidth: 850
});
export var mobileSectionContainer = css({
  display: 'flex',
  alignItems: 'center'
});
export var marginRight12 = css({
  marginRight: 12
});
export var filtersContainer = css({
  marginBottom: 12
});
export var migrationsContainer = function migrationsContainer(theme) {
  return css({
    background: theme.blueGrayLight,
    '& strong': {
      marginBottom: 10,
      lineHeight: 3
    },
    '& .migration-filter': {
      width: 'calc(100% - 200px)',
      minWidth: 700,
      '& > div': {
        background: 'none'
      }
    }
  });
};
export var titleContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
});