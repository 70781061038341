import { css } from 'emotion';
export var uploadButton = css({
  marginTop: 20
});
export var tabContainer = css({
  padding: '48px 40px'
});
export var buttonContainer = css({
  display: 'flex',
  justifyContent: 'flex-start'
});
export var exportTooltip = css({
  minWidth: 200
});
export var exportButton = css({
  marginTop: 20
});
export var containerSearch = css({
  marginTop: 20,
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  '& > *': {
    marginBottom: 24
  },
  position: 'relative',
  backgroundColor: '#ffffff',
  padding: 16,
  border: '1px solid #E0E1E5',
  marginBottom: 8
});
export var datePicker = css({
  marginRight: 32,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});
export var mainOptionsContainer = css({
  display: 'flex',
  alignItems: 'center',
  flex: '1 0 420px'
});
export var transactionLabelText = css({
  fontSize: 13,
  marginRight: 8
});
export var transactionInput = css({
  width: '50%',
  marginLeft: 8
});
export var transactionDropdown = css({
  width: 150,
  maxWidth: 250
});
export var optionsContainer = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: 32,
  flex: '1 0 200px'
});
export var questionIcon = css({
  marginLeft: 8
});
export var tooltipContent = css({
  width: 250,
  textAlign: 'left',
  padding: 8
});