import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var _statusMap;
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import moment from 'moment';
import { DetailsLink } from '../../../components/Shared/TableComponents';
import ColorBatch from '../../../components/Shared/ColorBatch';
import { kycStatus } from '../../../enums/kyc';
import { validDate } from '../../../utils';
import { jsx as ___EmotionJSX } from "@emotion/react";
var statusMap = (_statusMap = {}, _defineProperty(_statusMap, kycStatus.pending, {
  text: 'Pending',
  color: 'info'
}), _defineProperty(_statusMap, kycStatus.approved, {
  text: 'Approved',
  color: 'success'
}), _defineProperty(_statusMap, kycStatus.rejected, {
  text: 'Rejected',
  color: 'error'
}), _statusMap);
export var kycColumns = [{
  name: 'Created Date',
  value: 'createdOn',
  dateTimeFormat: 'DD/MM/YYYY',
  width: 90
}, {
  name: 'Expiration Date',
  value: 'expirationDate',
  dateTimeFormat: 'DD/MM/YYYY',
  width: 90
}, {
  name: 'User',
  render: function render(row) {
    return ___EmotionJSX("div", {
      style: {
        padding: '12px 0'
      }
    }, ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Name: "), ___EmotionJSX("span", null, "".concat(row.profile.fullName))), ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Username: "), ___EmotionJSX("span", null, row.profile.userName)), ___EmotionJSX("p", null, ___EmotionJSX("b", null, "User ID: "), ___EmotionJSX("span", null, row.profile.userId)), ___EmotionJSX("p", null, ___EmotionJSX("b", null, "Date of Birth: "), ___EmotionJSX("span", null, validDate(row.dateOfBirth) ? moment(validDate(row.dateOfBirth)).format('DD/MM/YYYY') : 'N/A')));
  }
}, {
  name: 'Country',
  value: 'profile.country',
  width: 70
}, {
  name: 'Type',
  value: 'type',
  width: 100
}, {
  name: 'Sub Type',
  value: 'subType',
  width: 120
}, {
  name: 'State',
  render: function render(row) {
    var _statusMap$row$state, _statusMap$row$state2;
    return ___EmotionJSX(ColorBatch, {
      type: (_statusMap$row$state = statusMap[row.state]) === null || _statusMap$row$state === void 0 ? void 0 : _statusMap$row$state.color
    }, (_statusMap$row$state2 = statusMap[row.state]) === null || _statusMap$row$state2 === void 0 ? void 0 : _statusMap$row$state2.text);
  },
  width: 90
}, {
  name: 'View details',
  render: function render(row) {
    return ___EmotionJSX(DetailsLink, {
      row: row,
      url: "/kyc/".concat(row.id)
    });
  },
  width: 95
}];
export var pagesDetailsKyc = [{
  url: '/kyc',
  label: 'KYC'
}, {
  label: 'Detail Page'
}];
export var CAN_MARK_VIP = false;