import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.regexp.exec.js";
import _isNil from "lodash/isNil";
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.is-array.js";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { useState, useRef } from 'react';
import { jsonParse } from '../../../utils';
export default (function (initValue, getMatches, localStorageKey) {
  // Get the history for this searchBar from the localStorage
  // If there is nothing saved to provided key then save an empty array
  var previouslySearched = useRef(jsonParse(localStorage.getItem(localStorageKey), []));
  var hasSuggestions = !_isNil(localStorageKey);
  if (_isNil(previouslySearched.current) && hasSuggestions) {
    previouslySearched.current = [];
    localStorage.setItem(localStorageKey, JSON.stringify([]));
  }
  var _useState = useState(initValue || ''),
    _useState2 = _slicedToArray(_useState, 2),
    value = _useState2[0],
    changeValue = _useState2[1];
  var _useState3 = useState(previouslySearched.current),
    _useState4 = _slicedToArray(_useState3, 2),
    history = _useState4[0],
    setHistory = _useState4[1];
  var _useState5 = useState(false),
    _useState6 = _slicedToArray(_useState5, 2),
    isOpen = _useState6[0],
    setIsOpen = _useState6[1];
  var lastSearch = useRef(null);
  var handleChange = function handleChange(_ref) {
    var input = _ref.target.value;
    changeValue(input);
    if (!hasSuggestions) return getMatches(input.trim());
    !isOpen && setIsOpen(true);
    if (!input.length) return setHistory(previouslySearched.current);

    // Filter the previously searched items so we can show the user something relevant
    // There is no reason to show Gosho as suggestion when the input is Pesho, so we
    // search for something that includes Gosho inside of it
    var matches = previouslySearched.current.filter(function (item) {
      return item.toLowerCase().indexOf(input.toLowerCase().trim()) !== -1 && item.length >= input.length;
    });
    return setHistory(matches);
  };
  var handleOnBlur = function handleOnBlur(_ref2) {
    var currentTarget = _ref2.currentTarget;
    return (
      // Here the timeout is used only to make this function async and to be putted inside callback queue
      // We need at least one render to be done from the browser so document.activeElement will be changed
      // to the actual element which is clicked, if this was synchronous document.activeElement will be currentTarget
      setTimeout(function () {
        if (!currentTarget.contains(document.activeElement) && hasSuggestions) {
          performSearch();
          setIsOpen(false);
        }
      })
    );
  };
  var callSubmitMethod = function callSubmitMethod(data) {
    setIsOpen(false);

    // If the user press Enter without change on the input there is no reason to fetch the same results
    if (lastSearch.current === data) return;
    lastSearch.current = data;
    getMatches(data.trim());
  };
  var handleSelect = function handleSelect(data) {
    changeValue(data);
    callSubmitMethod(data);
  };
  var handleKeyPressed = function handleKeyPressed(e) {
    if (e.which === 13 || e.charCode === 13) performSearch();
  };
  var performSearch = function performSearch() {
    if (!hasSuggestions) return;
    if (previouslySearched.current.indexOf(value) === -1 && value.length) {
      previouslySearched.current = [value].concat(_toConsumableArray(previouslySearched.current));
      localStorage.setItem(localStorageKey, JSON.stringify(previouslySearched.current));
    }
    setHistory(previouslySearched.current);
    callSubmitMethod(value);
  };
  var handleDelete = function handleDelete() {
    changeValue('');
    if (!hasSuggestions) return getMatches('');
    setHistory(previouslySearched.current);
    return callSubmitMethod('');
  };
  return {
    input: {
      value: value,
      onChange: handleChange,
      onFocus: function onFocus() {
        return setIsOpen(!!history.length);
      },
      onKeyPress: handleKeyPressed
    },
    container: {
      onBlur: handleOnBlur,
      onSelect: handleSelect,
      onDelete: handleDelete
    },
    isOpen: isOpen,
    history: history
  };
});