import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.freeze.js";
import "core-js/modules/es.object.define-properties.js";
var _templateObject, _templateObject2, _templateObject3;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { css, keyframes } from 'emotion';
export var rippleBaseStyles = function rippleBaseStyles(theme, color, hasFocus) {
  return css({
    position: 'relative',
    overflow: 'hidden',
    outline: 'none',
    userSelect: 'none',
    '&:before': {
      content: hasFocus ? "''" : 'none',
      display: 'block',
      opacity: '0',
      backgroundColor: 'transparent',
      position: 'absolute',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      zIndex: '10',
      transition: 'all 300ms'
    },
    '&:after': {
      content: "''",
      display: 'block',
      opacity: '0',
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: color ? theme[color] : theme.primaryDark,
      pointerEvents: 'none',
      transform: 'scale(0)',
      transformOrigin: 'center center',
      zIndex: '20'
    }
  });
};
export var onFocus = function onFocus(theme, color) {
  return css({
    '&:before': {
      backgroundColor: color ? theme[color] : theme.primaryDark,
      opacity: '1'
    }
  });
};
var radiusIn = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  from {\n    animation-timing-function: linear;\n    transform: scale(0);\n  }\n\n  to {\n    transform: scale(2.5);\n  }\n"])));
var opacityIn = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  from {\n    animation-timing-function: linear;\n    opacity: 0;\n  }\n\n  to {\n    opacity: 1;\n  }\n"])));
export var animationOn = function animationOn(left, top, size) {
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  &:after {\n    width: ", "px;\n    height: ", "px;\n    left: ", "px;\n    top: ", "px;\n    animation: ", " 300ms forwards, ", " 100ms forwards;\n  }\n"])), size, size, left, top, radiusIn, opacityIn);
};