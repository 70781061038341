import _isEmpty from "lodash/isEmpty";
import _isPlainObject from "lodash/isPlainObject";
import _isArray from "lodash/isArray";
import _isNumber from "lodash/isNumber";
import _isString from "lodash/isString";
import _isNil from "lodash/isNil";
import "core-js/modules/es.string.trim.js";
export var noValue = function noValue(val) {
  return _isNil(val) || (_isString(val) || _isArray(val) || _isPlainObject(val)) && _isEmpty(val);
};
export var isStrNum = function isStrNum(val) {
  return _isString(val) || _isNumber(val);
};
export var jsonParse = function jsonParse(text) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  if (typeof text !== 'string') return defaultValue;
  try {
    return JSON.parse(text);
  } catch (error) {
    return defaultValue;
  }
};
export var stringToBoolean = function stringToBoolean(string) {
  switch (String(string).toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(string);
  }
};