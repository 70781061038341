function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import ValuePercent from '../ValuePercent';
import NetworkBonusBvPointsTable from '../NetworkBonusBVPointsTable';
import { bonusLastRoundBox, bonusLastRoundBoxBody, bonusLastRoundBoxHeader, bonusLastRoundBoxHeaderAmount, bonusLastRoundBoxHeaderDate, bonusLastRoundContainer, sectionHeadingLight, sectionHeadingSecondary } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var NetworkBonusLastRound = function NetworkBonusLastRound(props) {
  var _currentBonus$package, _currentBonus$network;
  var _props$currentBonus = props.currentBonus,
    currentBonus = _props$currentBonus === void 0 ? {} : _props$currentBonus,
    label = props.label,
    _props$isCurrentRound = props.isCurrentRound,
    isCurrentRound = _props$isCurrentRound === void 0 ? false : _props$isCurrentRound,
    detailsRootPath = props.detailsRootPath;
  var theme = useTheme();
  var getTargetDateTo = function getTargetDateTo() {
    if (!isCurrentRound) return moment(currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.targetDateTo).format('DD/MM/YYYY');
    var currentBonusTargetDateTo = new Date(currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.targetDateTo);
    currentBonusTargetDateTo.setDate(currentBonusTargetDateTo.getDate() + (7 - currentBonusTargetDateTo.getDay() + 1));
    return moment(currentBonusTargetDateTo).format('DD/MM/YYYY');
  };
  return currentBonus !== null && currentBonus !== void 0 && currentBonus.id ? ___EmotionJSX("div", {
    className: bonusLastRoundContainer
  }, ___EmotionJSX("h3", {
    className: sectionHeadingSecondary
  }, label), ___EmotionJSX("div", {
    className: bonusLastRoundBox(theme)
  }, ___EmotionJSX("div", {
    className: bonusLastRoundBoxHeader(theme)
  }, ___EmotionJSX("p", {
    className: bonusLastRoundBoxHeaderDate
  }, getTargetDateTo()), ___EmotionJSX("div", {
    className: bonusLastRoundBoxHeaderAmount
  }, currentBonus !== null && currentBonus !== void 0 && (_currentBonus$package = currentBonus.packageInfoForRound) !== null && _currentBonus$package !== void 0 && _currentBonus$package.hasNetworkBonus ? ___EmotionJSX(React.Fragment, null, ___EmotionJSX("h3", {
    className: sectionHeadingLight
  }, "Bonuses earned"), ___EmotionJSX(ValuePercent, {
    value: currentBonus === null || currentBonus === void 0 ? void 0 : (_currentBonus$network = currentBonus.networkBonus) === null || _currentBonus$network === void 0 ? void 0 : _currentBonus$network.amount
  })) : ___EmotionJSX("h3", {
    className: sectionHeadingLight
  }, "No bonus for this round"))), ___EmotionJSX("div", {
    className: bonusLastRoundBoxBody
  }, ___EmotionJSX(NetworkBonusBvPointsTable, {
    content: _objectSpread(_objectSpread({}, currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.networkBonus), {}, {
      id: currentBonus === null || currentBonus === void 0 ? void 0 : currentBonus.id
    }),
    isCurrentRound: isCurrentRound,
    detailsRootPath: detailsRootPath
  })))) : null;
};
NetworkBonusLastRound.propTypes = {
  currentBonus: PropTypes.object,
  label: PropTypes.string,
  isCurrentRound: PropTypes.bool,
  detailsRootPath: PropTypes.string
};
export default NetworkBonusLastRound;