import { css } from 'emotion';
export var networkTreeContainer = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});
export var networkTreeRoot = css({
  marginBottom: '32px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
});
export var networkTreeFirstLevel = css({
  marginBottom: '32px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
});
export var networkTreeSecondLevel = css({
  marginBottom: '32px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  '@media (min-width: 991px) and (max-width: 1080px)': {
    display: 'none'
  },
  '@media (max-width: 767px)': {
    display: 'none'
  }
});
export var networkTreePlaceholder = css({
  flex: '1 0 218px',
  '@media (min-width: 1080px) and (max-width: 1199px)': {
    flexBasis: '190px'
  },
  '@media (min-width: 991px) and (max-width: 1080px)': {
    display: 'none'
  },
  '@media (max-width: 991px)': {
    flexBasis: '190px'
  },
  '@media (max-width: 767px)': {
    display: 'none'
  }
});