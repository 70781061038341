import { css } from 'emotion';
export var navBarContainer = function navBarContainer(theme) {
  return css({
    backgroundColor: theme.white,
    color: theme.textLightPrimary,
    position: 'sticky',
    zIndex: 900,
    padding: '16px 40px',
    width: '100%',
    top: 0,
    borderBottom: "1px solid ".concat(theme.borderLight)
  });
};
export var appAvatarIcon = css({
  height: 'auto',
  maxHeight: '100%',
  width: 120,
  margin: '0 5px 0 0'
});
export var rightSideContainer = css({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  '& > *': {
    marginRight: 5,
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0
    }
  }
});
export var navLink = function navLink(theme) {
  return css({
    color: theme.whiteGray,
    border: 'none',
    marginRight: 50,
    textTransform: 'uppercase',
    '&.active': {
      borderBottom: "1px solid ".concat(theme.whiteGray),
      fontWeight: 'bold'
    }
  });
};