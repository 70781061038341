import _isNil from "lodash/isNil";
import "core-js/modules/es.array.map.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Paper, Row, Skeleton } from '../../UI';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ListPaymentMethods = function ListPaymentMethods(props) {
  var data = props.data,
    className = props.className,
    header = props.header;
  return ___EmotionJSX(Paper, {
    className: className,
    header: header
  }, data === null || data === void 0 ? void 0 : data.map(function (el) {
    return !_isNil(el) ? ___EmotionJSX(Row, {
      gap: 12,
      key: el.id
    }, ___EmotionJSX(Col, null, ___EmotionJSX("h4", null, el.displayName))) : ___EmotionJSX(Skeleton, {
      width: 150,
      height: 20
    });
  }));
};
ListPaymentMethods.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  header: PropTypes.string
};
export default ListPaymentMethods;