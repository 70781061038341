import { css } from 'emotion';
export var walletContainer = function walletContainer(theme) {
  return {
    backgroundColor: theme.blackLight,
    boxShadow: '0 1px 2px rgba(0,0,0,0.3)',
    minHeight: 161,
    padding: '24px 32px 12px 32px'
  };
};
export var walletIcon = function walletIcon(theme) {
  return css({
    color: theme.error,
    fontSize: 30,
    marginBottom: 12
  });
};
export var balanceContainer = function balanceContainer(theme) {
  return css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > h4': {
      color: theme.textDarkPrimary,
      marginBottom: 12
    }
  });
};
export var headerWallet = function headerWallet(theme) {
  return css({
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      '& > h3': {
        color: theme.white,
        marginBottom: 12
      }
    }
  });
};