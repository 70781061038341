import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Paper, Row, Col, Skeleton } from '../../UI';
import { NoResults } from '../../UI/Table/NoResults';
import { statsContainerYellow, statsContainerWhite } from './styles';
import TooltipDailyGoals from '../TooltipDailyGoals';
import { jsx as ___EmotionJSX } from "@emotion/react";
var taskRow = ['Pending', 'Approved', 'Declined', 'Total'];
var statsRow = taskRow === null || taskRow === void 0 ? void 0 : taskRow.map(function (el, ind) {
  return ___EmotionJSX(Col, {
    sm: 2,
    key: ind,
    dFlex: true,
    justify: "center"
  }, ___EmotionJSX("h4", null, el));
});
var taskLoader = Array(4).fill().map(function (el, ind) {
  return ___EmotionJSX(Col, {
    sm: 2,
    key: ind,
    dFlex: true,
    justify: "center"
  }, ___EmotionJSX(Skeleton, {
    width: "60%",
    height: 25
  }));
});
var statsInfo = function statsInfo(item) {
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Col, {
    sm: 2,
    dFlex: true,
    justify: "center"
  }, item.dailyPending, " (", item === null || item === void 0 ? void 0 : item.allPending, ")"), ___EmotionJSX(Col, {
    sm: 2,
    dFlex: true,
    justify: "center"
  }, item.dailyApproved, " (", item === null || item === void 0 ? void 0 : item.allApproved, ")"), ___EmotionJSX(Col, {
    sm: 2,
    dFlex: true,
    justify: "center"
  }, item.dailyRejected, " (", item === null || item === void 0 ? void 0 : item.allRejected, ")"), ___EmotionJSX(Col, {
    sm: 2,
    dFlex: true,
    justify: "center"
  }, item.dailyTotal, " (", item === null || item === void 0 ? void 0 : item.allTotal, ")"));
};
var StatsTable = function StatsTable(props) {
  var statsProfiles = props.statsProfiles;
  var theme = useTheme();
  return ___EmotionJSX(Paper, null, ___EmotionJSX(Row, {
    className: statsContainerYellow(theme)
  }, ___EmotionJSX(Col, {
    sm: 2
  }, ___EmotionJSX("h4", null, "Technician")), statsRow, ___EmotionJSX(Col, {
    sm: 2,
    dFlex: true,
    justify: "flex-start"
  }, ___EmotionJSX("h4", null, "Unfulfilled Daily Goals"))), statsProfiles ? statsProfiles !== null && statsProfiles !== void 0 && statsProfiles.length ? statsProfiles === null || statsProfiles === void 0 ? void 0 : statsProfiles.map(function (el) {
    return ___EmotionJSX(Row, {
      key: el.profileId,
      className: statsContainerWhite
    }, ___EmotionJSX(Col, {
      sm: 2
    }, ___EmotionJSX("h4", null, el.userName)), statsInfo(el), ___EmotionJSX(Col, {
      sm: 2,
      dFlex: true,
      justify: "center"
    }, ___EmotionJSX(TooltipDailyGoals, {
      position: "left",
      data: el.missedDailyGoalDates
    })));
  }) : ___EmotionJSX(Row, {
    className: statsContainerWhite
  }, ___EmotionJSX(Col, {
    sm: 12
  }, ___EmotionJSX(NoResults, {
    emptyMessage: "There are no stats for this period!"
  }))) : ___EmotionJSX(React.Fragment, null, Array(10).fill().map(function (el, ind) {
    return ___EmotionJSX(Row, {
      key: ind,
      className: statsContainerWhite
    }, ___EmotionJSX(Col, {
      sm: 2
    }, ___EmotionJSX(Skeleton, {
      width: "90%",
      height: 25
    })), taskLoader, ___EmotionJSX(Col, {
      sm: 2
    }, ___EmotionJSX(Skeleton, {
      width: "100%",
      height: 25
    })));
  })));
};
StatsTable.propTypes = {
  statsProfiles: PropTypes.array
};
export default StatsTable;