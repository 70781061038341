import { css } from 'emotion';
export var statsMissedDailyGoals = function statsMissedDailyGoals(theme) {
  return css({
    padding: '6px 12px',
    width: 250,
    color: theme.error
  });
};
export var titleOutstandingGoals = css({
  margin: '12px 0'
});