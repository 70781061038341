function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import _isPlainObject from "lodash/isPlainObject";
import _isEmpty from "lodash/isEmpty";
import _isObject from "lodash/isObject";
import _isUndefined from "lodash/isUndefined";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.every.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.define-properties.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var getNestedProperty = function getNestedProperty(object, properties) {
  return properties === null || properties === void 0 ? void 0 : properties.reduce(function (xs, x) {
    return xs && xs[x];
  }, object);
};
export var setNestedValue = function setNestedValue(object, properties, value) {
  var newObj = _objectSpread({}, object);
  if (properties.length === 1) {
    newObj[properties.pop()] = value;
    return newObj;
  }
  var currentProperty = properties.shift();
  if (_isUndefined(object[currentProperty]) || !_isObject(object[currentProperty])) newObj[currentProperty] = {};
  newObj[currentProperty] = setNestedValue(newObj[currentProperty], properties, value);
  return newObj;
};
export var deleteNestedValue = function deleteNestedValue(obj, properties) {
  var newObj = _objectSpread({}, obj);
  if (properties.length === 1) {
    delete newObj[properties.pop()];
    return newObj;
  }
  var curProperty = properties.shift();
  newObj[curProperty] = deleteNestedValue(newObj[curProperty], properties);
  if (_isEmpty(newObj[curProperty])) delete newObj[curProperty];
  return newObj;
};
export var flatObjectDeep = function flatObjectDeep(obj, deepLevel) {
  if (Object.keys(obj).every(function (el) {
    return !_isPlainObject(obj[el]);
  })) return obj;
  var flat = function flat(res, key, val, pre, depth) {
    var prefix = [pre, key].filter(Boolean).join('.');
    return _isPlainObject(val) && depth !== 1 ? Object.keys(val).reduce(function (prev, curr) {
      return flat(prev, curr, val[curr], prefix, depth - 1);
    }, res) : Object.assign(res, _defineProperty({}, prefix, val));
  };
  return Object.keys(obj).reduce(function (prev, curr) {
    return flat(prev, curr, obj[curr], '', deepLevel);
  }, {});
};