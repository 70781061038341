import _isNil from "lodash/isNil";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.array.fill.js";
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Image, Skeleton } from '../../UI';
import defaultAvatar from '../../../assets/images/default-avatar.png';
import { container, imageContainer, image, dateContainer, commentContainer, nameContainer, textContainer, marginBottom4 } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var Comment = function Comment() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$comments = _ref.comments,
    comments = _ref$comments === void 0 ? [] : _ref$comments;
  return comments.map(function (el, ind, arr) {
    var authorName = el.authorName,
      author = el.author,
      text = el.text,
      comment = el.comment,
      _el$avatar = el.avatar,
      avatar = _el$avatar === void 0 ? defaultAvatar : _el$avatar,
      _el$date = el.date,
      date = _el$date === void 0 ? new Date() : _el$date;
    return _isNil(comments) ? Array(3).fill().map(function (el, ind, arr) {
      return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
        key: "comment-".concat(ind),
        className: container
      }, ___EmotionJSX("div", {
        className: imageContainer
      }, ___EmotionJSX(Skeleton, {
        width: 50,
        height: 50,
        circle: true,
        className: image
      }), ___EmotionJSX(Skeleton, {
        width: 70,
        height: 30
      })), ___EmotionJSX("div", {
        className: commentContainer
      }, ___EmotionJSX(Skeleton, {
        width: 250,
        height: 25,
        className: marginBottom4
      }), ___EmotionJSX(Skeleton, {
        width: "100%",
        height: 15
      }))), arr.length - 1 !== ind && ___EmotionJSX("hr", null));
    }) : ___EmotionJSX("div", {
      key: "comment-".concat(ind)
    }, ___EmotionJSX("div", {
      className: container
    }, ___EmotionJSX("div", {
      className: imageContainer
    }, ___EmotionJSX(Image, {
      src: avatar,
      width: 55,
      height: 55,
      className: image
    }), ___EmotionJSX("p", {
      className: dateContainer
    }, moment(date).format('DD/MM/YYYY'), ___EmotionJSX("br", null), moment(date).format('HH:mm:ss'))), ___EmotionJSX("div", {
      className: commentContainer
    }, (authorName || author) && ___EmotionJSX("p", {
      className: nameContainer
    }, authorName || author), (text || comment) && ___EmotionJSX("p", {
      className: textContainer
    }, text || comment))), arr.length - 1 !== ind && ___EmotionJSX("hr", null));
  });
};
Comment.propTypes = {
  comments: PropTypes.array
};
export default Comment;