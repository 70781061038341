import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { Table } from '../../../../components/UI';
import TableSearchBarPackages from '../../../../components/Packages/TableSearchBarPackages';
import { getAllPackages } from '../../../../services/packages';
import { packageListColumns } from './config';
import { navLink, container } from '../styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var PackagesList = function PackagesList() {
  var theme = useTheme();
  var isSupport = hasRole(USER_ROLES.SUPPORT);
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX("div", {
    className: container
  }, ___EmotionJSX("h1", null, "Package List"), !isSupport && ___EmotionJSX(Link, {
    to: "/packages/add-package",
    className: navLink(theme)
  }, "Create Package")), ___EmotionJSX(Table, {
    hasPagination: false,
    getDataKey: "catalogueItems",
    getDataMethod: getAllPackages,
    columns: packageListColumns,
    emptyMessage: "There are no Packages",
    filterBar: TableSearchBarPackages,
    pageSize: 10
  }));
};
export default PackagesList;