import { css } from 'emotion';
export var labelContainer = css({
  fontWeight: 700,
  padding: '12px 6px'
});
export var valueContainer = css({
  padding: 12,
  fontSize: 14
});
export var paymentDataContainer = function paymentDataContainer(theme) {
  return css({
    borderTop: "1px solid ".concat(theme.borderLight)
  });
};