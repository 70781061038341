import { css } from 'emotion';
export var container = css({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 16,
  wordBreak: 'break-word',
  '&:last-child': {
    marginBottom: 0
  }
});
export var imageContainer = css({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: 12
});
export var image = css({
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 4
});
export var dateContainer = css({
  fontSize: 10,
  fontWeight: 600
});
export var commentContainer = css({
  display: 'flex',
  flexDirection: 'column'
});
export var nameContainer = css({
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 6
});
export var textContainer = css({
  fontSize: 13,
  fontWeight: 400,
  marginBottom: 6
});
export var marginBottom4 = css({
  marginBottom: 4
});