import { leadershipRunType } from '../../../enums/bonuses';
export var optionsLeadershipAccount = [{
  label: 'Nickname',
  value: 'nickname'
}, {
  label: 'Account ID',
  value: 'accountId'
}, {
  label: 'User ID',
  value: 'userId'
}, {
  label: 'Email',
  value: 'profileEmail'
}];
export var optionsLeadershipRunType = [{
  label: 'Regular',
  value: leadershipRunType.Regular
}, {
  label: 'Preliminary',
  value: leadershipRunType.Preliminary
}];